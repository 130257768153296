import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Fuse, { IFuseOptions } from "fuse.js";
import { useExpansionList } from "hooks";
import { QueryKeys, queryClient } from "queryClient";
import { Expansion, Product, ProductName, ProductShortened } from "types";
import {
  getProductListByIDs,
  getProductListByIDsShortened,
  getProductNamesList,
  getProductNamesListShortened,
} from "modules/products/api";
import { useAuthContext } from "./auth-context";
import utils from "utils";

interface SearchContextType {
  isLoadingProducts: boolean;
  isLoadingExpansions: boolean;
  searchProducts: (searchInput: string) => Promise<Product[]>;
  searchProductsShortened: (searchInput: string) => Promise<ProductShortened[]>;
  searchExpansions: (searchInput: string) => Promise<Expansion[]>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

type SearchProviderProps = {
  children: ReactNode;
};

export const SearchContextProvider: FC<SearchProviderProps> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const authContext = useAuthContext();

  const [productsSearchInstance, setProductsSearchInstance] =
    useState<Fuse<ProductName> | null>(null);
  const [productsSearchInstanceLoading, setProductsSearchInstanceLoading] =
    useState(true);
  const [expansionsSearchInstance, setExpansionsSearchInstance] =
    useState<Fuse<Expansion> | null>(null);
  const [expansionsSearchInstanceLoading, setExpansionsSearchInstanceLoading] =
    useState(true);

  const { expansionList, isLoading: isLoadingExpansions } =
    useExpansionList(false);

  const { data: productNameList, isLoading: isLoadingProducts } = useQuery({
    queryKey: authContext.isAuth
      ? [
          QueryKeys.ProductNamesShortened,
          { isLoading: authContext.isLoading, isAuth: authContext.isAuth },
        ]
      : [QueryKeys.ProductNames],
    queryFn: async () => {
      if (authContext.isLoading) return [];

      const productNames = authContext.isAuth
        ? await getProductNamesList(true)
        : await getProductNamesListShortened(true);
      return productNames;
    },
    staleTime: Infinity,
    enabled: !authContext.isLoading,
  });

  const definedProductNameList = useMemo(
    () => utils.graphql.getDefinedItems(productNameList),
    [productNameList],
  );

  useEffect(() => {
    if (definedProductNameList.length === 0) return;

    const fuseOptions = {
      keys: ["d", "e", "a"],
      findAllMatches: true,
      threshold: 0.3,
    };

    const fuseInstance = new Fuse(definedProductNameList, fuseOptions);
    setProductsSearchInstance(fuseInstance);
    setProductsSearchInstanceLoading(false);
  }, [definedProductNameList]);

  useEffect(() => {
    if (expansionList.length === 0) return;

    const fuseOptions: IFuseOptions<Expansion> = {
      keys: [
        "abbreviation",
        "expansionAlias",
        "expansionDeName",
        "expansionEnName",
        "idExpansion",
      ],
      threshold: 0.1,
    };

    const fuseInstance = new Fuse<Expansion>(expansionList, fuseOptions);

    setExpansionsSearchInstance(fuseInstance);
    setExpansionsSearchInstanceLoading(false);
  }, [expansionList]);

  useEffect(() => {
    if (!productsSearchInstance) return;
    const searchedText = searchParams.get("searchProduct");

    if (!searchedText || searchedText === "") return;

    queryClient.invalidateQueries({
      queryKey: [QueryKeys.Products, "search"],
    });
  }, [searchParams, productsSearchInstance]);

  const searchProducts = async (searchInput: string) => {
    if (!productsSearchInstance || searchInput.length < 3) {
      return [];
    }

    const results = productsSearchInstance.search(searchInput);

    const searchResultIDs = results.map((result) => result.item.i);

    const productList = await getProductListByIDs(searchResultIDs);

    return productList;
  };

  const searchProductsShortened = async (searchInput: string) => {
    if (!productsSearchInstance || searchInput.length < 3) {
      return [];
    }

    const results = productsSearchInstance.search(searchInput);

    const searchResultIDs = results.map((result) => result.item.i);

    const productList = await getProductListByIDsShortened(searchResultIDs);

    return productList;
  };

  const searchExpansions = async (searchInput: string) => {
    if (!expansionsSearchInstance || searchInput.length < 3) {
      return [];
    }

    const expansionResults = expansionsSearchInstance.search(searchInput);

    const expansions = expansionResults.map((result) => result.item);

    return expansions;
  };

  const value = {
    isLoadingProducts: isLoadingProducts || productsSearchInstanceLoading,
    isLoadingExpansions: isLoadingExpansions || expansionsSearchInstanceLoading,
    searchProducts,
    searchProductsShortened,
    searchExpansions,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
