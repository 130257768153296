import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  expansionName: {
    color: theme.palette.primary.main + " !important",
    fontWeight: "bold",
    fontSize: 16,
  },
}));

export default useStyles;
