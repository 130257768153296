import React, { useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateUserInput, SelectOption } from "types";
import {
  AvatarDropZone,
  CustomButton,
  CustomSelect,
  CustomSelectHandles,
  CustomSwitch,
  Phone,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { SalutationOption } from "modules/shared/options/SalutationOption";
import { TableHeaderColorOption } from "modules/shared/options/TableHeaderColorOption";
import { TableSpacingOption } from "modules/shared/options/TableSpacingOption";
import { TableThemeOption } from "modules/shared/options/TableThemeOption";
import { Routes } from "routes";
import { useUserSettingsForm } from "../useUserSettingsForm";
import { normalizePhone } from "utils/phone";
import utils from "utils";
import useStyles from "./styles";

type UserSettingsFormProps = {
  submitUserSettings: () => void;
  submitLoading: boolean;
  user: CreateUserInput;
};

export type UserSettingsFormHandles = {
  validateUserSettingsForm(): Promise<CreateUserInput | null>;
};

const UserSettingsFormComponent: React.ForwardRefRenderFunction<
  UserSettingsFormHandles,
  UserSettingsFormProps
> = ({ submitUserSettings, submitLoading, user }, userFormRef) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    salutation,
    setSalutation,
    avatar,
    setAvatar,
    phone,
    setPhone,
    phonePrefix,
    setPhonePrefix,
    fax,
    setFax,
    faxPrefix,
    setFaxPrefix,
    tableTheme,
    setTableTheme,
    tableHeaderColor,
    setTableHeaderColor,
    tableSpacing,
    setTableSpacing,
    tableSticky,
    setTableSticky,
    unitType,
    setUnitType,
    uploading,
    setUploading,
    uploadProgress,
    setUploadProgress,
  } = useUserSettingsForm();

  const salutationInputRef = useRef<CustomSelectHandles>(null);
  const firstNameInputRef = useRef<TextInputFieldHandles>(null);
  const lastNameInputRef = useRef<TextInputFieldHandles>(null);

  const tableThemeInputRef = useRef<CustomSelectHandles>(null);
  const tableHeaderColorInputRef = useRef<CustomSelectHandles>(null);
  const tableSpacingInputRef = useRef<CustomSelectHandles>(null);

  useImperativeHandle(userFormRef, () => ({
    validateUserSettingsForm: async () => {
      if (salutation === null) {
        enqueueSnackbar("Bitte wähle die Anrede aus!");
        salutationInputRef.current?.highlight();
        return null;
      }

      if (firstName === "") {
        enqueueSnackbar("Bitte gib deinen Vornamen ein!");
        firstNameInputRef.current?.highlight();
        return null;
      }

      if (lastName === "") {
        enqueueSnackbar(
          "Bitte gib den Schwellwert für das Überschreiten der Gesamt-Liefermenge einer Lieferung bei Eingabe des Giesslaufs ein!",
        );
        lastNameInputRef.current?.highlight();
        return null;
      }

      if (tableTheme === null) {
        enqueueSnackbar("Bitte wähle das Tabellenthema aus!");
        tableThemeInputRef.current?.highlight();
        return null;
      }

      if (tableHeaderColor === null) {
        enqueueSnackbar("Bitte wähle die Tabellenkopffarbe aus!");
        tableHeaderColorInputRef.current?.highlight();
        return null;
      }

      if (tableSpacing === null) {
        enqueueSnackbar("Bitte wähle den Tabellenabstand aus!");
        tableSpacingInputRef.current?.highlight();
        return null;
      }

      const uploadedBild = await utils.images.uploadS3Resource(
        avatar,
        "user",
        setUploading,
        setUploadProgress,
      );

      const userSettingsFormInput: CreateUserInput = {
        isUserActive: true,
        email: user.email,
        userSUB: user.userSUB,
        username: user.username,
        firstName: firstName,
        lastName: lastName,
        salutation: salutation,
        avatar: uploadedBild,
        phone: normalizePhone(phonePrefix, phone),
        fax: normalizePhone(faxPrefix, fax),
        userSettings: {
          tableTheme,
          tableHeaderColor,
          tableSpacing,
          tableSticky,
          unitType: unitType.value,
        },
        lastActive: user.lastActive,
      };

      return userSettingsFormInput;
    },
  }));

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <SalutationOption
            salutation={salutation}
            setSalutation={setSalutation}
            salutationInputRef={salutationInputRef}
            className={classes.customSelect}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <TextInputField
            className={classes.formField}
            id="Vorname"
            label="Vorname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            ref={firstNameInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
        <Grid item md={4}>
          <TextInputField
            className={classes.formField}
            label="Nachname"
            id="Nachname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            ref={lastNameInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <Phone
            label="Telefon"
            phoneOnChange={(value) => setPhone(value)}
            phoneValue={phone}
            prefixOnChange={(value) => value && setPhonePrefix(value)}
            prefixValue={phonePrefix}
            className={classes.phoneNumber}
          />
        </Grid>
        <Grid item md={4}>
          <Phone
            label="Fax"
            phoneOnChange={(value) => setFax(value)}
            phoneValue={fax}
            prefixOnChange={(value) => value && setFaxPrefix(value)}
            prefixValue={faxPrefix}
            className={classes.phoneNumber}
          />
        </Grid>
      </Grid>
      <Typography variant="h3">Benutzerbild</Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <AvatarDropZone
            dragActiveText="Bild hier ablegen..."
            dragInactiveText="Bild hier ablegen oder klicken..."
            uploading={uploading}
            avatar={avatar}
            setAvatar={setAvatar}
            maxSize={10000000}
            uploadProgress={uploadProgress}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <CustomSelect<SelectOption>
            value={unitType}
            label="Bevorzugte Einheit"
            placeholder="Bitte auswählen"
            options={utils.constants.WEIGHT_OPTIONS}
            onChange={(value) => value && setUnitType(value)}
            className={classes.customSelect}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <TableThemeOption
            tableTheme={tableTheme}
            setTableTheme={setTableTheme}
            tableThemeInputRef={tableThemeInputRef}
            className={classes.customSelect}
          />
        </Grid>
        <Grid item md={6}>
          <TableHeaderColorOption
            tableHeaderColor={tableHeaderColor}
            setTableHeaderColor={setTableHeaderColor}
            tableHeaderColorInputRef={tableHeaderColorInputRef}
            className={classes.customSelect}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <TableSpacingOption
            tableSpacing={tableSpacing}
            setTableSpacing={setTableSpacing}
            tableSpacingInputRef={tableSpacingInputRef}
            className={classes.customSelect}
          />
        </Grid>
        <Grid item md={6}>
          <CustomSwitch
            name="tableSticky"
            switchLabel="Tabellenkopf fixieren"
            checkedValue={tableSticky}
            onChange={(value) => setTableSticky(value.target.checked)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.userSettingsButtons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text="Einstellungen speichern"
            onClick={() => submitUserSettings()}
            loading={submitLoading}
            style="filled"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            onClick={() => {
              navigate(Routes.start.path);
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const UserSettingsForm = React.memo(
  React.forwardRef(UserSettingsFormComponent),
);
