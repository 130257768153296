import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { MinPrice } from "types";
import { DeleteDialog } from "components";

type MinPriceDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  minPrice?: MinPrice;
  setMinPrices?: (value: React.SetStateAction<MinPrice[]>) => void;
};

export const MinPriceDeleteDialog: React.FC<MinPriceDeleteDialogProps> = ({
  minPrice,
  dialogOpen,
  setDialogOpen,
  setMinPrices,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || minPrice === undefined) {
    return null;
  }

  const deleteMinPriceRequest = async () => {
    if (!setMinPrices) {
      enqueueSnackbar(
        "Beim Löschen des Mindestpreises ist ein Fehler aufgetreten!",
      );
      return;
    }

    setDeleteLoading(true);

    setMinPrices((prev) =>
      prev.filter((minPriceItem) => minPriceItem.id !== minPrice.id),
    );

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteMinPriceRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Mindestpreis Löschen"
      itemName={`Rarity "${minPrice.rarity ?? "Alle"}", Foil "${minPrice.isFoil === true ? "Ja" : minPrice.isFoil === false ? "Nein" : "Alle"}", Mindestpreis Laden "${minPrice.minPriceStore.toLocaleString(
        "de-DE",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}" und Mindestpreis Online "${minPrice.minPriceOnline.toLocaleString(
        "de-DE",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}"`}
      itemDeleteDescription="den Mindestpreis mit"
      confirmRequired={false}
    />
  );
};
