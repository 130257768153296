import React from "react";
import { client } from "queryClient";
import { AverageCalculationMethod, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface AverageCalculationMethodOptionProps
  extends Partial<CustomSelectOptionProps<AverageCalculationMethod>> {
  averageCalculationMethod: AverageCalculationMethod | null;
  setAverageCalculationMethod: (
    value: React.SetStateAction<AverageCalculationMethod | null>,
  ) => void;
  averageCalculationMethodInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getAverageCalculationMethodName = (
  value: AverageCalculationMethod,
) => {
  switch (value) {
    case "TrimmedMean":
      return "Getrimmter Mittelwert";
    case "Median":
      return "Median";
    case "IQR":
      return "Interquartilsabstand";
    case "ZScore":
      return "Z-Score";
  }
};

export const getAverageCalculationMethodEnumByName = (
  value: string,
): AverageCalculationMethod => {
  switch (value) {
    case "Getrimmter Mittelwert":
      return "TrimmedMean";
    case "Median":
      return "Median";
    case "Interquartilsabstand":
      return "IQR";
    case "Z-Score":
      return "ZScore";
    default:
      return "TrimmedMean";
  }
};

export const getAverageCalculationMethodOptions = (): SelectOption[] => {
  const values = client.enums.AverageCalculationMethod?.values() ?? [];

  return values.map((syncAverageCalculationMethod) => ({
    value: syncAverageCalculationMethod,
    label: getAverageCalculationMethodName(syncAverageCalculationMethod),
  }));
};

export const AverageCalculationMethodOption: React.FC<
  AverageCalculationMethodOptionProps
> = ({
  averageCalculationMethod,
  setAverageCalculationMethod,
  averageCalculationMethodInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Durchschnitts-Berechnungsmethode"
    options={getAverageCalculationMethodOptions()}
    onChange={(averageCalculationMethodOption) =>
      setAverageCalculationMethod(
        averageCalculationMethodOption.value as AverageCalculationMethod,
      )
    }
    value={
      averageCalculationMethod
        ? {
            value: averageCalculationMethod,
            label: getAverageCalculationMethodName(averageCalculationMethod),
          }
        : null
    }
    placeholder="Durchschnitts-Berechnungsmethode auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={averageCalculationMethodInputRef}
  />
);
