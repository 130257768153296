import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { ExcludedSeller } from "types";
import { TextInputField, TextInputFieldHandles } from "core";
import { useExcludedSellerForm } from "../useExcludedSellerForm";

type ExcludedSellerFormProps = {
  excludedSellerList: ExcludedSeller[];
  excludedSeller: ExcludedSeller | undefined;
};

export type ExcludedSellerFormHandles = {
  validateExcludedSellerForm(): ExcludedSeller | null;
};

const ExcludedSellerFormComponent: React.ForwardRefRenderFunction<
  ExcludedSellerFormHandles,
  ExcludedSellerFormProps
> = ({ excludedSellerList, excludedSeller }, excludedSellerFormRef) => {
  const { enqueueSnackbar } = useSnackbar();

  const { username, setUsername, comment, setComment } =
    useExcludedSellerForm(excludedSeller);

  const usernameInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(excludedSellerFormRef, () => ({
    validateExcludedSellerForm: () => {
      if (username === "") {
        enqueueSnackbar("Bitte gib den Benutzernamen des Händlers ein!");
        usernameInputRef.current?.highlight(false);
        return null;
      }

      const existingExcludedSeller = excludedSellerList.find(
        (excludedSellerItem) =>
          excludedSellerItem.username !== excludedSeller?.username &&
          excludedSellerItem.username === username,
      );

      if (existingExcludedSeller) {
        enqueueSnackbar(
          "Es existiert bereits ein Händler mit diesem Benutzernamen!",
        );
        return null;
      }

      const excludedSellerFormInput: ExcludedSeller = {
        username,
        comment,
      };

      return excludedSellerFormInput;
    },
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextInputField
          label="Benutzername des Händlers"
          value={username}
          onChange={({ target: { value } }) => setUsername(value)}
          validate={(value) => value.trim() !== ""}
          ref={usernameInputRef}
          required={true}
        />
      </Grid>

      <Grid item>
        <TextInputField
          label="Kommentar"
          value={comment}
          onChange={({ target: { value } }) => setComment(value)}
        />
      </Grid>
    </Grid>
  );
};

export const ExcludedSellerForm = React.forwardRef(ExcludedSellerFormComponent);
