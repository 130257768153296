import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useSearchContext } from "context";
import { usePersistedState } from "hooks";
import { useDebounce } from "react-use";
import { Expansion, Product } from "types";
import { SearchInputField } from "core";
import { SearchFilter } from "modules/search/SearchFilter";
import { SearchResults } from "modules/search/SearchResults";
import { searchRequest } from "modules/search/api";

export const HeaderSearchBar: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    searchProducts,
    isLoadingProducts,
    isLoadingExpansions,
    searchExpansions,
  } = useSearchContext();

  const [searchInput, setSearchInput] = useState<string>("");
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [searchResultsOpen, setSearchResultsOpen] = useState<boolean>(false);
  const [searchFilterOpen, setSearchFilterOpen] = useState<boolean>(false);

  const [expansionList, setExpansionList] = useState<Expansion[]>([]);
  const [productList, setProductList] = useState<Product[]>([]);

  const [includeExpansions, setIncludeExpansions] = usePersistedState<boolean>(
    "headersearch_includeExpansions",
    true,
    "create",
  );
  const [includeProducts, setIncludeProducts] = usePersistedState<boolean>(
    "headersearch_includeProducts",
    true,
    "create",
  );

  const searchHandler = async () => {
    if (searchInput.length < 3) {
      return;
    }

    if (isLoadingProducts || isLoadingExpansions) {
      enqueueSnackbar(
        "Die Kartennamen werden für die Suche indexiert. Bitte warte einen Moment.",
      );
      setSearchInput("");
      return;
    }

    setIsLoadingSearch(true);

    const { expansions, products } = await searchRequest(
      searchInput,
      includeExpansions,
      includeProducts,
      searchProducts,
      searchExpansions,
    );
    setExpansionList(expansions);
    setProductList(products);

    setIsLoadingSearch(false);
    setSearchResultsOpen(true);
  };

  const [,] = useDebounce(() => searchHandler(), 999, [searchInput]);

  return (
    <>
      <SearchInputField
        value={searchInput}
        onChange={(value) => {
          setSearchInput(value);
        }}
        isLoadingSearch={isLoadingSearch || isLoadingProducts}
        onClickSettings={() => setSearchFilterOpen(true)}
        placeholder={isLoadingProducts ? "Wird geladen..." : "Suche..."}
      />
      <SearchFilter
        setIsOpen={setSearchFilterOpen}
        isOpen={searchFilterOpen}
        includeExpansions={includeExpansions}
        setIncludeExpansions={setIncludeExpansions}
        includeProducts={includeProducts}
        setIncludeProducts={setIncludeProducts}
      />
      <SearchResults
        isOpen={searchResultsOpen}
        setIsOpen={setSearchResultsOpen}
        expansionList={expansionList}
        productList={productList}
        searchInput={searchInput}
      />
    </>
  );
};
