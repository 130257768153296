import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useExpansionList } from "hooks";
import {
  LanguageFilterType,
  Product,
  ProductColumnToUpdate,
  SearchTextParamName,
  ValueSubmitTrigger,
  getNextProductColumn,
  getPreviousProductColumn,
} from "types";
import {
  CustomReactTableHandles,
  CustomTable,
  Loading,
  SortableHeader,
} from "core";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { WebsiteColumnFormatter } from "modules/shared/columnformatters/WebsiteColumnFormatter";
import { FolderChangeColumn } from "../ColumnFormatters/FolderChangeColumn";
import { ProductNumberColumn } from "../ColumnFormatters/ProductNumberColumn";
import useStyles from "./styles";

type ProductListTableProps = {
  isLoading: boolean;
  productList: Product[];
  productTableRef?: React.RefObject<CustomReactTableHandles<Product>>;
};

export const ProductListTable: React.FC<ProductListTableProps> = ({
  isLoading,
  productList,
}) => {
  const { classes, cx } = useStyles();

  const [searchParams] = useSearchParams();

  const { expansionList } = useExpansionList(false);

  const [selectedRow, setSelectedRow] = useState<Product | null>(null);
  const [selectedColumn, setSelectedColumn] =
    useState<ProductColumnToUpdate | null>(null);

  const language = useMemo(() => {
    const languageParam = searchParams.get("language");

    if (!languageParam) {
      return LanguageFilterType.All;
    }

    return languageParam as LanguageFilterType;
  }, [searchParams]);

  const expansionID = searchParams.get("expansionID");

  const resetSelection = () => {
    setSelectedRow(null);
    setSelectedColumn(null);
  };

  const handleProductSelection = (
    column: ProductColumnToUpdate,
    rowIndex: number,
    trigger: ValueSubmitTrigger,
  ) => {
    const isFirstRow = rowIndex === 0;
    const isLastRow = rowIndex === productList.length - 1;
    const isFirstColumn = column === ProductColumnToUpdate.priceStore;
    const isLastColumn =
      language === LanguageFilterType.DE
        ? column === ProductColumnToUpdate.deTransfer
        : column === ProductColumnToUpdate.enTransfer;

    switch (trigger) {
      case ValueSubmitTrigger.Enter:
        if (isLastRow) {
          resetSelection();
        } else {
          setSelectedRow(productList[rowIndex + 1]);
        }
        break;
      case ValueSubmitTrigger.Blur:
      case ValueSubmitTrigger.Escape:
        resetSelection();
        break;
      case ValueSubmitTrigger.ArrowUp:
        if (!isFirstRow) {
          setSelectedRow(productList[rowIndex - 1]);
        }
        break;
      case ValueSubmitTrigger.ArrowDown:
        if (!isLastRow) {
          setSelectedRow(productList[rowIndex + 1]);
        }
        break;
      case ValueSubmitTrigger.ShiftTab:
      case ValueSubmitTrigger.ArrowLeft:
        if (isFirstColumn && isFirstRow) return;

        if (isFirstColumn) {
          setSelectedRow(productList[rowIndex - 1]);
        }

        setSelectedColumn(getPreviousProductColumn(column, language));

        break;
      case ValueSubmitTrigger.Tab:
      case ValueSubmitTrigger.ArrowRight:
        if (isLastColumn && isLastRow) return;

        if (isLastColumn) {
          setSelectedRow(productList[rowIndex + 1]);
        }

        setSelectedColumn(getNextProductColumn(column, language));

        break;
    }
  };

  const isProductColumnSelected = (
    product: Product,
    column: ProductColumnToUpdate,
  ) => selectedRow?.id === product.id && selectedColumn === column;

  const productColumnSelectHandler = (
    product: Product,
    column: ProductColumnToUpdate,
  ) => {
    setSelectedRow(product);
    setSelectedColumn(column);
  };

  return (
    <>
      <CustomTable
        marginBottom={0}
        uppercaseHeaders={false}
        fixedLayout={productList.length !== 0}
      >
        {language === LanguageFilterType.All ? (
          <colgroup>
            <col width="10px" />
            <col width="10px" />
            <col width="100px" />
            <col width="100px" />
            <col width="10px" />
            <col width="10px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
          </colgroup>
        ) : (
          <colgroup>
            <col width="10px" />
            <col width="10px" />
            <col width="300px" />
            <col width="10px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
            <col width="15px" />
          </colgroup>
        )}

        <thead>
          {language === LanguageFilterType.All ? (
            <tr>
              <th colSpan={5}></th>
              <th colSpan={6}>Bestand Laden</th>
              <th colSpan={2}>Bestand MKM</th>
            </tr>
          ) : (
            <tr>
              <th colSpan={4}></th>
              <th colSpan={3}>Bestand Laden</th>
              <th>MKM</th>
            </tr>
          )}
          <tr>
            <SortableHeader
              headerID="website"
              header="CM"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="rarity"
              header="R"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            {language !== LanguageFilterType.EN && (
              <SortableHeader
                headerID="productDeName"
                header="DE-Name"
                isDefaultSort={true}
                defaultSortOrder="asc"
                dataLength={productList.length}
                isLoadingDataList={isLoading}
              />
            )}
            {language !== LanguageFilterType.DE && (
              <SortableHeader
                headerID="productEnName"
                header="EN-Name"
                dataLength={productList.length}
                isLoadingDataList={isLoading}
              />
            )}
            <SortableHeader
              headerID="icon"
              header="Exp"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            {language === LanguageFilterType.All && (
              <SortableHeader
                headerID="folderChange"
                header="Ord"
                dataLength={productList.length}
                isLoadingDataList={isLoading}
              />
            )}
            <SortableHeader
              headerID="priceStore"
              header="Preis"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            {language !== LanguageFilterType.EN && (
              <>
                <SortableHeader
                  headerID="deStock"
                  header="DE"
                  dataLength={productList.length}
                  isLoadingDataList={isLoading}
                />
                <SortableHeader
                  headerID="deTransfer"
                  header="O:DE"
                  dataLength={productList.length}
                  isLoadingDataList={isLoading}
                />
              </>
            )}
            {language !== LanguageFilterType.DE && (
              <>
                <SortableHeader
                  headerID="enStock"
                  header="EN"
                  dataLength={productList.length}
                  isLoadingDataList={isLoading}
                />
                <SortableHeader
                  headerID="enTransfer"
                  header="O:EN"
                  dataLength={productList.length}
                  isLoadingDataList={isLoading}
                />
              </>
            )}

            {language !== LanguageFilterType.EN && (
              <SortableHeader
                headerID="stockCountDe"
                header="DE"
                dataLength={productList.length}
                isLoadingDataList={isLoading}
              />
            )}

            {language !== LanguageFilterType.DE && (
              <SortableHeader
                headerID="stockCountEn"
                header="EN"
                dataLength={productList.length}
                isLoadingDataList={isLoading}
              />
            )}
          </tr>
        </thead>
        <tbody>
          {productList.length === 0 ? (
            <tr>
              <td colSpan={99}>
                <div className={classes.noResults}>
                  {isLoading ? (
                    <Loading
                      size="25px"
                      description={
                        "Bitte warten. " +
                        (!expansionID || expansionID === "search"
                          ? "Suche wird durchgeführt..."
                          : "Karten der Edition werden geladen...")
                      }
                      style={{ margin: 0 }}
                    />
                  ) : expansionID === "search" &&
                    !searchParams.get(SearchTextParamName.SearchProduct) ? (
                    "Bitte geben Sie einen Suchbegriff ein!"
                  ) : expansionID ? (
                    "Keine Produkte gefunden"
                  ) : (
                    "Bitte wählen Sie eine Edition aus oder geben Sie einen Suchbegriff ein!"
                  )}
                </div>
              </td>
            </tr>
          ) : (
            productList.map((product, rowIndex) => {
              const expansion = expansionList.find(
                (expansion) => expansion.id === product.expansionID,
              );

              if (!expansion) {
                return null;
              }

              const rowClass =
                rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow;

              return (
                <tr key={product.id} className={classes.productItem}>
                  <td width="10px">
                    <WebsiteColumnFormatter product={product} />
                  </td>
                  <td width="10px">{product.rarity.charAt(0)}</td>
                  {language !== LanguageFilterType.EN && (
                    <td width="200px" className={classes.noWrap}>
                      <div className={classes.productName}>
                        {product.productAlias ?? product.productDeName}
                      </div>
                    </td>
                  )}
                  {language !== LanguageFilterType.DE && (
                    <td width="200px" className={classes.noWrap}>
                      <div className={classes.productName}>
                        {product.productEnName}
                      </div>
                    </td>
                  )}
                  <td width="20px">
                    <IconColumnFormatter expansion={expansion} />
                  </td>
                  {language === LanguageFilterType.All && (
                    <td width="20px" style={{ padding: 0 }}>
                      <FolderChangeColumn product={product} />
                    </td>
                  )}
                  <td
                    width="30px"
                    onClick={() =>
                      productColumnSelectHandler(
                        product,
                        ProductColumnToUpdate.priceStore,
                      )
                    }
                    className={cx(
                      classes.numberColumn,
                      isProductColumnSelected(
                        product,
                        ProductColumnToUpdate.priceStore,
                      )
                        ? classes.numberColumnSelected
                        : "",
                      rowClass,
                    )}
                  >
                    <ProductNumberColumn
                      product={product}
                      productField="priceStore"
                      productColumn={ProductColumnToUpdate.priceStore}
                      rowIndex={rowIndex}
                      selectedRow={selectedRow}
                      selectedColumn={selectedColumn}
                      handleProductSelection={handleProductSelection}
                      decimalScale={2}
                      numberFormatOptions={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }}
                    />
                  </td>
                  {language !== LanguageFilterType.EN && (
                    <>
                      <td
                        width="30px"
                        onClick={() =>
                          productColumnSelectHandler(
                            product,
                            ProductColumnToUpdate.deStock,
                          )
                        }
                        className={cx(
                          classes.numberColumn,
                          isProductColumnSelected(
                            product,
                            ProductColumnToUpdate.deStock,
                          )
                            ? classes.numberColumnSelected
                            : "",
                          rowClass,
                        )}
                      >
                        <ProductNumberColumn
                          product={product}
                          productField="deStock"
                          productColumn={ProductColumnToUpdate.deStock}
                          rowIndex={rowIndex}
                          selectedRow={selectedRow}
                          selectedColumn={selectedColumn}
                          handleProductSelection={handleProductSelection}
                          decimalScale={0}
                        />
                      </td>
                      <td
                        width="30px"
                        onClick={() =>
                          productColumnSelectHandler(
                            product,
                            ProductColumnToUpdate.deTransfer,
                          )
                        }
                        className={cx(
                          classes.numberColumn,
                          isProductColumnSelected(
                            product,
                            ProductColumnToUpdate.deTransfer,
                          )
                            ? classes.numberColumnSelected
                            : "",
                          rowClass,
                        )}
                      >
                        <ProductNumberColumn
                          product={product}
                          productField="deTransfer"
                          productColumn={ProductColumnToUpdate.deTransfer}
                          rowIndex={rowIndex}
                          selectedRow={selectedRow}
                          selectedColumn={selectedColumn}
                          handleProductSelection={handleProductSelection}
                          decimalScale={0}
                        />
                      </td>
                    </>
                  )}
                  {language !== LanguageFilterType.DE && (
                    <>
                      <td
                        width="30px"
                        onClick={() =>
                          productColumnSelectHandler(
                            product,
                            ProductColumnToUpdate.enStock,
                          )
                        }
                        className={cx(
                          classes.numberColumn,
                          isProductColumnSelected(
                            product,
                            ProductColumnToUpdate.enStock,
                          )
                            ? classes.numberColumnSelected
                            : "",
                          rowClass,
                        )}
                      >
                        <ProductNumberColumn
                          product={product}
                          productField="enStock"
                          productColumn={ProductColumnToUpdate.enStock}
                          rowIndex={rowIndex}
                          selectedRow={selectedRow}
                          selectedColumn={selectedColumn}
                          handleProductSelection={handleProductSelection}
                          decimalScale={0}
                        />
                      </td>
                      <td
                        width="30px"
                        onClick={() =>
                          productColumnSelectHandler(
                            product,
                            ProductColumnToUpdate.enTransfer,
                          )
                        }
                        className={cx(
                          classes.numberColumn,
                          isProductColumnSelected(
                            product,
                            ProductColumnToUpdate.enTransfer,
                          )
                            ? classes.numberColumnSelected
                            : "",
                          rowClass,
                        )}
                      >
                        <ProductNumberColumn
                          product={product}
                          productField="enTransfer"
                          productColumn={ProductColumnToUpdate.enTransfer}
                          rowIndex={rowIndex}
                          selectedRow={selectedRow}
                          selectedColumn={selectedColumn}
                          handleProductSelection={handleProductSelection}
                          decimalScale={0}
                        />
                      </td>
                    </>
                  )}
                  {language !== LanguageFilterType.EN && (
                    <td width="30px">{product.stockCountDe}</td>
                  )}
                  {language !== LanguageFilterType.DE && (
                    <td width="30px">{product.stockCountEn}</td>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTable>
    </>
  );
};
