import React, { useRef } from "react";
import { useProductList } from "hooks";
import { Product } from "types";
import { CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { ProductListExcelExport } from "../ProductListExcelExport";
import { ProductListFilter } from "../ProductListFilter";
import { ProductListTable } from "../ProductListTable";

const ProductListPage: React.FC = () => {
  const { isLoading, productList } = useProductList();

  const productTableRef = useRef<CustomReactTableHandles<Product>>(null);

  return (
    <BoxHeadlineContainer
      boxTitle="Karten"
      boxIcon={Routes.products.icon}
      boxSubTitle={productList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
      boxWidth="lg"
      boxMenu={<ProductListExcelExport productList={productList} />}
    >
      <ProductListFilter />
      <ProductListTable
        isLoading={isLoading}
        productList={productList}
        productTableRef={productTableRef}
      />
    </BoxHeadlineContainer>
  );
};

export default ProductListPage;
