import React from "react";
import { CustomTooltip } from "core";
import { EnglandIcon, GermanyIcon, UnknownLanguageIcon } from "modules/icons";
import useStyles from "./styles";

const getLanguageName = (idLanguage: number) => {
  switch (idLanguage) {
    case 1:
      return "Englisch";
    case 2:
      return "Französisch";
    case 3:
      return "Deutsch";
    case 4:
      return "Spanisch";
    case 5:
      return "Italienisch";
    case 6:
      return "Vereinfachtes Chinesisch";
    case 7:
      return "Japanisch";
    case 8:
      return "Portugiesisch";
    case 9:
      return "Russisch";
    case 10:
      return "Koreanisch";
    case 11:
      return "Traditionelles Chinesisch";
    default:
      return "Unbekannt";
  }
};

type LanguageColumnFormatterProps = {
  idLanguage: number;
};

export const LanguageColumnFormatter: React.FC<
  LanguageColumnFormatterProps
> = ({ idLanguage }) => {
  const { classes } = useStyles();

  return (
    <CustomTooltip title={getLanguageName(idLanguage)} followCursor={true}>
      <span className={classes.language}>
        {idLanguage === 1 ? (
          <EnglandIcon />
        ) : idLanguage === 3 ? (
          <GermanyIcon />
        ) : (
          <UnknownLanguageIcon />
        )}
      </span>
    </CustomTooltip>
  );
};
