import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateSyncTaskInput, SyncTask } from "types";

export const useSyncTaskCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateSyncTaskInput) => {
      const { data: newSyncTask } = await client.models.SyncTask.create(input);
      return newSyncTask;
    },
    // When mutate is called:
    onMutate: async (newSyncTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.SyncTasks] });

      // Snapshot the previous value
      const previousSyncTasks = queryClient.getQueryData([QueryKeys.SyncTasks]);

      // Optimistically update to the new value
      if (previousSyncTasks) {
        queryClient.setQueryData([QueryKeys.SyncTasks], (old: SyncTask[]) => [
          ...old,
          newSyncTask,
        ]);
      }

      // Return a context object with the snapshotted value
      return { previousSyncTasks };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newSyncTask, context) => {
      console.error("Error saving record:", err, newSyncTask);
      if (context?.previousSyncTasks) {
        queryClient.setQueryData(
          [QueryKeys.SyncTasks],
          context.previousSyncTasks,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.SyncTasks] });
    },
  });

  return createMutation;
};
