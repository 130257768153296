import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useExpansionUpdate, useProductUpdate } from "hooks";
import { Expansion, Product } from "types";
import { EyeLineIcon, EyeLineOffIcon } from "modules/icons";
import useStyles from "./styles";

type ProductActiveColumnFormatterProps = {
  product: Product;
  expansionList: Expansion[];
};

export const ProductActiveColumnFormatter: React.FC<
  ProductActiveColumnFormatterProps
> = ({ product, expansionList }) => {
  const { classes } = useStyles();

  const updateProductMutation = useProductUpdate();
  const updateExpansionMutation = useExpansionUpdate();

  const [isProductActive, setIsProductActive] = useState<boolean>(
    product.isProductActive,
  );

  useEffect(() => {
    setIsProductActive(product.isProductActive);
  }, [product.isProductActive]);

  const productActiveHandler = async () => {
    const newActiveState = !isProductActive;

    updateProductMutation.mutate({
      id: product.id,
      expansionID: product.expansionID,
      isProductActive: newActiveState,
    });
    setIsProductActive(newActiveState);

    const expansion = expansionList.find(
      (exp) => exp.id === product.expansionID,
    );

    if (!expansion || !newActiveState) return;

    updateExpansionMutation.mutate({
      id: expansion.id,
      isExpansionActive: newActiveState,
    });
  };

  return (
    <IconButton
      onClick={() => productActiveHandler()}
      className={classes.iconButton}
    >
      {isProductActive ? (
        <EyeLineIcon className={classes.active} />
      ) : (
        <EyeLineOffIcon className={classes.inactive} />
      )}
    </IconButton>
  );
};
