import React from "react";
import { ArticleUserType } from "types";
import { CustomTooltip } from "core";
import {
  CommercialSellerIcon,
  PowerSellerIcon,
  PrivateSellerIcon,
} from "modules/icons";
import useStyles from "./styles";

const getUserTypeName = (userType: ArticleUserType) => {
  switch (userType) {
    case "Private":
      return "Privat";
    case "Commercial":
      return "Gewerblich";
    case "Powerseller":
      return "Powerseller";
    default:
      return "Gewerblich";
  }
};

type UserTypeColumnFormatterProps = {
  userType: ArticleUserType;
};

export const UserTypeColumnFormatter: React.FC<
  UserTypeColumnFormatterProps
> = ({ userType }) => {
  const { classes } = useStyles({ userType });

  return (
    <CustomTooltip title={getUserTypeName(userType)} followCursor={true}>
      <span className={classes.userType}>
        {userType === "Private" ? (
          <PrivateSellerIcon />
        ) : userType === "Powerseller" ? (
          <PowerSellerIcon />
        ) : (
          <CommercialSellerIcon />
        )}
      </span>
    </CustomTooltip>
  );
};
