import { makeStyles } from "tss-react/mui";
import { ArticleUserType } from "types";

type ReputationStyleProps = {
  userType: ArticleUserType;
};

const useStyles = makeStyles<ReputationStyleProps>()((_, { userType }) => ({
  userType: {
    color:
      userType === "Private"
        ? "#bdc772"
        : userType === "Powerseller"
          ? "#4c4c4c"
          : "#2796a8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& svg": {
      width: 23,
      height: 23,
    },
  },
}));

export default useStyles;
