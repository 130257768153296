import { client } from "queryClient";
import { CardmarketCalcTask } from "types";

export const getCardmarketCalcTaskListByExpansion = async (
  expansionID: string,
  nextTokenParam: string | null = null,
  prevCardmarketCalcTaskList: CardmarketCalcTask[] = [],
): Promise<CardmarketCalcTask[]> => {
  const { data, nextToken } =
    await client.models.CardmarketCalcTask.cardmarketCalcTasksByExpansion(
      {
        expansionID,
      },
      {
        limit: 1000,
        nextToken: nextTokenParam,
      },
    );

  const cardmarketCalcTaskList = [...prevCardmarketCalcTaskList, ...data];

  return nextToken
    ? getCardmarketCalcTaskListByExpansion(
        expansionID,
        nextToken,
        cardmarketCalcTaskList,
      )
    : cardmarketCalcTaskList;
};

export const getAllCardmarketCalcTaskList = async (
  nextTokenParam: string | null = null,
  prevCardmarketCalcTaskList: CardmarketCalcTask[] = [],
): Promise<CardmarketCalcTask[]> => {
  const { data, nextToken } = await client.models.CardmarketCalcTask.list({
    limit: 15000,
    nextToken: nextTokenParam,
  });

  const cardmarketCalcTaskList = [...prevCardmarketCalcTaskList, ...data];

  return nextToken
    ? getAllCardmarketCalcTaskList(nextToken, cardmarketCalcTaskList)
    : cardmarketCalcTaskList;
};

export const getCardmarketCalcTask = async (
  id: string,
): Promise<CardmarketCalcTask | null> => {
  const { data } = await client.models.CardmarketCalcTask.get({
    id,
  });

  return data;
};
