import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import { CalcTask, SortDirection, isKeyOfCalcTask } from "types";
import {
  FilterField,
  SortField,
  filterByDateField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByDateField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const filterByCalcTaskField = (
  calcTaskList: CalcTask[],
  filterField: FilterField<CalcTask>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "startTime":
      return filterByDateField(
        calcTaskList,
        "startTime",
        comparisonType,
        value,
      );
    case "endTime":
      return filterByDateField(calcTaskList, "endTime", comparisonType, value);
    case "errorMessage":
      return filterByTextField(calcTaskList, "errorMessage", value);
    case "expansionID":
      return filterByTextField(calcTaskList, "expansionID", value);
    case "status":
      return filterByTextField(calcTaskList, "status", value);
    case "taskType":
      return filterByTextField(calcTaskList, "taskType", value);
    case "lastTriggered":
      return filterByDateField(
        calcTaskList,
        "lastTriggered",
        comparisonType,
        value,
      );
    case "createdAt":
      return filterByDateField(
        calcTaskList,
        "createdAt",
        comparisonType,
        value,
      );
    case "id":
      return filterByTextField(calcTaskList, "id", value);
    case "updatedAt":
      return filterByDateField(
        calcTaskList,
        "updatedAt",
        comparisonType,
        value,
      );
    default:
      return calcTaskList;
  }
};

const sortByCalcTaskField = (
  a: CalcTask,
  b: CalcTask,
  sortField: SortField<CalcTask>,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "startTime":
      return sortByDateField(a, b, "startTime", direction);
    case "expansionID":
      return sortByTextField(a, b, "expansionID", direction);
    case "errorMessage":
      return sortByTextField(a, b, "errorMessage", direction);
    case "status":
      return sortByTextField(a, b, "status", direction);
    case "taskType":
      return sortByTextField(a, b, "taskType", direction);
    case "lastTriggered":
      return sortByDateField(a, b, "lastTriggered", direction);
    case "endTime":
      if (!a.endTime && !b.endTime) return 0;
      if (!a.endTime) return 1;
      if (!b.endTime) return -1;
      return sortByDateField(a, b, "endTime", direction);
    case "createdAt":
      return sortByDateField(a, b, "createdAt", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "updatedAt":
      return sortByDateField(a, b, "updatedAt", direction);
    default:
      return 0;
  }
};

export const useCalcTaskListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<CalcTask>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof CalcTask, string>> = {};

    const filters: FilterField<CalcTask>[] = Array.from(searchParams.entries())
      .filter(([key]) => isKeyOfCalcTask(key))
      .map(([field, value]) => ({
        field: field as keyof CalcTask,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<CalcTask>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof CalcTask,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof CalcTask, SortDirection>> = {
      startTime: SortDirection.asc,
    };

    return getSortFields<CalcTask>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfCalcTask,
    );
  }, [searchParams]);

  const filterAndSortCalcTasks = useCallback(
    (calcTaskList: CalcTask[]) =>
      sortEntities<CalcTask>(
        filter
          ? filterEntities<CalcTask>(
              calcTaskList,
              filterFields,
              [],
              null,
              filterByCalcTaskField,
            )
          : calcTaskList,
        sortFields,
        sortByCalcTaskField,
        false,
      ).map((calcTask) => ({
        ...calcTask,
        updatedAt: new Date(
          new Date(calcTask.updatedAt ?? new Date().getTime()).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, sortFields],
  );

  return filterAndSortCalcTasks;
};
