import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { KeysEnum } from "./General.types";

export const ExpansionSelectionSet = [
  "id",
  "parentID",
  "abbreviation",
  "expansionAlias",
  "expansionDeName",
  "expansionEnName",
  "isExpansionActive",
] as const;

export type ExpansionShortened = SelectionSet<
  Schema["Expansion"]["type"],
  typeof ExpansionSelectionSet
>;

export type Expansion = Schema["Expansion"]["type"];

export type CreateExpansionInput = Schema["Expansion"]["createType"];

export type UpdateExpansionInput = Schema["Expansion"]["updateType"];

export type IntervalType = Schema["IntervalType"]["type"];

export type IntervalTypeWithEvery = IntervalType | "Every";

const ExpansionTypeKeys: KeysEnum<Expansion> = {
  abbreviation: true,
  createdAt: true,
  expansionAlias: true,
  expansionDeName: true,
  expansionEnName: true,
  expansionWebsite: true,
  icon: true,
  iconCol: true,
  iconRow: true,
  id: true,
  idExpansion: true,
  isExpansionActive: true,
  isReleased: true,
  lastSynced: true,
  lastStockSynced: true,
  lastStorePriceCalc: true,
  lastOnlinePriceCalc: true,
  intervalType: true,
  intervalValue: true,
  nextOnlinePriceCalc: true,
  owner: true,
  parentID: true,
  parentName: true,
  products: true,
  productsCount: true,
  releaseDate: true,
  updatedAt: true,
};

const ExpansionTypeKeysShortened: KeysEnum<ExpansionShortened> = {
  abbreviation: true,
  expansionAlias: true,
  expansionDeName: true,
  expansionEnName: true,
  id: true,
  parentID: true,
  isExpansionActive: true,
};

export const isKeyOfExpansion = (key: string): key is keyof Expansion =>
  key in ExpansionTypeKeys;

export const isKeyOfExpansionShortened = (
  key: string,
): key is keyof ExpansionShortened => key in ExpansionTypeKeysShortened;
