import { useEffect, useState } from "react";
import {
  AverageCalculationMethod,
  CardmarketCredential,
  ExcludedSeller,
  GlobalSettings,
  MinPrice,
  PriceModus,
} from "types";

export const useGlobalSettingsForm = (globalSettings: GlobalSettings) => {
  const [
    cardmarketCredentialListManually,
    setCardmarketCredentialListManually,
  ] = useState<CardmarketCredential[]>([]);
  const [
    cardmarketCredentialListScheduled,
    setCardmarketCredentialListScheduled,
  ] = useState<CardmarketCredential[]>([]);

  const [excludedSellers, setExcludedSellers] = useState<ExcludedSeller[]>([]);

  const [minPricesEnabled, setMinPricesEnabled] = useState<boolean>(false);
  const [minPrices, setMinPrices] = useState<MinPrice[]>([]);

  const [priceModi, setPriceModi] = useState<PriceModus[]>([]);

  const [priceListSteps, setPriceListSteps] = useState<number[]>([]);

  const [skipPriceCalcString, setSkipPriceCalcString] = useState<string>("");
  const [priceListCutOffPercentage, setPriceListCutOffPercentage] = useState<
    number | ""
  >("");
  const [priceSurchargePrivate, setPriceSurchargePrivate] = useState<
    number | ""
  >("");
  const [priceTresholdFolderChange, setPriceTresholdFolderChange] = useState<
    number | ""
  >("");
  const [minCommercialArticles, setMinCommercialArticles] = useState<
    number | ""
  >("");
  const [averageCalculationMethod, setAverageCalculationMethod] =
    useState<AverageCalculationMethod | null>(null);
  const [expansionIconsURL, setExpansionIconsURL] = useState<string>("");

  useEffect(() => {
    setCardmarketCredentialListManually(
      globalSettings.cardmarketCredentialsManually,
    );
    setCardmarketCredentialListScheduled(
      globalSettings.cardmarketCredentialsScheduled,
    );

    setExcludedSellers(
      globalSettings.excludedSellers.sort((a, b) =>
        a.username.localeCompare(b.username),
      ),
    );

    setMinPricesEnabled(globalSettings.minPricesEnabled);
    setMinPrices(
      globalSettings.minPrices.sort((a, b) => a.priority - b.priority),
    );

    setPriceModi(
      globalSettings.priceModi.sort((a, b) => a.priority - b.priority),
    );

    setPriceListSteps(globalSettings.priceListSteps.sort((a, b) => a - b));

    setSkipPriceCalcString(globalSettings.skipPriceCalcString);
    setPriceListCutOffPercentage(globalSettings.priceListCutOffPercentage);
    setPriceSurchargePrivate(globalSettings.priceSurchargePrivate);
    setPriceTresholdFolderChange(globalSettings.priceTresholdFolderChange);
    setMinCommercialArticles(globalSettings.minCommercialArticles);
    setAverageCalculationMethod(globalSettings.averageCalculationMethod);
    setExpansionIconsURL(globalSettings.expansionIconsURL);
  }, [globalSettings]);

  return {
    cardmarketCredentialListManually,
    setCardmarketCredentialListManually,
    cardmarketCredentialListScheduled,
    setCardmarketCredentialListScheduled,
    excludedSellers,
    setExcludedSellers,
    minPricesEnabled,
    setMinPricesEnabled,
    minPrices,
    setMinPrices,
    priceModi,
    setPriceModi,
    priceListSteps,
    setPriceListSteps,
    skipPriceCalcString,
    setSkipPriceCalcString,
    priceListCutOffPercentage,
    setPriceListCutOffPercentage,
    priceSurchargePrivate,
    setPriceSurchargePrivate,
    priceTresholdFolderChange,
    setPriceTresholdFolderChange,
    minCommercialArticles,
    setMinCommercialArticles,
    averageCalculationMethod,
    setAverageCalculationMethod,
    expansionIconsURL,
    setExpansionIconsURL,
  };
};
