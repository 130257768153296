import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CustomButton, CustomReactTable, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { PriceListStepDeleteDialog } from "../../priceliststepDelete/PriceListStepDeleteDialog";
import { PriceListStepFormDialog } from "../../priceliststepForm/PriceListStepFormDialog";

type PriceListStepListTableProps = {
  editable: boolean;
  priceListStepTableRef?: React.RefObject<CustomReactTableHandles<number>>;
  priceListSteps: number[];
  setPriceListSteps?: (value: React.SetStateAction<number[]>) => void;
};

export const PriceListStepListTable: React.FC<PriceListStepListTableProps> = ({
  editable,
  priceListStepTableRef,
  priceListSteps,
  setPriceListSteps,
}) => {
  console.log("priceListSteps: ", priceListSteps);

  const [deletePriceListStepDialogOpen, setDeletePriceListStepDialogOpen] =
    useState<boolean>(false);
  const [priceListStepToDelete, setPriceListStepToDelete] = useState<
    number | undefined
  >();

  const [createPriceListStepDialogOpen, setCreatePriceListStepDialogOpen] =
    useState<boolean>(false);

  const [editPriceListStepDialogOpen, setEditPriceListStepDialogOpen] =
    useState<boolean>(false);
  const [priceListStepToEdit, setPriceListStepToEdit] = useState<
    number | undefined
  >();

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<number>[]>(
    () => [
      {
        header: "Preisstufe",
        accessorFn: (row) =>
          row.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        },
      },
      ...(!editable
        ? []
        : ([
            {
              header: "Aktionen",
              cell: ({ row }) => (
                <ActionsColumnFormatter<number>
                  row={row}
                  listIntent="list"
                  setRowToEdit={setPriceListStepToEdit}
                  setEditRowDialogOpen={setEditPriceListStepDialogOpen}
                  setRowToDelete={setPriceListStepToDelete}
                  setDeleteRowDialogOpen={setDeletePriceListStepDialogOpen}
                />
              ),
              footer: (props) => props.column.id,
            },
          ] as ColumnDef<number>[])),
    ],
    [editable],
  );

  return (
    <BoxHeadlineContainer
      boxTitle="Preisstufen"
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        editable && (
          <CustomButton
            text="Neue Preisstufe"
            onClick={() => setCreatePriceListStepDialogOpen(true)}
            size="small"
          />
        )
      }
    >
      {priceListStepToDelete && (
        <PriceListStepDeleteDialog
          dialogOpen={deletePriceListStepDialogOpen}
          setDialogOpen={setDeletePriceListStepDialogOpen}
          priceListStep={priceListStepToDelete}
          setPriceListSteps={setPriceListSteps}
        />
      )}

      <PriceListStepFormDialog
        formIntent="create"
        dialogOpen={createPriceListStepDialogOpen}
        setDialogOpen={setCreatePriceListStepDialogOpen}
        priceListStepList={priceListSteps}
        setPriceListSteps={setPriceListSteps}
      />

      {priceListStepToEdit && (
        <PriceListStepFormDialog
          formIntent="edit"
          dialogOpen={editPriceListStepDialogOpen}
          setDialogOpen={setEditPriceListStepDialogOpen}
          priceListStepList={priceListSteps}
          priceListStep={priceListStepToEdit}
          setPriceListSteps={setPriceListSteps}
        />
      )}

      <CustomReactTable<number>
        data={priceListSteps}
        columns={defaultColumns}
        ref={priceListStepTableRef}
        marginBottom={0}
        leftSpacing="medium"
      />
    </BoxHeadlineContainer>
  );
};
