import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  expansionName: {
    color: theme.palette.primary.main + " !important",
    fontWeight: "bold",
    fontSize: 16,
  },
  comments: {
    color: "#9b9b9b",
    fontStyle: "italic",
  },
  price: {
    color: "#012169",
    fontWeight: 600,
  },
  excluded: {
    color: "#c97282",
  },
  excludedRow: {
    backgroundColor: "#fbecee !important",
  },
}));

export default useStyles;
