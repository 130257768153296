import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Expansion } from "types";
import { CustomReactTable } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { Routes } from "routes";
import { useSyncTaskListUIContext } from "../SyncTaskListUIContext";
import { ExpansionSyncColumnFormatter } from "./ExpansionSyncColumnFormatter";
import utils from "utils";

export const UnsyncedExpansionListTable: React.FC = () => {
  const syncTaskListUIContext = useSyncTaskListUIContext();
  const syncTaskListUIProps = useMemo(() => {
    return {
      unsyncedExpansionList: syncTaskListUIContext.unsyncedExpansionList,
      isLoadingUnsyncedExpansionList:
        syncTaskListUIContext.isLoadingUnsyncedExpansionList,
      hasNextData: syncTaskListUIContext.hasNextData,
      nextToken: syncTaskListUIContext.nextToken,
      loadSyncTaskList: syncTaskListUIContext.loadSyncTaskList,
    };
  }, [syncTaskListUIContext]);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<Expansion>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "idExpansion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.idExpansion,
        },
        enableSorting: false,
      },
      {
        header: "Icon",
        accessorKey: "icon",
        cell: ({ row }) => <IconColumnFormatter expansion={row.original} />,
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.icon,
        },
        enableSorting: false,
      },
      {
        header: "Abk.",
        accessorKey: "abbreviation",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.abbreviation,
        },
        enableSorting: false,
      },
      {
        header: "DE-Name",
        accessorKey: "expansionDeName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.abbreviation,
          isNowrap: true,
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
        enableSorting: false,
      },
      {
        header: "EN-Name",
        accessorKey: "expansionEnName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.expansionEnName,
          isNowrap: true,
        },
        enableSorting: false,
      },
      {
        header: "Release",
        accessorKey: "releaseDate",
        accessorFn: (row) =>
          utils.dates.getDateInGermanFromAWSDateFormat(row.releaseDate),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDateInGermanFromAWSDateFormat(
              row.original.releaseDate,
            ),
          isNowrap: true,
        },
        enableSorting: false,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ExpansionSyncColumnFormatter
            key={row.original.id}
            expansion={row.original}
          />
        ),
        footer: (props) => props.column.id,
        enableSorting: false,
      },
    ],
    [],
  );

  return (
    <BoxHeadlineContainer
      boxTitle="Noch nicht synchronisierte Editionen"
      boxIcon={Routes.expansions.icon}
      boxSubTitle={syncTaskListUIProps.unsyncedExpansionList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
    >
      <CustomReactTable<Expansion>
        data={syncTaskListUIProps.unsyncedExpansionList}
        columns={defaultColumns}
        isLoadingDataList={syncTaskListUIProps.isLoadingUnsyncedExpansionList}
        tableTheme="default"
        leftSpacing="large"
        noResultsText="Keine noch nicht synchronisierten Editionen gefunden."
        hasNextData={syncTaskListUIProps.hasNextData}
        onClickLoadMore={() =>
          syncTaskListUIProps.loadSyncTaskList(syncTaskListUIProps.nextToken)
        }
        onClickLoadAll={() => syncTaskListUIProps.loadSyncTaskList(null, true)}
      />
    </BoxHeadlineContainer>
  );
};
