import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { DeleteDialog } from "components";

type PriceListStepDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  priceListStep?: number;
  setPriceListSteps?: (value: React.SetStateAction<number[]>) => void;
};

export const PriceListStepDeleteDialog: React.FC<
  PriceListStepDeleteDialogProps
> = ({ priceListStep, dialogOpen, setDialogOpen, setPriceListSteps }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || priceListStep === undefined) {
    return null;
  }

  const deletePriceListStepRequest = async () => {
    if (!setPriceListSteps) {
      enqueueSnackbar(
        "Beim Löschen des Staffelpreises ist ein Fehler aufgetreten!",
      );
      return;
    }

    setDeleteLoading(true);

    setPriceListSteps((prev) =>
      prev.filter((priceListStepItem) => priceListStepItem !== priceListStep),
    );

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deletePriceListStepRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Staffelpreis Löschen"
      itemName={`"${priceListStep.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}"`}
      itemDeleteDescription="die Preisstufe mit dem Preis"
      confirmRequired={false}
    />
  );
};
