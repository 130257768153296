import React, { useState } from "react";
import { Grid2 } from "@mui/material";
import { useExpansionProductList } from "hooks";
import { CustomTab, CustomTabs } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TabContainer } from "layout/Container/TabContainer";
import CalcTaskListPage from "modules/calctasks/calctasklist/CalcTaskListPage";
import CardmarketCalcTaskListPage from "modules/cardmarketcalctasks/cardmarketcalctasklist/CardmarketCalcTaskListPage";
import { ProductListExcelExport } from "modules/products/productlist/ProductListExcelExport";
import StockTaskListPage from "modules/stocktasks/stocktasklist/StockTaskListPage";
import SyncTaskListPage from "modules/synctasks/synctasklist/SyncTaskListPage";
import { Routes } from "routes";
import { ExpansionDetails } from "../ExpansionDetails";
import { ExpansionProductListFilter } from "../ExpansionProductListFilter";
import { ExpansionProductListTable } from "../ExpansionProductListTable";

const ExpansionProductListPage: React.FC = () => {
  const { isLoading, expansionProductList } = useExpansionProductList();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <>
      <ExpansionDetails />

      <CustomTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        rootStyle={{ marginTop: 33 }}
      >
        <CustomTab tabLabel="Karten" tabIcon={Routes.products.icon} />
        <CustomTab tabLabel="Aufgaben" tabIcon={Routes.calcTasks.icon} />
      </CustomTabs>

      <TabContainer tabContainerIndex={0} tabContainerValue={selectedTab}>
        <BoxHeadlineContainer
          boxTitle="Karten"
          boxIcon={Routes.products.icon}
          boxSubTitle={expansionProductList.length.toString()}
          paddingHorizontal={0}
          paddingVertical={0}
          boxAlignment="center"
          boxMenu={
            <ProductListExcelExport productList={expansionProductList} />
          }
          marginTop={false}
        >
          <ExpansionProductListFilter />
          <ExpansionProductListTable
            isLoading={isLoading}
            productList={expansionProductList}
          />
        </BoxHeadlineContainer>
      </TabContainer>

      <TabContainer tabContainerIndex={1} tabContainerValue={selectedTab}>
        <Grid2 container direction="row" spacing={5} justifyContent="center">
          <Grid2>
            <CalcTaskListPage showExpansionDetails={false} marginTop={false} />
          </Grid2>
          <Grid2>
            <CardmarketCalcTaskListPage
              showExpansionDetails={false}
              marginTop={false}
            />
          </Grid2>
        </Grid2>

        <Grid2 container direction="row" spacing={5} justifyContent="center">
          <Grid2>
            <StockTaskListPage />
          </Grid2>
          <Grid2>
            <SyncTaskListPage showUnsyncedExpansionList={false} />
          </Grid2>
        </Grid2>
      </TabContainer>
    </>
  );
};

export default ExpansionProductListPage;
