import { CSSProperties } from "react";
import { makeStyles } from "tss-react/mui";

type StyleProps = {
  textAlign: CSSProperties["textAlign"];
};

const useStyles = makeStyles<StyleProps>()((theme, { textAlign }) => ({
  input: {
    '&[value=""]': {
      color: theme.palette.primary.light,
    },
    color: theme.palette.primary.main,
    textAlign: textAlign,
    fontSize: 14,
    width: "100%",
    height: "100%",
    padding: 5,
    paddingLeft: 7,
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    "::selection": {
      backgroundColor: "#dffff6",
    },
    "::-moz-selection": {
      backgroundColor: "#dffff6",
    },
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
}));

export default useStyles;
