import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllExpansionList } from "modules/expansions/api";
import { useExpansionListFilterAndSort } from "../useExpansionListFilterAndSort";

export const useExpansionList = (filter = true) => {
  const filterAndSortExpansions = useExpansionListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Expansions],
    queryFn: async () => {
      const expansionList = await getAllExpansionList();

      return expansionList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const expansionList = useMemo(
    () => filterAndSortExpansions(data ?? []),
    [data, filterAndSortExpansions],
  );

  return {
    expansionList,
    isLoading,
  };
};
