import React from "react";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";

export const ExpansionListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <BooleanFilter
          param="isExpansionActive"
          paramName="Aktiv"
          defaultOption="true"
        />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchExpansion}
        />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);
