import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import {
  getAllCalcTaskList,
  getCalcTaskListByExpansion,
} from "modules/calctasks/api";
import { useCalcTaskListFilterAndSort } from "../useCalcTaskListFilterAndSort";

type CalcTaskListParamsType = {
  expansionID: string;
};

export const useCalcTaskList = () => {
  const { expansionID } = useParams<CalcTaskListParamsType>();

  const filterAndSortCalcTasks = useCalcTaskListFilterAndSort();

  const { data, isLoading } = useQuery({
    queryKey: expansionID
      ? [QueryKeys.CalcTasks]
      : [QueryKeys.CalcTasks, expansionID],
    queryFn: async () => {
      console.log("Calling useCalcTaskList with expansionID", expansionID);
      const calcTaskList = expansionID
        ? await getCalcTaskListByExpansion(expansionID)
        : await getAllCalcTaskList();

      return calcTaskList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const calcTaskList = useMemo(
    () => filterAndSortCalcTasks(data ?? []),
    [data, filterAndSortCalcTasks],
  );

  return {
    calcTaskList,
    expansionID,
    isLoading,
  };
};
