import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { Expansion, PriceModus, isSelectOption } from "types";
import { CustomNumberField, CustomNumberFieldHandles } from "core";
import { BooleanOption } from "modules/shared/options/BooleanOption";
import { ExpansionOption } from "modules/shared/options/ExpansionOption";
import { RarityWithAllOption } from "modules/shared/options/RarityWithAllOption";
import { usePriceModusForm } from "../usePriceModusForm";

type PriceModusFormProps = {
  formIntent: "create" | "edit";
  expansionList: Expansion[];
  priceModusList: PriceModus[];
  priceModus: PriceModus | undefined;
};

export type PriceModusFormHandles = {
  validatePriceModusForm(): PriceModus | null;
};

const PriceModusFormComponent: React.ForwardRefRenderFunction<
  PriceModusFormHandles,
  PriceModusFormProps
> = (
  { formIntent, expansionList, priceModusList, priceModus },
  priceModusFormRef,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    priority,
    setPriority,
    expansion,
    setExpansion,
    priceFactorStore,
    setPriceFactorStore,
    priceFactorOnline,
    setPriceFactorOnline,
    rarity,
    setRarity,
    isFoil,
    setIsFoil,
  } = usePriceModusForm(formIntent, expansionList, priceModusList, priceModus);

  const priorityInputRef = useRef<CustomNumberFieldHandles>(null);
  const priceFactorStoreInputRef = useRef<CustomNumberFieldHandles>(null);
  const priceFactorOnlineInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(priceModusFormRef, () => ({
    validatePriceModusForm: () => {
      if (priority === "") {
        enqueueSnackbar("Bitte gib eine Priorität ein!");
        priorityInputRef.current?.highlight(false);
        return null;
      }

      const existingPriority = priceModusList.find(
        (priceModusItem) =>
          priceModusItem.id !== priceModus?.id &&
          priceModusItem.priority === priority,
      );

      if (existingPriority) {
        enqueueSnackbar(
          "Es existiert bereits ein Preismodus mit der gleichen Priorität!",
        );
        priorityInputRef.current?.highlight(false);
        return null;
      }

      if (priceFactorStore === "") {
        enqueueSnackbar("Bitte gib einen Preisfaktor für den Laden ein!");
        priceFactorStoreInputRef.current?.highlight(false);
        return null;
      }

      if (priceFactorOnline === "") {
        enqueueSnackbar("Bitte gib einen Preisfaktor für Online ein!");
        priceFactorOnlineInputRef.current?.highlight(false);
        return null;
      }

      const existingPriceModus = priceModusList.find(
        (priceModusItem) =>
          priceModusItem.id !== priceModus?.id &&
          ((!priceModusItem.expansionID && isSelectOption(expansion)) ||
            (priceModusItem.expansionID &&
              priceModusItem.expansionID ===
                (isSelectOption(expansion)
                  ? expansion.value
                  : expansion.id))) &&
          ((!priceModusItem.rarity && rarity === "All") ||
            (priceModusItem.rarity && priceModusItem.rarity === rarity)) &&
          (((priceModusItem.isFoil === null ||
            priceModusItem.isFoil === undefined) &&
            isFoil === "All") ||
            (priceModusItem.isFoil !== null &&
              priceModusItem.isFoil === undefined &&
              priceModusItem.isFoil === (isFoil === "true"))),
      );

      if (existingPriceModus) {
        enqueueSnackbar(
          "Es existiert bereits ein Preismodus mit der gleichen Edition, Rarity und Foil!",
        );
        return null;
      }

      const priceModusFormInput: PriceModus = {
        id: priceModus ? priceModus.id : nanoid(),
        priority,
        priceFactorStore,
        priceFactorOnline,
        expansionID: isSelectOption(expansion) ? null : expansion.id,
        rarity: rarity === "All" ? null : rarity,
        isFoil: isFoil === "All" ? null : isFoil === "true",
      };

      return priceModusFormInput;
    },
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CustomNumberField
          label="Priorität"
          number={priority}
          setNumber={setPriority}
          ref={priorityInputRef}
          decimalScale={0}
          required={true}
        />
      </Grid>
      <Grid item>
        <ExpansionOption
          label="Edition"
          expansion={expansion}
          setExpansion={setExpansion}
          required={true}
        />
      </Grid>
      <Grid item>
        <RarityWithAllOption
          label="Rarity"
          rarity={rarity}
          setRarity={setRarity}
          required={true}
        />
      </Grid>
      <Grid item>
        <BooleanOption
          label="Foil"
          boolean={isFoil}
          setBoolean={setIsFoil}
          required={true}
        />
      </Grid>

      <Grid item>
        <CustomNumberField
          label="Preisfaktor Laden"
          number={priceFactorStore}
          setNumber={setPriceFactorStore}
          ref={priceFactorStoreInputRef}
          required={true}
        />
      </Grid>

      <Grid item>
        <CustomNumberField
          label="Preisfaktor Online"
          number={priceFactorOnline}
          setNumber={setPriceFactorOnline}
          ref={priceFactorOnlineInputRef}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export const PriceModusForm = React.forwardRef(PriceModusFormComponent);
