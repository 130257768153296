import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "context";
import { QueryKeys, client, queryClient } from "queryClient";
import { Expansion, SyncTask } from "types";
import {
  getSyncTaskList,
  getSyncTaskListByExpansion,
} from "modules/synctasks/api";

type SyncTaskListContext = {
  syncTaskList: SyncTask[];
  loadSyncTaskList: (
    nextTokenParam?: string | null | undefined,
    loadAll?: boolean,
  ) => Promise<SyncTask[]>;
  hasNextData: boolean;
  nextToken: string | null;
  isLoadingSyncTaskList: boolean;
  unsyncedExpansionList: Expansion[];
  loadUnsyncedExpansionList: () => Promise<Expansion[]>;
  isLoadingUnsyncedExpansionList: boolean;
};

type SyncTaskListParams = {
  expansionID: string;
};

const SyncTaskListUIContext = createContext<SyncTaskListContext>(
  {} as SyncTaskListContext,
);

export function useSyncTaskListUIContext() {
  return useContext(SyncTaskListUIContext);
}

export const SyncTaskListUIConsumer = SyncTaskListUIContext.Consumer;

type SyncTaskListUIProviderProps = {
  children: React.ReactNode;
};

const SyncTaskListUIProvider: React.FC<SyncTaskListUIProviderProps> = ({
  children,
}) => {
  const authContext = useAuthContext();

  const { expansionID } = useParams<SyncTaskListParams>();

  const [isLoadingSyncTaskList, setIsLoadingSyncTaskList] =
    useState<boolean>(true);

  const [syncTaskList, setSyncTaskList] = useState<SyncTask[]>([]);
  const [hasNextData, setHasNextData] = useState<boolean>(true);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const [isLoadingUnsyncedExpansionList, setIsLoadingUnsyncedExpansionList] =
    useState<boolean>(false);

  const [unsyncedExpansionList, setUnsyncedExpansionList] = useState<
    Expansion[]
  >([]);

  useEffect(() => {
    // Subscribe to creation of SyncTask
    const createSub = client.models.SyncTask.onCreate(
      expansionID
        ? {
            filter: {
              expansionID: {
                eq: expansionID,
              },
            },
          }
        : undefined,
    ).subscribe({
      next: (data) => {
        console.log("Create SyncTask", data);
        setSyncTaskList((prev) => [data, ...prev]);
      },
      error: (error) => console.warn(error),
    });

    // Subscribe to update of SyncTask
    const updateSub = client.models.SyncTask.onUpdate(
      expansionID
        ? {
            filter: {
              expansionID: {
                eq: expansionID,
              },
            },
          }
        : undefined,
    ).subscribe({
      next: (data) => {
        console.log("Update SyncTask", data);
        if (data.status === "Completed") {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.Expansions],
          });
          loadUnsyncedExpansionList();
        }
        setSyncTaskList((prev) =>
          prev.map((item) => (item.id === data.id ? data : item)),
        );
      },
      error: (error) => console.warn(error),
    });

    // Subscribe to deletion of SyncTask
    const deleteSub = client.models.SyncTask.onDelete(
      expansionID
        ? {
            filter: {
              expansionID: {
                eq: expansionID,
              },
            },
          }
        : undefined,
    ).subscribe({
      next: (data) => {
        console.log("Delete SyncTask", data);
        setSyncTaskList((prev) => prev.filter((item) => item.id !== data.id));
      },
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
    };
  }, []);

  const loadSyncTaskList = useCallback(
    async (
      nextTokenParam?: string | null | undefined,
      loadAll?: boolean,
    ): Promise<SyncTask[]> => {
      setIsLoadingSyncTaskList(true);
      const timestamp1 = new Date();

      const { newSyncTaskList, newNextToken } = expansionID
        ? await getSyncTaskListByExpansion(expansionID, nextTokenParam, loadAll)
        : await getSyncTaskList(nextTokenParam, loadAll);

      console.log("newSyncTaskList", newSyncTaskList);

      if (nextTokenParam) {
        setSyncTaskList((prev) => [...prev, ...newSyncTaskList]);
      } else {
        setSyncTaskList(newSyncTaskList);
      }

      setHasNextData(newSyncTaskList.length === 50);
      setNextToken(newNextToken);

      const timestamp2 = new Date();
      const timeDiff = timestamp2.getTime() - timestamp1.getTime();

      console.log(Math.round(timeDiff) + " ms for loading");
      setIsLoadingSyncTaskList(false);

      return newSyncTaskList;
    },
    [expansionID],
  );

  useEffect(() => {
    if (authContext.isLoadingStateEntities) {
      return;
    }
    loadSyncTaskList();
  }, [loadSyncTaskList, authContext.isLoadingStateEntities]);

  const loadUnsyncedExpansionList = async () => {
    setIsLoadingUnsyncedExpansionList(true);

    const { data } = await client.queries.listUnsyncedExpansions();

    setUnsyncedExpansionList(data ?? []);

    setIsLoadingUnsyncedExpansionList(false);

    return data ?? [];
  };

  useEffect(() => {
    loadUnsyncedExpansionList();
  }, []);

  const value = {
    syncTaskList,
    setSyncTaskList,
    loadSyncTaskList,
    isLoadingSyncTaskList,
    hasNextData,
    nextToken,
    unsyncedExpansionList,
    loadUnsyncedExpansionList,
    isLoadingUnsyncedExpansionList,
  };

  return (
    <SyncTaskListUIContext.Provider value={value}>
      {children}
    </SyncTaskListUIContext.Provider>
  );
};

export default SyncTaskListUIProvider;
