import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { Expansion, PriceModus } from "types";
import { CustomDialog } from "core";
import { PriceModusForm, PriceModusFormHandles } from "../PriceModusForm";

type PriceModusFormDialogProps = {
  formIntent: "create" | "edit";
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  expansionList: Expansion[];
  priceModusList: PriceModus[];
  priceModus?: PriceModus;
  setPriceModi?: (value: React.SetStateAction<PriceModus[]>) => void;
};

export const PriceModusFormDialog: React.FC<PriceModusFormDialogProps> = ({
  formIntent,
  dialogOpen,
  setDialogOpen,
  expansionList,
  priceModusList,
  priceModus,
  setPriceModi,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const priceModusFormRef = useRef<PriceModusFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitPriceModus = async () => {
    const priceModusFormInput =
      priceModusFormRef.current?.validatePriceModusForm();
    if (!priceModusFormInput) {
      console.error("PriceModusForm is invalid...");
      return;
    }

    if (!authContext.user || !setPriceModi) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Preismodus ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log("priceModusFormInput: ", priceModusFormInput);

    if (formIntent === "create") {
      setPriceModi((prev) => [...prev, priceModusFormInput]);
    } else {
      setPriceModi((prev) =>
        prev.map((priceModusItem) =>
          priceModusItem.id !== priceModus?.id
            ? priceModusItem
            : priceModusFormInput,
        ),
      );
    }

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText={
        "Preismodus " + (formIntent === "edit" ? "speichern" : "anlegen")
      }
      dialogOpen={dialogOpen}
      positive={true}
      titleText={
        "Preismodus " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitPriceModus()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <PriceModusForm
        formIntent={formIntent}
        expansionList={expansionList}
        priceModusList={priceModusList}
        priceModus={priceModus}
        ref={priceModusFormRef}
      />
    </CustomDialog>
  );
};
