export const USER_GROUPS = {
  ADMIN: "Admin",
  EMPLOYEE: "employee",
};

export const USER_GROUPS_OPTIONS = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "employee",
    label: "Mitarbeiter",
  },
];

export const getUserRoleName = (value: string) => {
  const userRole = USER_GROUPS_OPTIONS.find(
    (userRoleItem) => userRoleItem.value === value,
  );

  if (!userRole) {
    throw new Error("Could not find userRole " + value);
  }

  return userRole.label;
};
