import React from "react";
import { Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { MinPrice } from "types";
import { CustomReactTable } from "core";

type MinPriceViewProps = {
  minPrice: MinPrice | null | undefined;
};

export const MinPriceView: React.FC<MinPriceViewProps> = ({ minPrice }) => {
  console.log("minPrice: ", minPrice);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<MinPrice>[]>(
    () => [
      {
        header: "Priorität",
        accessorKey: "priority",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.priority,
        },
      },
      {
        header: "Rarität",
        accessorKey: "rarity",
        accessorFn: (row) => row.rarity ?? "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.rarity ?? "Alle",
        },
      },
      {
        header: "Foil",
        accessorKey: "isFoil",
        accessorFn: (row) =>
          row.isFoil === true ? "Ja" : row.isFoil === false ? "Nein" : "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.isFoil === true
              ? "Ja"
              : row.original.isFoil === false
                ? "Nein"
                : "Alle",
        },
      },
      {
        header: "Mindestpreis Laden",
        accessorKey: "minPriceStore",
        accessorFn: (row) =>
          row.minPriceStore.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.minPriceStore.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        },
      },
      {
        header: "Mindestpreis Online",
        accessorKey: "minPriceOnline",
        accessorFn: (row) =>
          row.minPriceOnline.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.minPriceOnline.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        },
      },
    ],
    [],
  );

  if (!minPrice) {
    return (
      <>
        <Typography variant="h4">Gefundener Mindestpreis</Typography>
        <Typography variant="body1">
          Es wurde kein Mindestpreis für diese Karte gefunden.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="h4">Gefundener Mindestpreis</Typography>
      <CustomReactTable<MinPrice>
        data={[minPrice]}
        columns={defaultColumns}
        marginBottom={0}
        leftSpacing="medium"
        sticky={false}
      />
    </>
  );
};
