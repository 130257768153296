import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  expansionDetilsTable: {
    "& td": {
      border: "none !important",
      height: 40,
    },
    "& h4": {
      margin: 0,
    },
  },
  dateFromNow: {
    fontSize: 14,
    color: "#83838A",
    marginLeft: 15,
  },
  iconButton: {
    padding: 0,
    marginLeft: 15,
    "& svg": {
      height: 25,
      width: 25,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  inactive: {
    color: theme.palette.red.main,
  },
  websiteLink: {
    fontSize: 13,
    color: theme.palette.primary.main,
    cursor: "pointer",

    "&:hover": {
      color: theme.palette.primary.dark,
    },

    "& svg": {
      height: 19,
      width: 19,
      verticalAlign: "text-top",
      marginLeft: 5,
    },
  },
}));

export default useStyles;
