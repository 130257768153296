import { makeStyles } from "tss-react/mui";
import { Expansion, GlobalSettings } from "types";

type StyleProps = {
  expansion: Expansion | null | undefined;
  globalSettings: GlobalSettings | null;
};

const useStyles = makeStyles<StyleProps>()(
  (_, { expansion, globalSettings }) => ({
    icon: {
      display: "block",
      height: 21,
      width: 21,
      background: `transparent url(${globalSettings?.expansionIconsURL}) no-repeat 0 0`,
      backgroundPosition: expansion
        ? `${expansion.iconCol}px ${expansion.iconRow}px`
        : "unset",
      cursor: "pointer",
    },
  }),
);

export default useStyles;
