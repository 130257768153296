import React, { useState } from "react";
import { Grid } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { MinPrice } from "types";
import {
  CustomButton,
  CustomReactTable,
  CustomReactTableHandles,
  CustomSwitch,
  LabeledTypography,
} from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { MinPriceDeleteDialog } from "../../minpriceDelete/MinPriceDeleteDialog";
import { MinPriceFormDialog } from "../../minpriceForm/MinPriceFormDialog";

type MinPriceListTableProps = {
  editable: boolean;
  minPriceTableRef?: React.RefObject<CustomReactTableHandles<MinPrice>>;
  minPrices: MinPrice[];
  setMinPrices?: (value: React.SetStateAction<MinPrice[]>) => void;
  minPricesEnabled: boolean;
  onMinPricesEnabledChange?: (value: boolean) => void;
};

export const MinPriceListTable: React.FC<MinPriceListTableProps> = ({
  editable,
  minPriceTableRef,
  minPrices,
  setMinPrices,
  minPricesEnabled,
  onMinPricesEnabledChange,
}) => {
  console.log("minPrices: ", minPrices);

  const [deleteMinPriceDialogOpen, setDeleteMinPriceDialogOpen] =
    useState<boolean>(false);
  const [minPriceToDelete, setMinPriceToDelete] = useState<
    MinPrice | undefined
  >();

  const [createMinPriceDialogOpen, setCreateMinPriceDialogOpen] =
    useState<boolean>(false);

  const [editMinPriceDialogOpen, setEditMinPriceDialogOpen] =
    useState<boolean>(false);
  const [minPriceToEdit, setMinPriceToEdit] = useState<MinPrice | undefined>();

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<MinPrice>[]>(
    () => [
      {
        header: "Priorität",
        accessorKey: "priority",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.priority,
        },
      },
      {
        header: "Rarität",
        accessorKey: "rarity",
        accessorFn: (row) => row.rarity ?? "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.rarity ?? "Alle",
        },
      },
      {
        header: "Foil",
        accessorKey: "isFoil",
        accessorFn: (row) =>
          row.isFoil === true ? "Ja" : row.isFoil === false ? "Nein" : "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.isFoil === true
              ? "Ja"
              : row.original.isFoil === false
                ? "Nein"
                : "Alle",
        },
      },
      {
        header: "Mindestpreis Laden",
        accessorKey: "minPriceStore",
        accessorFn: (row) =>
          row.minPriceStore.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.minPriceStore.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        },
      },
      {
        header: "Mindestpreis Online",
        accessorKey: "minPriceOnline",
        accessorFn: (row) =>
          row.minPriceOnline.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.minPriceOnline.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
        },
      },
      ...(!editable
        ? []
        : ([
            {
              header: "Aktionen",
              cell: ({ row }) => (
                <ActionsColumnFormatter<MinPrice>
                  row={row}
                  listIntent="list"
                  setRowToEdit={setMinPriceToEdit}
                  setEditRowDialogOpen={setEditMinPriceDialogOpen}
                  setRowToDelete={setMinPriceToDelete}
                  setDeleteRowDialogOpen={setDeleteMinPriceDialogOpen}
                />
              ),
              footer: (props) => props.column.id,
            },
          ] as ColumnDef<MinPrice>[])),
    ],
    [editable],
  );

  return (
    <BoxHeadlineContainer
      boxTitle="Mindestpreise"
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        editable && (
          <CustomButton
            text="Neuer Mindestpreis"
            onClick={() => setCreateMinPriceDialogOpen(true)}
            size="small"
          />
        )
      }
    >
      {minPriceToDelete && (
        <MinPriceDeleteDialog
          dialogOpen={deleteMinPriceDialogOpen}
          setDialogOpen={setDeleteMinPriceDialogOpen}
          minPrice={minPriceToDelete}
          setMinPrices={setMinPrices}
        />
      )}

      <MinPriceFormDialog
        formIntent="create"
        dialogOpen={createMinPriceDialogOpen}
        setDialogOpen={setCreateMinPriceDialogOpen}
        minPriceList={minPrices}
        setMinPrices={setMinPrices}
      />

      {minPriceToEdit && (
        <MinPriceFormDialog
          formIntent="edit"
          dialogOpen={editMinPriceDialogOpen}
          setDialogOpen={setEditMinPriceDialogOpen}
          minPriceList={minPrices}
          minPrice={minPriceToEdit}
          setMinPrices={setMinPrices}
        />
      )}

      <div
        style={{
          marginLeft: 30,
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item md={4}>
            {editable ? (
              <CustomSwitch
                label="Mindestpreise aktiviert"
                name="minPricesEnabled"
                switchLabel={minPricesEnabled ? "Ja" : "Nein"}
                checkedValue={minPricesEnabled}
                onChange={(value) =>
                  onMinPricesEnabledChange &&
                  onMinPricesEnabledChange(value.target.checked)
                }
                required={true}
              />
            ) : (
              <LabeledTypography
                label="Mindestpreise aktiviert"
                content={minPricesEnabled ? "Ja" : "Nein"}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <CustomReactTable<MinPrice>
        data={minPrices}
        columns={defaultColumns}
        ref={minPriceTableRef}
        marginBottom={0}
        leftSpacing="medium"
      />
    </BoxHeadlineContainer>
  );
};
