import React, { useRef } from "react";
import { useCalcTaskList } from "hooks";
import { CalcTask } from "types";
import { CustomButton, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ExpansionDetails } from "modules/expansions/expansionproducts/ExpansionDetails";
import { ExcelIcon } from "modules/icons";
import { Routes } from "routes";
import { CalcTaskListFilter } from "../CalcTaskListFilter";
import { CalcTaskListTable } from "../CalcTaskListTable";

type CalcTaskListPageProps = {
  showExpansionDetails?: boolean;
  marginTop?: boolean;
};

const CalcTaskListPage: React.FC<CalcTaskListPageProps> = ({
  showExpansionDetails = true,
  marginTop,
}) => {
  const { isLoading, calcTaskList, expansionID } = useCalcTaskList();

  const calcTaskTableRef = useRef<CustomReactTableHandles<CalcTask>>(null);

  return (
    <>
      {showExpansionDetails && expansionID && <ExpansionDetails />}

      <BoxHeadlineContainer
        boxTitle="Laden Preisberechnungen"
        boxIcon={Routes.calcTasks.icon}
        boxSubTitle={calcTaskList.length.toString()}
        paddingHorizontal={0}
        paddingVertical={0}
        boxAlignment="center"
        boxMenu={
          <CustomButton
            text="Download Excel"
            size="small"
            iconBefore={<ExcelIcon />}
            onClick={() => calcTaskTableRef.current?.exportToExcel()}
            styleWrapper={{ marginLeft: 33 }}
          />
        }
        marginTop={marginTop}
      >
        <CalcTaskListFilter />
        <CalcTaskListTable
          isLoading={isLoading}
          calcTaskList={calcTaskList}
          calcTaskTableRef={calcTaskTableRef}
        />
      </BoxHeadlineContainer>
    </>
  );
};

export default CalcTaskListPage;
