import React from "react";
import { client } from "queryClient";
import { RarityWithAll, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface RarityWithAllOptionProps
  extends Partial<CustomSelectOptionProps<RarityWithAll>> {
  rarity: RarityWithAll;
  setRarity: (value: React.SetStateAction<RarityWithAll>) => void;
  rarityInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getRarityWithAllName = (value: RarityWithAll) => {
  switch (value) {
    case "All":
      return "Alle";
    case "TimeShifted":
      return "Time Shifted";
    case "TipCard":
      return "Tip Card";
    case "CodeCard":
      return "Code Card";
    default:
      return value;
  }
};

export const getRarityWithAllEnumByName = (value: string): RarityWithAll => {
  switch (value) {
    case "Alle":
      return "All";
    case "Time Shifted":
      return "TimeShifted";
    case "TimeShifted":
      return "TimeShifted";
    case "Tip Card":
      return "TipCard";
    case "TipCard":
      return "TipCard";
    case "Code Card":
      return "CodeCard";
    case "CodeCard":
      return "CodeCard";
    case "Common":
      return "Common";
    case "Hero":
      return "Hero";
    case "Land":
      return "Land";
    case "Legend":
      return "Legend";
    case "Masterpiece":
      return "Masterpiece";
    case "Mythic":
      return "Mythic";
    case "Promo":
      return "Promo";
    case "Rare":
      return "Rare";
    case "Special":
      return "Special";
    case "Starter":
      return "Starter";
    case "Token":
      return "Token";
    case "Uncommon":
      return "Uncommon";
    default:
      return "All";
  }
};

export const getRarityWithAllOptions = (): SelectOption[] => {
  const values = client.enums.Rarity?.values() ?? [];

  return [
    {
      value: "All",
      label: getRarityWithAllName("All"),
    },

    ...values.map((rarity) => ({
      value: rarity,
      label: getRarityWithAllName(rarity),
    })),
  ];
};

export const RarityWithAllOption: React.FC<RarityWithAllOptionProps> = ({
  rarity,
  setRarity,
  rarityInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Rarity"
    options={getRarityWithAllOptions()}
    onChange={(rarityOption) => setRarity(rarityOption.value as RarityWithAll)}
    value={
      rarity
        ? {
            value: rarity,
            label: getRarityWithAllName(rarity),
          }
        : null
    }
    placeholder="Rarity auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={rarityInputRef}
  />
);
