import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { SelectOption } from "types";
import { CustomSelect } from "core";

const booleanOptionList: SelectOption[] = [
  {
    label: "Alle",
    value: "all",
  },
  {
    label: "JA",
    value: "true",
  },
  {
    label: "NEIN",
    value: "false",
  },
];

type BooleanFilterOption = "all" | "true" | "false";

type BooleanFilterProps = {
  param: string;
  paramName: string;
  defaultOption?: BooleanFilterOption;
};

export const BooleanFilter: React.FC<BooleanFilterProps> = ({
  param,
  paramName,
  defaultOption = "all",
}) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [bool, setBool] = useState<SelectOption | null>({
    label: "Alle",
    value: "all",
  });

  useEffect(() => {
    const booleanParam = searchParams.get(param);

    const selectedBoolean = booleanOptionList.find(
      (booleanOption) =>
        booleanOption.value === (booleanParam ?? defaultOption),
    ) ?? {
      label: "Alle",
      value: "all",
    };

    setBool(selectedBoolean);
  }, [param, searchParams, defaultOption]);

  return (
    <CustomSelect<SelectOption>
      label={paramName}
      options={booleanOptionList}
      onChange={(booleanOption) => {
        setBool(booleanOption);
        if (booleanOption.value === defaultOption) {
          searchParams.delete(param);
        } else {
          searchParams.set(param, booleanOption.value);
        }
        sessionStorage.setItem(pathname, searchParams.toString());
        setSearchParams(searchParams, {
          replace: true,
        });
      }}
      value={bool}
    />
  );
};
