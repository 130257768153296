import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  CardmarketCalcTask,
  SortDirection,
  isKeyOfCardmarketCalcTask,
} from "types";
import {
  FilterField,
  SortField,
  filterByDateField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByDateField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const filterByCardmarketCalcTaskField = (
  cardmarketCalcTaskList: CardmarketCalcTask[],
  filterField: FilterField<CardmarketCalcTask>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "startTime":
      return filterByDateField(
        cardmarketCalcTaskList,
        "startTime",
        comparisonType,
        value,
      );
    case "endTime":
      return filterByDateField(
        cardmarketCalcTaskList,
        "endTime",
        comparisonType,
        value,
      );
    case "errorMessage":
      return filterByTextField(cardmarketCalcTaskList, "errorMessage", value);
    case "expansionID":
      return filterByTextField(cardmarketCalcTaskList, "expansionID", value);
    case "status":
      return filterByTextField(cardmarketCalcTaskList, "status", value);
    case "taskType":
      return filterByTextField(cardmarketCalcTaskList, "taskType", value);
    case "lastTriggered":
      return filterByDateField(
        cardmarketCalcTaskList,
        "lastTriggered",
        comparisonType,
        value,
      );
    case "createdAt":
      return filterByDateField(
        cardmarketCalcTaskList,
        "createdAt",
        comparisonType,
        value,
      );
    case "id":
      return filterByTextField(cardmarketCalcTaskList, "id", value);
    case "updatedAt":
      return filterByDateField(
        cardmarketCalcTaskList,
        "updatedAt",
        comparisonType,
        value,
      );
    default:
      return cardmarketCalcTaskList;
  }
};

const sortByCardmarketCalcTaskField = (
  a: CardmarketCalcTask,
  b: CardmarketCalcTask,
  sortField: SortField<CardmarketCalcTask>,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "startTime":
      return sortByDateField(a, b, "startTime", direction);
    case "expansionID":
      return sortByTextField(a, b, "expansionID", direction);
    case "errorMessage":
      return sortByTextField(a, b, "errorMessage", direction);
    case "status":
      return sortByTextField(a, b, "status", direction);
    case "taskType":
      return sortByTextField(a, b, "taskType", direction);
    case "lastTriggered":
      return sortByDateField(a, b, "lastTriggered", direction);
    case "endTime":
      if (!a.endTime && !b.endTime) return 0;
      if (!a.endTime) return 1;
      if (!b.endTime) return -1;
      return sortByDateField(a, b, "endTime", direction);
    case "createdAt":
      return sortByDateField(a, b, "createdAt", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "updatedAt":
      return sortByDateField(a, b, "updatedAt", direction);
    default:
      return 0;
  }
};

export const useCardmarketCalcTaskListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<CardmarketCalcTask>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof CardmarketCalcTask, string>> =
      {};

    const filters: FilterField<CardmarketCalcTask>[] = Array.from(
      searchParams.entries(),
    )
      .filter(([key]) => isKeyOfCardmarketCalcTask(key))
      .map(([field, value]) => ({
        field: field as keyof CardmarketCalcTask,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<CardmarketCalcTask>[] =
      Object.entries(defaultFilters)
        .filter(
          ([key, value]) =>
            value !== undefined &&
            !filters.some((filterField) => filterField.field === key),
        )
        .map(([field, value]) => ({
          field: field as keyof CardmarketCalcTask,
          value,
          comparisonType: getComparisonType(searchParams, field),
        }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<
      Record<keyof CardmarketCalcTask, SortDirection>
    > = {
      startTime: SortDirection.asc,
    };

    return getSortFields<CardmarketCalcTask>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfCardmarketCalcTask,
    );
  }, [searchParams]);

  const filterAndSortCardmarketCalcTasks = useCallback(
    (cardmarketCalcTaskList: CardmarketCalcTask[]) =>
      sortEntities<CardmarketCalcTask>(
        filter
          ? filterEntities<CardmarketCalcTask>(
              cardmarketCalcTaskList,
              filterFields,
              [],
              null,
              filterByCardmarketCalcTaskField,
            )
          : cardmarketCalcTaskList,
        sortFields,
        sortByCardmarketCalcTaskField,
        false,
      ).map((cardmarketCalcTask) => ({
        ...cardmarketCalcTask,
        updatedAt: new Date(
          new Date(
            cardmarketCalcTask.updatedAt ?? new Date().getTime(),
          ).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, sortFields],
  );

  return filterAndSortCardmarketCalcTasks;
};
