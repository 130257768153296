import React from "react";
import { Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { PriceModus } from "types";
import { CustomReactTable } from "core";
import { getExpansionName } from "modules/expansions/business";

type PriceModusViewProps = {
  priceModus: PriceModus | null | undefined;
};

export const PriceModusView: React.FC<PriceModusViewProps> = ({
  priceModus,
}) => {
  console.log("priceModus: ", priceModus);

  const { expansionList } = useExpansionList(false);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<PriceModus>[]>(
    () => [
      {
        header: "Priorität",
        accessorKey: "priority",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.priority,
        },
      },
      {
        header: "Edition",
        accessorKey: "expansionID",
        accessorFn: (row) => {
          if (!row.expansionID) return "Alle";

          const expansion = expansionList.find(
            (expansion) => expansion.id === row.expansionID,
          );

          return expansion ? getExpansionName(expansion) : "Alle";
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => {
            if (!row.original.expansionID) return "Alle";

            const expansion = expansionList.find(
              (expansion) => expansion.id === row.original.expansionID,
            );

            return expansion ? getExpansionName(expansion) : "Alle";
          },
        },
      },
      {
        header: "Rarität",
        accessorKey: "rarity",
        accessorFn: (row) => row.rarity ?? "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.rarity ?? "Alle",
        },
      },
      {
        header: "Foil",
        accessorKey: "isFoil",
        accessorFn: (row) =>
          row.isFoil === true ? "Ja" : row.isFoil === false ? "Nein" : "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.isFoil === true
              ? "Ja"
              : row.original.isFoil === false
                ? "Nein"
                : "Alle",
        },
      },
      {
        header: "Preisfaktor Laden",
        accessorKey: "priceFactorStore",
        accessorFn: (row) => row.priceFactorStore.toLocaleString("de-DE"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.priceFactorStore.toLocaleString("de-DE"),
        },
      },
      {
        header: "Preisfaktor Online",
        accessorKey: "priceFactorOnline",
        accessorFn: (row) => row.priceFactorOnline.toLocaleString("de-DE"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.priceFactorOnline.toLocaleString("de-DE"),
        },
      },
    ],
    [expansionList],
  );

  if (!priceModus) {
    return (
      <>
        <Typography variant="h4">Gefundener Preismodus</Typography>
        <Typography variant="body1">
          Es wurde kein Preismodus für diese Karte gefunden.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="h4">Gefundener Preismodus</Typography>
      <CustomReactTable<PriceModus>
        data={[priceModus]}
        columns={defaultColumns}
        marginBottom={0}
        leftSpacing="medium"
        sticky={false}
      />
    </>
  );
};
