import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { Expansion } from "types";
import { CustomLink, CustomReactTable, CustomReactTableHandles } from "core";
import { ExpansionActiveColumnFormatter } from "modules/shared/columnformatters/ExpansionActiveColumnFormatter";
import { ExpansionAliasColumnFormatter } from "modules/shared/columnformatters/ExpansionAliasColumnFormatter";
import { ExpansionParentColumnFormatter } from "modules/shared/columnformatters/ExpansionParentColumnFormatter";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { Routes } from "routes";
import utils from "utils";
import useStyles from "./styles";

type ExpansionListTableProps = {
  isLoading: boolean;
  expansionList: Expansion[];
  expansionTableRef?: React.RefObject<CustomReactTableHandles<Expansion>>;
};

export const ExpansionListTable: React.FC<ExpansionListTableProps> = ({
  isLoading,
  expansionList,
  expansionTableRef,
}) => {
  const { classes } = useStyles();

  const { expansionList: allExpansionList } = useExpansionList(false);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<Expansion>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "idExpansion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.idExpansion,
        },
      },
      {
        header: "Icon",
        accessorKey: "icon",
        cell: ({ row }) => <IconColumnFormatter expansion={row.original} />,
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.icon,
        },
      },
      {
        header: "Abk.",
        accessorKey: "abbreviation",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.abbreviation,
        },
      },
      {
        header: "DE-Name",
        accessorKey: "expansionDeName",
        cell: ({ row }) => (
          <CustomLink
            linkText={row.original.expansionDeName}
            link={Routes.expansions.subNavigations.expansion.path.replace(
              ":expansionID",
              row.original.id,
            )}
            className={classes.expansionName}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.abbreviation,
          isNowrap: true,
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "EN-Name",
        accessorKey: "expansionEnName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.expansionEnName,
          isNowrap: true,
        },
      },
      {
        header: "Produkte",
        accessorKey: "productsCount",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.productsCount ?? "",
          align: "center",
        },
      },
      {
        header: "Release",
        accessorKey: "releaseDate",
        accessorFn: (row) =>
          utils.dates.getDateInGermanFromAWSDateFormat(row.releaseDate),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDateInGermanFromAWSDateFormat(
              row.original.releaseDate,
            ),
          isNowrap: true,
        },
      },
      {
        header: "Alias",
        accessorKey: "expansionAlias",
        cell: ({ row }) => (
          <ExpansionAliasColumnFormatter
            key={row.original.id}
            expansion={row.original}
            expansionList={allExpansionList}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.expansionAlias ?? "",
          isNowrap: true,
        },
      },
      {
        header: "Parent",
        accessorKey: "parentName",
        cell: ({ row }) => (
          <ExpansionParentColumnFormatter
            key={row.original.id}
            expansion={row.original}
            expansionList={allExpansionList}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.productsCount ?? "",
          isNowrap: true,
        },
      },
      {
        header: "Preisberechnung",
        accessorKey: "lastStorePriceCalc",
        accessorFn: (row) =>
          row.lastStorePriceCalc
            ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                row.lastStorePriceCalc,
              ) + " Uhr"
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.lastStorePriceCalc
              ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                  row.original.lastStorePriceCalc,
                ) + " Uhr"
              : "",
          isNowrap: true,
          align: "center",
        },
      },
      {
        header: "Aktiv",
        accessorKey: "isExpansionActive",
        accessorFn: (row) => (row.isExpansionActive ? "Ja" : "Nein"),
        cell: ({ row }) => (
          <ExpansionActiveColumnFormatter
            key={row.original.id}
            expansion={row.original}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.isExpansionActive ? "Ja" : "Nein",
          align: "center",
        },
      },
    ],
    [allExpansionList, classes.expansionName],
  );

  return (
    <CustomReactTable<Expansion>
      data={expansionList}
      columns={defaultColumns}
      isLoadingDataList={isLoading}
      ref={expansionTableRef}
    />
  );
};
