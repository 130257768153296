import React from "react";
import { Tabs } from "@mui/material";
import useStyles from "./styles";

type CustomTabsProps = {
  selectedTab: number;
  setSelectedTab: (value: React.SetStateAction<number>) => void;
  children: React.ReactNode;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
};

export const CustomTabs: React.FC<CustomTabsProps> = ({
  selectedTab,
  setSelectedTab,
  children,
  rootClassName,
  rootStyle,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Tabs
      value={selectedTab}
      classes={{
        indicator: classes.tabIndicator,
        flexContainer: classes.flexContainer,
        root: cx(classes.root, rootClassName),
      }}
      onChange={(_: React.ChangeEvent<unknown>, newValue: number) =>
        setSelectedTab(newValue)
      }
      variant="scrollable"
      scrollButtons="auto"
      style={rootStyle}
    >
      {children}
    </Tabs>
  );
};
