import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Article,
  SearchTextParamName,
  SortDirection,
  isKeyOfArticle,
} from "types";
import {
  FilterField,
  SortField,
  filterByBooleanField,
  filterByDateField,
  filterByNumberField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByBooleanField,
  sortByDateField,
  sortByNumberField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const searchFields: (keyof Article)[] = [
  "username",
  "company",
  "userType",
  "comments",
  "idLanguage",
  "idProduct",
  "id",
  "idArticle",
];

const filterByArticleField = (
  articleList: Article[],
  filterField: FilterField<Article>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "idArticle":
      return filterByNumberField(
        articleList,
        "idArticle",
        comparisonType,
        parseInt(value),
      );
    case "idProduct":
      return filterByNumberField(
        articleList,
        "idProduct",
        comparisonType,
        parseInt(value),
      );
    case "idLanguage":
      return filterByNumberField(
        articleList,
        "idLanguage",
        comparisonType,
        parseInt(value),
      );
    case "idCurrency":
      return filterByNumberField(
        articleList,
        "idCurrency",
        comparisonType,
        parseInt(value),
      );
    case "count":
      return filterByNumberField(
        articleList,
        "count",
        comparisonType,
        parseInt(value),
      );
    case "price":
      return filterByNumberField(
        articleList,
        "price",
        comparisonType,
        parseInt(value),
      );
    case "comments":
      return filterByTextField(articleList, "comments", value);
    case "lastEdited":
      return filterByDateField(
        articleList,
        "lastEdited",
        comparisonType,
        value,
      );
    case "condition":
      return filterByTextField(articleList, "condition", value);
    case "inShoppingCart":
      return filterByBooleanField(
        articleList,
        "inShoppingCart",
        value === "true" ? true : false,
      );
    case "onSale":
      return filterByBooleanField(
        articleList,
        "onSale",
        value === "true" ? true : false,
      );
    case "isFoil":
      return filterByBooleanField(
        articleList,
        "isFoil",
        value === "true" ? true : false,
      );
    case "idUser":
      return filterByNumberField(
        articleList,
        "idUser",
        comparisonType,
        parseInt(value),
      );
    case "username":
      return filterByTextField(articleList, "username", value);
    case "company":
      return filterByTextField(articleList, "company", value);
    case "userType":
      return filterByTextField(articleList, "userType", value);
    case "reputation":
      return filterByNumberField(
        articleList,
        "reputation",
        comparisonType,
        parseInt(value),
      );
    case "isSeller":
      return filterByBooleanField(
        articleList,
        "isSeller",
        value === "true" ? true : false,
      );
    case "sellCount":
      return filterByNumberField(
        articleList,
        "sellCount",
        comparisonType,
        parseInt(value),
      );
    case "soldItems":
      return filterByNumberField(
        articleList,
        "soldItems",
        comparisonType,
        parseInt(value),
      );
    case "shipsFast":
      return filterByNumberField(
        articleList,
        "shipsFast",
        comparisonType,
        parseInt(value),
      );
    case "avgShippingTime":
      return filterByNumberField(
        articleList,
        "avgShippingTime",
        comparisonType,
        parseInt(value),
      );
    case "riskGroup":
      return filterByNumberField(
        articleList,
        "riskGroup",
        comparisonType,
        parseInt(value),
      );
    case "isExcludedStore":
      return filterByBooleanField(
        articleList,
        "isExcludedStore",
        value === "true" ? true : false,
      );
    case "excludedReasonStore":
      return filterByTextField(articleList, "excludedReasonStore", value);
    case "isExcludedOnlineNotFoil":
      return filterByBooleanField(
        articleList,
        "isExcludedOnlineNotFoil",
        value === "true" ? true : false,
      );
    case "excludedReasonOnlineNotFoil":
      return filterByTextField(
        articleList,
        "excludedReasonOnlineNotFoil",
        value,
      );
    case "isExcludedOnlineFoil":
      return filterByBooleanField(
        articleList,
        "isExcludedOnlineFoil",
        value === "true" ? true : false,
      );
    case "excludedReasonOnlineFoil":
      return filterByTextField(articleList, "excludedReasonOnlineFoil", value);
    case "id":
      return filterByTextField(articleList, "id", value);
    default:
      return articleList;
  }
};

const sortByArticleField = (
  a: Article,
  b: Article,
  sortField: SortField<Article>,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "idArticle":
      return sortByNumberField(a, b, "idArticle", direction);
    case "idProduct":
      return sortByNumberField(a, b, "idProduct", direction);
    case "idLanguage":
      return sortByNumberField(a, b, "idLanguage", direction);
    case "idCurrency":
      return sortByNumberField(a, b, "idCurrency", direction);
    case "count":
      return sortByNumberField(a, b, "count", direction);
    case "price":
      return sortByNumberField(a, b, "price", direction);
    case "comments":
      if (!a.comments && !b.comments) return 0;
      if (!a.comments) return 1;
      if (!b.comments) return -1;
      return sortByTextField(a, b, "comments", direction);
    case "lastEdited":
      if (!a.lastEdited && !b.lastEdited) return 0;
      if (!a.lastEdited) return 1;
      if (!b.lastEdited) return -1;
      return sortByDateField(a, b, "lastEdited", direction);
    case "condition":
      return sortByTextField(a, b, "condition", direction);
    case "inShoppingCart":
      return sortByBooleanField(a, b, "inShoppingCart", direction);
    case "onSale":
      return sortByBooleanField(a, b, "onSale", direction);
    case "isFoil":
      if (
        (a.isFoil === null || a.isFoil === undefined) &&
        (b.isFoil === null || b.isFoil === undefined)
      )
        return 0;
      if (a.isFoil === null || a.isFoil === undefined) return 1;
      if (b.isFoil === null || b.isFoil === undefined) return -1;
      return sortByBooleanField(a, b, "isFoil", direction);
    case "idUser":
      return sortByNumberField(a, b, "idUser", direction);
    case "username":
      return sortByTextField(a, b, "username", direction);
    case "company":
      if (!a.company && !b.company) return 0;
      if (!a.company) return 1;
      if (!b.company) return -1;
      return sortByTextField(a, b, "company", direction);
    case "userType":
      return sortByTextField(a, b, "userType", direction);
    case "reputation":
      return sortByNumberField(a, b, "reputation", direction);
    case "isSeller":
      return sortByBooleanField(a, b, "isSeller", direction);
    case "sellCount":
      return sortByNumberField(a, b, "sellCount", direction);
    case "soldItems":
      return sortByNumberField(a, b, "soldItems", direction);
    case "shipsFast":
      return sortByNumberField(a, b, "shipsFast", direction);
    case "avgShippingTime":
      return sortByNumberField(a, b, "avgShippingTime", direction);
    case "riskGroup":
      return sortByNumberField(a, b, "riskGroup", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "isExcludedStore":
      return sortByBooleanField(a, b, "isExcludedStore", direction);
    case "excludedReasonStore":
      if (!a.excludedReasonStore && !b.excludedReasonStore) return 0;
      if (!a.excludedReasonStore) return 1;
      if (!b.excludedReasonStore) return -1;
      return sortByTextField(a, b, "excludedReasonStore", direction);
    case "isExcludedOnlineNotFoil":
      return sortByBooleanField(a, b, "isExcludedOnlineNotFoil", direction);
    case "excludedReasonOnlineNotFoil":
      if (!a.excludedReasonOnlineNotFoil && !b.excludedReasonOnlineNotFoil)
        return 0;
      if (!a.excludedReasonOnlineNotFoil) return 1;
      if (!b.excludedReasonOnlineNotFoil) return -1;
      return sortByTextField(a, b, "excludedReasonOnlineNotFoil", direction);
    case "isExcludedOnlineFoil":
      return sortByBooleanField(a, b, "isExcludedOnlineFoil", direction);
    case "excludedReasonOnlineFoil":
      if (!a.excludedReasonOnlineFoil && !b.excludedReasonOnlineFoil) return 0;
      if (!a.excludedReasonOnlineFoil) return 1;
      if (!b.excludedReasonOnlineFoil) return -1;
      return sortByTextField(a, b, "excludedReasonOnlineFoil", direction);
    default:
      return 0;
  }
};

export const useArticleListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Article>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Article, string>> = {};

    const filters: FilterField<Article>[] = Array.from(searchParams.entries())
      .filter(([key]) => isKeyOfArticle(key))
      .map(([field, value]) => ({
        field: field as keyof Article,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<Article>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Article,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Article, SortDirection>> = {
      price: SortDirection.asc,
    };

    return getSortFields<Article>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfArticle,
    );
  }, [searchParams]);

  const filterAndSortArticles = useCallback(
    (articleList: Article[]) =>
      sortEntities<Article>(
        filter
          ? filterEntities<Article>(
              articleList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchArticle),
              filterByArticleField,
            )
          : articleList,
        sortFields,
        sortByArticleField,
        false,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortArticles;
};
