import { useQuery } from "@tanstack/react-query";
import { QueryKeys, client } from "queryClient";
import { GlobalSettings } from "types";
import { GLOBAL_SETTINGS_ID } from "utils/constants";

export const useGlobalSettingsRead = () => {
  const { data: globalSettings, isLoading } = useQuery({
    queryKey: [QueryKeys.GlobalSettings],
    queryFn: async () => {
      const globalSettingsResponse = await client.models.GlobalSettings.get({
        id: GLOBAL_SETTINGS_ID,
      });

      return globalSettingsResponse.data as GlobalSettings | null;
    },
  });

  return {
    globalSettings,
    isLoading,
  };
};
