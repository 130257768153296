import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { client } from "queryClient";
import { SelectOption, TaskStatus } from "types";
import { CustomSelect } from "core";
import { getTaskStatusWithAllName } from "modules/shared/options/TaskStatusOption";

export const getTaskStatusWithAllOptions = (): SelectOption[] => {
  const values = client.enums.TaskStatus?.values() ?? [];

  return [
    {
      value: "All",
      label: getTaskStatusWithAllName("All"),
    },

    ...values.map((taskStatus) => ({
      value: taskStatus,
      label: getTaskStatusWithAllName(taskStatus),
    })),
  ];
};

export const TaskStatusFilter: React.FC = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [taskStatus, setTaskStatus] = useState<SelectOption>({
    value: "All",
    label: getTaskStatusWithAllName("All"),
  });

  useEffect(() => {
    const taskStatusParam = searchParams.get("status");

    const selectedTaskStatusOption =
      taskStatusParam && taskStatusParam !== "All"
        ? (taskStatusParam as TaskStatus)
        : "All";

    setTaskStatus({
      value: selectedTaskStatusOption,
      label: getTaskStatusWithAllName(selectedTaskStatusOption),
    });
  }, [searchParams]);

  return (
    <CustomSelect<SelectOption>
      label="Status"
      options={getTaskStatusWithAllOptions()}
      onChange={(taskStatusOption) => {
        setTaskStatus(taskStatusOption);
        if (taskStatusOption.value === "All") {
          searchParams.delete("status");
        } else {
          searchParams.set("status", taskStatusOption.value);
        }
        sessionStorage.setItem(pathname, searchParams.toString());
        setSearchParams(searchParams, {
          replace: true,
        });
      }}
      value={taskStatus}
      getOptionValue={undefined}
      getOptionLabel={undefined}
      isOptionDisabled={undefined}
      isOptionSelected={undefined}
      placeholder="Status auswählen"
    />
  );
};
