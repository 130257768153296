import React from "react";
import { client } from "queryClient";
import { ArticleExcludeReason, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface ArticleExcludeReasonOptionProps
  extends Partial<CustomSelectOptionProps<ArticleExcludeReason>> {
  articleExcludeReason: ArticleExcludeReason | null;
  setArticleExcludeReason: (
    value: React.SetStateAction<ArticleExcludeReason | null>,
  ) => void;
  articleExcludeReasonInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getArticleExcludeReasonName = (value: ArticleExcludeReason) => {
  switch (value) {
    case "ExcludedSeller":
      return "Ausg. Händler";
    case "DifferentFoil":
      return "And. Foil";
    case "DifferentLanguage":
      return "And. Sprache";
    case "CutOffPercentage":
      return "Cut Off";
  }
};

export const getArticleExcludeReasonEnumByName = (
  value: string,
): ArticleExcludeReason => {
  switch (value) {
    case "Ausg. Händler":
      return "ExcludedSeller";
    case "And. Foil":
      return "DifferentFoil";
    case "And. Sprache":
      return "DifferentLanguage";
    case "Cut Off":
      return "CutOffPercentage";
    default:
      return "ExcludedSeller";
  }
};

export const getArticleExcludeReasonOptions = (): SelectOption[] => {
  const values = client.enums.ArticleExcludeReason?.values() ?? [];

  return values.map((syncArticleExcludeReason) => ({
    value: syncArticleExcludeReason,
    label: getArticleExcludeReasonName(syncArticleExcludeReason),
  }));
};

export const ArticleExcludeReasonOption: React.FC<
  ArticleExcludeReasonOptionProps
> = ({
  articleExcludeReason,
  setArticleExcludeReason,
  articleExcludeReasonInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Ausschlussgrund"
    options={getArticleExcludeReasonOptions()}
    onChange={(articleExcludeReasonOption) =>
      setArticleExcludeReason(
        articleExcludeReasonOption.value as ArticleExcludeReason,
      )
    }
    value={
      articleExcludeReason
        ? {
            value: articleExcludeReason,
            label: getArticleExcludeReasonName(articleExcludeReason),
          }
        : null
    }
    placeholder="Ausschlussgrund auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={articleExcludeReasonInputRef}
  />
);
