import React, { useState } from "react";
import { Button } from "@mui/material";
import { useExpansionUpdate } from "hooks";
import { Expansion } from "types";
import { TextEditField } from "../TextEditField";
import useStyles from "./styles";

type ExpansionAliasColumnFormatterProps = {
  expansion: Expansion;
  expansionList: Expansion[];
};

export const ExpansionAliasColumnFormatter: React.FC<
  ExpansionAliasColumnFormatterProps
> = ({ expansion, expansionList }) => {
  const { classes, cx } = useStyles();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [currentExpansion, setCurrentExpansion] =
    useState<Expansion>(expansion);

  const updateMutation = useExpansionUpdate();

  if (!isSelected) {
    return (
      <Button
        onClick={() => setIsSelected(true)}
        className={cx(
          classes.aliasButton,
          currentExpansion.expansionAlias
            ? classes.aliasButtonFilled
            : classes.aliasButtonEmpty,
        )}
      >
        {currentExpansion.expansionAlias ?? "alias"}
      </Button>
    );
  }

  const submitExpansionAlias = (value: string) => {
    if (value.trim() !== expansion.expansionAlias) {
      setCurrentExpansion((prev) => ({
        ...prev,
        expansionAlias: value.trim() === "" ? null : value.trim(),
      }));

      updateMutation.mutate({
        id: expansion.id,
        expansionAlias: value.trim() === "" ? null : value.trim(),
      });

      const parentOfCurrentExpansion = expansionList.filter(
        (exp) => exp.parentID === expansion.id,
      );

      parentOfCurrentExpansion.forEach((exp) => {
        updateMutation.mutate({
          id: exp.id,
          parentName:
            value.trim() === "" ? expansion.expansionDeName : value.trim(),
        });
      });
    }

    setIsSelected(false);
  };

  return (
    <TextEditField
      id={expansion.id}
      defaultValue={currentExpansion.expansionAlias ?? null}
      submitValue={submitExpansionAlias}
      onCancel={() => setIsSelected(false)}
    />
  );
};
