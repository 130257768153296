import { Schema } from "queryClient";
import { KeysEnum } from "./General.types";

export type CalcTask = Schema["CalcTask"]["type"];

export type CreateCalcTaskInput = Schema["CalcTask"]["createType"];

export type UpdateCalcTaskInput = Schema["CalcTask"]["updateType"];

const CalcTaskTypeKeys: KeysEnum<CalcTask> = {
  createdAt: true,
  endTime: true,
  errorMessage: true,
  expansionID: true,
  id: true,
  lastTriggered: true,
  owner: true,
  startTime: true,
  status: true,
  taskType: true,
  updatedAt: true,
};

export const isKeyOfCalcTask = (key: string): key is keyof CalcTask =>
  key in CalcTaskTypeKeys;
