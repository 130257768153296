import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { KeysEnum, LanguageFilterType } from "./General.types";

export const ProductSelectionSet = [
  "id",
  "rarity",
  "productAlias",
  "productDeName",
  "productEnName",
  "expansionID",
  "priceStore",
  "isProductActive",
] as const;

export type ProductShortened = SelectionSet<
  Schema["Product"]["type"],
  typeof ProductSelectionSet
>;

export type FolderChange = Schema["FolderChange"]["type"];

export type Product = Schema["Product"]["type"];

export type CreateProductInput = Schema["Product"]["createType"];

export type UpdateProductInput = Schema["Product"]["updateType"] & {
  expansionID: string;
};

export type ProductName = Schema["ProductName"]["type"];

const ProductTypeKeys: KeysEnum<Product> = {
  countArticles: true,
  countFoils: true,
  countReprints: true,
  createdAt: true,
  deStock: true,
  deTransfer: true,
  enStock: true,
  enTransfer: true,
  expansion: true,
  expansionID: true,
  folderChange: true,
  id: true,
  idMetaproduct: true,
  idProduct: true,
  image: true,
  isProductActive: true,
  number: true,
  onlyFoil: true,
  owner: true,
  priceStore: true,
  priceOldStore: true,
  priceOnline: true,
  priceOldOnline: true,
  storePriceCalcID: true,
  storePriceOldCalcID: true,
  lastStorePriceCalcSuccess: true,
  priceStoreSetManually: true,
  onlinePriceCalcID: true,
  onlinePriceOldCalcID: true,
  lastOnlinePriceCalcSuccess: true,
  priceCalcs: true,
  productAlias: true,
  productDeName: true,
  productEnName: true,
  rarity: true,
  stockCount: true,
  stockCountDe: true,
  stockCountEn: true,
  updatedAt: true,
  website: true,
};

const ProductTypeKeysShortened: KeysEnum<ProductShortened> = {
  expansionID: true,
  id: true,
  priceStore: true,
  productAlias: true,
  productDeName: true,
  productEnName: true,
  rarity: true,
  isProductActive: true,
};

export enum ProductColumnToUpdate {
  priceStore = "priceStore",
  deStock = "deStock",
  deTransfer = "deTransfer",
  enStock = "enStock",
  enTransfer = "enTransfer",
}

export const getPreviousProductColumn = (
  column: ProductColumnToUpdate,
  languageFilter: LanguageFilterType,
): ProductColumnToUpdate => {
  switch (column) {
    case ProductColumnToUpdate.priceStore: {
      if (languageFilter === LanguageFilterType.DE) {
        return ProductColumnToUpdate.deTransfer;
      }
      return ProductColumnToUpdate.enTransfer;
    }
    case ProductColumnToUpdate.deStock:
      return ProductColumnToUpdate.priceStore;
    case ProductColumnToUpdate.deTransfer:
      return ProductColumnToUpdate.deStock;
    case ProductColumnToUpdate.enStock: {
      if (languageFilter === LanguageFilterType.EN) {
        return ProductColumnToUpdate.priceStore;
      }
      return ProductColumnToUpdate.deTransfer;
    }
    case ProductColumnToUpdate.enTransfer:
      return ProductColumnToUpdate.enStock;
  }
};

export const getNextProductColumn = (
  column: ProductColumnToUpdate,
  languageFilter: LanguageFilterType,
): ProductColumnToUpdate => {
  switch (column) {
    case ProductColumnToUpdate.priceStore: {
      if (languageFilter === LanguageFilterType.EN) {
        return ProductColumnToUpdate.enStock;
      }
      return ProductColumnToUpdate.deStock;
    }
    case ProductColumnToUpdate.deStock:
      return ProductColumnToUpdate.deTransfer;
    case ProductColumnToUpdate.deTransfer: {
      if (languageFilter === LanguageFilterType.DE) {
        return ProductColumnToUpdate.priceStore;
      }
      return ProductColumnToUpdate.enStock;
    }
    case ProductColumnToUpdate.enStock:
      return ProductColumnToUpdate.enTransfer;
    case ProductColumnToUpdate.enTransfer:
      return ProductColumnToUpdate.priceStore;
  }
};

export const isKeyOfProduct = (key: string): key is keyof Product =>
  key in ProductTypeKeys;

export const isKeyOfProductShortened = (
  key: string,
): key is keyof ProductShortened => key in ProductTypeKeysShortened;

export const isProduct = (product: unknown): product is Product =>
  typeof product === "object" &&
  product !== null &&
  "expansionDeName" in product;
