import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { GlobalSettings, UpdateGlobalSettingsInput } from "types";
import {
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { CardmarketCredentialListTable } from "modules/globalsettings/cardmarketcredentials/cardmarketcredentiallist/CardmarketCredentialListTable";
import { ExcludedSellerListTable } from "modules/globalsettings/excludedsellers/excludedsellerlist/ExcludedSellerListTable";
import { ExpansionIconsURLForm } from "modules/globalsettings/expansioniconsurl/ExpansionIconsURLForm";
import { MinPriceListTable } from "modules/globalsettings/minprices/minpricelist/MinPriceListTable";
import { PriceListStepListTable } from "modules/globalsettings/priceliststeps/priceliststeplist/PriceListStepListTable";
import { PriceModusListTable } from "modules/globalsettings/pricemodi/pricemoduslist/PriceModusListTable";
import { AverageCalculationMethodOption } from "modules/shared/options/AverageCalculationMethodOption";
import { useGlobalSettingsForm } from "../useGlobalSettingsForm";
import useStyles from "./styles";

type GlobalSettingsFormProps = {
  globalSettings: GlobalSettings;
};

export type GlobalSettingsFormHandles = {
  validateGlobalSettingsForm(): UpdateGlobalSettingsInput | null;
};

const GlobalSettingsFormComponent: React.ForwardRefRenderFunction<
  GlobalSettingsFormHandles,
  GlobalSettingsFormProps
> = ({ globalSettings }, calculationFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    cardmarketCredentialListManually,
    setCardmarketCredentialListManually,
    cardmarketCredentialListScheduled,
    setCardmarketCredentialListScheduled,
    excludedSellers,
    setExcludedSellers,
    minPricesEnabled,
    setMinPricesEnabled,
    minPrices,
    setMinPrices,
    priceModi,
    setPriceModi,
    priceListSteps,
    setPriceListSteps,
    skipPriceCalcString,
    setSkipPriceCalcString,
    priceListCutOffPercentage,
    setPriceListCutOffPercentage,
    priceSurchargePrivate,
    setPriceSurchargePrivate,
    priceTresholdFolderChange,
    setPriceTresholdFolderChange,
    minCommercialArticles,
    setMinCommercialArticles,
    averageCalculationMethod,
    setAverageCalculationMethod,
    expansionIconsURL,
    setExpansionIconsURL,
  } = useGlobalSettingsForm(globalSettings);

  const skipPriceCalcStringInputRef = useRef<TextInputFieldHandles>(null);
  const priceListCutOffPercentageInputRef =
    useRef<CustomNumberFieldHandles>(null);
  const priceSurchargePrivateInputRef = useRef<CustomNumberFieldHandles>(null);
  const priceTresholdFolderChangeInputRef =
    useRef<CustomNumberFieldHandles>(null);
  const minCommercialArticlesInputRef = useRef<CustomNumberFieldHandles>(null);
  const averageCalculationMethodInputRef = useRef<CustomSelectHandles>(null);
  const expansionIconsURLInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(calculationFormRef, () => ({
    validateGlobalSettingsForm: () => {
      if (minCommercialArticles === "") {
        enqueueSnackbar(
          "Bitte gib die Mindestanzahl an gewerblichen Artikeln ein!",
        );
        minCommercialArticlesInputRef.current?.highlight();
        return null;
      }

      if (averageCalculationMethod === null) {
        enqueueSnackbar(
          "Bitte gib die Methode für die Durchschnittsberechnung ein!",
        );
        averageCalculationMethodInputRef.current?.highlight();
        return null;
      }

      if (skipPriceCalcString === "") {
        enqueueSnackbar(
          "Bitte gib die Zeichenkette für 'Preisberechnung Ignorieren' ein!",
        );
        skipPriceCalcStringInputRef.current?.highlight();
        return null;
      }

      if (priceListCutOffPercentage === "") {
        enqueueSnackbar("Bitte gib den Cut Off Prozent ein!");
        priceListCutOffPercentageInputRef.current?.highlight();
        return null;
      }

      if (priceListCutOffPercentage < 0 || priceListCutOffPercentage > 100) {
        enqueueSnackbar("Der Cut Off Prozent muss zwischen 0 und 100 liegen!");
        priceListCutOffPercentageInputRef.current?.highlight();
        return null;
      }

      if (priceSurchargePrivate === "") {
        enqueueSnackbar("Bitte gib den Preisaufschlag auf Privatpreise ein!");
        priceSurchargePrivateInputRef.current?.highlight();
        return null;
      }

      if (priceTresholdFolderChange === "") {
        enqueueSnackbar("Bitte gib den Preisschweller für Ordnerwechsel ein!");
        priceTresholdFolderChangeInputRef.current?.highlight();
        return null;
      }

      if (expansionIconsURL === "") {
        enqueueSnackbar("Bitte gib die URL für die Editions-Icons ein!");
        expansionIconsURLInputRef.current?.highlight();
        return null;
      }

      const updateGlobalSettingsInput: UpdateGlobalSettingsInput = {
        id: globalSettings.id,
        cardmarketCredentialsManually: cardmarketCredentialListManually,
        cardmarketCredentialsScheduled: cardmarketCredentialListScheduled,
        excludedSellers: excludedSellers,
        minPricesEnabled: minPricesEnabled,
        minPrices: minPrices,
        priceModi: priceModi,
        priceListSteps: priceListSteps,
        skipPriceCalcString: skipPriceCalcString,
        priceListCutOffPercentage: priceListCutOffPercentage,
        priceSurchargePrivate: priceSurchargePrivate,
        priceTresholdFolderChange: priceTresholdFolderChange,
        minCommercialArticles: minCommercialArticles,
        averageCalculationMethod: averageCalculationMethod,
        expansionIconsURL: expansionIconsURL,
      };

      return updateGlobalSettingsInput;
    },
  }));

  return (
    <>
      <BoxHeadlineContainer boxTitle="Preisberechnung" marginTop={false}>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <CustomNumberField
              label="Mindestanzahl an gewerblichen Artikeln"
              number={minCommercialArticles}
              setNumber={setMinCommercialArticles}
              ref={minCommercialArticlesInputRef}
              required={true}
              info={true}
              infoContent="Wenn weniger gewerbliche Artikel gefunden werden, werden die Preise von Privatangeboten genommen"
              minValue={0}
              decimalScale={0}
            />
          </Grid>
          <Grid item md={6}>
            <AverageCalculationMethodOption
              averageCalculationMethod={averageCalculationMethod}
              setAverageCalculationMethod={setAverageCalculationMethod}
              averageCalculationMethodInputRef={
                averageCalculationMethodInputRef
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <TextInputField
              label="Preisberechnung Ignorieren"
              value={skipPriceCalcString}
              onChange={({ target: { value } }) =>
                setSkipPriceCalcString(value)
              }
              validate={(value) => value.trim() !== ""}
              required={true}
              ref={skipPriceCalcStringInputRef}
              info={true}
              infoContent="Zeichenkette welche im Kommentar des Artikel gesucht wird um die Preisberechnung zu überspringen"
            />
          </Grid>
          <Grid item md={6}>
            <CustomNumberField
              label="Cut Off Prozent"
              number={priceListCutOffPercentage}
              setNumber={setPriceListCutOffPercentage}
              ref={priceListCutOffPercentageInputRef}
              unitAdornment="%"
              required={true}
              info={true}
              infoContent="Ignoriert top X Prozent der Preise"
              minValue={0}
              maxValue={100}
              decimalScale={0}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <CustomNumberField
              label="Preisaufschlag auf Privatpreise"
              number={priceSurchargePrivate}
              setNumber={setPriceSurchargePrivate}
              ref={priceSurchargePrivateInputRef}
              unitAdornment="%"
              required={true}
              info={true}
              infoContent="Multiplikator in % für Preise die von Privat berechnet werden weil die Preisberechnung zu wenig deutschen Händler gefunden hat."
              decimalScale={0}
            />
          </Grid>
          <Grid item md={6}>
            <CustomNumberField
              label="Preisschweller für Ordnerwechsel"
              number={priceTresholdFolderChange}
              setNumber={setPriceTresholdFolderChange}
              ref={priceTresholdFolderChangeInputRef}
              unitAdornment="€"
              required={true}
            />
          </Grid>
        </Grid>
      </BoxHeadlineContainer>

      <ExcludedSellerListTable
        excludedSellers={excludedSellers.sort((a, b) =>
          a.username.localeCompare(b.username),
        )}
        setExcludedSellers={setExcludedSellers}
        editable={true}
      />

      <MinPriceListTable
        minPricesEnabled={minPricesEnabled}
        onMinPricesEnabledChange={(value) => setMinPricesEnabled(value)}
        minPrices={minPrices}
        setMinPrices={setMinPrices}
        editable={true}
      />

      <PriceModusListTable
        priceModi={priceModi}
        setPriceModi={setPriceModi}
        editable={true}
      />

      <PriceListStepListTable
        priceListSteps={priceListSteps}
        setPriceListSteps={setPriceListSteps}
        editable={true}
      />

      <ExpansionIconsURLForm
        expansionIconsURL={expansionIconsURL}
        onExpansionIconsURLChange={(value) => setExpansionIconsURL(value)}
        expansionIconsURLInputRef={expansionIconsURLInputRef}
      />

      <CardmarketCredentialListTable
        title="Cardmarket Zugänge für manuelle Anfragen"
        editable={true}
        cardmarketCredentials={cardmarketCredentialListManually}
        setCardmarketCredentials={setCardmarketCredentialListManually}
      />

      <CardmarketCredentialListTable
        title="Cardmarket Zugänge für automatische Anfragen"
        editable={true}
        cardmarketCredentials={cardmarketCredentialListScheduled}
        setCardmarketCredentials={setCardmarketCredentialListScheduled}
      />
    </>
  );
};

export const GlobalSettingsForm = React.memo(
  React.forwardRef(GlobalSettingsFormComponent),
);
