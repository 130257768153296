import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  checkmark: {
    width: 27,
    height: 27,
    color: theme.palette.primary.main,
    verticalAlign: "middle",
  },
}));

export default useStyles;
