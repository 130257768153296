import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { ExcludedSeller } from "types";
import { DeleteDialog } from "components";

type ExcludedSellerDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  excludedSeller?: ExcludedSeller;
  setExcludedSellers?: (value: React.SetStateAction<ExcludedSeller[]>) => void;
};

export const ExcludedSellerDeleteDialog: React.FC<
  ExcludedSellerDeleteDialogProps
> = ({ excludedSeller, dialogOpen, setDialogOpen, setExcludedSellers }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || excludedSeller === undefined) {
    return null;
  }

  const deleteExcludedSellerRequest = async () => {
    if (!setExcludedSellers) {
      enqueueSnackbar(
        "Beim Löschen des ausgeschlossenen Händlers ist ein Fehler aufgetreten!",
      );
      return;
    }

    setDeleteLoading(true);

    setExcludedSellers((prev) =>
      prev.filter(
        (excludedSellerItem) =>
          excludedSellerItem.username !== excludedSeller.username,
      ),
    );

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteExcludedSellerRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Ausgeschlossenen Händler Löschen"
      itemName={excludedSeller.username}
      itemDeleteDescription="den ausgeschlossenen Händler mit dem Namen"
      confirmRequired={false}
    />
  );
};
