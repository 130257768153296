import { useEffect, useState } from "react";

export const usePriceListStepForm = (
  priceListStep: number | null | undefined,
) => {
  const [priceStep, setPriceStep] = useState<number | "">("");

  const resetPriceListStepForm = () => {
    setPriceStep("");
  };

  useEffect(() => {
    if (priceListStep === null || priceListStep === undefined) {
      return;
    }

    setPriceStep(priceListStep);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceListStep]);

  return {
    priceStep,
    setPriceStep,
    resetPriceListStepForm,
  };
};
