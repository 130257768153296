import React from "react";
import { useAuthContext } from "context";
import { Expansion } from "types";
import { CustomTooltip } from "core";
import { getExpansionName } from "modules/expansions/business";
import useStyles from "./styles";

type IconColumnFormatterProps = {
  expansion: Expansion | null | undefined;
};

export const IconColumnFormatter: React.FC<IconColumnFormatterProps> = ({
  expansion,
}) => {
  const { globalSettings } = useAuthContext();
  const { classes } = useStyles({ expansion, globalSettings });

  return (
    <CustomTooltip
      title={expansion ? getExpansionName(expansion) : ""}
      followCursor={true}
    >
      <span className={classes.icon} />
    </CustomTooltip>
  );
};
