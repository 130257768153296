import { useEffect, useState } from "react";
import { CardmarketCredential } from "types";

export const useCardmarketCredentialForm = (
  cardmarketCredential: CardmarketCredential | null | undefined,
) => {
  const [appToken, setAppToken] = useState<string>("");
  const [appSecret, setAppSecret] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [accessTokenSecret, setAccessTokenSecret] = useState<string>("");
  const [apiName, setApiName] = useState<string>("");
  const [baseUrl, setBaseUrl] = useState<string>("");
  const [isMainAccount, setIsMainAccount] = useState<boolean>(false);

  const resetCardmarketCredentialForm = () => {
    setAppToken("");
    setAppSecret("");
    setAccessToken("");
    setAccessTokenSecret("");
    setApiName("");
    setBaseUrl("");
    setIsMainAccount(false);

    localStorage.removeItem("cardmarketCredential_menge");
  };

  useEffect(() => {
    if (!cardmarketCredential) {
      return;
    }

    setAppToken(cardmarketCredential.appToken);
    setAppSecret(cardmarketCredential.appSecret);
    setAccessToken(cardmarketCredential.accessToken);
    setAccessTokenSecret(cardmarketCredential.accessTokenSecret);
    setApiName(cardmarketCredential.apiName);
    setBaseUrl(cardmarketCredential.baseUrl);
    setIsMainAccount(cardmarketCredential.isMainAccount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardmarketCredential]);

  return {
    appToken,
    setAppToken,
    appSecret,
    setAppSecret,
    accessToken,
    setAccessToken,
    accessTokenSecret,
    setAccessTokenSecret,
    apiName,
    setApiName,
    baseUrl,
    setBaseUrl,
    isMainAccount,
    setIsMainAccount,
    resetCardmarketCredentialForm,
  };
};
