import { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { CustomButton, TextInputField, TextInputFieldHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { InfoRoundIcon } from "modules/icons";
import { InstructionsToExpansionIconsUrlDialog } from "../InstructionsToExpansionIconsUrlDialog";
import useStyles from "./styles";

type ExpansionIconsURLFormProps = {
  expansionIconsURL: string;
  onExpansionIconsURLChange?: (value: string) => void;
  expansionIconsURLInputRef?: React.RefObject<TextInputFieldHandles>;
};

export const ExpansionIconsURLForm: FC<ExpansionIconsURLFormProps> = ({
  expansionIconsURL,
  onExpansionIconsURLChange,
  expansionIconsURLInputRef,
}) => {
  const { classes } = useStyles();

  const [instructionsDialogOpen, setInstructionsDialogOpen] =
    useState<boolean>(false);

  return (
    <BoxHeadlineContainer boxTitle="Editions-Icons URL">
      <InstructionsToExpansionIconsUrlDialog
        dialogOpen={instructionsDialogOpen}
        setDialogOpen={setInstructionsDialogOpen}
      />

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <TextInputField
            label="URL für Editions-Icons"
            value={expansionIconsURL}
            onChange={({ target: { value } }) =>
              onExpansionIconsURLChange && onExpansionIconsURLChange(value)
            }
            validate={(value) => value.trim() !== ""}
            required={true}
            ref={expansionIconsURLInputRef}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography>
            Die URL für die Editions-Icons kann über Cardmarket bezogen werden.
          </Typography>
        </Grid>

        <Grid item>
          <CustomButton
            text="Anleitung zur aktuellen URL"
            onClick={() => setInstructionsDialogOpen(true)}
            size="small"
            iconBefore={<InfoRoundIcon />}
            marginLeft={25}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
