import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CardmarketCredential } from "types";
import { CustomSwitch, TextInputField, TextInputFieldHandles } from "core";
import { useCardmarketCredentialForm } from "../useCardmarketCredentialForm";

type CardmarketCredentialFormProps = {
  cardmarketCredentialList: CardmarketCredential[];
  cardmarketCredential: CardmarketCredential | undefined;
};

export type CardmarketCredentialFormHandles = {
  validateCardmarketCredentialForm(): CardmarketCredential | null;
};

const CardmarketCredentialFormComponent: React.ForwardRefRenderFunction<
  CardmarketCredentialFormHandles,
  CardmarketCredentialFormProps
> = (
  { cardmarketCredentialList, cardmarketCredential },
  cardmarketCredentialFormRef,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    appToken,
    setAppToken,
    appSecret,
    setAppSecret,
    accessToken,
    setAccessToken,
    accessTokenSecret,
    setAccessTokenSecret,
    apiName,
    setApiName,
    baseUrl,
    setBaseUrl,
    isMainAccount,
    setIsMainAccount,
  } = useCardmarketCredentialForm(cardmarketCredential);

  const appTokenInputRef = useRef<TextInputFieldHandles>(null);
  const appSecretInputRef = useRef<TextInputFieldHandles>(null);
  const accessTokenInputRef = useRef<TextInputFieldHandles>(null);
  const accessTokenSecretInputRef = useRef<TextInputFieldHandles>(null);
  const apiNameInputRef = useRef<TextInputFieldHandles>(null);
  const baseUrlInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(cardmarketCredentialFormRef, () => ({
    validateCardmarketCredentialForm: () => {
      if (appToken === "") {
        enqueueSnackbar("Bitte gib einen App Token ein!");
        appTokenInputRef.current?.highlight(false);
        return null;
      }

      const appTokenExists = cardmarketCredentialList.find(
        (cardmarketCredentialItem) =>
          cardmarketCredentialItem.appToken === appToken,
      );

      if (appTokenExists && cardmarketCredential?.id !== appTokenExists.id) {
        enqueueSnackbar("Ein Zugang mit diesem App Token existiert bereits!");
        appTokenInputRef.current?.highlight(false);
        return null;
      }

      if (appSecret === "") {
        enqueueSnackbar("Bitte gib ein App Secret ein!");
        appSecretInputRef.current?.highlight(false);
        return null;
      }

      if (accessToken === "") {
        enqueueSnackbar("Bitte gib einen Access Token ein!");
        accessTokenInputRef.current?.highlight(false);
        return null;
      }

      if (accessTokenSecret === "") {
        enqueueSnackbar("Bitte gib ein Access Token Secret ein!");
        accessTokenSecretInputRef.current?.highlight(false);
        return null;
      }

      if (apiName === "") {
        enqueueSnackbar("Bitte gib einen API Namen ein!");
        apiNameInputRef.current?.highlight(false);
        return null;
      }

      if (baseUrl === "") {
        enqueueSnackbar("Bitte gib eine Base URL ein!");
        baseUrlInputRef.current?.highlight(false);
        return null;
      }

      const cardmarketCredentialFormInput: CardmarketCredential = {
        id: appToken,
        appToken: appToken,
        appSecret: appSecret,
        accessToken: accessToken,
        accessTokenSecret: accessTokenSecret,
        apiName: apiName,
        baseUrl: baseUrl,
        isMainAccount: isMainAccount,
      };

      return cardmarketCredentialFormInput;
    },
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={5}>
          <Grid item md={8}>
            <TextInputField
              label="API Name"
              value={apiName}
              onChange={({ target: { value } }) => setApiName(value)}
              validate={(value) => value.trim() !== ""}
              ref={apiNameInputRef}
              required={true}
            />
          </Grid>

          <Grid item md={4}>
            <CustomSwitch
              label="Ist Hauptaccount"
              name="isMainAccount"
              switchLabel={isMainAccount ? "Ja" : "Nein"}
              checkedValue={isMainAccount}
              onChange={(value) => setIsMainAccount(value.target.checked)}
              required={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextInputField
          label="App Token"
          value={appToken}
          onChange={({ target: { value } }) => setAppToken(value)}
          validate={(value) => value.trim() !== ""}
          ref={appTokenInputRef}
          required={true}
        />
      </Grid>
      <Grid item>
        <TextInputField
          label="App Secret"
          value={appSecret}
          onChange={({ target: { value } }) => setAppSecret(value)}
          validate={(value) => value.trim() !== ""}
          ref={appSecretInputRef}
          required={true}
        />
      </Grid>

      <Grid item>
        <TextInputField
          label="Access Token"
          value={accessToken}
          onChange={({ target: { value } }) => setAccessToken(value)}
          validate={(value) => value.trim() !== ""}
          ref={accessTokenInputRef}
          required={true}
        />
      </Grid>
      <Grid item>
        <TextInputField
          label="Access Token Secret"
          value={accessTokenSecret}
          onChange={({ target: { value } }) => setAccessTokenSecret(value)}
          validate={(value) => value.trim() !== ""}
          ref={accessTokenSecretInputRef}
          required={true}
        />
      </Grid>

      <Grid item>
        <TextInputField
          label="Base URL"
          value={baseUrl}
          onChange={({ target: { value } }) => setBaseUrl(value)}
          validate={(value) => value.trim() !== ""}
          ref={baseUrlInputRef}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export const CardmarketCredentialForm = React.forwardRef(
  CardmarketCredentialFormComponent,
);
