import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { CardmarketCalcTask } from "types";
import { CustomReactTable, CustomReactTableHandles, CustomTag } from "core";
import { getExpansionName } from "modules/expansions/business";
import { getTaskStatusName } from "modules/shared/options/TaskStatusOption";
import utils from "utils";

type CardmarketCalcTaskListTableProps = {
  isLoading: boolean;
  cardmarketCalcTaskList: CardmarketCalcTask[];
  cardmarketCalcTaskTableRef?: React.RefObject<
    CustomReactTableHandles<CardmarketCalcTask>
  >;
};

export const CardmarketCalcTaskListTable: React.FC<
  CardmarketCalcTaskListTableProps
> = ({ isLoading, cardmarketCalcTaskList, cardmarketCalcTaskTableRef }) => {
  const { expansionList } = useExpansionList(false);

  const defaultColumns = React.useMemo<ColumnDef<CardmarketCalcTask>[]>(
    () => [
      {
        header: "Aufgabentyp",
        accessorKey: "taskType",
        accessorFn: (row) =>
          row.taskType === "Manually" ? "Manuell" : "Automatisch",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.taskType === "Manually" ? "Manuell" : "Automatisch",
        },
        enableHiding: true,
      },
      {
        header: "Edition",
        accessorKey: "expansionID",
        accessorFn: (row) => {
          const expansion = expansionList.find(
            (exp) => exp.id === row.expansionID,
          );

          return expansion
            ? getExpansionName(expansion)
            : "Edition " + row.expansionID.replace("exp_", "");
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => {
            const expansion = expansionList.find(
              (exp) => exp.id === row.original.expansionID,
            );

            return expansion
              ? getExpansionName(expansion)
              : "Edition " + row.original.expansionID.replace("exp_", "");
          },
        },
        enableHiding: true,
      },
      {
        header: "Start",
        accessorKey: "startTime",
        accessorFn: (row) =>
          utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
            row.startTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
              row.original.startTime,
            ),
          isDefaultSort: true,
          defaultSortOrder: "desc",
        },
        enableHiding: true,
      },
      {
        header: "Ende",
        accessorKey: "endTime",
        accessorFn: (row) =>
          row.endTime
            ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                row.endTime,
              )
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.endTime
              ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                  row.original.endTime,
                )
              : "",
        },
        enableHiding: true,
      },
      {
        header: "Dauer",
        accessorKey: "duration",
        accessorFn: (row) =>
          utils.dates.getDurationFromAWSDateTimeFormat(
            row.startTime,
            row.endTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDurationFromAWSDateTimeFormat(
              row.original.startTime,
              row.original.endTime,
            ),
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <CustomTag
            tagColor={
              row.original.status === "Completed"
                ? "green"
                : row.original.status === "Running"
                  ? "yellow"
                  : row.original.status === "Failed"
                    ? "red"
                    : "blue"
            }
            content={getTaskStatusName(row.original.status)}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => getTaskStatusName(row.original.status),
          align: "center",
        },
      },
    ],
    [expansionList],
  );

  return (
    <CustomReactTable<CardmarketCalcTask>
      data={cardmarketCalcTaskList}
      columns={defaultColumns}
      isLoadingDataList={isLoading}
      ref={cardmarketCalcTaskTableRef}
    />
  );
};
