import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { PriceModus } from "types";
import { CustomButton, CustomReactTable, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { getExpansionName } from "modules/expansions/business";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { PriceModusDeleteDialog } from "../../pricemodusDelete/PriceModusDeleteDialog";
import { PriceModusFormDialog } from "../../pricemodusForm/PriceModusFormDialog";

type PriceModusListTableProps = {
  editable: boolean;
  priceModusTableRef?: React.RefObject<CustomReactTableHandles<PriceModus>>;
  priceModi: PriceModus[];
  setPriceModi?: (value: React.SetStateAction<PriceModus[]>) => void;
};

export const PriceModusListTable: React.FC<PriceModusListTableProps> = ({
  editable,
  priceModusTableRef,
  priceModi,
  setPriceModi,
}) => {
  console.log("priceModi: ", priceModi);

  const { expansionList } = useExpansionList(false);

  const [deletePriceModusDialogOpen, setDeletePriceModusDialogOpen] =
    useState<boolean>(false);
  const [priceModusToDelete, setPriceModusToDelete] = useState<
    PriceModus | undefined
  >();

  const [createPriceModusDialogOpen, setCreatePriceModusDialogOpen] =
    useState<boolean>(false);

  const [editPriceModusDialogOpen, setEditPriceModusDialogOpen] =
    useState<boolean>(false);
  const [priceModusToEdit, setPriceModusToEdit] = useState<
    PriceModus | undefined
  >();

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<PriceModus>[]>(
    () => [
      {
        header: "Priorität",
        accessorKey: "priority",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.priority,
        },
      },
      {
        header: "Edition",
        accessorKey: "expansionID",
        accessorFn: (row) => {
          if (!row.expansionID) return "Alle";

          const expansion = expansionList.find(
            (expansion) => expansion.id === row.expansionID,
          );

          return expansion ? getExpansionName(expansion) : "Alle";
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => {
            if (!row.original.expansionID) return "Alle";

            const expansion = expansionList.find(
              (expansion) => expansion.id === row.original.expansionID,
            );

            return expansion ? getExpansionName(expansion) : "Alle";
          },
        },
      },
      {
        header: "Rarität",
        accessorKey: "rarity",
        accessorFn: (row) => row.rarity ?? "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.rarity ?? "Alle",
        },
      },
      {
        header: "Foil",
        accessorKey: "isFoil",
        accessorFn: (row) =>
          row.isFoil === true ? "Ja" : row.isFoil === false ? "Nein" : "Alle",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.isFoil === true
              ? "Ja"
              : row.original.isFoil === false
                ? "Nein"
                : "Alle",
        },
      },
      {
        header: "Preisfaktor Laden",
        accessorKey: "priceFactorStore",
        accessorFn: (row) => row.priceFactorStore.toLocaleString("de-DE"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.priceFactorStore.toLocaleString("de-DE"),
        },
      },
      {
        header: "Preisfaktor Online",
        accessorKey: "priceFactorOnline",
        accessorFn: (row) => row.priceFactorOnline.toLocaleString("de-DE"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) =>
            row.original.priceFactorOnline.toLocaleString("de-DE"),
        },
      },
      ...(!editable
        ? []
        : ([
            {
              header: "Aktionen",
              cell: ({ row }) => (
                <ActionsColumnFormatter<PriceModus>
                  row={row}
                  listIntent="list"
                  setRowToEdit={setPriceModusToEdit}
                  setEditRowDialogOpen={setEditPriceModusDialogOpen}
                  setRowToDelete={setPriceModusToDelete}
                  setDeleteRowDialogOpen={setDeletePriceModusDialogOpen}
                />
              ),
              footer: (props) => props.column.id,
            },
          ] as ColumnDef<PriceModus>[])),
    ],
    [editable, expansionList],
  );

  return (
    <BoxHeadlineContainer
      boxTitle="Preismodi"
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        editable && (
          <CustomButton
            text="Neuer Preismodus"
            onClick={() => setCreatePriceModusDialogOpen(true)}
            size="small"
          />
        )
      }
    >
      {priceModusToDelete && (
        <PriceModusDeleteDialog
          dialogOpen={deletePriceModusDialogOpen}
          setDialogOpen={setDeletePriceModusDialogOpen}
          expansionList={expansionList}
          priceModus={priceModusToDelete}
          setPriceModi={setPriceModi}
        />
      )}

      <PriceModusFormDialog
        formIntent="create"
        dialogOpen={createPriceModusDialogOpen}
        setDialogOpen={setCreatePriceModusDialogOpen}
        expansionList={expansionList}
        priceModusList={priceModi}
        setPriceModi={setPriceModi}
      />

      {priceModusToEdit && (
        <PriceModusFormDialog
          formIntent="edit"
          dialogOpen={editPriceModusDialogOpen}
          setDialogOpen={setEditPriceModusDialogOpen}
          expansionList={expansionList}
          priceModusList={priceModi}
          priceModus={priceModusToEdit}
          setPriceModi={setPriceModi}
        />
      )}
      <CustomReactTable<PriceModus>
        data={priceModi}
        columns={defaultColumns}
        ref={priceModusTableRef}
        marginBottom={0}
        leftSpacing="medium"
      />
    </BoxHeadlineContainer>
  );
};
