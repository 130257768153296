import { Expansion, Product } from "types";

export const searchRequest = async (
  searchInput: string,
  includeExpansions: boolean,
  includeProducts: boolean,
  searchProductsHandler: (searchInput: string) => Promise<Product[]>,
  searchExpansionsHandler: (searchInput: string) => Promise<Expansion[]>,
): Promise<{
  expansions: Expansion[];
  products: Product[];
}> => {
  const [expansions, products] = await Promise.all([
    searchExpansions(searchInput, includeExpansions, searchExpansionsHandler),
    searchProducts(searchInput, includeProducts, searchProductsHandler),
  ]);

  return {
    expansions,
    products,
  };
};

export const searchExpansions = async (
  searchInput: string,
  includeExpansions: boolean,
  searchExpansionsHandler: (searchInput: string) => Promise<Expansion[]>,
): Promise<Expansion[]> => {
  if (!includeExpansions) {
    return [];
  }

  console.log("Searching for expansions with searchInput: ", searchInput);

  const expansions: Expansion[] = await searchExpansionsHandler(searchInput);

  return expansions;
};

export const searchProducts = async (
  searchInput: string,
  includeProducts: boolean,
  searchProductsHandler: (searchInput: string) => Promise<Product[]>,
): Promise<Product[]> => {
  if (!includeProducts) {
    return [];
  }

  console.log("Searching for products with searchInput: ", searchInput);
  const products: Product[] = await searchProductsHandler(searchInput);

  return products;
};
