import React, { useState } from "react";
import { Button } from "@mui/material";
import { useProductUpdate } from "hooks";
import { Product } from "types";
import { TextEditField } from "../TextEditField";
import useStyles from "./styles";

type ProductAliasColumnFormatterProps = {
  product: Product;
};

export const ProductAliasColumnFormatter: React.FC<
  ProductAliasColumnFormatterProps
> = ({ product }) => {
  const { classes, cx } = useStyles();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<Product>(product);

  const updateMutation = useProductUpdate();

  if (!isSelected) {
    return (
      <Button
        onClick={() => setIsSelected(true)}
        className={cx(
          classes.aliasButton,
          currentProduct.productAlias
            ? classes.aliasButtonFilled
            : classes.aliasButtonEmpty,
        )}
      >
        {currentProduct.productAlias ?? "alias"}
      </Button>
    );
  }

  const submitProductAlias = async (value: string) => {
    if (value.trim() !== product.productAlias) {
      setCurrentProduct((prev) => ({
        ...prev,
        productAlias: value.trim() === "" ? null : value.trim(),
      }));

      updateMutation.mutate({
        id: product.id,
        expansionID: product.expansionID,
        productAlias: value.trim() === "" ? null : value.trim(),
      });
    }

    setIsSelected(false);
  };

  return (
    <TextEditField
      id={product.id}
      defaultValue={currentProduct.productAlias ?? null}
      submitValue={submitProductAlias}
      onCancel={() => setIsSelected(false)}
    />
  );
};
