import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { MinPrice } from "types";
import { CustomNumberField, CustomNumberFieldHandles } from "core";
import { BooleanOption } from "modules/shared/options/BooleanOption";
import { RarityWithAllOption } from "modules/shared/options/RarityWithAllOption";
import { useMinPriceForm } from "../useMinPriceForm";

type MinPriceFormProps = {
  formIntent: "create" | "edit";
  minPriceList: MinPrice[];
  minPrice: MinPrice | undefined;
};

export type MinPriceFormHandles = {
  validateMinPriceForm(): MinPrice | null;
};

const MinPriceFormComponent: React.ForwardRefRenderFunction<
  MinPriceFormHandles,
  MinPriceFormProps
> = ({ formIntent, minPriceList, minPrice }, minPriceFormRef) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    priority,
    setPriority,
    minPriceStore,
    setMinPriceStore,
    minPriceOnline,
    setMinPriceOnline,
    rarity,
    setRarity,
    isFoil,
    setIsFoil,
  } = useMinPriceForm(formIntent, minPriceList, minPrice);

  const priorityInputRef = useRef<CustomNumberFieldHandles>(null);
  const minPriceStoreInputRef = useRef<CustomNumberFieldHandles>(null);
  const minPriceOnlineInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(minPriceFormRef, () => ({
    validateMinPriceForm: () => {
      if (priority === "") {
        enqueueSnackbar("Bitte gib eine Priorität ein!");
        priorityInputRef.current?.highlight(false);
        return null;
      }

      const existingPriority = minPriceList.find(
        (minPriceItem) =>
          minPriceItem.id !== minPrice?.id &&
          minPriceItem.priority === priority,
      );

      if (existingPriority) {
        enqueueSnackbar(
          "Es existiert bereits ein Staffelpreis mit der gleichen Priorität!",
        );
        priorityInputRef.current?.highlight(false);
        return null;
      }

      if (minPriceStore === "") {
        enqueueSnackbar("Bitte gib einen Mindestpreis für den Laden ein!");
        minPriceStoreInputRef.current?.highlight(false);
        return null;
      }

      if (minPriceOnline === "") {
        enqueueSnackbar("Bitte gib einen Mindestpreis für Online ein!");
        minPriceOnlineInputRef.current?.highlight(false);
        return null;
      }

      const existingMinPrice = minPriceList.find(
        (minPriceItem) =>
          minPriceItem.id !== minPrice?.id &&
          ((!minPriceItem.rarity && rarity === "All") ||
            minPriceItem.rarity === rarity) &&
          (((minPriceItem.rarity === null ||
            minPriceItem.rarity === undefined) &&
            isFoil === "All") ||
            minPriceItem.isFoil === (isFoil === "true")),
      );

      if (existingMinPrice) {
        enqueueSnackbar(
          "Es existiert bereits ein Staffelpreis mit der gleichen Rarity und Foil!",
        );
        return null;
      }

      const minPriceFormInput: MinPrice = {
        id: minPrice ? minPrice.id : nanoid(),
        priority,
        minPriceStore,
        minPriceOnline,
        rarity: rarity === "All" ? null : rarity,
        isFoil: isFoil === "All" ? null : isFoil === "true",
      };

      return minPriceFormInput;
    },
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CustomNumberField
          label="Priorität"
          number={priority}
          setNumber={setPriority}
          ref={priorityInputRef}
          decimalScale={0}
          required={true}
        />
      </Grid>
      <Grid item>
        <RarityWithAllOption
          label="Rarity"
          rarity={rarity}
          setRarity={setRarity}
          required={true}
        />
      </Grid>
      <Grid item>
        <BooleanOption
          label="Foil"
          boolean={isFoil}
          setBoolean={setIsFoil}
          required={true}
        />
      </Grid>

      <Grid item>
        <CustomNumberField
          label="Mindestpreis Laden"
          number={minPriceStore}
          setNumber={setMinPriceStore}
          ref={minPriceStoreInputRef}
          unitAdornment="€"
          required={true}
        />
      </Grid>

      <Grid item>
        <CustomNumberField
          label="Mindestpreis Online"
          number={minPriceOnline}
          setNumber={setMinPriceOnline}
          ref={minPriceOnlineInputRef}
          unitAdornment="€"
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export const MinPriceForm = React.forwardRef(MinPriceFormComponent);
