import React from "react";
import { Grid, Typography } from "@mui/material";
import { CustomDialog } from "core";
import useStyles from "./styles";

type InstructionsToExpansionIconsUrlDialogDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
};

export const InstructionsToExpansionIconsUrlDialog: React.FC<
  InstructionsToExpansionIconsUrlDialogDialogProps
> = ({ dialogOpen, setDialogOpen }) => {
  const { classes } = useStyles();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={true}
      titleText="Anleitung zur Änderung der URL für die Editions-Icons"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={true}
      minWidth={700}
    >
      <Typography align="center" variant="h2" className={classes.dialogTitle}>
        Über diese Anleitung kannst du die aktuelle URL für die Editions-Icons
        finden.
      </Typography>

      <Grid container direction="column" gap={2}>
        <Grid item>
          <Typography variant="h3" className={classes.instruction}>
            1. Öffne die Website{" "}
            <a target="_blank" href="https://www.cardmarket.com">
              Cardmarket
            </a>{" "}
            und klicke auf eine beliebige Karte mit Icon:
          </Typography>
          <img src="/anleitung/anleitung1.png" />
        </Grid>

        <Grid item>
          <Typography variant="h3" className={classes.instruction}>
            2. Klicke mit der rechten Maustaste auf das Icon, das sich in der
            Infokarte bei "Erschienen in" befindet:
          </Typography>
          <img src="/anleitung/anleitung2.png" />
        </Grid>

        <Grid item>
          <Typography variant="h3" className={classes.instruction}>
            3. Klicke in dem sich öffnenden Kontextmenü auf "Untersuchen", um
            das Entwickler-Tool zu öffnen:
          </Typography>
          <img src="/anleitung/anleitung3.png" />
        </Grid>

        <Grid item>
          <Typography variant="h3" className={classes.instruction}>
            4. Die URL für die Editions-Icons findest du im Inspektor des
            Entwickler-Tools unter "background-image" des span-Elements.
            <br />
            Kopiere den Link mit Rechtsklick und "Adresse kopieren":
          </Typography>
          <img src="/anleitung/anleitung4.png" />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
