import React, { useMemo, useState } from "react";
import { client } from "queryClient";
import { CreateSyncTaskInput, Expansion } from "types";
import { CustomButton } from "core";
import { useSyncTaskListUIContext } from "../../SyncTaskListUIContext";

type ExpansionSyncColumnFormatterProps = {
  expansion: Expansion;
};

export const ExpansionSyncColumnFormatter: React.FC<
  ExpansionSyncColumnFormatterProps
> = ({ expansion }) => {
  const syncTaskListUIContext = useSyncTaskListUIContext();
  const syncTaskListUIProps = useMemo(() => {
    return {
      syncTaskList: syncTaskListUIContext.syncTaskList,
    };
  }, [syncTaskListUIContext]);

  const runningSyncTask = syncTaskListUIProps.syncTaskList.some(
    (syncTask) =>
      syncTask.status === "Running" || syncTask.status === "Pending",
  );

  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);

  const handleSyncTaskStart = async (syncExpansionWithoutProducts: boolean) => {
    setIsSyncLoading(true);

    const createSyncTaskInput: CreateSyncTaskInput = {
      taskType: "Sync",
      startTime: new Date().toISOString(),
      status: "Pending",
      expansionID: expansion.id,
      syncExpansionWithoutProducts,
    };

    await client.models.SyncTask.create(createSyncTaskInput);

    setIsSyncLoading(false);
  };

  return (
    <>
      <CustomButton
        text="Nur Edition importieren"
        size="xsmall"
        onClick={() => handleSyncTaskStart(true)}
        loading={isSyncLoading}
        disabled={runningSyncTask}
      />
      <CustomButton
        text="Edition und Karten importieren"
        size="xsmall"
        onClick={() => handleSyncTaskStart(false)}
        loading={isSyncLoading}
        disabled={runningSyncTask}
      />
    </>
  );
};
