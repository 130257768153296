import React, { FC, useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import { Expansion } from "types";
import { CustomSelect, CustomSelectHandles } from "core";
import { getExpansionName } from "modules/expansions/business";
import { CheckmarkBoldIcon, CloseBoldIcon } from "modules/icons";
import useStyles from "./styles";

type ExpansionSelectFieldProps = {
  defaultValue: Expansion | null;
  expansionList: Expansion[];
  onCancel: () => void;
  submitValue: (value: Expansion | null) => void;
  id: string;
};

export const ExpansionSelectField: FC<ExpansionSelectFieldProps> = ({
  defaultValue,
  expansionList,
  onCancel,
  submitValue,
  id,
}) => {
  const { classes } = useStyles();

  const [value, setValue] = useState<Expansion | null>(defaultValue);

  const inputRef = useRef<CustomSelectHandles>(null);

  useEffect(() => {
    inputRef.current?.highlight(false);
    // inputRef.current?.select();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        event.stopPropagation();
        submitValue(value);
        break;
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        onCancel();
        break;
    }
  };

  return (
    <div className={classes.selectWrapper}>
      <CustomSelect<Expansion>
        id={id}
        options={expansionList}
        value={value}
        onChange={(expansion) => setValue(expansion)}
        onKeyDown={handleKeyDown}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => getExpansionName(option)}
        placeholder="Bitte Edition auswählen"
        isClearable={true}
        minWidth={270}
        ref={inputRef}
      />
      <IconButton
        onClick={() => submitValue(value)}
        className={classes.submitButton}
      >
        <CheckmarkBoldIcon />
      </IconButton>

      <IconButton onClick={() => onCancel()} className={classes.cancelButton}>
        <CloseBoldIcon />
      </IconButton>
    </div>
  );
};
