import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { MinPrice } from "types";
import { CustomDialog } from "core";
import { MinPriceForm, MinPriceFormHandles } from "../MinPriceForm";

type MinPriceFormDialogProps = {
  formIntent: "create" | "edit";
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  minPriceList: MinPrice[];
  minPrice?: MinPrice;
  setMinPrices?: (value: React.SetStateAction<MinPrice[]>) => void;
};

export const MinPriceFormDialog: React.FC<MinPriceFormDialogProps> = ({
  formIntent,
  dialogOpen,
  setDialogOpen,
  minPriceList,
  minPrice,
  setMinPrices,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const minPriceFormRef = useRef<MinPriceFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitMinPrice = async () => {
    const minPriceFormInput = minPriceFormRef.current?.validateMinPriceForm();
    if (!minPriceFormInput) {
      console.error("MinPriceForm is invalid...");
      return;
    }

    if (!authContext.user || !setMinPrices) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Mindestpreises ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log("minPriceFormInput: ", minPriceFormInput);

    if (formIntent === "create") {
      setMinPrices((prev) => [...prev, minPriceFormInput]);
    } else {
      setMinPrices((prev) =>
        prev.map((minPriceItem) =>
          minPriceItem.id !== minPrice?.id ? minPriceItem : minPriceFormInput,
        ),
      );
    }

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText={
        "Mindestpreis " + (formIntent === "edit" ? "speichern" : "anlegen")
      }
      dialogOpen={dialogOpen}
      positive={true}
      titleText={
        "Mindestpreis " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitMinPrice()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <MinPriceForm
        formIntent={formIntent}
        minPriceList={minPriceList}
        minPrice={minPrice}
        ref={minPriceFormRef}
      />
    </CustomDialog>
  );
};
