import React from "react";
import { CustomTooltip } from "core";
import { StarFoilIcon } from "modules/icons";
import useStyles from "./styles";

type FoilColumnFormatterProps = {
  isFoil: boolean | null | undefined;
};

export const FoilColumnFormatter: React.FC<FoilColumnFormatterProps> = ({
  isFoil,
}) => {
  const { classes } = useStyles();

  if (!isFoil) return null;

  return (
    <CustomTooltip title="Foil" followCursor={true}>
      <div className={classes.foilIcon}>
        <StarFoilIcon />
      </div>
    </CustomTooltip>
  );
};
