import React from "react";
import { client } from "queryClient";
import { IntervalTypeWithEvery, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface IntervalTypeWithEveryOptionProps
  extends Partial<CustomSelectOptionProps<IntervalTypeWithEvery>> {
  intervalType: IntervalTypeWithEvery | null;
  setIntervalType: (
    value: React.SetStateAction<IntervalTypeWithEvery | null>,
  ) => void;
  intervalTypeInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getIntervalTypeWithEveryName = (value: IntervalTypeWithEvery) => {
  switch (value) {
    case "Every":
      return "alle X Intervall";
    case "Daily":
      return "täglich";
    case "Weekly":
      return "wöchentlich";
    case "Monthly":
      return "monatlich";
    case "Yearly":
      return "jährlich";
    default:
      return value;
  }
};

export const getIntervalTypeWithEveryEnumByName = (
  value: string,
): IntervalTypeWithEvery => {
  switch (value) {
    case "alle X Intervall":
      return "Every";
    case "täglich":
      return "Daily";
    case "wöchentlich":
      return "Weekly";
    case "monatlich":
      return "Monthly";
    case "jährlich":
      return "Yearly";
    default:
      return "Daily";
  }
};

export const getIntervalTypeWithEveryOptions = (): SelectOption[] => {
  const values = client.enums.IntervalType?.values() ?? [];

  return [
    ...values.map((intervalType) => ({
      value: intervalType,
      label: getIntervalTypeWithEveryName(intervalType),
    })),
    {
      value: "Every",
      label: "alle X Intervall",
    },
  ];
};

export const IntervalTypeWithEveryOption: React.FC<
  IntervalTypeWithEveryOptionProps
> = ({ intervalType, setIntervalType, intervalTypeInputRef, ...rest }) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Intervall"
    options={getIntervalTypeWithEveryOptions()}
    onChange={(intervalTypeOption) =>
      setIntervalType(intervalTypeOption.value as IntervalTypeWithEvery)
    }
    value={
      intervalType
        ? {
            value: intervalType,
            label: getIntervalTypeWithEveryName(intervalType),
          }
        : null
    }
    placeholder="Intervall auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={intervalTypeInputRef}
  />
);
