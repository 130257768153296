import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { SelectOption } from "types";
import { CustomSelect } from "core";

type OptionsFilterProps = {
  param: string;
  paramName: string;
  options: SelectOption[];
  defaultOption?: SelectOption;
};

export const OptionsFilter: React.FC<OptionsFilterProps> = ({
  param,
  paramName,
  options,
  defaultOption,
}) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [option, setOption] = useState<SelectOption | null>({
    label: "Alle",
    value: "all",
  });

  const optionList = useMemo(
    () => [
      {
        label: "Alle",
        value: "all",
      },
      ...options,
    ],
    [options],
  );

  useEffect(() => {
    const optionParam = searchParams.get(param);

    const selectedOption = optionList.find(
      (optionItem) => optionItem.value === optionParam,
    );

    setOption(
      selectedOption ??
        defaultOption ?? {
          label: "Alle",
          value: "all",
        },
    );
  }, [defaultOption, optionList, param, searchParams]);

  return (
    <CustomSelect<SelectOption>
      label={paramName}
      options={optionList}
      onChange={(optionOption) => {
        setOption(optionOption);
        if (
          defaultOption
            ? optionOption.value === defaultOption.value
            : optionOption.value === "all"
        ) {
          searchParams.delete(param);
        } else {
          searchParams.set(param, optionOption.value);
        }
        sessionStorage.setItem(pathname, searchParams.toString());
        setSearchParams(searchParams, {
          replace: true,
        });
      }}
      value={option}
    />
  );
};
