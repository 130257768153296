import React from "react";
import { Grid2 } from "@mui/material";
import { FilterRow, FilterWrapper } from "components";
import { TaskStatusFilter } from "modules/shared/filters/TaskStatusFilter";

export const CardmarketCalcTaskListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid2>
        <TaskStatusFilter />
      </Grid2>
    </FilterRow>
  </FilterWrapper>
);
