import React from "react";
import { IconButton, Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context";
import useStyles from "./styles";

type HeaderLogoProps = {
  layoutType: "layout" | "authLayout" | "giessPlan";
};

export const HeaderLogo: React.FC<HeaderLogoProps> = ({
  layoutType = "layout",
}) => {
  const authContext = useAuthContext();
  const { classes, cx, theme } = useStyles({ layoutType });
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        layoutType === "giessPlan" || layoutType === "authLayout"
          ? classes.headerLogoAuthLayout
          : classes.headerLogoLayout,
        layoutType === "giessPlan" ||
          layoutType === "authLayout" ||
          authContext.menuOpen
          ? null
          : classes.headerLogoSmall
      )}
    >
      <IconButton
        classes={{
          root: cx(
            classes.logoButton,
            authContext.menuOpen ? null : classes.logoButtonSmall
          ),
        }}
        onClick={() => navigate("/")}
      >
        <Slide
          in={!authContext.menuOpen && layoutType === "layout"}
          direction="left"
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeInOut,
          }}
        >
          <img
            src="/logos/logo-icon.png"
            className={cx(
              classes.logoIcon,
              authContext.menuOpen ? null : classes.logoIconClosed
            )}
            alt=""
          />
        </Slide>
        <Slide
          in={
            layoutType === "giessPlan" ||
            layoutType === "authLayout" ||
            authContext.menuOpen
          }
          direction="right"
        >
          <img
            src={
              layoutType === "layout"
                ? "/logos/logo-default.png"
                : "/logos/logo-dark.png"
            }
            className={classes.logo}
            alt=""
          />
        </Slide>
      </IconButton>
    </div>
  );
};
