import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  calendar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: 27,

    "& svg": {
      width: 27,
      height: 27,
    },
  },
  avgShippingTime: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    textAlign: "center",
    fontSize: 12 /* Passe die Schriftgröße nach Bedarf an */,
    marginTop: 7,
  },
}));

export default useStyles;
