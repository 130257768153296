import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { CardmarketCredential } from "types";
import { DeleteDialog } from "components";

type CardmarketCredentialDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  cardmarketCredential?: CardmarketCredential;
  setCardmarketCredentials?: (
    value: React.SetStateAction<CardmarketCredential[]>,
  ) => void;
};

export const CardmarketCredentialDeleteDialog: React.FC<
  CardmarketCredentialDeleteDialogProps
> = ({
  cardmarketCredential,
  dialogOpen,
  setDialogOpen,
  setCardmarketCredentials,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || cardmarketCredential === undefined) {
    return null;
  }

  const deleteCardmarketCredentialRequest = async () => {
    if (!setCardmarketCredentials) {
      enqueueSnackbar("Beim Löschen des Zugangs ist ein Fehler aufgetreten!");
      return;
    }

    setDeleteLoading(true);

    setCardmarketCredentials((prev) =>
      prev.filter(
        (cardmarketCredentialItem) =>
          cardmarketCredentialItem.id !== cardmarketCredential.id,
      ),
    );

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteCardmarketCredentialRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Zugang Löschen"
      itemName={cardmarketCredential.appToken}
      itemDeleteDescription="den Zugang mit dem App Token"
    />
  );
};
