import React from "react";
import { matchPath } from "react-router-dom";
import CalcTaskListPage from "modules/calctasks/calctasklist/CalcTaskListPage";
import CardmarketCalcTaskListPage from "modules/cardmarketcalctasks/cardmarketcalctasklist/CardmarketCalcTaskListPage";
import Dashboard from "modules/dashboard";
import Development from "modules/development/dev";
import GlobalSettingsPage from "modules/globalsettings/globalsettingsDetails/GlobalSettingsPage";
import GlobalSettingsFormPage from "modules/globalsettings/globalsettingsForm/GlobalSettingsFormPage";
import {
  CardmarketSyncIcon,
  GlobalSettingsIcon,
  LogoutIcon,
  PackagingInventoryIcon,
  PriceCalculationIcon,
  SettingsIcon,
  SpieleladenIcon,
} from "modules/icons";
import ProductPriceListPage from "modules/products/productpricelist/ProductPriceListPage";
import { USER_GROUPS } from "modules/roles/api";
import StockTaskListPage from "modules/stocktasks/stocktasklist/StockTaskListPage";
import SyncTaskListPage from "modules/synctasks/synctasklist/SyncTaskListPage";
import UserSettingsPage from "modules/usersettings/usersettingsDetails/UserSettingsPage";
import UserSettingsFormPage from "modules/usersettings/usersettingsForm/UserSettingsFormPage";
// import { Placeholder } from "modules/development/placeholder";
import { expansions } from "./moduleroutes/expansions";
import { products } from "./moduleroutes/products";
import { users } from "./moduleroutes/users";
import { ConfirmForgotPassword } from "../authentication/confirmForgotPassword";
import ForgotPassword from "../authentication/forgotPassword";
import Login from "../authentication/login";
import Logout from "../authentication/logout";

// Authentication Views
// Other Views

export interface RouteType {
  key: string;
  path: string;
  section: string;
  title: string;
  view: React.ReactNode;
  icon: React.ReactNode;
  groups: string[];
  level: number;
  navigation: boolean;
  indented: boolean;
  isWithParam: boolean;
}

export interface RouteMapType extends RouteType {
  subNavigations: Record<string, RouteMapType>;
}

export interface RouteArrayType extends RouteType {
  subNavigations: RouteArrayType[];
}

export const Sections: Record<string, string> = {
  verwaltung: "Verwaltung",
  grunddaten: "Grunddaten",
  admin: "Admin",
  account: "Account",
  expansion: "Edition",
  ordering: "Auftragswesen",
  accounting: "Buchhaltung",
  logistics: "Logistik",
  qualitymanagement: "Qualitätsmanagement",
  production: "Produktion",
  finance: "Finanzwesen",
  addresses: "Adressen",
  subcontractor: "Subunternehmen",
  form: "Form",
  stock: "Bestand",
  zincstock: "Zinkbestand",
};

/**
 * Routes by default exports the paths NOT under authentication.
 *
 * (!) Warning: Non navigation routes should not have navigation: true set
 * since they lack needed information
 *
 * (!) Warning: The order here is important, since we map over possible navigations
 * to render sidebar items
 */
export const Routes = {
  start: {
    key: "start",
    path: "/",
    section: "general",
    title: "Dashboard",
    view: <Dashboard />,
    icon: <SpieleladenIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
    level: 0,
    navigation: false,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  development: {
    key: "development",
    path: "/dev",
    section: "general",
    title: "Datentest",
    view: <Development />,
    icon: <SpieleladenIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: false,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  expansions: expansions,
  products: products,
  synchronization: {
    key: "synchronization",
    path: "/synchronization",
    section: "admin",
    title: "Synchronisation",
    view: <SyncTaskListPage />,
    icon: <CardmarketSyncIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  stockSync: {
    key: "stocksync",
    path: "/stocksync",
    section: "admin",
    title: "Bestände",
    view: <StockTaskListPage />,
    icon: <PackagingInventoryIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  calcTasks: {
    key: "calcTasks",
    path: "/calcTasks",
    section: "admin",
    title: "Laden Preisberechnungen",
    view: <CalcTaskListPage />,
    icon: <PriceCalculationIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  cardmarketcalctasks: {
    key: "cardmarketcalctasks",
    path: "/cardmarketcalctasks",
    section: "admin",
    title: "Online Preisberechnungen",
    view: <CardmarketCalcTaskListPage />,
    icon: <PriceCalculationIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  priceList: {
    key: "priceList",
    path: "/preisliste",
    section: "admin",
    title: "Preisliste",
    view: <ProductPriceListPage />,
    icon: <PriceCalculationIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
    level: 0,
    navigation: false,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  users: users,
  settings: {
    key: "settings",
    path: "/settings",
    section: "account",
    title: "Benutzer-Einstellungen",
    view: <UserSettingsPage />,
    icon: <SettingsIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {
      settingsedit: {
        key: "settingsedit",
        path: "/settings/edit",
        section: "verwaltung",
        title: "Benutzer-Einstellungen bearbeiten",
        view: <UserSettingsFormPage />,
        icon: <SpieleladenIcon />,
        groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
        level: 1,
        navigation: false,
        indented: false,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  globalsettings: {
    key: "globalsettings",
    path: "/globalsettings",
    section: "admin",
    title: "Globale Einstellungen",
    view: <GlobalSettingsPage />,
    icon: <GlobalSettingsIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {
      globalsettingsedit: {
        key: "globalsettingsedit",
        path: "/globalsettings/edit",
        section: "admin",
        title: "Globale Einstellungen bearbeiten",
        view: <GlobalSettingsFormPage />,
        icon: <SpieleladenIcon />,
        groups: [USER_GROUPS.ADMIN],
        level: 1,
        navigation: false,
        indented: false,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  logout: {
    key: "logout",
    path: "/logout",
    section: "account",
    title: "Ausloggen",
    view: <Logout />,
    icon: <LogoutIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
};

export const getSubNavigationsAsArray = (
  routes: Record<string, RouteMapType>,
) => {
  const tempRoutes: RouteArrayType[] = [];

  for (const routeKey in routes) {
    if (Object.keys(routes[routeKey].subNavigations).length) {
      const subNavigations = getSubNavigationsAsArray(
        routes[routeKey].subNavigations,
      );

      const tempRoute = {
        ...routes[routeKey],
        subNavigations: subNavigations,
      } as RouteArrayType;

      tempRoutes.push(tempRoute);
    } else {
      const tempRoute = {
        ...routes[routeKey],
        subNavigations: [],
      } as RouteArrayType;

      tempRoutes.push(tempRoute);
    }
  }

  return tempRoutes;
};

export const RoutesArray = getSubNavigationsAsArray(Routes);

export const getBreadcrumbsFromRoute = (
  breadcrumbs: RouteType[],
  routePath: RouteType[],
  pathname: string,
  routesArray: RouteArrayType[],
) => {
  for (const route of routesArray) {
    if (route.subNavigations.length) {
      const isCurrentRoute = checkRoute(pathname, route);
      if (isCurrentRoute) {
        breadcrumbs.push(...routePath, route);
        return;
      }

      getBreadcrumbsFromRoute(
        breadcrumbs,
        [...routePath, route],
        pathname,
        route.subNavigations,
      );
    } else {
      const isCurrentRoute = checkRoute(pathname, route);
      if (isCurrentRoute) {
        breadcrumbs.push(...routePath);
        if (
          routePath.length === 0 ||
          routePath[routePath.length - 1].path !== route.path
        ) {
          breadcrumbs.push(route);
        }
        return;
      }
    }
  }
};

export const getBreadcrumbs = (pathname: string): RouteType[] => {
  const breadcrumbs: RouteType[] = [];

  getBreadcrumbsFromRoute(breadcrumbs, [], pathname, RoutesArray);

  return breadcrumbs;
};

export const getRouteParamName = (pathname: string) => {
  const pathParts = pathname.split("/");

  return pathParts[pathParts.length - 1];
};

export const getRouteLastParamName = (pathname: string) => {
  const routeParams = getRouteParams(pathname);

  return routeParams.length ? routeParams[routeParams.length - 1] : null;
};

export const getRouteParams = (pathname: string) => {
  const pathParts = pathname.split("/");
  const params = pathParts.filter((pathPart) => pathPart.charAt(0) === ":");
  return params;
};

export const getCurrentRoute = (pathname: string) => {
  for (const route of RoutesArray) {
    if (route.subNavigations.length) {
      for (const subRoute of route.subNavigations) {
        if (subRoute.subNavigations.length) {
          for (const subSubRoute of subRoute.subNavigations) {
            if (pathname.includes(subSubRoute.path)) {
              return subSubRoute;
            }
          }
        }
        if (pathname.includes(subRoute.path)) {
          return subRoute;
        }
      }
    }
    if (pathname.includes(route.path)) {
      return route;
    }
  }
};

export const getSubmenuListOpen = (pathname: string): string[] => {
  const submenuListOpen: string[] = RoutesArray.filter((route) =>
    route!.subNavigations
      ?.filter((subRoute) => subRoute.navigation)
      .some((subRoute) => pathname.includes(subRoute.path)),
  ).map((route) => route.key);

  return submenuListOpen;
};

export const checkExpansionRoute = (pathname: string) => {
  const expansionListRoute = matchPath(
    {
      path: Routes.expansions.path,
    },
    pathname,
  );

  const expansionRoute = matchPath(
    {
      path: Routes.expansions.subNavigations.expansion.path + "/*",
    },
    pathname,
  );

  const isExpansionRoute = Boolean(!expansionListRoute && expansionRoute);

  return isExpansionRoute;
};

export const checkProductRoute = (pathname: string) => {
  const productListRoute = matchPath(
    {
      path: Routes.products.path,
    },
    pathname,
  );

  const productRoute = Boolean(
    matchPath(
      {
        path: Routes.products.subNavigations.product.path + "/*",
      },
      pathname,
    ),
  );

  const isProductRoute = Boolean(!productListRoute && productRoute);

  return isProductRoute;
};

export const checkRoute = (pathname: string, route: RouteType) => {
  const isRoute = matchPath(
    {
      path: route.path,
    },
    pathname,
  );

  const isCustomerRoute = Boolean(isRoute !== null && isRoute !== undefined);

  return isCustomerRoute;
};

export const checkRouteWithSubnavigations = (
  pathname: string,
  routeArray: RouteArrayType[],
): boolean => {
  if (!routeArray.length) {
    return false;
  }
  for (const route of routeArray) {
    const isRoute = matchPath(
      {
        path: route.path,
      },
      pathname,
    );

    const isRouteSelected = Boolean(isRoute !== null && isRoute !== undefined);

    if (isRouteSelected) {
      return true;
    }

    if (route.subNavigations.length) {
      const isSubRouteSelected = checkRouteWithSubnavigations(
        pathname,
        route.subNavigations,
      );
      if (isSubRouteSelected) {
        return true;
      }
    }
  }
  return false;
};

export const checkRouteIsSelected = (
  pathname: string,
  route: RouteArrayType,
) => {
  const routeMatch = matchPath(
    {
      path: route.path,
    },
    pathname,
  );

  const subRouteIsSelected = checkRouteWithSubnavigations(
    pathname,
    route.subNavigations,
  );

  const isRouteSelected = Boolean(
    routeMatch !== null && routeMatch !== undefined,
  );

  return isRouteSelected || subRouteIsSelected;
};

export const AuthRoutes = {
  login: "/login",
  register: "/register",
  verify: "/verify",
  resendVerify: "/resendverify",
  forgotPassword: "/forgotpassword",
  confirmForgotPassword: "/confirmForgotPassword",
  businessProfileSetup: "/businessProfileSetup",
};

export const isAuthRoute = (locationPathname: string) => {
  return Object.values(AuthRoutes).includes(locationPathname);
};

export const AuthRouting = [
  {
    path: AuthRoutes.login,
    view: <Login />,
  },
  {
    path: AuthRoutes.forgotPassword,
    view: <ForgotPassword />,
  },
  {
    path: AuthRoutes.confirmForgotPassword,
    view: <ConfirmForgotPassword />,
  },
];
