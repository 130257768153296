import React from "react";
import useStyles from "./styles";

type SpacingProps = {
  height: number | string;
};

export const Spacing: React.FC<SpacingProps> = ({ height }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.spacing} style={{ height }}>
      {" "}
    </div>
  );
};
