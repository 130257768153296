import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { useArticleListFilterAndSort } from "hooks";
import { Article } from "types";
import { CustomReactTable, CustomReactTableHandles, Spacing } from "core";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";
import { getArticleExcludeReasonName } from "modules/shared/options/ArticleExcludeReasonOption";
import { ConditionColumnFormatter } from "../ColumnFormatters/ConditionColumnFormatter";
import { FoilColumnFormatter } from "../ColumnFormatters/FoilColumnFormatter";
import { LanguageColumnFormatter } from "../ColumnFormatters/LanguageColumnFormatter";
import { ReputationColumnFormatter } from "../ColumnFormatters/ReputationColumnFormatter";
import { SellCountColumnFormatter } from "../ColumnFormatters/SellCountColumnFormatter";
import { SellerUsernameColumnFormatter } from "../ColumnFormatters/SellerUsernameColumnFormatter";
import { ShippingColumnFormatter } from "../ColumnFormatters/ShippingColumnFormatter";
import { UserTypeColumnFormatter } from "../ColumnFormatters/UserTypeColumnFormatter";
import useStyles from "./styles";

type ArticleListTableProps = {
  articleList: Article[];
  priceCalcTarget:
    | "Store"
    | "CardmarketNoFoil"
    | "CardmarketFoil"
    | "StoreOld"
    | "CardmarketNoFoilOld"
    | "CardmarketFoilOld";
  articleTableRef?: React.RefObject<CustomReactTableHandles<Article>>;
};

export const ArticleListTable: React.FC<ArticleListTableProps> = ({
  articleList,
  priceCalcTarget,
  articleTableRef,
}) => {
  const { classes } = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const filterAndSortArticles = useArticleListFilterAndSort();

  useEffect(() => {
    if (priceCalcTarget === "Store" || priceCalcTarget === "StoreOld") {
      searchParams.delete("isExcludedOnlineNotFoil");
      searchParams.delete("isExcludedOnlineFoil");
      setSearchParams(searchParams);
    } else if (
      priceCalcTarget === "CardmarketNoFoil" ||
      priceCalcTarget === "CardmarketNoFoilOld"
    ) {
      searchParams.delete("isExcludedStore");
      searchParams.delete("isExcludedOnlineFoil");
      setSearchParams(searchParams);
    } else if (
      priceCalcTarget === "CardmarketFoil" ||
      priceCalcTarget === "CardmarketFoilOld"
    ) {
      searchParams.delete("isExcludedStore");
      searchParams.delete("isExcludedOnlineNotFoil");
    }
  }, [priceCalcTarget, searchParams, setSearchParams]);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<Article>[]>(
    () => [
      {
        header: "Rep.",
        accessorKey: "reputation",
        cell: ({ row }) => (
          <ReputationColumnFormatter reputation={row.original.reputation} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.reputation,
        },
      },
      {
        header: "Verk.",
        accessorKey: "sellCount",
        cell: ({ row }) => (
          <SellCountColumnFormatter sellCount={row.original.sellCount} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.sellCount,
        },
      },
      {
        header: "Vers.",
        accessorKey: "avgShippingTime",
        cell: ({ row }) => (
          <ShippingColumnFormatter
            avgShippingTime={row.original.avgShippingTime}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.avgShippingTime,
        },
      },
      {
        header: "Verkäufer",
        accessorKey: "username",
        cell: ({ row }) => (
          <SellerUsernameColumnFormatter username={row.original.username} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.username,
          isNowrap: true,
        },
      },
      {
        header: "Typ",
        accessorKey: "userType",
        cell: ({ row }) => (
          <UserTypeColumnFormatter userType={row.original.userType} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.userType,
          isNowrap: true,
        },
      },
      {
        header: "Zustand",
        accessorKey: "condition",
        cell: ({ row }) => (
          <ConditionColumnFormatter condition={row.original.condition} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.condition,
          align: "center",
        },
      },
      {
        header: "Spr.",
        accessorKey: "idLanguage",
        cell: ({ row }) => (
          <LanguageColumnFormatter idLanguage={row.original.idLanguage} />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.idLanguage.toString(),
          align: "center",
        },
      },
      {
        header: "Foil",
        accessorKey: "isFoil",
        cell: ({ row }) => <FoilColumnFormatter isFoil={row.original.isFoil} />,
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.idLanguage.toString(),
          align: "center",
        },
      },
      {
        header: "Kommentare",
        accessorKey: "comments",
        cell: ({ row }) => (
          <span className={classes.comments}>{row.original.comments}</span>
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.comments ?? "",
          align: "center",
        },
      },
      {
        header: "Preis",
        accessorKey: "price",
        cell: ({ row }) => (
          <span className={classes.price}>
            {row.original.price.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.price.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          align: "center",
        },
      },
      {
        header: "Anz.",
        accessorKey: "count",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.count.toString(),
          align: "center",
        },
      },
      {
        header: "Einb.",
        accessorKey: "isExcludedStore",
        cell: ({ row }) => {
          const isExcluded =
            priceCalcTarget === "CardmarketFoil" ||
            priceCalcTarget === "CardmarketFoilOld"
              ? row.original.isExcludedOnlineFoil
              : priceCalcTarget === "CardmarketNoFoil" ||
                  priceCalcTarget === "CardmarketNoFoilOld"
                ? row.original.isExcludedOnlineNotFoil
                : row.original.isExcludedStore;

          return isExcluded ? (
            <span className={classes.excluded}>✘</span>
          ) : (
            <span>✔</span>
          );
        },
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.count.toString(),
          align: "center",
        },
        enableSorting: false,
      },
      {
        header: "Ausschlussg.",
        accessorKey: "excludedReasonStore",
        cell: ({ row }) => {
          const excludedReason =
            priceCalcTarget === "CardmarketFoil" ||
            priceCalcTarget === "CardmarketFoilOld"
              ? row.original.excludedReasonOnlineFoil
              : priceCalcTarget === "CardmarketNoFoil" ||
                  priceCalcTarget === "CardmarketNoFoilOld"
                ? row.original.excludedReasonOnlineNotFoil
                : row.original.excludedReasonStore;

          if (!excludedReason) return null;

          const excludedReasonText =
            getArticleExcludeReasonName(excludedReason);

          return excludedReasonText;
        },
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.count.toString(),
          align: "center",
        },
        enableSorting: false,
      },
    ],
    [classes.comments, classes.excluded, classes.price, priceCalcTarget],
  );

  return (
    <>
      <OptionsFilter
        param={
          priceCalcTarget === "CardmarketFoil" ||
          priceCalcTarget === "CardmarketFoilOld"
            ? "isExcludedOnlineFoil"
            : priceCalcTarget === "CardmarketNoFoil" ||
                priceCalcTarget === "CardmarketNoFoilOld"
              ? "isExcludedOnlineNotFoil"
              : "isExcludedStore"
        }
        paramName="Filter nach Ausschluss"
        options={[
          { value: "false", label: "Einbezogene" },
          { value: "true", label: "Ausgeschlossene" },
        ]}
      />

      <Spacing height={33} />

      <CustomReactTable<Article>
        data={filterAndSortArticles(articleList)}
        columns={defaultColumns}
        ref={articleTableRef}
        sticky={false}
        meta={{
          getRowClasses: (row) => {
            const isExcluded =
              priceCalcTarget === "CardmarketFoil" ||
              priceCalcTarget === "CardmarketFoilOld"
                ? row.original.isExcludedOnlineFoil
                : priceCalcTarget === "CardmarketNoFoil" ||
                    priceCalcTarget === "CardmarketNoFoilOld"
                  ? row.original.isExcludedOnlineNotFoil
                  : row.original.isExcludedStore;

            return isExcluded ? [classes.excludedRow] : [];
          },
        }}
      />
    </>
  );
};
