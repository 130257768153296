import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { StockTask } from "types";
import {
  CustomLink,
  CustomReactTable,
  CustomReactTableHandles,
  CustomTag,
} from "core";
import { getExpansionName } from "modules/expansions/business";
import { getTaskStatusName } from "modules/shared/options/TaskStatusOption";
import { Routes } from "routes";
import { useStockTaskListUIContext } from "../StockTaskListUIContext";
import utils from "utils";

type StockTaskListTableProps = {
  stockTaskTableRef?: React.RefObject<CustomReactTableHandles<StockTask>>;
};

export const StockTaskListTable: React.FC<StockTaskListTableProps> = ({
  stockTaskTableRef,
}) => {
  const { expansionList } = useExpansionList(false);

  const stockTaskListUIContext = useStockTaskListUIContext();
  const stockTaskListUIProps = useMemo(() => {
    return {
      stockTaskList: stockTaskListUIContext.stockTaskList,
      isLoadingStockTaskList: stockTaskListUIContext.isLoadingStockTaskList,
    };
  }, [stockTaskListUIContext]);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<StockTask>[]>(
    () => [
      {
        header: "Edition",
        accessorKey: "expansionID",
        cell: ({ row }) => {
          const expansion = expansionList.find(
            (expansion) => expansion.id === row.original.expansionID,
          );

          const expansionName = expansion
            ? getExpansionName(expansion)
            : "Edition " + row.original.expansionID.replace("exp_", "");

          return (
            <CustomLink
              link={Routes.expansions.subNavigations.expansion.path.replace(
                ":expansionID",
                row.original.expansionID,
              )}
              linkText={expansionName}
            />
          );
        },
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => {
            if (!row.original.expansionID) return "Neue Edition";

            const expansion = expansionList.find(
              (expansion) => expansion.id === row.original.expansionID,
            );

            const expansionName = expansion
              ? getExpansionName(expansion)
              : "Edition " + row.original.expansionID.replace("exp_", "");

            return expansionName;
          },
        },
      },
      {
        header: "Start",
        accessorKey: "startTime",
        accessorFn: (row) =>
          utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
            row.startTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
              row.original.startTime,
            ),
        },
      },
      {
        header: "Ende",
        accessorKey: "endTime",
        accessorFn: (row) =>
          row.endTime
            ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                row.endTime,
              )
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.endTime
              ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                  row.original.endTime,
                )
              : "",
        },
      },
      {
        header: "Dauer",
        accessorKey: "duration",
        accessorFn: (row) =>
          utils.dates.getDurationFromAWSDateTimeFormat(
            row.startTime,
            row.endTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDurationFromAWSDateTimeFormat(
              row.original.startTime,
              row.original.endTime,
            ),
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <CustomTag
            tagColor={
              row.original.status === "Completed"
                ? "green"
                : row.original.status === "Running"
                  ? "yellow"
                  : row.original.status === "Failed"
                    ? "red"
                    : "blue"
            }
            content={getTaskStatusName(row.original.status)}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => getTaskStatusName(row.original.status),
          align: "center",
        },
      },
    ],
    [expansionList],
  );

  return (
    <CustomReactTable<StockTask>
      data={stockTaskListUIProps.stockTaskList}
      columns={defaultColumns}
      isLoadingDataList={stockTaskListUIProps.isLoadingStockTaskList}
      ref={stockTaskTableRef}
      tableTheme="default"
      leftSpacing="large"
    />
  );
};
