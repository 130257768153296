import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateExpansionInput } from "types";

export const useExpansionUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (expansionInput: UpdateExpansionInput) => {
      const { data: updatedExpansion } =
        await client.models.Expansion.update(expansionInput);

      return updatedExpansion;
    },
    // When mutate is called:
    onMutate: async (newExpansion) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Expansions, newExpansion.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Expansions],
      });

      // Snapshot the previous value
      const previousExpansion = queryClient.getQueryData([
        QueryKeys.Expansions,
        newExpansion.id,
      ]);

      // Optimistically update to the new value
      if (previousExpansion) {
        queryClient.setQueryData(
          [QueryKeys.Expansions, newExpansion.id],
          /**
           * `newExpansion` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousExpansion, ...newExpansion },
        );
      }

      // Return a context with the previous and new expansion
      return {
        previousExpansion,
        newExpansion,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newExpansion, context) => {
      console.error("Error updating record:", err, newExpansion);
      if (context?.previousExpansion) {
        queryClient.setQueryData(
          [QueryKeys.Expansions, context.newExpansion.id],
          context.previousExpansion,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newExpansion) => {
      if (newExpansion) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Expansions, newExpansion.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Expansions],
        });
      }
    },
  });

  return updateMutation;
};
