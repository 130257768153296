import React from "react";
import { IconButton } from "@mui/material";
import { Product } from "types";
import { OpenBrowserIcon } from "modules/icons";
import useStyles from "./styles";

type WebsiteColumnFormatterProps = {
  product: Product;
};

export const WebsiteColumnFormatter: React.FC<WebsiteColumnFormatterProps> = ({
  product,
}) => {
  const { classes } = useStyles();

  return (
    <IconButton
      onClick={() =>
        window.open(
          "https://www.cardmarket.com/" + product.website,
          "_blank",
          "noopener,noreferrer",
        )
      }
      className={classes.iconButton}
    >
      <OpenBrowserIcon />
    </IconButton>
  );
};
