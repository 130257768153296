import React, { useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CustomButton, TextInputField, TextInputFieldHandles } from "core";
import { Routes } from "routes";
import useStyles from "./styles";

type UserPasswordFormProps = {
  oldPassword: string;
  setOldPassword: (value: React.SetStateAction<string>) => void;
  newPassword: string;
  setNewPassword: (value: React.SetStateAction<string>) => void;
  confirmNewPassword: string;
  setConfirmNewPassword: (value: React.SetStateAction<string>) => void;
  submitPasswordChange: () => void;
  submitPasswordChangeLoading: boolean;
};

type UserPasswordFormResult = {
  oldPassword: string;
  newPassword: string;
};

export type UserPasswordFormHandles = {
  validateUserPasswordForm(): UserPasswordFormResult | null;
};

const UserPasswordFormComponent: React.ForwardRefRenderFunction<
  UserPasswordFormHandles,
  UserPasswordFormProps
> = (
  {
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    submitPasswordChange,
    submitPasswordChangeLoading,
  },
  userPasswordFormRef,
) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const oldPasswordInputRef = useRef<TextInputFieldHandles>(null);
  const newPasswordInputRef = useRef<TextInputFieldHandles>(null);
  const confirmNewPasswordInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(userPasswordFormRef, () => ({
    validateUserPasswordForm: () => {
      if (oldPassword === "") {
        enqueueSnackbar("Bitte gib dein altes Passwort ein!");
        oldPasswordInputRef.current?.highlight();
        return null;
      }

      if (newPassword === "") {
        enqueueSnackbar("Bitte gib dein neues Passwort ein!");
        newPasswordInputRef.current?.highlight();
        return null;
      }

      if (newPassword.length > 30) {
        enqueueSnackbar(
          "Dein neues Passwort darf maximal nur 30 Zeichen enthalten!",
        );
        newPasswordInputRef.current?.highlight();
        return null;
      }

      if (confirmNewPassword === "") {
        enqueueSnackbar("Bitte wiederhole dein neues Passwort!");
        confirmNewPasswordInputRef.current?.highlight();
        return null;
      }

      const regularExpressionPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;

      if (!regularExpressionPassword.test(newPassword)) {
        enqueueSnackbar(
          "Bitte gib ein gültiges Passwort aus mind. 8 Zeichen mit Groß- und Kleinbuchstaben, Zahlen und folgenden Sonderzeichen: ^ $ * . [ ] { } ( ) ?  ! @ # % & / , > <  : ; | _ ~  ein!",
        );
        newPasswordInputRef.current?.highlight();
        return null;
      }

      if (newPassword !== confirmNewPassword) {
        enqueueSnackbar(
          "Die Wiederholung des Passworts stimmt nicht mit deinem eingegebenem Passeort überein!",
        );
        confirmNewPasswordInputRef.current?.highlight();
        return null;
      }

      return { oldPassword, newPassword };
    },
  }));

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <TextInputField
            className={classes.formField}
            id="oldPassword"
            label="Altes Passwort"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
            ref={oldPasswordInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <TextInputField
            className={classes.formField}
            id="newPassword"
            label="Neues Passwort"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            ref={newPasswordInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
        <Grid item md={4}>
          <TextInputField
            className={classes.formField}
            label="Neues Passwort wiederholen"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            type="password"
            ref={confirmNewPasswordInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.userSettingsButtons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text="Passwort ändern"
            onClick={() => submitPasswordChange()}
            loading={submitPasswordChangeLoading}
            style="filled"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            onClick={() => {
              navigate(Routes.start.path);
            }}
            disabled={submitPasswordChangeLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const UserPasswordForm = React.memo(
  React.forwardRef(UserPasswordFormComponent),
);
