import React from "react";
import { Grid } from "@mui/material";
import { useSearchContext } from "context";
import { QueryKeys } from "queryClient";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { ExpansionFilter } from "modules/shared/filters/ExpansionFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";
import { LanguageFilter } from "../LanguageFilter";

export const ProductListFilter: React.FC = () => {
  const { isLoadingProducts } = useSearchContext();

  return (
    <FilterWrapper>
      <FilterRow>
        <Grid item>
          <LanguageFilter />
        </Grid>
        <Grid item>
          <ExpansionFilter />
        </Grid>
        <Grid item>
          <SearchTextFilter
            searchTextParamName={SearchTextParamName.SearchProduct}
            resetQueryFields={[QueryKeys.Products, "search"]}
            placeholder={isLoadingProducts ? "Wird geladen..." : undefined}
            isLoading={isLoadingProducts}
          />
        </Grid>
      </FilterRow>
    </FilterWrapper>
  );
};
