import { Schema } from "queryClient";
import { KeysEnum } from "./General.types";

export type CardmarketCalcTask = Schema["CardmarketCalcTask"]["type"];

export type CreateCardmarketCalcTaskInput =
  Schema["CardmarketCalcTask"]["createType"];

export type UpdateCardmarketCalcTaskInput =
  Schema["CardmarketCalcTask"]["updateType"];

const CardmarketCalcTaskTypeKeys: KeysEnum<CardmarketCalcTask> = {
  createdAt: true,
  endTime: true,
  errorMessage: true,
  expansionID: true,
  id: true,
  lastTriggered: true,
  owner: true,
  startTime: true,
  status: true,
  taskType: true,
  updatedAt: true,
};

export const isKeyOfCardmarketCalcTask = (
  key: string,
): key is keyof CardmarketCalcTask => key in CardmarketCalcTaskTypeKeys;
