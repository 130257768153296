import { useEffect, useState } from "react";
import { BooleanValue, MinPrice, RarityWithAll } from "types";

export const useMinPriceForm = (
  formIntent: "create" | "edit",
  minPriceList: MinPrice[],
  minPrice: MinPrice | null | undefined,
) => {
  const [priority, setPriority] = useState<number | "">("");
  const [minPriceStore, setMinPriceStore] = useState<number | "">("");
  const [minPriceOnline, setMinPriceOnline] = useState<number | "">("");
  const [rarity, setRarity] = useState<RarityWithAll>("All");
  const [isFoil, setIsFoil] = useState<BooleanValue>("All");

  const resetMinPriceForm = () => {
    setPriority("");
    setMinPriceStore("");
    setMinPriceStore("");
    setRarity("All");
    setIsFoil("All");
  };

  useEffect(() => {
    if (formIntent === "edit") return;

    const highestPriority = minPriceList.reduce((acc, minPriceItem) => {
      return minPriceItem.priority > acc ? minPriceItem.priority : acc;
    }, 0);

    setPriority(highestPriority + 1);
  }, [formIntent, minPriceList]);

  useEffect(() => {
    if (!minPrice) {
      return;
    }

    setPriority(minPrice.priority);
    setMinPriceStore(minPrice.minPriceStore);
    setMinPriceOnline(minPrice.minPriceOnline);

    setRarity(minPrice.rarity ?? "All");
    setIsFoil(
      minPrice.isFoil === true
        ? "true"
        : minPrice.isFoil === false
          ? "false"
          : "All",
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice]);

  return {
    priority,
    setPriority,
    minPriceStore,
    setMinPriceStore,
    minPriceOnline,
    setMinPriceOnline,
    rarity,
    setRarity,
    isFoil,
    setIsFoil,
    resetMinPriceForm,
  };
};
