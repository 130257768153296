import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "context";
import { Helmet } from "react-helmet";
import { getExpansionName } from "modules/expansions/business";
import { ArrowIcon, HomeIcon } from "modules/icons";
import {
  RouteType,
  Routes,
  checkRoute,
  getBreadcrumbs,
  getRouteLastParamName,
  getRouteParamName,
} from "routes";
import useStyles from "./styles";

type BreadcrumbsProps = {
  layoutType: "layout" | "authLayout" | "giessPlan";
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ layoutType }) => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();
  const { pathname } = useLocation();

  const breadcrumbs = getBreadcrumbs(pathname);

  const breadcrumbsContentRef = useRef<HTMLDivElement>(null);

  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const [isTooLong, setIsTooLong] = useState<boolean>(false);

  useEffect(() => {
    setIsTooLong(
      breadcrumbsContentRef.current!.offsetWidth >
        breadcrumbsRef.current!.offsetWidth - 50,
    );
  }, [pathname]);

  const getPathOfRoute = (route: RouteType) => {
    let path = route.path
      .replace(":expansionID", authContext.expansion?.id ?? "")
      .replace(":productID", authContext.product?.id ?? "");

    if (route.isWithParam) {
      path = path.replace(
        getRouteParamName(route.path),
        authContext.currentData?.id ?? "",
      );
    }
    return path;
  };

  const getNameOfRouteWithParam = (route: RouteType) => {
    const routeLastParamName = getRouteLastParamName(route.path);

    if (!routeLastParamName) {
      return "";
    }

    if (routeLastParamName === ":expansionID" && !checkRoute(pathname, route)) {
      return authContext.expansion
        ? getExpansionName(authContext.expansion)
        : "";
    } else if (
      routeLastParamName === ":productID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.product?.productDeName;
    } else {
      return authContext.currentData?.name;
    }
  };

  return (
    <div
      ref={breadcrumbsRef}
      className={
        layoutType === "giessPlan"
          ? classes.breadcrumbsGiessPlan
          : classes.breadcrumbs
      }
    >
      <div ref={breadcrumbsContentRef} className={classes.breadcrumbsContent}>
        <Link
          className={cx(
            classes.breadcrumbLink,
            classes.breadcrumbLinkNormal,
            classes.homeLink,
          )}
          to={Routes.start.path}
        >
          <HomeIcon className={classes.homeIcon} />
        </Link>

        <ArrowIcon
          className={cx(
            classes.arrowIcon,
            isTooLong ? classes.arrowIconSmall : classes.arrowIconNormal,
          )}
        />

        {breadcrumbs.map((route, i) =>
          i + 1 === breadcrumbs.length ? (
            route.isWithParam ? (
              authContext.isLoadingCurrentData ||
              authContext.isLoadingStateEntities ? (
                <span key={i} className={classes.loadingName}></span>
              ) : (
                <span
                  className={cx(
                    isTooLong
                      ? classes.breadcrumbNameSmall
                      : classes.breadcrumbNameNormal,
                  )}
                  key={i}
                >
                  <Helmet>
                    <title>{getNameOfRouteWithParam(route)}</title>
                  </Helmet>
                  {getNameOfRouteWithParam(route)}
                </span>
              )
            ) : (
              <span
                className={cx(
                  isTooLong
                    ? classes.breadcrumbNameSmall
                    : classes.breadcrumbNameNormal,
                )}
                key={i}
              >
                <Helmet>
                  <title>{route.title}</title>
                </Helmet>
                {route.title}
              </span>
            )
          ) : (
            <>
              <Link
                className={cx(
                  classes.breadcrumbLink,
                  isTooLong
                    ? classes.breadcrumbLinkSmall
                    : classes.breadcrumbLinkNormal,
                )}
                to={getPathOfRoute(route)}
              >
                {route.isWithParam
                  ? getNameOfRouteWithParam(route)
                  : route.title}
              </Link>

              <ArrowIcon
                className={cx(
                  classes.arrowIcon,
                  isTooLong ? classes.arrowIconSmall : classes.arrowIconNormal,
                )}
              />
            </>
          ),
        )}
      </div>
    </div>
  );
};
