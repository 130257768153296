import React from "react";
import { Grid } from "@mui/material";
import { useSearchContext } from "context";
import { QueryKeys } from "queryClient";
import { SearchTextParamName } from "types";
import { ExpansionFilterShortened } from "modules/shared/filters/ExpansionFilterShortened";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";
import useStyles from "./styles";

export const ProductPriceListFilter: React.FC = () => {
  const { classes } = useStyles();
  const { isLoadingProducts } = useSearchContext();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.pricelistFilter}
    >
      <Grid item>
        <ExpansionFilterShortened />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchProductShortened}
          resetQueryFields={[QueryKeys.ProductsShortened, "search"]}
          placeholder={isLoadingProducts ? "Wird geladen..." : undefined}
          isLoading={isLoadingProducts}
        />
      </Grid>
    </Grid>
  );
};
