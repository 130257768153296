import React, { FC, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, InputBase } from "@mui/material";
import { CheckmarkBoldIcon, CloseBoldIcon } from "modules/icons";
import useStyles from "./styles";

type TextEditFieldProps = {
  defaultValue: string | null;
  onCancel: () => void;
  submitValue: (value: string) => void;
  id: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

export const TextEditField: FC<TextEditFieldProps> = ({
  defaultValue,
  onCancel,
  submitValue,
  id,
  autoFocus = false,
  disabled = false,
  placeholder,
}) => {
  const { classes, cx } = useStyles();

  const [value, setValue] = useState<string>(defaultValue ?? "");

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
    // inputRef.current?.select();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        event.stopPropagation();
        submitValue(value);
        break;
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        onCancel();
        break;
    }
  };

  return (
    <div ref={wrapperRef}>
      <InputBase
        autoFocus={autoFocus}
        classes={{
          input: cx(classes.input),
          focused: classes.focusedInput,
        }}
        type="text"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        // onBlur={onCancel}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        id={id}
        name={id}
        inputRef={inputRef}
        placeholder={placeholder}
        endAdornment={
          <>
            <IconButton
              onClick={() => {
                setValue("");
                inputRef.current?.focus();
              }}
              className={classes.clearButton}
            >
              <CloseBoldIcon />
            </IconButton>

            <InputAdornment position="end" className={classes.editButtons}>
              <IconButton
                onClick={() => submitValue(value)}
                className={classes.submitButton}
              >
                <CheckmarkBoldIcon />
              </IconButton>

              <IconButton
                onClick={() => onCancel()}
                className={classes.cancelButton}
              >
                <CloseBoldIcon />
              </IconButton>
            </InputAdornment>
          </>
        }
      />
    </div>
  );
};
