import React, { useState } from "react";
import { Button } from "@mui/material";
import { useExpansionUpdate } from "hooks";
import { Expansion } from "types";
import { ExpansionSelectField } from "../ExpansionSelectField";
import useStyles from "./styles";

type ExpansionParentColumnFormatterProps = {
  expansion: Expansion;
  expansionList: Expansion[];
};

export const ExpansionParentColumnFormatter: React.FC<
  ExpansionParentColumnFormatterProps
> = ({ expansion, expansionList }) => {
  const { classes, cx } = useStyles();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [currentExpansion, setCurrentExpansion] =
    useState<Expansion>(expansion);

  const updateMutation = useExpansionUpdate();

  const defaultValue = expansionList.find(
    (exp) => exp.id === currentExpansion.parentID,
  );

  if (!isSelected) {
    return (
      <Button
        onClick={() => setIsSelected(true)}
        className={cx(
          classes.aliasButton,
          defaultValue ? classes.aliasButtonFilled : classes.aliasButtonEmpty,
        )}
      >
        {defaultValue
          ? defaultValue.expansionAlias ?? defaultValue.expansionDeName
          : "empty"}
      </Button>
    );
  }

  const submitExpansionParent = (value: Expansion | null) => {
    console.log("submitExpansionAlias", value);

    const parentID = value ? value.id : null;

    if (parentID !== expansion.parentID) {
      setCurrentExpansion((prev) => ({
        ...prev,
        parentID: value ? value.id : null,
      }));

      updateMutation.mutate({
        id: expansion.id,
        parentID: value ? value.id : null,
        parentName: value ? value.expansionAlias ?? value.expansionDeName : "",
      });
    }

    setIsSelected(false);
  };

  return (
    <ExpansionSelectField
      id={expansion.id}
      expansionList={expansionList}
      defaultValue={defaultValue ?? null}
      submitValue={submitExpansionParent}
      onCancel={() => setIsSelected(false)}
    />
  );
};
