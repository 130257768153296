import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateProductInput } from "types";

export const useProductUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (productInput: UpdateProductInput) => {
      const { data: updatedProduct } =
        await client.models.Product.update(productInput);

      return updatedProduct;
    },
    // When mutate is called:
    onMutate: async (newProduct) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Products, newProduct.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Products],
      });

      await queryClient.cancelQueries({
        queryKey: [
          QueryKeys.ExpansionProducts,
          newProduct.expansionID,
          newProduct.id,
        ],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.ExpansionProducts, newProduct.expansionID],
      });

      // Snapshot the previous value
      const previousProduct = queryClient.getQueryData([
        QueryKeys.Products,
        newProduct.id,
      ]);

      // Optimistically update to the new value
      if (previousProduct) {
        queryClient.setQueryData(
          [QueryKeys.Products, newProduct.id],
          /**
           * `newProduct` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousProduct, ...newProduct },
        );
      }

      const previousExpansionProduct = queryClient.getQueryData([
        QueryKeys.ExpansionProducts,
        newProduct.expansionID,
        newProduct.id,
      ]);

      // Optimistically update to the new value
      if (previousExpansionProduct) {
        queryClient.setQueryData(
          [QueryKeys.ExpansionProducts, newProduct.expansionID, newProduct.id],
          /**
           * `newProduct` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousExpansionProduct, ...newProduct },
        );
      }

      // Return a context with the previous and new product
      return {
        previousProduct,
        previousExpansionProduct,
        newProduct,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newProduct, context) => {
      console.error("Error updating record:", err, newProduct);
      if (context?.previousProduct) {
        queryClient.setQueryData(
          [QueryKeys.Products, context.newProduct.id],
          context.previousProduct,
        );
      }
      if (context?.previousExpansionProduct) {
        queryClient.setQueryData(
          [
            QueryKeys.ExpansionProducts,
            context.newProduct.expansionID,
            context.newProduct.id,
          ],
          context.previousExpansionProduct,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newProduct) => {
      if (newProduct) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Products, newProduct.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Products],
        });
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeys.ExpansionProducts,
            newProduct.expansionID,
            newProduct.id,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ExpansionProducts, newProduct.expansionID],
        });
      }
    },
  });

  return updateMutation;
};
