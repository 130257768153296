import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  badge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d1d7e2",
    fontSize: 13,
    borderRadius: 7,
    color: "#000",
  },
  thousand: {
    color: "#dc3545",
    marginLeft: 2,
    fontStyle: "italic",
    fontWeight: 500,
  },
}));

export default useStyles;
