import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchContext } from "context";
import { useListQueryParams } from "hooks";
import { QueryKeys } from "queryClient";
import { SearchTextParamName } from "types";
import { getAllExpansionListShortened } from "modules/expansions/api";
import { getProductListByExpansionShortened } from "modules/products/api";
import { useProductListFilterAndSortShortened } from "../useProductListFilterAndSortShortened";

export const useProductListShortened = (filter = true, useAlias = true) => {
  const searchParams = useListQueryParams();

  const { searchProductsShortened, isLoadingProducts: isLoadingSearch } =
    useSearchContext();

  const filterAndSortProducts = useProductListFilterAndSortShortened(
    filter,
    useAlias,
  );

  const expansionID = searchParams.get("expansionID");
  const searchText = searchParams.get(
    SearchTextParamName.SearchProductShortened,
  );

  const { data, isLoading } = useQuery({
    queryKey:
      (!expansionID || expansionID === "search") && searchText
        ? [QueryKeys.ProductsShortened, expansionID ?? "search", searchText]
        : [QueryKeys.ProductsShortened, expansionID],
    queryFn: async () => {
      if ((!expansionID || expansionID === "search") && !searchText) {
        return [];
      }

      const expansionList = await getAllExpansionListShortened();

      const expansionChildIDs = expansionList
        .filter((expansion) => expansion.parentID === expansionID)
        .map((expansion) => expansion.id);

      const expansionIDs = expansionID
        ? [...expansionChildIDs, expansionID]
        : [];

      console.log("expansionIDs: ", expansionIDs);

      if ((!expansionID || expansionID === "search") && searchText) {
        const productList = await searchProductsShortened(searchText);
        return productList.filter((product) => product.isProductActive);
      } else if (expansionID && expansionID !== "search") {
        const productListPromises = expansionIDs.map((expansionIDItem) =>
          getProductListByExpansionShortened(expansionIDItem),
        );

        const productListResults = await Promise.all(productListPromises);

        const productList = productListResults.reduce(
          (acc, productList) => [...acc, ...productList],
          [],
        );

        return productList.filter((product) => product.isProductActive);
      } else {
        return [];
      }
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const productList = useMemo(
    () => filterAndSortProducts(data ?? []),
    [filterAndSortProducts, data],
  );

  return {
    productList,
    isLoading,
    isLoadingSearch,
  };
};
