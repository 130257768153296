import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  dialogTitle: {
    marginTop: 0,
    marginBottom: 15,
  },
  instruction: {
    marginBottom: 20,
  },
}));

export default useStyles;
