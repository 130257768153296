import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useUserDelete } from "hooks";
import { client } from "queryClient";
import { User } from "types";
import { DeleteDialog } from "components";
import { Routes } from "routes";
import { isUserUsed } from "../../api";

type UserDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  user: User | undefined;
  loadUserList?: (
    nextTokenParam?: string | null | undefined,
  ) => Promise<User[]>;
};

export const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({
  user,
  dialogOpen,
  setDialogOpen,
  loadUserList,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const deleteMutation = useUserDelete();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || user === undefined) {
    return null;
  }

  const deleteUserRequest = async () => {
    setDeleteLoading(true);
    const userIsUsed = await isUserUsed(user.id);

    if (userIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Diese Benutzer kann nicht gelöscht werden, da sie in Verwendung ist!",
      );
      return;
    }

    const { data, errors } = await client.queries.deleteCognitoUser({
      username: user.userSUB,
    });

    if (errors) {
      enqueueSnackbar(errors[0].message);
      setDeleteLoading(false);
      return;
    } else {
      console.log("Deleted user succesfully: ", data);
    }

    const deletedUser = await deleteMutation.mutateAsync(user);

    loadUserList && (await loadUserList());

    console.log("deletedUser: ", deletedUser);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigate(Routes.users.path);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteUserRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Benutzer Löschen"
      itemName={user.firstName + " " + user.lastName}
      itemDeleteDescription="der Benutzer"
    />
  );
};
