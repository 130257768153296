import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useProductUpdate } from "hooks";
import {
  Product,
  ProductColumnToUpdate,
  UpdateProductInput,
  ValueSubmitTrigger,
} from "types";
import { Loading } from "core";
import { TableNumberField } from "components";
import { getPriceCalc } from "modules/pricecalcs/api";

type ProductNumberColumnProps = {
  selectedColumn: ProductColumnToUpdate | null;
  selectedRow: Product | null;
  product: Product;
  productField: keyof Product;
  productColumn: ProductColumnToUpdate;
  rowIndex: number;
  handleProductSelection: (
    column: ProductColumnToUpdate,
    rowIndex: number,
    trigger: ValueSubmitTrigger,
  ) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  decimalScale?: number;
  numberFormatOptions?: Intl.NumberFormatOptions;
};

export const ProductNumberColumn: React.FC<ProductNumberColumnProps> = ({
  selectedColumn,
  selectedRow,
  product,
  productField,
  productColumn,
  rowIndex,
  handleProductSelection,
  autoFocus,
  disabled,
  decimalScale,
  numberFormatOptions,
}) => {
  // const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentProduct, setCurrentProduct] = useState<Product>(product);
  const [value, setValue] = useState<number | "">("");

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    const defaultValue = product[productField] ?? "";
    setValue(typeof defaultValue === "number" ? defaultValue : "");
  }, [product, productField]);

  const updateMutation = useProductUpdate();

  if (selectedColumn !== productColumn || selectedRow?.id !== product.id) {
    return currentProduct[productField]?.toLocaleString(
      "de-DE",
      numberFormatOptions,
    );
  }

  const submitPrice = async (
    value: number | "",
    trigger: ValueSubmitTrigger,
  ) => {
    if (value === "" && trigger !== ValueSubmitTrigger.Escape) {
      enqueueSnackbar("Bitte gib einen Preis ein!");
      return;
    }

    setSubmitLoading(true);

    if (
      value !== "" &&
      value !== product[productField] &&
      trigger !== ValueSubmitTrigger.Escape
    ) {
      setCurrentProduct((prev) => ({
        ...prev,
        [productField]: value,
      }));

      const priceCalc = product.storePriceCalcID
        ? await getPriceCalc(product.storePriceCalcID)
        : null;

      const updateProductInput: UpdateProductInput = {
        id: product.id,
        expansionID: product.expansionID,
        [productField]: value,
      };

      if (priceCalc && priceCalc.priceCalculationStore) {
        const samePrice = value === priceCalc.priceCalculationStore.price;

        if (!samePrice) {
          updateProductInput.priceStoreSetManually = true;
        }
      } else {
        updateProductInput.priceStoreSetManually = true;
      }

      updateMutation.mutate(updateProductInput);
    }

    handleProductSelection(productColumn, rowIndex, trigger);
    setSubmitLoading(false);
  };

  return submitLoading ? (
    <Loading
      description={value.toLocaleString("de-DE", {
        minimumFractionDigits: decimalScale,
        maximumFractionDigits: decimalScale,
      })}
      size="20px"
      style={{ margin: 0 }}
    />
  ) : (
    <TableNumberField
      id={product.id}
      value={value}
      onValueChange={(value) => setValue(value)}
      submitValue={submitPrice}
      autoFocus={autoFocus}
      disabled={disabled}
      decimalScale={decimalScale}
    />
  );
};
