import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ExcludedSeller } from "types";
import { CustomButton, CustomReactTable, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { ExcludedSellerDeleteDialog } from "../../excludedsellerDelete/ExcludedSellerDeleteDialog";
import { ExcludedSellerFormDialog } from "../../excludedsellerForm/ExcludedSellerFormDialog";

type ExcludedSellerListTableProps = {
  editable: boolean;
  excludedSellerTableRef?: React.RefObject<
    CustomReactTableHandles<ExcludedSeller>
  >;
  excludedSellers: ExcludedSeller[];
  setExcludedSellers?: (value: React.SetStateAction<ExcludedSeller[]>) => void;
};

export const ExcludedSellerListTable: React.FC<
  ExcludedSellerListTableProps
> = ({
  editable,
  excludedSellerTableRef,
  excludedSellers,
  setExcludedSellers,
}) => {
  console.log("excludedSellers: ", excludedSellers);

  const [deleteExcludedSellerDialogOpen, setDeleteExcludedSellerDialogOpen] =
    useState<boolean>(false);
  const [excludedSellerToDelete, setExcludedSellerToDelete] = useState<
    ExcludedSeller | undefined
  >();

  const [createExcludedSellerDialogOpen, setCreateExcludedSellerDialogOpen] =
    useState<boolean>(false);

  const [editExcludedSellerDialogOpen, setEditExcludedSellerDialogOpen] =
    useState<boolean>(false);
  const [excludedSellerToEdit, setExcludedSellerToEdit] = useState<
    ExcludedSeller | undefined
  >();

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<ExcludedSeller>[]>(
    () => [
      {
        header: "Händler",
        accessorKey: "username",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.username,
        },
      },
      {
        header: "Kommentar",
        accessorKey: "comment",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.comment ?? "",
        },
      },
      ...(!editable
        ? []
        : ([
            {
              header: "Aktionen",
              cell: ({ row }) => (
                <ActionsColumnFormatter<ExcludedSeller>
                  row={row}
                  listIntent="list"
                  setRowToEdit={setExcludedSellerToEdit}
                  setEditRowDialogOpen={setEditExcludedSellerDialogOpen}
                  setRowToDelete={setExcludedSellerToDelete}
                  setDeleteRowDialogOpen={setDeleteExcludedSellerDialogOpen}
                />
              ),
              footer: (props) => props.column.id,
            },
          ] as ColumnDef<ExcludedSeller>[])),
    ],
    [editable],
  );

  return (
    <BoxHeadlineContainer
      boxTitle="Ausgeschlossene Händler"
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        editable && (
          <CustomButton
            text="Neuer ausgeschlossener Händler"
            onClick={() => setCreateExcludedSellerDialogOpen(true)}
            size="small"
          />
        )
      }
    >
      {excludedSellerToDelete && (
        <ExcludedSellerDeleteDialog
          dialogOpen={deleteExcludedSellerDialogOpen}
          setDialogOpen={setDeleteExcludedSellerDialogOpen}
          excludedSeller={excludedSellerToDelete}
          setExcludedSellers={setExcludedSellers}
        />
      )}

      <ExcludedSellerFormDialog
        formIntent="create"
        dialogOpen={createExcludedSellerDialogOpen}
        setDialogOpen={setCreateExcludedSellerDialogOpen}
        excludedSellerList={excludedSellers}
        setExcludedSellers={setExcludedSellers}
      />

      {excludedSellerToEdit && (
        <ExcludedSellerFormDialog
          formIntent="edit"
          dialogOpen={editExcludedSellerDialogOpen}
          setDialogOpen={setEditExcludedSellerDialogOpen}
          excludedSellerList={excludedSellers}
          excludedSeller={excludedSellerToEdit}
          setExcludedSellers={setExcludedSellers}
        />
      )}

      <CustomReactTable<ExcludedSeller>
        data={excludedSellers}
        columns={defaultColumns}
        ref={excludedSellerTableRef}
        marginBottom={0}
        leftSpacing="medium"
      />
    </BoxHeadlineContainer>
  );
};
