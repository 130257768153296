import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { useGlobalSettingsRead } from "hooks";
import { PriceCalc, Product } from "types";
import { CustomDialog, CustomTab, CustomTabs, Loading } from "core";
import {
  CardmarketFoilIcon,
  CardmarketSyncIcon,
  StoreIcon,
} from "modules/icons";
import { getPriceCalc } from "modules/pricecalcs/api";
import { PriceCalculationMethod } from "../PriceCalculationMethod";

type ProductPriceCalculationMethodDialogDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  product: Product;
};

export const ProductPriceCalculationMethodDialog: React.FC<
  ProductPriceCalculationMethodDialogDialogProps
> = ({ dialogOpen, setDialogOpen, product }) => {
  console.log("ProductPriceCalculationMethodDialog", product);

  const { globalSettings } = useGlobalSettingsRead();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [storePriceCalc, setStorePriceCalc] = useState<PriceCalc | null>(null);
  const [storePriceOldCalc, setStorePriceOldCalc] = useState<PriceCalc | null>(
    null,
  );
  const [onlinePriceCalc, setOnlinePriceCalc] = useState<PriceCalc | null>(
    null,
  );
  const [onlinePriceOldCalc, setOnlinePriceOldCalc] =
    useState<PriceCalc | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadCalcMethodDetails = useCallback(async () => {
    setIsLoading(true);

    const [
      storePriceCalcFromAPI,
      storePriceOldCalcFromAPI,
      onlinePriceCalcFromAPI,
      onlinePriceOldCalcFromAPI,
    ] = await Promise.all([
      product.storePriceCalcID ? getPriceCalc(product.storePriceCalcID) : null,
      product.storePriceOldCalcID
        ? getPriceCalc(product.storePriceOldCalcID)
        : null,
      product.onlinePriceCalcID
        ? getPriceCalc(product.onlinePriceCalcID)
        : null,
      product.onlinePriceOldCalcID
        ? getPriceCalc(product.onlinePriceOldCalcID)
        : null,
    ]);

    setStorePriceCalc(storePriceCalcFromAPI);
    setStorePriceOldCalc(storePriceOldCalcFromAPI);
    setOnlinePriceCalc(onlinePriceCalcFromAPI);
    setOnlinePriceOldCalc(onlinePriceOldCalcFromAPI);

    setIsLoading(false);
  }, [product]);

  useEffect(() => {
    loadCalcMethodDetails();
  }, [loadCalcMethodDetails]);

  const priceCalcTarget = useMemo(
    () =>
      selectedTab === 5
        ? "CardmarketFoilOld"
        : selectedTab === 4
          ? "CardmarketNoFoilOld"
          : selectedTab === 3
            ? "StoreOld"
            : selectedTab === 2
              ? "CardmarketFoil"
              : selectedTab === 1
                ? "CardmarketNoFoil"
                : "Store",
    [selectedTab],
  );

  const priceCalc = useMemo(
    () =>
      priceCalcTarget === "CardmarketFoilOld" ||
      priceCalcTarget === "CardmarketNoFoilOld"
        ? onlinePriceOldCalc
        : priceCalcTarget === "StoreOld"
          ? storePriceOldCalc
          : priceCalcTarget === "CardmarketFoil" ||
              priceCalcTarget === "CardmarketNoFoil"
            ? onlinePriceCalc
            : storePriceCalc,
    [
      onlinePriceCalc,
      onlinePriceOldCalc,
      priceCalcTarget,
      storePriceCalc,
      storePriceOldCalc,
    ],
  );

  const priceCalculationResult = useMemo(
    () =>
      priceCalcTarget === "CardmarketFoilOld"
        ? onlinePriceOldCalc?.priceCalculationOnlineFoil
        : priceCalcTarget === "CardmarketNoFoilOld"
          ? onlinePriceOldCalc?.priceCalculationOnline
          : priceCalcTarget === "StoreOld"
            ? storePriceOldCalc?.priceCalculationStore
            : priceCalcTarget === "CardmarketFoil"
              ? onlinePriceCalc?.priceCalculationOnlineFoil
              : priceCalcTarget === "CardmarketNoFoil"
                ? onlinePriceCalc?.priceCalculationOnline
                : storePriceCalc?.priceCalculationStore,
    [
      onlinePriceCalc,
      onlinePriceOldCalc,
      priceCalcTarget,
      storePriceCalc,
      storePriceOldCalc,
    ],
  );

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={true}
      titleText="Rechenweg für Preisberechnung"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={true}
      minWidth={700}
    >
      <CustomTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
        <CustomTab tabLabel="Laden" tabIcon={<StoreIcon />} />
        <CustomTab
          tabLabel="Cardmarket - Kein Foil"
          tabIcon={<CardmarketSyncIcon />}
        />
        <CustomTab
          tabLabel="Cardmarket - Foil"
          tabIcon={<CardmarketFoilIcon />}
        />
        <CustomTab tabLabel="Laden (alt)" tabIcon={<StoreIcon />} />
        <CustomTab
          tabLabel="Cardmarket - Kein Foil (alt)"
          tabIcon={<CardmarketSyncIcon />}
        />
        <CustomTab
          tabLabel="Cardmarket - Foil (alt)"
          tabIcon={<CardmarketFoilIcon />}
        />
      </CustomTabs>

      {isLoading ? (
        <Loading
          align="center"
          description="Bitte warten. Der Rechenweg wird geladen..."
        />
      ) : !globalSettings ? (
        <Typography variant="h3">
          Die globalen Einstellungen für die Preisberechnung konnten nicht
          geladen werden.
        </Typography>
      ) : !priceCalc ? (
        <Typography variant="h3">
          Es wurde noch kein Rechenweg für die Preisberechnung hinterlegt.
        </Typography>
      ) : (
        <PriceCalculationMethod
          priceCalcTarget={priceCalcTarget}
          priceCalc={priceCalc}
          priceCalculationResult={priceCalculationResult}
          globalSettings={globalSettings}
        />
      )}
    </CustomDialog>
  );
};
