import { BoxContainer } from "layout/Container/BoxContainer";
import React from "react";
import useStyles from "./styles";

const Dashboard: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.dashboardPage}>
      <BoxContainer>Dashboard</BoxContainer>
    </div>
  );
};

export default Dashboard;
