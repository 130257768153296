import React, { useRef } from "react";
import { useCardmarketCalcTaskList } from "hooks";
import { CardmarketCalcTask } from "types";
import { CustomButton, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ExpansionDetails } from "modules/expansions/expansionproducts/ExpansionDetails";
import { ExcelIcon } from "modules/icons";
import { Routes } from "routes";
import { CardmarketCalcTaskListFilter } from "../CardmarketCalcTaskListFilter";
import { CardmarketCalcTaskListTable } from "../CardmarketCalcTaskListTable";

type CardmarketCalcTaskListPageProps = {
  showExpansionDetails?: boolean;
  marginTop?: boolean;
};

const CardmarketCalcTaskListPage: React.FC<CardmarketCalcTaskListPageProps> = ({
  showExpansionDetails = true,
  marginTop,
}) => {
  const { isLoading, cardmarketCalcTaskList, expansionID } =
    useCardmarketCalcTaskList();

  const cardmarketCalcTaskTableRef =
    useRef<CustomReactTableHandles<CardmarketCalcTask>>(null);

  return (
    <>
      {showExpansionDetails && expansionID && <ExpansionDetails />}

      <BoxHeadlineContainer
        boxTitle="Online Preisberechnungen"
        boxIcon={Routes.cardmarketcalctasks.icon}
        boxSubTitle={cardmarketCalcTaskList.length.toString()}
        paddingHorizontal={0}
        paddingVertical={0}
        boxAlignment="center"
        boxMenu={
          <CustomButton
            text="Download Excel"
            size="small"
            iconBefore={<ExcelIcon />}
            onClick={() => cardmarketCalcTaskTableRef.current?.exportToExcel()}
            styleWrapper={{ marginLeft: 33 }}
          />
        }
        marginTop={marginTop}
      >
        <CardmarketCalcTaskListFilter />
        <CardmarketCalcTaskListTable
          isLoading={isLoading}
          cardmarketCalcTaskList={cardmarketCalcTaskList}
          cardmarketCalcTaskTableRef={cardmarketCalcTaskTableRef}
        />
      </BoxHeadlineContainer>
    </>
  );
};

export default CardmarketCalcTaskListPage;
