import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CardmarketCredential } from "types";
import { CustomButton, CustomReactTable, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { CardmarketCredentialDeleteDialog } from "../../cardmarketcredentialDelete/CardmarketCredentialDeleteDialog";
import { CardmarketCredentialFormDialog } from "../../cardmarketcredentialForm/CardmarketCredentialFormDialog";

type CardmarketCredentialListTableProps = {
  editable: boolean;
  title: string;
  cardmarketCredentialTableRef?: React.RefObject<
    CustomReactTableHandles<CardmarketCredential>
  >;
  cardmarketCredentials: CardmarketCredential[];
  setCardmarketCredentials?: (
    value: React.SetStateAction<CardmarketCredential[]>,
  ) => void;
};

export const CardmarketCredentialListTable: React.FC<
  CardmarketCredentialListTableProps
> = ({
  editable,
  title,
  cardmarketCredentialTableRef,
  cardmarketCredentials,
  setCardmarketCredentials,
}) => {
  console.log("cardmarketCredentials: ", cardmarketCredentials);

  const [
    deleteCardmarketCredentialDialogOpen,
    setDeleteCardmarketCredentialDialogOpen,
  ] = useState<boolean>(false);
  const [cardmarketCredentialToDelete, setCardmarketCredentialToDelete] =
    useState<CardmarketCredential | undefined>();

  const [
    createCardmarketCredentialDialogOpen,
    setCreateCardmarketCredentialDialogOpen,
  ] = useState<boolean>(false);

  const [
    editCardmarketCredentialDialogOpen,
    setEditCardmarketCredentialDialogOpen,
  ] = useState<boolean>(false);
  const [cardmarketCredentialToEdit, setCardmarketCredentialToEdit] = useState<
    CardmarketCredential | undefined
  >();

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<CardmarketCredential>[]>(
    () => [
      {
        header: "API Name",
        accessorKey: "apiName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.apiName,
        },
      },
      {
        header: "App Token",
        accessorKey: "appToken",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.appToken,
        },
      },
      {
        header: "App Secret",
        accessorKey: "appSecret",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.appSecret,
        },
      },
      {
        header: "Access Token",
        accessorKey: "accessToken",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.accessToken,
        },
      },
      {
        header: "Access Token Secret",
        accessorKey: "accessTokenSecret",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.accessTokenSecret,
        },
      },
      {
        header: "Base URL",
        accessorKey: "baseUrl",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => row.original.baseUrl,
        },
      },
      {
        header: "Ist Hauptzugang",
        accessorKey: "isMainAccount",
        accessorFn: (row) => (row.isMainAccount ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => (row.original.isMainAccount ? "Ja" : "Nein"),
        },
      },
      ...(!editable
        ? []
        : ([
            {
              header: "Aktionen",
              cell: ({ row }) => (
                <ActionsColumnFormatter<CardmarketCredential>
                  row={row}
                  listIntent="list"
                  setRowToEdit={setCardmarketCredentialToEdit}
                  setEditRowDialogOpen={setEditCardmarketCredentialDialogOpen}
                  setRowToDelete={setCardmarketCredentialToDelete}
                  setDeleteRowDialogOpen={
                    setDeleteCardmarketCredentialDialogOpen
                  }
                />
              ),
              footer: (props) => props.column.id,
            },
          ] as ColumnDef<CardmarketCredential>[])),
    ],
    [editable],
  );

  return (
    <BoxHeadlineContainer
      boxTitle={title}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        editable && (
          <CustomButton
            text="Neuer Zugang"
            onClick={() => setCreateCardmarketCredentialDialogOpen(true)}
            size="small"
          />
        )
      }
    >
      {cardmarketCredentialToDelete && (
        <CardmarketCredentialDeleteDialog
          dialogOpen={deleteCardmarketCredentialDialogOpen}
          setDialogOpen={setDeleteCardmarketCredentialDialogOpen}
          cardmarketCredential={cardmarketCredentialToDelete}
          setCardmarketCredentials={setCardmarketCredentials}
        />
      )}

      <CardmarketCredentialFormDialog
        formIntent="create"
        dialogOpen={createCardmarketCredentialDialogOpen}
        setDialogOpen={setCreateCardmarketCredentialDialogOpen}
        cardmarketCredentialList={cardmarketCredentials}
        setCardmarketCredentials={setCardmarketCredentials}
      />

      {cardmarketCredentialToEdit && (
        <CardmarketCredentialFormDialog
          formIntent="edit"
          dialogOpen={editCardmarketCredentialDialogOpen}
          setDialogOpen={setEditCardmarketCredentialDialogOpen}
          cardmarketCredentialList={cardmarketCredentials}
          cardmarketCredential={cardmarketCredentialToEdit}
          setCardmarketCredentials={setCardmarketCredentials}
        />
      )}
      <CustomReactTable<CardmarketCredential>
        data={cardmarketCredentials}
        columns={defaultColumns}
        ref={cardmarketCredentialTableRef}
        marginBottom={0}
      />
    </BoxHeadlineContainer>
  );
};
