import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Product,
  SearchTextParamName,
  SortDirection,
  isKeyOfProduct,
} from "types";
import {
  FilterField,
  SortField,
  filterByBooleanField,
  filterByDateField,
  filterByNumberField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByBooleanField,
  sortByDateField,
  sortByNumberField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const searchFields: (keyof Product)[] = [
  "website",
  // "image",
  "expansionID",
  "productAlias",
  "productDeName",
  "productEnName",
];

const filterByProductField = (
  productList: Product[],
  filterField: FilterField<Product>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "productAlias":
      return filterByTextField(productList, "productAlias", value);
    case "countArticles":
      return filterByNumberField(
        productList,
        "countArticles",
        comparisonType,
        parseInt(value),
      );
    case "countFoils":
      return filterByNumberField(
        productList,
        "countFoils",
        comparisonType,
        parseInt(value),
      );
    case "countReprints":
      return filterByNumberField(
        productList,
        "countReprints",
        comparisonType,
        parseInt(value),
      );
    case "createdAt":
      return filterByDateField(productList, "createdAt", comparisonType, value);
    case "deStock":
      return filterByNumberField(
        productList,
        "deStock",
        comparisonType,
        parseInt(value),
      );
    case "deTransfer":
      return filterByNumberField(
        productList,
        "deTransfer",
        comparisonType,
        parseInt(value),
      );
    case "enStock":
      return filterByNumberField(
        productList,
        "enStock",
        comparisonType,
        parseInt(value),
      );
    case "enTransfer":
      return filterByNumberField(
        productList,
        "enTransfer",
        comparisonType,
        parseInt(value),
      );
    // case "expansionID":
    //   if (value === "search") return productList;
    //   return filterByTextField(productList, "expansionID", value);
    case "folderChange":
      return filterByNumberField(
        productList,
        "folderChange",
        comparisonType,
        parseInt(value),
      );
    case "id":
      return filterByTextField(productList, "id", value);
    case "idMetaproduct":
      return filterByNumberField(
        productList,
        "idMetaproduct",
        comparisonType,
        parseInt(value),
      );
    case "idProduct":
      return filterByNumberField(
        productList,
        "idProduct",
        comparisonType,
        parseInt(value),
      );
    case "image":
      return filterByTextField(productList, "image", value);
    case "isProductActive":
      return filterByBooleanField(
        productList,
        "isProductActive",
        value === "true" ? true : false,
      );
    case "lastStorePriceCalcSuccess":
      return filterByBooleanField(
        productList,
        "lastStorePriceCalcSuccess",
        value === "true" ? true : false,
      );
    case "lastOnlinePriceCalcSuccess":
      return filterByBooleanField(
        productList,
        "lastOnlinePriceCalcSuccess",
        value === "true" ? true : false,
      );
    case "number":
      return filterByTextField(productList, "number", value);
    case "onlyFoil":
      return filterByBooleanField(
        productList,
        "onlyFoil",
        value === "true" ? true : false,
      );
    case "priceStore":
      return filterByNumberField(
        productList,
        "priceStore",
        comparisonType,
        parseInt(value),
      );
    case "priceOldStore":
      return filterByNumberField(
        productList,
        "priceOldStore",
        comparisonType,
        parseInt(value),
      );
    case "priceOnline":
      return filterByNumberField(
        productList,
        "priceOnline",
        comparisonType,
        parseInt(value),
      );
    case "priceOldOnline":
      return filterByNumberField(
        productList,
        "priceOldOnline",
        comparisonType,
        parseInt(value),
      );
    case "productDeName":
      return filterByTextField(productList, "productDeName", value);
    case "productEnName":
      return filterByTextField(productList, "productEnName", value);
    case "rarity":
      return filterByTextField(productList, "rarity", value);
    case "stockCount":
      return filterByNumberField(
        productList,
        "stockCount",
        comparisonType,
        parseInt(value),
      );
    case "stockCountDe":
      return filterByNumberField(
        productList,
        "stockCountDe",
        comparisonType,
        parseInt(value),
      );
    case "stockCountEn":
      return filterByNumberField(
        productList,
        "stockCountEn",
        comparisonType,
        parseInt(value),
      );
    case "website":
      return filterByTextField(productList, "website", value);
    case "updatedAt":
      return filterByDateField(productList, "updatedAt", comparisonType, value);
    default:
      return productList;
  }
};

const sortByProductField = (
  a: Product,
  b: Product,
  sortField: SortField<Product>,
  useAlias: boolean,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "productAlias":
      if (!a.productAlias && !b.productAlias) return 0;
      if (!a.productAlias) return 1;
      if (!b.productAlias) return -1;
      return sortByTextField(a, b, "productAlias", direction);
    case "countArticles":
      return sortByNumberField(a, b, "countArticles", direction);
    case "countFoils":
      return sortByNumberField(a, b, "countFoils", direction);
    case "countReprints":
      return sortByNumberField(a, b, "countReprints", direction);
    case "createdAt":
      return sortByDateField(a, b, "createdAt", direction);
    case "deStock":
      return sortByNumberField(a, b, "deStock", direction);
    case "deTransfer":
      return sortByNumberField(a, b, "deTransfer", direction);
    case "enStock":
      return sortByNumberField(a, b, "enStock", direction);
    case "enTransfer":
      return sortByNumberField(a, b, "enTransfer", direction);
    case "expansionID":
      return sortByTextField(a, b, "expansionID", direction);
    case "folderChange":
      if (a.folderChange === "Unchanged") return 1;
      if (b.folderChange === "Unchanged") return -1;

      if (a.folderChange === "Downgrade") return -1;
      if (b.folderChange === "Downgrade") return 1;
      return 0;
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "idMetaproduct":
      return sortByNumberField(a, b, "idMetaproduct", direction);
    case "idProduct":
      return sortByNumberField(a, b, "idProduct", direction);
    case "image":
      return sortByTextField(a, b, "image", direction);
    case "isProductActive":
      return sortByBooleanField(a, b, "isProductActive", direction);
    case "lastStorePriceCalcSuccess":
      return sortByBooleanField(a, b, "lastStorePriceCalcSuccess", direction);
    case "lastOnlinePriceCalcSuccess":
      return sortByBooleanField(a, b, "lastOnlinePriceCalcSuccess", direction);
    case "number":
      return sortByTextField(a, b, "number", direction);
    case "onlyFoil":
      return sortByBooleanField(a, b, "onlyFoil", direction);
    case "priceStore":
      return sortByNumberField(a, b, "priceStore", direction);
    case "priceOldStore":
      return sortByNumberField(a, b, "priceOldStore", direction);
    case "priceOnline":
      return sortByNumberField(a, b, "priceOnline", direction);
    case "priceOldOnline":
      return sortByNumberField(a, b, "priceOldOnline", direction);
    case "productDeName": {
      if (!useAlias) return sortByTextField(a, b, "productDeName", direction);

      const aFieldValue: string = a.productAlias ?? a.productDeName;
      const bFieldValue: string = b.productAlias ?? b.productDeName;

      return direction === SortDirection.asc
        ? aFieldValue.localeCompare(bFieldValue)
        : bFieldValue.localeCompare(aFieldValue);
    }
    case "productEnName":
      return sortByTextField(a, b, "productEnName", direction);
    case "rarity":
      return sortByTextField(a, b, "rarity", direction);
    case "stockCount":
      return sortByNumberField(a, b, "stockCount", direction);
    case "stockCountDe":
      return sortByNumberField(a, b, "stockCountDe", direction);
    case "stockCountEn":
      return sortByNumberField(a, b, "stockCountEn", direction);
    case "updatedAt":
      return sortByDateField(a, b, "updatedAt", direction);
    case "website":
      return sortByTextField(a, b, "website", direction);
    default:
      return 0;
  }
};

export const useProductListFilterAndSort = (
  filter: boolean,
  useAlias: boolean,
) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Product>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Product, string>> = {
      isProductActive: "true",
    };

    const filters: FilterField<Product>[] = Array.from(searchParams.entries())
      .filter(([key]) => isKeyOfProduct(key))
      .map(([field, value]) => ({
        field: field as keyof Product,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<Product>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Product,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Product, SortDirection>> = {
      productDeName: SortDirection.asc,
    };

    return getSortFields<Product>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfProduct,
    );
  }, [searchParams]);

  const filterAndSortProducts = useCallback(
    (productList: Product[]) =>
      sortEntities<Product>(
        filter
          ? filterEntities<Product>(
              productList,
              filterFields,
              searchFields,
              searchParams.get("expansionID") === "search"
                ? null
                : searchParams.get(SearchTextParamName.SearchProduct),
              filterByProductField,
            )
          : productList,
        sortFields,
        sortByProductField,
        useAlias,
      ).map((product) => ({
        ...product,
        updatedAt: new Date(
          new Date(product.updatedAt ?? new Date().getTime()).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields, useAlias],
  );

  return filterAndSortProducts;
};
