import { Expansion, ExpansionShortened } from "types";

export const getExpansionIconPosition = (iconNumber: number) => {
  // Bestimme die Länge der zu subtrahierenden Zeichen basierend auf dem Wert von icon
  const subtrLength = iconNumber >= 100 ? 2 : 1;

  // Berechne die Spalte
  const col = -21 * parseInt(iconNumber.toString().substr(-1), 10);

  // Berechne die Zeile, wenn icon größer als 9 ist, sonst 0
  let row =
    iconNumber > 9
      ? parseInt(iconNumber.toString().substr(0, subtrLength), 10)
      : 0;
  row = row > 0 ? -21 * row : 0;

  // Rückgabe des HTML-Strings mit den berechneten Positionen
  return `<span class="expicon" style="background-position: ${col}px ${row}px"></span>`;
};

export const getExpansionName = (expansion: Expansion | ExpansionShortened) => {
  return (
    expansion?.expansionAlias ??
    expansion?.expansionDeName ??
    expansion?.expansionEnName
  );
};
