import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CustomNumberField, CustomNumberFieldHandles } from "core";
import { usePriceListStepForm } from "../usePriceListStepForm";

type PriceListStepFormProps = {
  priceListStepList: number[];
  priceListStep: number | undefined;
};

export type PriceListStepFormHandles = {
  validatePriceListStepForm(): number | null;
};

const PriceListStepFormComponent: React.ForwardRefRenderFunction<
  PriceListStepFormHandles,
  PriceListStepFormProps
> = ({ priceListStepList, priceListStep }, priceListStepFormRef) => {
  const { enqueueSnackbar } = useSnackbar();

  const { priceStep, setPriceStep } = usePriceListStepForm(priceListStep);

  const priceStepInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(priceListStepFormRef, () => ({
    validatePriceListStepForm: () => {
      if (priceStep === "") {
        enqueueSnackbar("Bitte gib eine Preisstufe ein!");
        priceStepInputRef.current?.highlight(false);
        return null;
      }

      const existingPriceListStep = priceListStepList.find(
        (priceListStepItem) =>
          priceListStepItem !== priceListStep &&
          priceListStepItem === priceStep,
      );

      if (existingPriceListStep) {
        enqueueSnackbar(
          "Es existiert bereits eine Preisstufe mit dem gleichen Preis!",
        );
        return null;
      }

      return priceStep;
    },
  }));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CustomNumberField
          label="Mindestpreis"
          number={priceStep}
          setNumber={setPriceStep}
          ref={priceStepInputRef}
          unitAdornment="€"
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export const PriceListStepForm = React.forwardRef(PriceListStepFormComponent);
