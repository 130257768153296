import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Expansion,
  SearchTextParamName,
  SortDirection,
  isKeyOfExpansion,
} from "types";
import {
  FilterField,
  SortField,
  filterByBooleanField,
  filterByDateField,
  filterByNumberField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByBooleanField,
  sortByDateField,
  sortByNumberField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const searchFields: (keyof Expansion)[] = [
  "abbreviation",
  "expansionAlias",
  "expansionDeName",
  "expansionEnName",
  "id",
  "idExpansion",
];

const filterByExpansionField = (
  expansionList: Expansion[],
  filterField: FilterField<Expansion>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "abbreviation":
      return filterByTextField(expansionList, "abbreviation", value);
    case "expansionEnName":
      return filterByTextField(expansionList, "expansionEnName", value);
    case "expansionAlias":
      return filterByTextField(expansionList, "expansionAlias", value);
    case "expansionDeName":
      return filterByTextField(expansionList, "expansionDeName", value);
    case "createdAt":
      return filterByDateField(
        expansionList,
        "createdAt",
        comparisonType,
        value,
      );
    case "icon":
      return filterByNumberField(
        expansionList,
        "icon",
        comparisonType,
        parseInt(value),
      );
    case "iconCol":
      return filterByNumberField(
        expansionList,
        "iconCol",
        comparisonType,
        parseInt(value),
      );
    case "iconRow":
      return filterByNumberField(
        expansionList,
        "iconRow",
        comparisonType,
        parseInt(value),
      );
    case "id":
      return filterByTextField(expansionList, "id", value);
    case "idExpansion":
      return filterByNumberField(
        expansionList,
        "idExpansion",
        comparisonType,
        parseInt(value),
      );
    case "isExpansionActive":
      return filterByBooleanField(
        expansionList,
        "isExpansionActive",
        value === "true" ? true : false,
      );
    case "isReleased":
      return filterByBooleanField(
        expansionList,
        "isReleased",
        value === "true" ? true : false,
      );
    case "lastStorePriceCalc":
      return filterByDateField(
        expansionList,
        "lastStorePriceCalc",
        comparisonType,
        value,
      );
    case "lastOnlinePriceCalc":
      return filterByDateField(
        expansionList,
        "lastOnlinePriceCalc",
        comparisonType,
        value,
      );
    case "lastSynced":
      return filterByDateField(
        expansionList,
        "lastSynced",
        comparisonType,
        value,
      );
    case "lastStockSynced":
      return filterByDateField(
        expansionList,
        "lastStockSynced",
        comparisonType,
        value,
      );
    case "parentID":
      return filterByTextField(expansionList, "parentID", value);
    case "parentName":
      return filterByTextField(expansionList, "parentName", value);
    case "productsCount":
      return filterByNumberField(
        expansionList,
        "productsCount",
        comparisonType,
        parseInt(value),
      );
    case "releaseDate":
      return filterByDateField(
        expansionList,
        "releaseDate",
        comparisonType,
        value,
      );
    case "updatedAt":
      return filterByDateField(
        expansionList,
        "updatedAt",
        comparisonType,
        value,
      );
    default:
      return expansionList;
  }
};

const sortByExpansionField = (
  a: Expansion,
  b: Expansion,
  sortField: SortField<Expansion>,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "abbreviation":
      return sortByTextField(a, b, "abbreviation", direction);
    case "expansionEnName":
      return sortByTextField(a, b, "expansionEnName", direction);
    case "expansionAlias":
      if (!a.expansionAlias && !b.expansionAlias) return 0;
      if (!a.expansionAlias) return 1;
      if (!b.expansionAlias) return -1;
      return sortByTextField(a, b, "expansionAlias", direction);
    case "expansionDeName":
      return sortByTextField(a, b, "expansionDeName", direction);
    case "createdAt":
      return sortByDateField(a, b, "createdAt", direction);
    case "icon":
      return sortByNumberField(a, b, "icon", direction);
    case "iconCol":
      return sortByNumberField(a, b, "iconCol", direction);
    case "iconRow":
      return sortByNumberField(a, b, "iconRow", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "idExpansion":
      return sortByNumberField(a, b, "idExpansion", direction);
    case "isExpansionActive":
      return sortByBooleanField(a, b, "isExpansionActive", direction);
    case "isReleased":
      return sortByBooleanField(a, b, "isReleased", direction);
    case "lastStorePriceCalc":
      return sortByDateField(a, b, "lastStorePriceCalc", direction);
    case "lastOnlinePriceCalc":
      return sortByDateField(a, b, "lastOnlinePriceCalc", direction);
    case "lastSynced":
      return sortByDateField(a, b, "lastSynced", direction);
    case "lastStockSynced":
      return sortByDateField(a, b, "lastStockSynced", direction);
    case "parentID":
      return sortByTextField(a, b, "parentID", direction);
    case "parentName":
      if (!a.parentName && !b.parentName) return 0;
      if (!a.parentName) return 1;
      if (!b.parentName) return -1;
      return sortByTextField(a, b, "parentName", direction);
    case "productsCount":
      return sortByNumberField(a, b, "productsCount", direction);
    case "releaseDate":
      return sortByDateField(a, b, "releaseDate", direction);
    case "updatedAt":
      return sortByDateField(a, b, "updatedAt", direction);
    default:
      return 0;
  }
};

export const useExpansionListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Expansion>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Expansion, string>> = {
      isExpansionActive: "true",
    };

    const filters: FilterField<Expansion>[] = Array.from(searchParams.entries())
      .filter(([key]) => isKeyOfExpansion(key))
      .map(([field, value]) => ({
        field: field as keyof Expansion,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<Expansion>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Expansion,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Expansion, SortDirection>> = {
      expansionDeName: SortDirection.asc,
    };

    return getSortFields<Expansion>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfExpansion,
    );
  }, [searchParams]);

  const filterAndSortExpansions = useCallback(
    (expansionList: Expansion[]) =>
      sortEntities<Expansion>(
        filter
          ? filterEntities<Expansion>(
              expansionList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchExpansion),
              filterByExpansionField,
            )
          : expansionList,
        sortFields,
        sortByExpansionField,
        false,
      ).map((expansion) => ({
        ...expansion,
        updatedAt: new Date(
          new Date(expansion.updatedAt ?? new Date().getTime()).getTime() +
            1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortExpansions;
};
