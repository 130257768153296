import React from "react";
import { CustomTooltip } from "core";
import { StarIcon } from "modules/icons";
import useStyles from "./styles";

const getReputationName = (reputation: number) => {
  switch (reputation) {
    case 1:
      return "Herausragend";
    case 2:
      return "Sehr gut";
    case 3:
      return "Gut";
    case 4:
      return "Durchschnittlich";
    case 5:
      return "Schlecht";
    default:
      return "Keine";
  }
};

type ReputationColumnFormatterProps = {
  reputation: number;
};

export const ReputationColumnFormatter: React.FC<
  ReputationColumnFormatterProps
> = ({ reputation }) => {
  const { classes } = useStyles({ reputation });

  return (
    <CustomTooltip title={getReputationName(reputation)} followCursor={true}>
      <span className={classes.reputation}>
        <StarIcon />
      </span>
    </CustomTooltip>
  );
};
