import React from "react";
import { Product } from "types";
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from "modules/icons";
import useStyles from "./styles";

type FolderChangeColumnProps = {
  product: Product;
};

export const FolderChangeColumn: React.FC<FolderChangeColumnProps> = ({
  product,
}) => {
  const { classes } = useStyles();

  const { folderChange } = product;

  if (folderChange === "Unchanged") {
    return null;
  }

  return (
    <div className={classes.arrow}>
      {folderChange === "Downgrade" ? (
        <ArrowCircleDownIcon className={classes.arrowDown} />
      ) : (
        <ArrowCircleUpIcon className={classes.arrowUp} />
      )}
    </div>
  );
};
