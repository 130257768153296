import React from "react";
import { CustomTooltip } from "core";
import useStyles from "./styles";

const getConditionName = (condition: string) => {
  switch (condition) {
    case "MT":
      return "Mint";
    case "NM":
      return "Near Mint";
    case "EX":
      return "Excellent";
    case "GD":
      return "Good";
    case "LP":
      return "Light Played";
    case "PL":
      return "Played";
    case "PO":
      return "Poor";
    default:
      return "Excellent";
  }
};

type ConditionColumnFormatterProps = {
  condition: string;
};

export const ConditionColumnFormatter: React.FC<
  ConditionColumnFormatterProps
> = ({ condition }) => {
  const { classes } = useStyles({ condition });

  return (
    <CustomTooltip title={getConditionName(condition)} followCursor={true}>
      <span className={classes.condition}>{condition}</span>
    </CustomTooltip>
  );
};
