import React from "react";
import { CustomTooltip } from "core";
import { CalendarDateIcon } from "modules/icons";
import useStyles from "./styles";

type ShippingColumnFormatterProps = {
  avgShippingTime: number;
};

export const ShippingColumnFormatter: React.FC<
  ShippingColumnFormatterProps
> = ({ avgShippingTime }) => {
  const { classes } = useStyles();

  return (
    <CustomTooltip
      title={
        "Geschätzte Versandzeit: " +
        avgShippingTime +
        (avgShippingTime === 1 ? " Tag" : " Tage")
      }
      followCursor={true}
    >
      <div className={classes.calendar}>
        <CalendarDateIcon />
        <span className={classes.avgShippingTime}>{avgShippingTime}</span>
      </div>
    </CustomTooltip>
  );
};
