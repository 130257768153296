import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const useListQueryParams = (
  selectedTab?: number,
  tabContainerIndex?: number,
) => {
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsFromStorage = useMemo(() => {
    const newSearchParams =
      sessionStorage.getItem(pathname) ?? searchParams.toString();
    return new URLSearchParams(newSearchParams);
  }, [pathname, searchParams]);

  useEffect(() => {
    if (
      selectedTab !== undefined &&
      tabContainerIndex !== undefined &&
      selectedTab !== tabContainerIndex
    ) {
      return;
    }

    sessionStorage.setItem(pathname, searchParamsFromStorage.toString());

    setSearchParams(searchParamsFromStorage, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParamsFromStorage, selectedTab, tabContainerIndex]);

  return searchParamsFromStorage;
};
