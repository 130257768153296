import { client } from "queryClient";
import { CalcTask } from "types";

export const getCalcTaskListByExpansion = async (
  expansionID: string,
  nextTokenParam: string | null = null,
  prevCalcTaskList: CalcTask[] = [],
): Promise<CalcTask[]> => {
  const { data, nextToken } = await client.models.CalcTask.calcTasksByExpansion(
    {
      expansionID,
    },
    {
      limit: 1000,
      nextToken: nextTokenParam,
    },
  );

  const calcTaskList = [...prevCalcTaskList, ...data];

  return nextToken
    ? getCalcTaskListByExpansion(expansionID, nextToken, calcTaskList)
    : calcTaskList;
};

export const getAllCalcTaskList = async (
  nextTokenParam: string | null = null,
  prevCalcTaskList: CalcTask[] = [],
): Promise<CalcTask[]> => {
  const { data, nextToken } = await client.models.CalcTask.list({
    limit: 15000,
    nextToken: nextTokenParam,
  });

  const calcTaskList = [...prevCalcTaskList, ...data];

  return nextToken ? getAllCalcTaskList(nextToken, calcTaskList) : calcTaskList;
};

export const getCalcTask = async (id: string): Promise<CalcTask | null> => {
  const { data } = await client.models.CalcTask.get({
    id,
  });

  return data;
};
