import React from "react";
import { ClickAwayListener, Paper, Popper, Typography } from "@mui/material";
import { useExpansionList } from "hooks";
import { Expansion, Product, SearchTextParamName } from "types";
import { CustomLink } from "core";
import { getExpansionName } from "modules/expansions/business";
import {
  ArrowRightIcon,
  ArticleIcon,
  CompanyIcon,
  FileDuoIcon,
} from "modules/icons";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { Routes } from "routes";
import useStyles from "./styles";

type SearchResultsProps = {
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  expansionList: Expansion[];
  productList: Product[];
  searchInput: string;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  isOpen,
  setIsOpen,
  expansionList,
  productList,
  searchInput,
}) => {
  const { classes } = useStyles();

  const { expansionList: allExpansionList } = useExpansionList(false);

  if (!isOpen) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Popper
        className={classes.popper}
        open={isOpen}
        placement="bottom-start"
        transition
        disablePortal
      >
        <Paper className={classes.paper}>
          {expansionList.length === 0 && productList.length === 0 && (
            <div className={classes.noResultsContent}>
              <FileDuoIcon className={classes.noResultsIcon} />
              <Typography className={classes.noResultsTitle}>
                Keine Ergebnisse gefunden
              </Typography>
              <Typography className={classes.noResultsText}>
                Bitte versuchen Sie eine andere Abfrage
              </Typography>
            </div>
          )}

          {expansionList.length > 0 && (
            <div className={classes.resultsContent}>
              <span className={classes.resultsHeader}>
                <CompanyIcon className={classes.resultsIcon} />
                <Typography variant="h3" className={classes.resultsTitle}>
                  Editionen
                </Typography>
              </span>
              <ul className={classes.resultList}>
                {expansionList.slice(0, 3).map((expansion) => (
                  <li>
                    <CustomLink
                      link={Routes.expansions.subNavigations.expansion.path.replace(
                        ":expansionID",
                        expansion.id,
                      )}
                      linkText={
                        <div className={classes.resultLink}>
                          <span className={classes.resultName}>
                            {getExpansionName(expansion)}
                          </span>
                          <span className={classes.resultSubname}>
                            {expansion.abbreviation}
                          </span>
                        </div>
                      }
                    />

                    <div className={classes.resultInfo}>
                      <span className={classes.expansionIdIcon}>
                        <IconColumnFormatter expansion={expansion} />{" "}
                        <span>{expansion.idExpansion}</span>
                      </span>
                      <span className={classes.resultInfo2}>
                        {expansion.productsCount} Karten
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              {expansionList.length > 3 && (
                <div className={classes.loadMore}>
                  <CustomLink
                    link={
                      Routes.expansions.path +
                      "?sort=deName:asc&" +
                      SearchTextParamName.SearchExpansion +
                      "=" +
                      searchInput
                    }
                    linkText="Mehr laden"
                    iconAfter={
                      <ArrowRightIcon className={classes.toSearchIcon} />
                    }
                    className={classes.toSearchLink}
                  />
                </div>
              )}
            </div>
          )}

          {productList.length > 0 && (
            <div className={classes.resultsContent}>
              <span className={classes.resultsHeader}>
                <ArticleIcon className={classes.resultsIcon} />
                <Typography variant="h3" className={classes.resultsTitle}>
                  Karten
                </Typography>
              </span>
              <ul className={classes.resultList}>
                {productList.slice(0, 3).map((product) => {
                  const expansion = allExpansionList.find(
                    (exp) => exp.id === product.expansionID,
                  );

                  return (
                    <li>
                      <CustomLink
                        link={Routes.products.subNavigations.product.path.replace(
                          ":productID",
                          product.id,
                        )}
                        linkText={
                          <div className={classes.resultLink}>
                            <span className={classes.resultName}>
                              {product.productDeName}
                            </span>
                            <span className={classes.resultSubname}>
                              {expansion ? getExpansionName(expansion) : "N/A"}
                            </span>
                          </div>
                        }
                      />

                      <div className={classes.resultInfo}>
                        <span className={classes.resultInfo1}>
                          {product.productEnName}
                        </span>
                        <span className={classes.resultInfo2}>
                          {product.priceStore
                            ? product.priceStore.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) + " €"
                            : "N/A"}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {productList.length > 3 && (
                <div className={classes.loadMore}>
                  <CustomLink
                    link={
                      Routes.products.path +
                      "?sort=locName:asc&" +
                      SearchTextParamName.SearchProduct +
                      "=" +
                      searchInput
                    }
                    linkText="Mehr laden"
                    iconAfter={
                      <ArrowRightIcon className={classes.toSearchIcon} />
                    }
                    className={classes.toSearchLink}
                  />
                </div>
              )}
            </div>
          )}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};
