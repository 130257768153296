import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useExpansionListShortened } from "hooks";
import { SelectOption } from "types";
import { CustomSelect } from "core";
import { getExpansionName } from "modules/expansions/business";

export const ExpansionFilterShortened: React.FC = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, expansionList } = useExpansionListShortened();

  const [expansion, setExpansion] = useState<SelectOption | null>(null);

  const expansionOptionList = useMemo(
    () => [
      {
        label: "Suche in allen Editionen",
        value: "search",
      },
      ...expansionList
        .filter(
          (expansion) => !expansion.parentID && expansion.isExpansionActive,
        )
        .map((expansionOption) => {
          return {
            label: getExpansionName(expansionOption),
            value: expansionOption.id,
          };
        }),
    ],
    [expansionList],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const expansionIDParam = searchParams.get("expansionID");

    const selectedExpansion = expansionOptionList.find(
      (expansionOption) => expansionOption.value === expansionIDParam,
    );
    const selectedExpansionOption = selectedExpansion ?? null;

    setExpansion(selectedExpansionOption);
  }, [expansionOptionList, isLoading, searchParams]);

  return (
    <CustomSelect<SelectOption>
      label="Edition"
      options={expansionOptionList}
      onChange={(expansionOption) => {
        setExpansion(expansionOption);
        if (!expansionOption) {
          searchParams.delete("expansionID");
        } else {
          searchParams.set("expansionID", expansionOption.value);
        }
        sessionStorage.setItem(pathname, searchParams.toString());
        setSearchParams(searchParams, {
          replace: true,
        });
      }}
      value={expansion}
      placeholder="Bitte Edition auswählen"
      isClearable={true}
    />
  );
};
