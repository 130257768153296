import React, { useImperativeHandle, useRef } from "react";
import { Collapse, Grid2 } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Expansion, UpdateExpansionInput } from "types";
import {
  CustomDatePicker,
  CustomDatePickerHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  LabeledTypography,
} from "core";
import {
  IntervalTypeOption,
  getIntervalTypeName,
} from "modules/shared/options/IntervalTypeOption";
import { IntervalTypeWithEveryOption } from "modules/shared/options/IntervalTypeWithEveryOption";
import { useCalcIntervalForm } from "../useCalcIntervalForm";
import utils from "utils";

type CalcIntervalFormProps = {
  expansion: Expansion;
};

export type CalcIntervalFormHandles = {
  validateCalcIntervalForm(): UpdateExpansionInput | null;
  resetCalcIntervalForm(): void;
};

const CalcIntervalFormComponent: React.ForwardRefRenderFunction<
  CalcIntervalFormHandles,
  CalcIntervalFormProps
> = ({ expansion }, calcIntervalFormRef) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    intervalTypeEvery,
    setIntervalTypeEvery,
    intervalType,
    setIntervalType,
    intervalValue,
    setIntervalValue,
    nextOnlinePriceCalc,
    setNextOnlinePriceCalc,
    resetCalcIntervalForm,
  } = useCalcIntervalForm(expansion);

  const intervalTypeEveryInputRef = useRef<CustomSelectHandles>(null);
  const intervalTypeInputRef = useRef<CustomSelectHandles>(null);
  const intervalValueInputRef = useRef<CustomNumberFieldHandles>(null);
  const nextOnlinePriceCalcInputRef = useRef<CustomDatePickerHandles>(null);

  useImperativeHandle(calcIntervalFormRef, () => ({
    validateCalcIntervalForm: () => {
      if (!intervalTypeEvery) {
        enqueueSnackbar("Bitte wähle einen Intervalltyp aus!");
        intervalTypeEveryInputRef.current?.highlight(false);
        return null;
      }

      if (intervalTypeEvery === "Every" && !intervalType) {
        enqueueSnackbar("Bitte wähle einen Intervalltyp aus!");
        intervalTypeInputRef.current?.highlight(false);
        return null;
      }

      if (intervalTypeEvery === "Every" && intervalValue === "") {
        enqueueSnackbar("Bitte gib einen Intervallwert ein!");
        intervalValueInputRef.current?.highlight(false);
        return null;
      }

      const intervalTypeValue =
        intervalTypeEvery === "Every" ? intervalType : intervalTypeEvery;

      const intervalValueNumber = intervalValue === "" ? 1 : intervalValue;

      if (!intervalTypeValue) {
        enqueueSnackbar("Bitte wähle einen Intervalltyp aus!");
        intervalTypeEveryInputRef.current?.highlight(false);
        return null;
      }

      if (!nextOnlinePriceCalc) {
        enqueueSnackbar(
          "Bitte wähle ein Datum für die nächste Onlinepreisberechnung aus!",
        );
        nextOnlinePriceCalcInputRef.current?.highlight(false);
        return null;
      }

      if (moment(nextOnlinePriceCalc).isBefore(moment(), "day")) {
        enqueueSnackbar(
          "Das Datum für die nächste Onlinepreisberechnung darf nicht in der Vergangenheit liegen!",
        );
        nextOnlinePriceCalcInputRef.current?.highlight(false);
        return null;
      }

      const updateExpansionFormInput: UpdateExpansionInput = {
        id: expansion.id,
        intervalType: intervalTypeValue,
        intervalValue: intervalValueNumber,
        nextOnlinePriceCalc:
          utils.dates.convertDateToAWSDateFormat(nextOnlinePriceCalc),
      };

      return updateExpansionFormInput;
    },
    resetCalcIntervalForm: resetCalcIntervalForm,
  }));

  return (
    <Grid2 container direction="column" spacing={5}>
      <Grid2>
        <IntervalTypeWithEveryOption
          label="Intervalltyp"
          intervalType={intervalTypeEvery}
          setIntervalType={setIntervalTypeEvery}
          required={true}
        />
      </Grid2>
      <Grid2>
        <Collapse in={intervalTypeEvery === "Every"}>
          <Grid2 container direction="row" spacing={2}>
            <Grid2>
              <CustomNumberField
                label="Intervallwert"
                number={intervalValue}
                setNumber={setIntervalValue}
                ref={intervalValueInputRef}
                required={true}
              />
            </Grid2>
            <Grid2>
              <IntervalTypeOption
                label="Intervalltyp"
                intervalType={intervalType}
                setIntervalType={setIntervalType}
                required={true}
              />
            </Grid2>
            <Grid2>
              <LabeledTypography
                label="Ausgewählter Intervall"
                content={
                  "alle " +
                  intervalValue +
                  " " +
                  (intervalType ? getIntervalTypeName(intervalType) : "")
                }
              />
            </Grid2>
          </Grid2>
        </Collapse>
      </Grid2>

      <Grid2>
        <CustomDatePicker
          label="Nächste Onlinepreisberechnung"
          value={nextOnlinePriceCalc}
          onChange={(date) => setNextOnlinePriceCalc(date)}
          ref={nextOnlinePriceCalcInputRef}
          required={true}
        />
      </Grid2>
    </Grid2>
  );
};

export const CalcIntervalForm = React.forwardRef(CalcIntervalFormComponent);
