import { client } from "queryClient";
import { PriceCalc } from "types";

export const getAllPriceCalcList = async (
  nextTokenParam: string | null = null,
  prevPriceCalcList: PriceCalc[] = [],
): Promise<PriceCalc[]> => {
  const params: any = {
    limit: 1000,
  };

  if (nextTokenParam) {
    params.nextToken = nextTokenParam;
  }

  const { data, nextToken } = await client.models.PriceCalc.list(params);

  const priceCalcList = [...prevPriceCalcList, ...data];

  return nextToken
    ? getAllPriceCalcList(nextToken, priceCalcList)
    : priceCalcList;
};

export const getPriceCalcListByProduct = async (
  productID: string,
  nextTokenParam: string | null = null,
  prevPriceCalcList: PriceCalc[] = [],
): Promise<PriceCalc[]> => {
  const { data, nextToken } = await client.models.PriceCalc.priceCalcsByProduct(
    {
      productID,
    },
    {
      limit: 1000,
      nextToken: nextTokenParam,
    },
  );

  const priceCalcList = [...prevPriceCalcList, ...data];

  return nextToken
    ? getPriceCalcListByProduct(productID, nextToken, priceCalcList)
    : priceCalcList;
};

export const getPriceCalc = async (id: string): Promise<PriceCalc | null> => {
  const { data } = await client.models.PriceCalc.get({
    id,
  });

  return data;
};
