import React, { useRef } from "react";
import { useProductListShortened } from "hooks";
import { ProductShortened } from "types";
import { CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { ProductPriceListFilter } from "../ProductPriceListFilter";
import { ProductPriceListTable } from "../ProductPriceListTable";

const ProductPriceListPage: React.FC = () => {
  const { isLoading, productList } = useProductListShortened();

  const productTableRef =
    useRef<CustomReactTableHandles<ProductShortened>>(null);

  return (
    <BoxHeadlineContainer
      boxTitle="Preisliste"
      boxIcon={Routes.products.icon}
      boxSubTitle={productList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
      boxWidth="lg"
      marginTop={false}
    >
      <ProductPriceListFilter />
      <ProductPriceListTable
        isLoading={isLoading}
        productList={productList}
        productTableRef={productTableRef}
      />
    </BoxHeadlineContainer>
  );
};

export default ProductPriceListPage;
