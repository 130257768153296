import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";

export type Article = Schema["Article"]["type"];

export type ArticleExcludeReason = Schema["ArticleExcludeReason"]["type"];

export type ArticleUserType = Schema["ArticleUserType"]["type"];

// // Extrahiere den non-nullable und non-undefined Typ
// type ExtractNonNullable<T> = T extends null | undefined ? never : T;

// // Generischer Typ, um den Typ aus dem Schema zu extrahieren und zu bereinigen
// type ExtractSchemaType<S, K extends keyof S> = ExtractNonNullable<S[K]>;

// type ArticleSchema = ExtractSchemaType<Schema["Article"]["type"], "idUser">;

const ArticleTypeKeys: TypeKeysEnum<Article> = {
  idArticle: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  idProduct: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  idLanguage: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  idCurrency: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  count: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  price: {
    type: "float",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  comments: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  lastEdited: {
    type: "datetime",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  condition: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  inShoppingCart: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  onSale: {
    type: "boolean",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  isFoil: {
    type: "boolean",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  idUser: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  username: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  company: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  userType: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  reputation: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  isSeller: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  sellCount: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  soldItems: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  shipsFast: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  avgShippingTime: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  riskGroup: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  isExcludedStore: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  excludedReasonStore: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  isExcludedOnlineNotFoil: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  excludedReasonOnlineNotFoil: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  isExcludedOnlineFoil: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
  excludedReasonOnlineFoil: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
  },
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
  },
};

export const isKeyOfArticle = (key: string): key is keyof Article =>
  key in ArticleTypeKeys;

export const isArticle = (article: unknown): article is Article =>
  typeof article === "object" && article !== null && "sellCount" in article;
