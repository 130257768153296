import { client } from "queryClient";
import { SyncTask, TaskStatus } from "types";

export const getSyncTaskList = async (
  token: string | null = null,
  loadAll = false,
): Promise<{
  newNextToken: string | null;
  newSyncTaskList: SyncTask[];
}> => {
  const syncTaskList: SyncTask[] = [];
  let newNextToken = token;

  do {
    const { data, nextToken } = await client.models.SyncTask.syncTasks(
      {
        taskType: "Sync",
      },
      newNextToken
        ? {
            limit: 50,
            nextToken: newNextToken,
            sortDirection: "DESC",
          }
        : {
            limit: 50,
            sortDirection: "DESC",
          },
    );

    syncTaskList.push(...data);

    newNextToken = nextToken ?? null;

    if (!loadAll) {
      return { newNextToken, newSyncTaskList: data };
    }
  } while (loadAll && newNextToken !== null);

  return {
    newNextToken,
    newSyncTaskList: syncTaskList,
  };
};

export const getSyncTaskListByExpansion = async (
  expansionID: string,
  token: string | null = null,
  loadAll = false,
): Promise<{
  newNextToken: string | null;
  newSyncTaskList: SyncTask[];
}> => {
  const syncTaskList: SyncTask[] = [];
  let newNextToken = token;

  do {
    const { data, nextToken } =
      await client.models.SyncTask.syncTasksByExpansion(
        {
          expansionID: expansionID,
        },
        newNextToken
          ? {
              limit: 50,
              nextToken: newNextToken,
              sortDirection: "DESC",
            }
          : {
              limit: 50,
              sortDirection: "DESC",
            },
      );

    syncTaskList.push(...data);

    newNextToken = nextToken ?? null;

    if (!loadAll) {
      return { newNextToken, newSyncTaskList: data };
    }
  } while (loadAll && newNextToken !== null);

  return {
    newNextToken,
    newSyncTaskList: syncTaskList,
  };
};

export const getSyncTaskListByStatus = async (
  status: TaskStatus,
): Promise<SyncTask[]> => {
  const { data } = await client.models.SyncTask.syncTasksByStatus(
    {
      status,
    },
    {
      limit: 50,
      sortDirection: "DESC",
    },
  );

  return data;
};

export const getRunningOrPendingSyncTaskList = async (): Promise<
  SyncTask[]
> => {
  const [runningSyncTaskList, pendingSyncTaskList] = await Promise.all([
    getSyncTaskListByStatus("Running"),
    getSyncTaskListByStatus("Pending"),
  ]);

  return [...runningSyncTaskList, ...pendingSyncTaskList];
};
