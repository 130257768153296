import React from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { Product } from "types";
import { CustomButton } from "core";
import { getExpansionName } from "modules/expansions/business";
import { ExcelIcon } from "modules/icons";
import utils from "utils";

type ProductListExcelExportProps = {
  productList: Product[];
};

export const ProductListExcelExport: React.FC<ProductListExcelExportProps> = ({
  productList,
}) => {
  const { expansionList } = useExpansionList(false);

  const defaultColumns = React.useMemo<ColumnDef<Product>[]>(
    () => [
      {
        header: "Rar",
        accessorKey: "rarity",
        accessorFn: (row) => row.rarity.charAt(0),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.rarity.charAt(0),
        },
        enableHiding: true,
      },
      {
        header: "DE-Name",
        accessorKey: "productDeName",
        accessorFn: (row) => row.productAlias ?? row.productDeName,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.productAlias ?? row.original.productDeName,
          isNowrap: true,
        },
        enableHiding: true,
      },
      {
        header: "EN-Name",
        accessorKey: "productEnName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => row.original.productEnName,
          isNowrap: true,
        },
        enableHiding: true,
      },
      {
        header: "Edition",
        accessorKey: "expansionID",
        accessorFn: (row) => {
          const expansion = expansionList.find(
            (exp) => exp.id === row.expansionID,
          );

          return expansion ? getExpansionName(expansion) : row.expansionID;
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => {
            const expansion = expansionList.find(
              (exp) => exp.id === row.original.expansionID,
            );

            return expansion
              ? getExpansionName(expansion)
              : row.original.expansionID;
          },
        },
        enableHiding: true,
      },
      {
        header: "Preis",
        accessorKey: "priceStore",
        accessorFn: (row) =>
          row.priceStore
            ? row.priceStore.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " €"
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.priceStore
              ? row.original.priceStore.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " €"
              : "",
          align: "right",
        },
      },
      {
        header: "DE",
        accessorKey: "deStock",
        accessorFn: (row) =>
          row.deStock ? row.deStock.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.deStock
              ? row.original.deStock.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
      {
        header: "O:DE",
        accessorKey: "deTransfer",
        accessorFn: (row) =>
          row.deTransfer ? row.deTransfer.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.deTransfer
              ? row.original.deTransfer.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
      {
        header: "EN",
        accessorKey: "enStock",
        accessorFn: (row) =>
          row.enStock ? row.enStock.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.enStock
              ? row.original.enStock.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
      {
        header: "O:EN",
        accessorKey: "enTransfer",
        accessorFn: (row) =>
          row.enTransfer ? row.enTransfer.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.enTransfer
              ? row.original.enTransfer.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
      {
        header: "DE",
        accessorKey: "stockCountDe",
        accessorFn: (row) =>
          row.stockCountDe ? row.stockCountDe.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.stockCountDe
              ? row.original.stockCountDe.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
      {
        header: "EN",
        accessorKey: "stockCountEn",
        accessorFn: (row) =>
          row.stockCountEn ? row.stockCountEn.toLocaleString("de-DE") : "0",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.stockCountEn
              ? row.original.stockCountEn.toLocaleString("de-DE")
              : "0",
          align: "right",
        },
      },
    ],
    [expansionList],
  );

  const table = useReactTable({
    data: productList,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <CustomButton
      text="Download Excel"
      size="small"
      iconBefore={<ExcelIcon />}
      onClick={() => utils.excel.tableListAsExcel(table, "Kartenliste.xlsx")}
      styleWrapper={{ marginLeft: 33 }}
    />
  );
};
