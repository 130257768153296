import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { ExcludedSeller } from "types";
import { CustomDialog } from "core";
import {
  ExcludedSellerForm,
  ExcludedSellerFormHandles,
} from "../ExcludedSellerForm";

type ExcludedSellerFormDialogProps = {
  formIntent: "create" | "edit";
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  excludedSellerList: ExcludedSeller[];
  excludedSeller?: ExcludedSeller;
  setExcludedSellers?: (value: React.SetStateAction<ExcludedSeller[]>) => void;
};

export const ExcludedSellerFormDialog: React.FC<
  ExcludedSellerFormDialogProps
> = ({
  formIntent,
  dialogOpen,
  setDialogOpen,
  excludedSellerList,
  excludedSeller,
  setExcludedSellers,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const excludedSellerFormRef = useRef<ExcludedSellerFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitExcludedSeller = async () => {
    const excludedSellerFormInput =
      excludedSellerFormRef.current?.validateExcludedSellerForm();
    if (!excludedSellerFormInput) {
      console.error("ExcludedSellerForm is invalid...");
      return;
    }

    if (!authContext.user || !setExcludedSellers) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines ausgeschlossenen Händlers ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log("excludedSellerFormInput: ", excludedSellerFormInput);

    if (formIntent === "create") {
      setExcludedSellers((prev) => [...prev, excludedSellerFormInput]);
    } else {
      setExcludedSellers((prev) =>
        prev.map((excludedSellerItem) =>
          excludedSellerItem.username !== excludedSeller?.username
            ? excludedSellerItem
            : excludedSellerFormInput,
        ),
      );
    }

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText={
        "Ausgeschlossenen Händler " +
        (formIntent === "edit" ? "speichern" : "anlegen")
      }
      dialogOpen={dialogOpen}
      positive={true}
      titleText={
        "Ausgeschlossenen Händler " +
        (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitExcludedSeller()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <ExcludedSellerForm
        excludedSellerList={excludedSellerList}
        excludedSeller={excludedSeller}
        ref={excludedSellerFormRef}
      />
    </CustomDialog>
  );
};
