import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  ExpansionShortened,
  SearchTextParamName,
  SortDirection,
  isKeyOfExpansionShortened,
} from "types";
import {
  FilterField,
  SortField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const searchFields: (keyof ExpansionShortened)[] = [
  "abbreviation",
  "expansionAlias",
  "expansionDeName",
  "expansionEnName",
  "id",
];

const filterByExpansionField = (
  expansionList: ExpansionShortened[],
  filterField: FilterField<ExpansionShortened>,
) => {
  const { field, value } = filterField;

  switch (field) {
    case "abbreviation":
      return filterByTextField(expansionList, "abbreviation", value);
    case "expansionEnName":
      return filterByTextField(expansionList, "expansionEnName", value);
    case "expansionAlias":
      return filterByTextField(expansionList, "expansionAlias", value);
    case "expansionDeName":
      return filterByTextField(expansionList, "expansionDeName", value);
    case "id":
      return filterByTextField(expansionList, "id", value);
    case "parentID":
      return filterByTextField(expansionList, "parentID", value);
    default:
      return expansionList;
  }
};

const sortByExpansionField = (
  a: ExpansionShortened,
  b: ExpansionShortened,
  sortField: SortField<ExpansionShortened>,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "abbreviation":
      return sortByTextField(a, b, "abbreviation", direction);
    case "expansionEnName":
      return sortByTextField(a, b, "expansionEnName", direction);
    case "expansionAlias":
      if (!a.expansionAlias && !b.expansionAlias) return 0;
      if (!a.expansionAlias) return 1;
      if (!b.expansionAlias) return -1;
      return sortByTextField(a, b, "expansionAlias", direction);
    case "expansionDeName":
      return sortByTextField(a, b, "expansionDeName", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "parentID":
      return sortByTextField(a, b, "parentID", direction);
    default:
      return 0;
  }
};

export const useExpansionListFilterAndSortShortened = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<ExpansionShortened>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof ExpansionShortened, string>> =
      {};

    const filters: FilterField<ExpansionShortened>[] = Array.from(
      searchParams.entries(),
    )
      .filter(([key]) => isKeyOfExpansionShortened(key))
      .map(([field, value]) => ({
        field: field as keyof ExpansionShortened,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<ExpansionShortened>[] =
      Object.entries(defaultFilters)
        .filter(
          ([key, value]) =>
            value !== undefined &&
            !filters.some((filterField) => filterField.field === key),
        )
        .map(([field, value]) => ({
          field: field as keyof ExpansionShortened,
          value,
          comparisonType: getComparisonType(searchParams, field),
        }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<
      Record<keyof ExpansionShortened, SortDirection>
    > = {
      expansionDeName: SortDirection.asc,
    };

    return getSortFields<ExpansionShortened>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfExpansionShortened,
    );
  }, [searchParams]);

  const filterAndSortExpansions = useCallback(
    (expansionList: ExpansionShortened[]) =>
      sortEntities<ExpansionShortened>(
        filter
          ? filterEntities<ExpansionShortened>(
              expansionList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchExpansionShortened),
              filterByExpansionField,
            )
          : expansionList,
        sortFields,
        sortByExpansionField,
        false,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortExpansions;
};
