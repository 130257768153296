import React from "react";
import { Grid } from "@mui/material";
import { client } from "queryClient";
import { UpdateExpansionInput } from "types";
import { CustomButton } from "core";
import { BoxContainer } from "layout/Container/BoxContainer";
import { getAllExpansionList } from "modules/expansions/api";
// import { getProductNamesList } from "modules/products/api";
import useStyles from "./styles";

const Development: React.FC = () => {
  const { classes } = useStyles();

  const updateExpansions = async () => {
    const expansionList = await getAllExpansionList();

    let counter = 0;

    for (const expansion of expansionList) {
      if (expansion.expansionWebsite) {
        continue;
      }

      console.log(
        "Processing expansion: ",
        counter++,
        " of ",
        expansionList.length,
      );

      const expansionWebsiteFromName = expansion.expansionEnName
        .replaceAll(":", "")
        .replaceAll(" ", "-");

      const expansionWebsite =
        "/de/Magic/Products/Singles/" + expansionWebsiteFromName;

      console.log("expansionWebsite: ", expansionWebsite);

      const expansionInput: UpdateExpansionInput = {
        id: expansion.id,
        expansionWebsite,
      };

      const { data: updatedExpansion } =
        await client.models.Expansion.update(expansionInput);

      console.log(updatedExpansion);
    }
  };

  return (
    <div className={classes.dashboardPage}>
      <BoxContainer>
        {process.env.NODE_ENV === "development" && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item>
              <CustomButton
                text="Editionen aktualisieren"
                onClick={() => updateExpansions()}
              />
            </Grid>
          </Grid>
        )}
      </BoxContainer>
    </div>
  );
};

export default Development;
