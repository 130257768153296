import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { useExpansionUpdate } from "hooks";
import { Expansion } from "types";
import { CustomDialog } from "core";
import { CalcIntervalForm, CalcIntervalFormHandles } from "../CalcIntervalForm";

type CalcIntervalFormDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  expansion: Expansion;
};

export const CalcIntervalFormDialog: React.FC<CalcIntervalFormDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  expansion,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const calcIntervalFormRef = useRef<CalcIntervalFormHandles>(null);

  const updateExpansionMutation = useExpansionUpdate();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitCalcInterval = async () => {
    const calcIntervalFormInput =
      calcIntervalFormRef.current?.validateCalcIntervalForm();
    if (!calcIntervalFormInput) {
      console.error("CalcIntervalForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim Bearbeiten der Edition ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log("calcIntervalFormInput: ", calcIntervalFormInput);

    await updateExpansionMutation.mutateAsync(calcIntervalFormInput);

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText="Intervall speichern"
      dialogOpen={dialogOpen}
      positive={true}
      titleText="Intervall bearbeiten"
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitCalcInterval()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <CalcIntervalForm expansion={expansion} ref={calcIntervalFormRef} />
    </CustomDialog>
  );
};
