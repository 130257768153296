import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchContext } from "context";
import { useListQueryParams } from "hooks";
import { QueryKeys } from "queryClient";
import { SearchTextParamName } from "types";
import { getAllExpansionList } from "modules/expansions/api";
import { getProductListByExpansion } from "modules/products/api";
import { useProductListFilterAndSort } from "../useProductListFilterAndSort";

export const useProductList = (filter = true, useAlias = true) => {
  const searchParams = useListQueryParams();

  const { searchProducts, isLoadingProducts: isLoadingSearch } =
    useSearchContext();

  const filterAndSortProducts = useProductListFilterAndSort(filter, useAlias);

  const expansionID = searchParams.get("expansionID");
  const searchText = searchParams.get(SearchTextParamName.SearchProduct);

  const { data, isLoading } = useQuery({
    queryKey:
      (!expansionID || expansionID === "search") && searchText
        ? [QueryKeys.Products, expansionID ?? "search", searchText]
        : [QueryKeys.Products, expansionID],
    queryFn: async () => {
      if ((!expansionID || expansionID === "search") && !searchText) {
        return [];
      }

      const expansionList = await getAllExpansionList();

      const expansionChildIDs = expansionList
        .filter((expansion) => expansion.parentID === expansionID)
        .map((expansion) => expansion.id);

      const expansionIDs = expansionID
        ? [...expansionChildIDs, expansionID]
        : [];

      console.log("expansionIDs: ", expansionIDs);

      if ((!expansionID || expansionID === "search") && searchText) {
        const productList = await searchProducts(searchText);
        return productList;
      } else if (expansionID && expansionID !== "search") {
        const productListPromises = expansionIDs.map((expansionIDItem) =>
          getProductListByExpansion(expansionIDItem),
        );

        const productListResults = await Promise.all(productListPromises);

        const productList = productListResults.reduce(
          (acc, productList) => [...acc, ...productList],
          [],
        );

        return productList;
      } else {
        return [];
      }
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const productList = useMemo(
    () => filterAndSortProducts(data ?? []),
    [filterAndSortProducts, data],
  );

  return {
    productList,
    isLoading,
    isLoadingSearch,
  };
};
