import React, { useRef } from "react";
import { useExpansionList } from "hooks";
import { Expansion } from "types";
import { CustomButton, CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ExcelIcon } from "modules/icons";
import { Routes } from "routes";
import { ExpansionListFilter } from "../ExpansionListFilter";
import { ExpansionListTable } from "../ExpansionListTable";

const ExpansionListPage: React.FC = () => {
  const { isLoading, expansionList } = useExpansionList();

  const expansionTableRef = useRef<CustomReactTableHandles<Expansion>>(null);

  return (
    <BoxHeadlineContainer
      boxTitle="Editionsliste"
      boxIcon={Routes.expansions.icon}
      boxSubTitle={expansionList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
      boxMenu={
        <CustomButton
          text="Download Excel"
          size="small"
          iconBefore={<ExcelIcon />}
          onClick={() => expansionTableRef.current?.exportToExcel()}
          styleWrapper={{ marginLeft: 33 }}
        />
      }
    >
      <ExpansionListFilter />
      <ExpansionListTable
        isLoading={isLoading}
        expansionList={expansionList}
        expansionTableRef={expansionTableRef}
      />
    </BoxHeadlineContainer>
  );
};

export default ExpansionListPage;
