import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { CustomDialog } from "core";
import {
  PriceListStepForm,
  PriceListStepFormHandles,
} from "../PriceListStepForm";

type PriceListStepFormDialogProps = {
  formIntent: "create" | "edit";
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  priceListStepList: number[];
  priceListStep?: number;
  setPriceListSteps?: (value: React.SetStateAction<number[]>) => void;
};

export const PriceListStepFormDialog: React.FC<
  PriceListStepFormDialogProps
> = ({
  formIntent,
  dialogOpen,
  setDialogOpen,
  priceListStepList,
  priceListStep,
  setPriceListSteps,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const priceListStepFormRef = useRef<PriceListStepFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitPriceListStep = async () => {
    const priceListStepFormInput =
      priceListStepFormRef.current?.validatePriceListStepForm();
    if (!priceListStepFormInput) {
      console.error("PriceListStepForm is invalid...");
      return;
    }

    if (!authContext.user || !setPriceListSteps) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Preisstufe ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log("priceListStepFormInput: ", priceListStepFormInput);

    if (formIntent === "create") {
      setPriceListSteps((prev) => [...prev, priceListStepFormInput]);
    } else {
      setPriceListSteps((prev) =>
        prev.map((priceListStepItem) =>
          priceListStepItem !== priceListStep
            ? priceListStepItem
            : priceListStepFormInput,
        ),
      );
    }

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText={
        "Preisstufe " + (formIntent === "edit" ? "speichern" : "anlegen")
      }
      dialogOpen={dialogOpen}
      positive={true}
      titleText={
        "Preisstufe " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitPriceListStep()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <PriceListStepForm
        priceListStepList={priceListStepList}
        priceListStep={priceListStep}
        ref={priceListStepFormRef}
      />
    </CustomDialog>
  );
};
