import React from "react";
import {
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { CustomSwitchiOS } from "core";
import { CustomButton } from "core";
import useStyles from "./styles";

type SearchFilterProps = {
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  includeExpansions: boolean;
  setIncludeExpansions: (value: React.SetStateAction<boolean>) => void;
  includeProducts: boolean;
  setIncludeProducts: (value: React.SetStateAction<boolean>) => void;
};

export const SearchFilter: React.FC<SearchFilterProps> = ({
  isOpen,
  setIsOpen,
  includeExpansions,
  setIncludeExpansions,
  includeProducts,
  setIncludeProducts,
}) => {
  const { classes } = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Popper
        className={classes.popper}
        open={isOpen}
        placement="bottom-start"
        transition
        disablePortal
      >
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.searchFilterTitle}>
            Sucheinstellungen
          </Typography>

          <CustomSwitchiOS
            name="includeExpansions"
            switchLabel="Editionen"
            checkedValue={includeExpansions}
            onChange={(_, value) => setIncludeExpansions(value)}
            classNameLabelRoot={classes.filterItem}
            classNameLabel={classes.filterLabel}
          />

          <CustomSwitchiOS
            name="includeProducts"
            switchLabel="Karten"
            checkedValue={includeProducts}
            onChange={(_, value) => setIncludeProducts(value)}
            classNameLabelRoot={classes.filterItem}
            classNameLabel={classes.filterLabel}
          />

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            className={classes.buttons}
          >
            <Grid item>
              <CustomButton
                text="Speichern"
                size="small"
                onClick={() => setIsOpen(false)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};
