import { generateClient } from "aws-amplify/data";
import { QueryClient } from "@tanstack/react-query";
import type { Schema } from "../amplify/data/resource";

export const queryClient = new QueryClient();

export const client = generateClient<Schema>();

export const publicClient = generateClient<Schema>({
  authMode: "identityPool",
});

export type { Schema } from "../amplify/data/resource";

export enum QueryKeys {
  Users = "users",
  CognitoUsers = "cognitoUsers",
  Expansions = "expansions",
  ExpansionsShortened = "expansionsShortened",
  Products = "products",
  ProductsShortened = "productsShortened",
  ProductNames = "productNames",
  ProductNamesShortened = "productNamesShortened",
  ExpansionProducts = "expansionProducts",
  GlobalSettings = "globalSettings",
  CalcTasks = "calcTasks",
  CardmarketCalcTasks = "cardmarketCalcTasks",
  StockTasks = "stockTasks",
  SyncTasks = "syncTasks",
  PriceCalcs = "priceCalcs",
}
