import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    padding: 0,
    "& svg": {
      height: 25,
      width: 25,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  inactive: {
    color: theme.palette.red.main,
  },
}));

export default useStyles;
