import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  icon: {
    width: "21px !important",
    height: "21px !important",
    marginTop: 0,
    marginBottom: -5,
  },
}));

export default useStyles;
