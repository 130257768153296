import { makeStyles } from "tss-react/mui";

type ReputationStyleProps = {
  reputation: number;
};

const useStyles = makeStyles<ReputationStyleProps>()((_, { reputation }) => ({
  reputation: {
    color:
      reputation === 1
        ? "gold"
        : reputation === 2
          ? "#ffd700"
          : reputation === 3
            ? "#c0c0c0"
            : reputation === 4
              ? "#dbb399"
              : "#e35d6a",

    display: "flex",
    flexDirection: "row",
    width: 22,
    height: 23,
    alignItems: "center",
    justifyContent: "center",

    ...(reputation === 1
      ? {
          backgroundColor: "#8090b4",
          borderRadius: "50%",
        }
      : {}),

    "& svg": {
      width: 20,
      height: 20,
    },
  },
}));

export default useStyles;
