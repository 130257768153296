import PriceCalcListPage from "modules/calctasks/calctasklist/CalcTaskListPage";
import ExpansionListPage from "modules/expansions/expansionlist/ExpansionListPage";
import ExpansionProductListPage from "modules/expansions/expansionproducts/ExpansionProductListPage";
import {
  CompanyIcon,
  PriceCalculationIcon,
  SpieleladenIcon,
} from "modules/icons";
import { USER_GROUPS } from "modules/roles/api";

export const expansions = {
  key: "expansions",
  path: "/expansions",
  section: "general",
  title: "Editionen",
  view: <ExpansionListPage />,
  icon: <CompanyIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    expansion: {
      key: "expansion",
      path: "/expansions/:expansionID",
      section: "expansion",
      title: "Edition",
      view: <ExpansionProductListPage />,
      icon: <SpieleladenIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        expansionpricecalcs: {
          key: "expansionpricecalcs",
          path: "/expansions/:expansionID/pricecalcs",
          section: "expansion",
          title: "Preisberechnungen",
          view: <PriceCalcListPage />,
          icon: <PriceCalculationIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
          level: 1,
          navigation: true,
          indented: false,
          isWithParam: true,
          subNavigations: {},
        },
      },
    },
  },
};
