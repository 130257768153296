import React, { useMemo, useRef } from "react";
import { StockTask } from "types";
import { CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { StockTaskListTable } from "../StockTaskListTable";
import StockTaskListUIProvider, {
  useStockTaskListUIContext,
} from "../StockTaskListUIContext";

const StockTaskListCard: React.FC = () => {
  const stockTaskListUIContext = useStockTaskListUIContext();
  const stockTaskListUIProps = useMemo(() => {
    return {
      stockTaskList: stockTaskListUIContext.stockTaskList,
      isLoadingStockTaskList: stockTaskListUIContext.isLoadingStockTaskList,
    };
  }, [stockTaskListUIContext]);

  const stockTaskTableRef = useRef<CustomReactTableHandles<StockTask>>(null);

  return (
    <BoxHeadlineContainer
      boxTitle="Bestände"
      boxIcon={Routes.synchronization.icon}
      boxSubTitle={stockTaskListUIProps.stockTaskList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
    >
      <StockTaskListTable stockTaskTableRef={stockTaskTableRef} />
    </BoxHeadlineContainer>
  );
};

const StockTaskListPage: React.FC = () => {
  return (
    <StockTaskListUIProvider>
      <StockTaskListCard />
    </StockTaskListUIProvider>
  );
};

export default StockTaskListPage;
