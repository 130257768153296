import moment from "moment";
import { SortDirection } from "types";

export interface SortField<T> {
  field: keyof T;
  direction: SortDirection;
}

export const sortByTextField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "string" || typeof bFieldValue !== "string") {
    return 0;
  }

  if (sortDirection === SortDirection.asc) {
    return aFieldValue.localeCompare(bFieldValue);
  }

  return bFieldValue.localeCompare(aFieldValue);
};

export const sortByDateField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "string" || typeof bFieldValue !== "string") {
    return 0;
  }

  if (sortDirection === SortDirection.asc) {
    return moment(bFieldValue).diff(moment(aFieldValue));
  }

  return moment(aFieldValue).diff(moment(bFieldValue));
};

export const sortByBooleanField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "boolean" || typeof bFieldValue !== "boolean") {
    return 0;
  }

  if (sortDirection === SortDirection.asc) {
    return aFieldValue === bFieldValue ? 0 : aFieldValue ? 1 : -1;
  }

  return aFieldValue === bFieldValue ? 0 : aFieldValue ? -1 : 1;
};

export const sortByNumberField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "number" || typeof bFieldValue !== "number") {
    return 0;
  }

  if (sortDirection === SortDirection.asc) {
    return aFieldValue - bFieldValue;
  }

  return bFieldValue - aFieldValue;
};

export const getSortFields = <T>(
  sortParam: string | null,
  defaultSorts: Partial<Record<keyof T, SortDirection>>,
  isKeyOfEntity: (key: string) => key is Extract<keyof T, string>,
): SortField<T>[] => {
  if (sortParam && sortParam.length > 0) {
    const sortFields = sortParam.split(",");
    const defaultSortFields = defaultSorts
      ? Object.entries(defaultSorts).filter(
          ([key]) => !sortFields.some((field) => field.startsWith(key)),
        )
      : [];
    sortFields.push(
      ...defaultSortFields.map(([key, value]) => `${key}:${value}`),
    );

    return sortFields
      .filter((sortField) => isKeyOfEntity(sortField.split(":")[0]))
      .map((sortField) => {
        const [field, direction] = sortField.split(":");
        return {
          field: field as keyof T,
          direction:
            direction === "desc" ? SortDirection.desc : SortDirection.asc,
        };
      });
  } else if (defaultSorts && Object.keys(defaultSorts).length > 0) {
    return Object.entries(defaultSorts)
      .filter(([key]) => isKeyOfEntity(key))
      .map(([key, value]) => `${key}:${value}`)
      .map((sortField) => {
        const [field, direction] = sortField.split(":");
        return {
          field: field as keyof T,
          direction:
            direction === "desc" ? SortDirection.desc : SortDirection.asc,
        };
      });
  }

  return [];
};

export const sortEntities = <T>(
  entityList: T[],
  sortFields: SortField<T>[],
  sortByFieldFunction: (
    a: T,
    b: T,
    sortField: SortField<T>,
    useAlias: boolean,
  ) => number,
  useAlias: boolean,
) => {
  if (sortFields.length > 0) {
    const sortedEntityList = entityList.sort((a, b) => {
      for (const sortField of sortFields) {
        const value = sortByFieldFunction(a, b, sortField, useAlias);

        if (value !== 0) return value;
      }
      return 0;
    });

    return sortedEntityList;
  }

  return entityList;
};
