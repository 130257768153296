import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { confirmResetPassword } from "aws-amplify/auth";
import { useAuthContext } from "context";
import { CustomButton, TextInputField } from "core";
import {
  KlickEmail,
  PasswordChangeSuccessIcon,
  ReadEmail,
  RetrieveEmail,
} from "modules/icons";
import { AuthRoutes } from "routes";
import { LoginErrorType } from "utils/errorHandling";
import utils from "utils";
import useStyles from "./styles";

export const ConfirmForgotPassword = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [userName, setUsername] = useState<string>(
    decodeURIComponent(searchParams.get("username") || ""),
  );
  const [code, setCode] = useState<string>(searchParams.get("code") || "");
  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [changePasswordSuccess, setChangePasswordSuccess] =
    useState<boolean>(false);

  const tryChangePassword = async () => {
    try {
      const regularExpressionPassword =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;

      console.log("userName: ", userName);
      console.log("code: ", code);
      console.log("password: ", password);
      console.log("confirmedPassword: ", confirmedPassword);

      if (userName === "" || userName.length > 200) {
        return enqueueSnackbar("Bitte gib deine E-Mail-Adresse ein!");
      }

      if (code === "" || code.length > 200) {
        return enqueueSnackbar(
          "Bitte gib den Code ein, den wir dir per E-Mail zugeschickt haben!",
        );
      }

      if (password === "" || password.length > 200) {
        return enqueueSnackbar("Bitte gib dein Passwort ein!");
      } else if (confirmedPassword === "" || confirmedPassword.length > 200) {
        return enqueueSnackbar("Bitte wiederhole dein Passwort!");
      }

      if (!regularExpressionPassword.test(password)) {
        return enqueueSnackbar(
          "Bitte gib ein gültiges Passwort aus mind. 8 Zeichen mit Groß- und Kleinbuchstaben, Zahlen und folgenden Sonderzeichen: ^ $ * . [ ] { } ( ) ?  ! @ # % & / , > <  : ; | _ ~  ein!",
        );
      } else if (password !== confirmedPassword) {
        return enqueueSnackbar("Bitte wiederhole dein Passwort!");
      }

      setLoading(true);
      await confirmResetPassword({
        username: userName,
        newPassword: password,
        confirmationCode: code,
      });
      setSuccess(true);
      setChangePasswordSuccess(true);
      utils.scroll.scrollToTop();
    } catch (err: unknown) {
      const error = err as LoginErrorType;
      if (error?.code === "UserNotFoundException") {
        enqueueSnackbar(
          "Die angegebene E-Mail-Adresse wurde leider nicht gefunden. Bitte überprüfe deine Eingabe oder kontaktiere den Support!",
        );
      } else if (error?.code === "CodeMismatchException") {
        enqueueSnackbar(
          "Der angegebene Code stimmt nicht mit dem dir gesendeten Code überein!",
        );
      } else {
        enqueueSnackbar(error?.message);
      }
      utils.errorHandling.logToSentry(
        "Error on tryChangePassword!",
        "Authentication -> confirmForgotPassword",
        err,
        authContext,
      );
      setSuccess(false);
      console.log("err: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        direction="column"
      >
        <Typography className={classes.title} variant="h3">
          Passwort ändern
        </Typography>
        {!changePasswordSuccess && (
          <>
            <Grid item container direction="row" justifyContent="center">
              <div className={classes.iconTextContainer}>
                <RetrieveEmail className={classes.icon} />
                <Typography className={classes.iconText}>
                  Postfach prüfen
                </Typography>
              </div>
              <div className={classes.iconTextContainer}>
                <ReadEmail className={classes.icon} />
                <Typography className={classes.iconText}>
                  E-Mail öffnen
                </Typography>
              </div>
              <div className={classes.iconTextContainer}>
                <KlickEmail className={classes.icon} />
                <Typography className={classes.iconText}>
                  Link in E-Mail klicken
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography
                className={classes.codeSend}
                variant="h3"
                style={{ fontWeight: 500 }}
              >
                Wir haben dir eine E-Mail mit einem Code geschickt.
                <br />
                Bitte klicke diesen, um dein Passwort zurückzusetzen.
              </Typography>
              <Typography className={classes.checkSpamTitle} variant="h3">
                E-Mail nicht erhalten?
              </Typography>
              <Typography className={classes.checkSpam} variant="body1">
                Es kann passieren, dass Dein E-Mail Provider dieses E-Mail als
                Spam angesehen hat.
                <br />
                Überprüfe dann bitte Deinen Spam- bzw. Junk-E-Mail-Ordner auf
                Erhalt unserer Nachricht.
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {!changePasswordSuccess && (
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          direction="column"
        >
          <div className={classes.sectionContainer}>
            <div className={classes.formContainer}>
              <TextInputField
                label="Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                type="text"
              />
              <TextInputField
                label="E-Mail-Adresse"
                value={userName}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
              />
              <TextInputField
                label="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              <TextInputField
                label="Passwort wiederholen"
                value={confirmedPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
              />
            </div>
          </div>
          <Grid
            justifyContent="center"
            alignItems="center"
            container
            direction="column"
            className={classes.buttonsContainer}
          >
            <CustomButton
              text="Passwort ändern"
              onClick={tryChangePassword}
              loading={loading}
              success={success}
              paddingHorizontal="25px"
              style="filled"
            />
            <div className={classes.resendCodeContainer}>
              <Link
                className={classes.resendCodeLink}
                to={AuthRoutes.forgotPassword}
              >
                Code erneut senden
              </Link>
            </div>
          </Grid>
        </Grid>
      )}

      {changePasswordSuccess && (
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          direction="column"
          className={classes.passwordChangeSuccessContainer}
        >
          <PasswordChangeSuccessIcon
            className={classes.passwordChangeSuccessIcon}
          />
          <Typography className={classes.passwordChangeSuccess}>
            Dein Passwort wurde erfolgreich geändert!
            <br />
            Wechsle nun zum Login-Fenster, um dich mit den neuen Zugangsdaten
            einzuloggen.
          </Typography>
          <CustomButton
            text="Jetzt Einloggen"
            onClick={() => navigate(AuthRoutes.login)}
            paddingHorizontal="25px"
            style="filled"
          />
        </Grid>
      )}
    </>
  );
};
