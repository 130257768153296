import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  ProductShortened,
  SearchTextParamName,
  SortDirection,
  isKeyOfProductShortened,
} from "types";
import {
  FilterField,
  SortField,
  filterByNumberField,
  filterByTextField,
  filterEntities,
  getComparisonType,
  getSortFields,
  sortByNumberField,
  sortByTextField,
  sortEntities,
} from "utils/tables";

const searchFields: (keyof ProductShortened)[] = [
  // "image",
  "expansionID",
  "productAlias",
  "productDeName",
  "productEnName",
];

const filterByProductField = (
  productList: ProductShortened[],
  filterField: FilterField<ProductShortened>,
) => {
  const { field, comparisonType, value } = filterField;

  switch (field) {
    case "productAlias":
      return filterByTextField(productList, "productAlias", value);
    // case "expansionID":
    //   if (value === "search") return productList;
    //   return filterByTextField(productList, "expansionID", value);
    case "id":
      return filterByTextField(productList, "id", value);
    case "priceStore":
      return filterByNumberField(
        productList,
        "priceStore",
        comparisonType,
        parseInt(value),
      );
    case "productDeName":
      return filterByTextField(productList, "productDeName", value);
    case "productEnName":
      return filterByTextField(productList, "productEnName", value);
    case "rarity":
      return filterByTextField(productList, "rarity", value);
    default:
      return productList;
  }
};

const sortByProductField = (
  a: ProductShortened,
  b: ProductShortened,
  sortField: SortField<ProductShortened>,
  useAlias: boolean,
) => {
  const { field, direction } = sortField;
  switch (field) {
    case "productAlias":
      if (!a.productAlias && !b.productAlias) return 0;
      if (!a.productAlias) return 1;
      if (!b.productAlias) return -1;
      return sortByTextField(a, b, "productAlias", direction);
    case "expansionID":
      return sortByTextField(a, b, "expansionID", direction);
    case "id":
      return sortByTextField(a, b, "id", direction);
    case "priceStore":
      return sortByNumberField(a, b, "priceStore", direction);
    case "productDeName": {
      if (!useAlias) return sortByTextField(a, b, "productDeName", direction);

      const aFieldValue: string = a.productAlias ?? a.productDeName;
      const bFieldValue: string = b.productAlias ?? b.productDeName;

      return direction === SortDirection.asc
        ? aFieldValue.localeCompare(bFieldValue)
        : bFieldValue.localeCompare(aFieldValue);
    }
    case "productEnName":
      return sortByTextField(a, b, "productEnName", direction);
    case "rarity":
      return sortByTextField(a, b, "rarity", direction);
    default:
      return 0;
  }
};

export const useProductListFilterAndSortShortened = (
  filter: boolean,
  useAlias: boolean,
) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<ProductShortened>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof ProductShortened, string>> = {};

    const filters: FilterField<ProductShortened>[] = Array.from(
      searchParams.entries(),
    )
      .filter(([key]) => isKeyOfProductShortened(key))
      .map(([field, value]) => ({
        field: field as keyof ProductShortened,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const defaultFilterFields: FilterField<ProductShortened>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof ProductShortened,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof ProductShortened, SortDirection>> =
      {
        productDeName: SortDirection.asc,
      };

    return getSortFields<ProductShortened>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfProductShortened,
    );
  }, [searchParams]);

  const filterAndSortProducts = useCallback(
    (productList: ProductShortened[]) =>
      sortEntities<ProductShortened>(
        filter
          ? filterEntities<ProductShortened>(
              productList,
              filterFields,
              searchFields,
              searchParams.get("expansionID") === "search"
                ? null
                : searchParams.get(SearchTextParamName.SearchProductShortened),
              filterByProductField,
            )
          : productList,
        sortFields,
        sortByProductField,
        useAlias,
      ),
    [filter, filterFields, searchParams, sortFields, useAlias],
  );

  return filterAndSortProducts;
};
