import React from "react";
import { HeaderLogo } from "../HeaderLogo";
import useStyles from "./styles";

type HeaderLeftProps = {
  layoutType: "layout" | "authLayout" | "giessPlan";
  children?: React.ReactNode;
};

export const HeaderLeft: React.FC<HeaderLeftProps> = ({
  children,
  layoutType,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.headerLeftSide}>
      {(layoutType === "authLayout" || layoutType === "giessPlan") && (
        <HeaderLogo layoutType={layoutType} />
      )}
      {children}
    </div>
  );
};
