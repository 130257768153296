import React from "react";
import { BooleanValue, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface BooleanOptionProps
  extends Partial<CustomSelectOptionProps<BooleanValue>> {
  boolean: BooleanValue;
  setBoolean: (value: React.SetStateAction<BooleanValue>) => void;
  booleanInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getBooleanName = (value: BooleanValue) => {
  switch (value) {
    case "All":
      return "Alle";
    case "true":
      return "Ja";
    case "false":
      return "Nein";
    default:
      return value;
  }
};

export const getBooleanEnumByName = (value: string): BooleanValue => {
  switch (value) {
    case "Alle":
      return "All";
    case "Ja":
      return "true";
    case "Nein":
      return "false";
    default:
      return "All";
  }
};

export const getBooleanOptions = (): SelectOption[] => {
  return [
    {
      value: "All",
      label: getBooleanName("All"),
    },
    {
      value: "true",
      label: getBooleanName("true"),
    },
    {
      value: "false",
      label: getBooleanName("false"),
    },
  ];
};

export const BooleanOption: React.FC<BooleanOptionProps> = ({
  boolean,
  setBoolean,
  booleanInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    options={getBooleanOptions()}
    onChange={(booleanOption) =>
      setBoolean(booleanOption.value as BooleanValue)
    }
    value={
      boolean
        ? {
            value: boolean,
            label: getBooleanName(boolean),
          }
        : null
    }
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={booleanInputRef}
  />
);
