import React, { useMemo } from "react";
import { useExpansionList } from "hooks";
import { Expansion, SelectOption, isSelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";
import { getExpansionName } from "modules/expansions/business";

interface ExpansionOptionProps extends CustomSelectOptionProps<Expansion> {
  expansion: Expansion | SelectOption;
  setExpansion: (value: React.SetStateAction<Expansion | SelectOption>) => void;
  expansionInputRef?: React.RefObject<CustomSelectHandles>;
}

export const ExpansionOption: React.FC<ExpansionOptionProps> = ({
  expansion,
  setExpansion,
  expansionInputRef,
  ...rest
}) => {
  const { expansionList } = useExpansionList(false);

  const expansionOptionList = useMemo(
    () => [{ value: "All", label: "Alle" }, ...expansionList],
    [expansionList],
  );

  return (
    <CustomSelect<Expansion | SelectOption>
      {...rest}
      options={expansionOptionList}
      onChange={(expansionOption) => setExpansion(expansionOption)}
      value={expansion}
      placeholder="Edition auswählen"
      getOptionLabel={(option) =>
        isSelectOption(option) ? option.label : getExpansionName(option)
      }
      getOptionValue={(option) =>
        isSelectOption(option) ? option.value : option.id
      }
      isOptionDisabled={undefined}
      isOptionSelected={undefined}
      ref={expansionInputRef}
    />
  );
};
