import React from "react";
import { useLocation } from "react-router-dom";
import { Drawer, Slide } from "@mui/material";
import { useAuthContext } from "context";
import { HeaderLogo } from "layout/Header/HeaderLogo";
import { getExpansionName } from "modules/expansions/business";
import {
  RouteArrayType,
  Routes,
  RoutesArray,
  Sections,
  checkExpansionRoute,
  checkProductRoute,
  getSubNavigationsAsArray,
} from "routes";
import { SidebarBackLink } from "./SidebarBackLink";
import { SidebarLockButton } from "./SidebarLockButton";
import { SidebarSubmenu } from "./SidebarSubmenu";
import { SidebarSubmenuHeader } from "./SidebarSubmenuHeader";
import { SidebarSubmenuLink } from "./SidebarSubmenuLink";
import { SidebarSubmenuList } from "./SidebarSubmenuList";
import useStyles from "./styles";

export const Sidebar: React.FC = () => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();
  const { pathname } = useLocation();

  // const currentRoute = getCurrentRoute(pathname);

  const isExpansionRoute = checkExpansionRoute(pathname);

  const isProductRoute = checkProductRoute(pathname);

  const getSectionMenu = (
    subNavigations: RouteArrayType[],
    sectionKey: string,
    index: number | string,
    paramName?: string,
    paramValue?: string,
    param2Name?: string,
    param2Value?: string,
    param3Name?: string,
    param3Value?: string,
  ) => (
    <SidebarSubmenu key={index} submenuTitle={Sections[sectionKey]}>
      {subNavigations
        .filter((route) => route.section === sectionKey && route.navigation)
        .map((route, i) => {
          if (
            route.subNavigations.length &&
            route.subNavigations.some(
              (subNavigation) => subNavigation.navigation,
            )
          ) {
            return (
              <SidebarSubmenuList key={i} route={route}>
                {route.subNavigations
                  .filter((subRoute) => subRoute.navigation)
                  .map((subRoute, j) => (
                    <SidebarSubmenuLink
                      key={i + " " + j}
                      inSubmenuList={true}
                      route={subRoute}
                      param1Name={paramName}
                      param1Value={paramValue}
                      param2Name={param2Name}
                      param2Value={param2Value}
                      param3Name={param3Name}
                      param3Value={param3Value}
                    />
                  ))}
              </SidebarSubmenuList>
            );
          }

          return (
            <SidebarSubmenuLink
              key={i}
              inSubmenuList={false}
              route={route}
              param1Name={paramName}
              param1Value={paramValue}
              param2Name={param2Name}
              param2Value={param2Value}
              param3Name={param3Name}
              param3Value={param3Value}
            />
          );
        })}
    </SidebarSubmenu>
  );

  const getExpansionMenu = () => {
    const expansionSubNavigations = getSubNavigationsAsArray(
      Routes.expansions.subNavigations.expansion.subNavigations,
    );
    const expansionSubNavigationSections = expansionSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = expansionSubNavigationSections.filter(
      (section, index) =>
        expansionSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        expansionSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          expansionSubNavigations,
          sectionKey,
          i,
          ":expansionID",
          authContext.expansion?.id,
        ),
      );
  };

  const getProductMenu = () => {
    const productSubNavigations = getSubNavigationsAsArray(
      Routes.products.subNavigations.product.subNavigations,
    );

    const productSubNavigationSections = productSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = productSubNavigationSections.filter(
      (section, index) =>
        productSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        productSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          productSubNavigations,
          sectionKey,
          i,
          ":productID",
          authContext.product?.id,
          ":expansionID",
          authContext.expansion?.id,
        ),
      );
  };

  const isNotSubMenuRoute = !isExpansionRoute && !isProductRoute;

  return (
    <Drawer
      className={cx(classes.drawer, {
        [classes.drawerOpen]: authContext.menuOpen,
        [classes.drawerClose]: !authContext.menuOpen,
      })}
      variant="permanent"
      classes={{
        paper: cx(classes.drawerPaper, {
          [classes.drawerOpen]: authContext.menuOpen,
          [classes.drawerClose]: !authContext.menuOpen,
        }),
      }}
      onMouseEnter={() =>
        !authContext.menuLocked &&
        !authContext.menuOpen &&
        authContext.setMenuOpen(true)
      }
      onMouseLeave={() =>
        !authContext.menuLocked && authContext.setMenuOpen(false)
      }
      anchor="left"
    >
      <HeaderLogo layoutType="layout" />

      <SidebarLockButton />
      <div className={classes.drawerMenuItems}>
        {isNotSubMenuRoute
          ? RoutesArray.filter(
              (route) =>
                route.section === "general" &&
                route.navigation &&
                (!authContext.cognitoUser ||
                  route.groups.includes(
                    authContext.cognitoUser.currentGroup.groupID,
                  )),
            ).map((route) => (
              <SidebarSubmenuLink
                inSubmenuList={false}
                key={route.key}
                route={route}
              />
            ))
          : null}

        {isExpansionRoute ? (
          <Slide
            direction="right"
            in={isExpansionRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.expansions.path}
                title="Zurück zur Editionsliste"
              />
              <SidebarSubmenuHeader
                icon={Routes.expansions.icon}
                link={Routes.expansions.subNavigations.expansion.path.replace(
                  ":expansionID",
                  authContext.expansion?.id ?? "",
                )}
                title={
                  authContext.expansion
                    ? getExpansionName(authContext.expansion)
                    : undefined
                }
                subTitle={authContext.expansion?.releaseDate}
              />
              {getExpansionMenu()}
            </div>
          </Slide>
        ) : null}

        {isProductRoute ? (
          <Slide
            direction="right"
            in={isProductRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.products.path}
                title="Zurück zur Kartenliste"
              />
              <SidebarSubmenuHeader
                icon={Routes.products.icon}
                link={Routes.products.subNavigations.product.path.replace(
                  ":productID",
                  authContext.product?.id ?? "",
                )}
                title={authContext.product?.productDeName}
                subTitle={authContext.product?.productEnName}
              />
              {getProductMenu()}
            </div>
          </Slide>
        ) : null}

        {/* ADMIN  */}
        {isNotSubMenuRoute ? (
          <Slide
            direction="right"
            in={!isExpansionRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              {Object.keys(Sections)
                .filter((sectionKey) =>
                  RoutesArray.some(
                    (route) =>
                      route.section === sectionKey &&
                      route.navigation &&
                      (!authContext.cognitoUser ||
                        route.groups.includes(
                          authContext.cognitoUser.currentGroup.groupID,
                        )),
                  ),
                )
                .map((sectionKey, i) =>
                  getSectionMenu(RoutesArray, sectionKey, i),
                )}
            </div>
          </Slide>
        ) : null}
      </div>
    </Drawer>
  );
};
