import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import {
  getAllCardmarketCalcTaskList,
  getCardmarketCalcTaskListByExpansion,
} from "modules/cardmarketcalctasks/api";
import { useCardmarketCalcTaskListFilterAndSort } from "../useCardmarketCalcTaskListFilterAndSort";

type CardmarketCalcTaskListParamsType = {
  expansionID: string;
};

export const useCardmarketCalcTaskList = () => {
  const { expansionID } = useParams<CardmarketCalcTaskListParamsType>();

  const filterAndSortCardmarketCalcTasks =
    useCardmarketCalcTaskListFilterAndSort();

  const { data, isLoading } = useQuery({
    queryKey: expansionID
      ? [QueryKeys.CardmarketCalcTasks]
      : [QueryKeys.CardmarketCalcTasks, expansionID],
    queryFn: async () => {
      console.log(
        "Calling useCardmarketCalcTaskList with expansionID",
        expansionID,
      );
      const cardmarketCalcTaskList = expansionID
        ? await getCardmarketCalcTaskListByExpansion(expansionID)
        : await getAllCardmarketCalcTaskList();

      return cardmarketCalcTaskList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const cardmarketCalcTaskList = useMemo(
    () => filterAndSortCardmarketCalcTasks(data ?? []),
    [data, filterAndSortCardmarketCalcTasks],
  );

  return {
    cardmarketCalcTaskList,
    expansionID,
    isLoading,
  };
};
