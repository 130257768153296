import React from "react";
import { useSearchParams } from "react-router-dom";
import { useExpansionListShortened } from "hooks";
import { ProductShortened, SearchTextParamName } from "types";
import {
  CustomReactTableHandles,
  CustomTable,
  Loading,
  SortableHeader,
} from "core";
import { getExpansionName } from "modules/expansions/business";
import useStyles from "./styles";

type ProductPriceListTableProps = {
  isLoading: boolean;
  productList: ProductShortened[];
  productTableRef?: React.RefObject<CustomReactTableHandles<ProductShortened>>;
};

export const ProductPriceListTable: React.FC<ProductPriceListTableProps> = ({
  isLoading,
  productList,
}) => {
  const { classes, cx } = useStyles();

  const [searchParams] = useSearchParams();

  const { expansionList } = useExpansionListShortened(false);

  const expansionID = searchParams.get("expansionID");

  return (
    <>
      <CustomTable
        marginBottom={0}
        uppercaseHeaders={false}
        fixedLayout={productList.length !== 0}
        stickyOffset={0}
      >
        <colgroup>
          <col width="15px" />
          <col width="100px" />
          <col width="100px" />
          <col width="100px" />
          <col width="30px" />
        </colgroup>

        <thead>
          <tr>
            <SortableHeader
              headerID="rarity"
              header="R"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="productDeName"
              header="Deutsch"
              isDefaultSort={true}
              defaultSortOrder="asc"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="productEnName"
              header="English"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="expansionID"
              header="Erweiterung"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="priceStore"
              header="Preis"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
              textAlign="center"
            />
          </tr>
        </thead>
        <tbody>
          {productList.length === 0 ? (
            <tr>
              <td colSpan={99}>
                <div className={classes.noResults}>
                  {isLoading ? (
                    <Loading
                      size="25px"
                      description={
                        "Bitte warten. " +
                        (!expansionID || expansionID === "search"
                          ? "Suche wird durchgeführt..."
                          : "Karten der Edition werden geladen...")
                      }
                      style={{ margin: 0 }}
                    />
                  ) : expansionID === "search" &&
                    !searchParams.get(SearchTextParamName.SearchProduct) ? (
                    "Bitte geben Sie einen Suchbegriff ein!"
                  ) : expansionID ? (
                    "Keine Produkte gefunden"
                  ) : (
                    "Bitte wählen Sie eine Edition aus oder geben Sie einen Suchbegriff ein!"
                  )}
                </div>
              </td>
            </tr>
          ) : (
            productList.map((product) => {
              const expansion = expansionList.find(
                (expansion) => expansion.id === product.expansionID,
              );

              if (!expansion) {
                return null;
              }

              return (
                <tr key={product.id} className={classes.productItem}>
                  <td width="15px">{product.rarity.charAt(0)}</td>
                  <td width="200px" className={classes.noWrap}>
                    <div className={classes.productName}>
                      {product.productAlias ?? product.productDeName}
                    </div>
                  </td>
                  <td width="200px" className={classes.noWrap}>
                    <div className={classes.productName}>
                      {product.productEnName}
                    </div>
                  </td>
                  <td width="200px" className={classes.noWrap}>
                    <div className={classes.productName}>
                      {getExpansionName(expansion)}
                    </div>
                  </td>
                  <td
                    width="30px"
                    align={product.priceStore ? "right" : "center"}
                    className={cx(
                      classes.noWrap,
                      product.priceStore ? classes.price : "",
                    )}
                  >
                    {product.priceStore
                      ? product.priceStore.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " €"
                      : "N/A"}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTable>
    </>
  );
};
