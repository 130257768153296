import React, { useContext } from "react";
import {
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signOut,
} from "aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { client } from "queryClient";
import { Expansion, GlobalSettings, Product, User } from "types";
import { getUserRoleName } from "modules/roles/api";
import { getUser } from "modules/users/api";
import { GLOBAL_SETTINGS_ID } from "utils/constants";
import utils from "utils";

export type CurrentData = {
  id: string;
  name: string;
};

type CognitoUserGroup = {
  groupID: string;
  groupName: string;
};

export type CurrentCognitoUserType = {
  username: string;
  salutation: string;
  customUsername: string;
  userSUB: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  currentGroup: CognitoUserGroup;
  groups: CognitoUserGroup[];
  userID: string;
};

export type AuthDataProps = {
  isLoading: boolean;
  isAuth: boolean;
  cognitoUser: CurrentCognitoUserType | null;
  user: {
    username: string;
    attributes: {
      [x: string]: any;
    };
  } | null;
  userData: User | null;
  activeView: string;
};

interface ContextProps extends AuthDataProps {
  isLoading: boolean;
  isAuth: boolean;
  loginHandler: () => Promise<any>;
  logoutHandler: () => Promise<void>;
  reloadUserData: () => void;
  checkUserExists: (username: string) => Promise<boolean>;
  cognitoUser: CurrentCognitoUserType | null;
  user: {
    username: string;
    attributes: {
      [x: string]: any;
    };
  } | null;
  userData: User | null;
  activeView: string;
  setActiveView: (view: string) => void;
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  menuLocked: boolean;
  setMenuLocked: (value: boolean) => void;
  product: Product | null;
  setProduct: (value: Product | null) => void;
  expansion: Expansion | null;
  setExpansion: (value: Expansion | null) => void;
  globalSettings: GlobalSettings | null;
  setGlobalSettings: (value: GlobalSettings | null) => void;
  isLoadingStateEntities: boolean;
  setIsLoadingStateEntities: (value: boolean) => void;
  isLoadingCurrentData: boolean;
  setIsLoadingCurrentData: (value: boolean) => void;
  currentData: CurrentData | null;
  setCurrentData: (value: CurrentData | null) => void;
}

export const AuthContext = React.createContext<ContextProps>({
  isLoading: true,
  isAuth: false,
  loginHandler: async () => {},
  logoutHandler: async () => {
    utils.logger.info("called empty logout");
  },
  reloadUserData: async () => {},
  checkUserExists: () => {
    return new Promise((resolve) => {
      resolve(false);
    });
  },
  cognitoUser: null,
  user: null,
  userData: null,
  activeView: "contactInfo",
  setActiveView: () => {},
  menuOpen: false,
  setMenuOpen: () => {},
  menuLocked: false,
  setMenuLocked: () => {},
  product: null,
  setProduct: () => {},
  expansion: null,
  setExpansion: () => {},
  globalSettings: null,
  setGlobalSettings: () => {},
  isLoadingStateEntities: false,
  setIsLoadingStateEntities: () => {},
  isLoadingCurrentData: false,
  setIsLoadingCurrentData: () => {},
  currentData: null,
  setCurrentData: () => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}

class AuthContextProvider extends React.Component<any, any> {
  state = {
    isLoading: true,
    cognitoUser: null,
    user: null,
    isAuth: false,
    userData: null, // userData contains data from database based on user group: user -> User-Table
    activeView: "contactInfo",
    menuOpen: false,
    menuLocked: false,
    product: null,
    expansion: null,
    globalSettings: null,
    isLoadingStateEntities: false,
    isLoadingCurrentData: false,
    currentData: null,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.initAuthProcess();
    // Hub.listen("auth", this, "onHubCapsule");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onHubCapsule = (capsule: any) => {
    switch (capsule.payload.event) {
      case "signIn":
        utils.logger.info("onHubCapsule signed in -> initAuthProcess..");
        this.initAuthProcess();
        break;
      case "signUp":
        utils.logger.info("signed up");
        break;
      case "signOut":
        utils.logger.info("signed out");
        this.setState({
          cognitoUser: null,
          user: null,
          isAuth: false,
          userData: null,
          locations: [],
        });
        break;
      default:
        return;
    }
  };

  getUserSignedInAndAttributes = async () => {
    try {
      const [authSession, currentUser, userAttributes] = await Promise.all([
        fetchAuthSession(),
        getCurrentUser(),
        fetchUserAttributes(),
      ]);

      if (authSession.tokens) {
        return {
          isSignedIn: true,
          currentUser: currentUser,
          userAttributes: userAttributes,
        };
      }

      return {
        isSignedIn: false,
        currentUser: null,
        userAttributes: null,
      };
    } catch (err) {
      return {
        isSignedIn: false,
        currentUser: null,
        userAttributes: null,
      };
    }
  };

  logoutHandler = async () => {
    await signOut();
    this.setState({
      cognitoUser: null,
      user: null,
      isAuth: false,
      userData: null, // userData contains data from database based on user group: user -> User-Table
    });
  };

  checkUserExists = (username: string) => {
    return new Promise<boolean>((resolve, reject) => {
      const code = "000000";
      confirmSignUp({
        username,
        confirmationCode: code,
        options: {
          // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
          forceAliasCreation: false,
        },
      })
        .then((data) => utils.logger.info(data))
        .catch((err) => {
          switch (err.code) {
            case "UserNotFoundException":
              resolve(false);
              break;
            case "NotAuthorizedException":
              resolve(true);
              break;
            case "AliasExistsException":
              // Email alias already exists
              resolve(true);
              break;
            case "CodeMismatchException":
              resolve(true);
              break;
            case "ExpiredCodeException":
              resolve(true);
              break;
            default:
              reject("No case found for confirmSignUp()");
          }
        });
    });
  };

  loadUserGlobalSettings = async (userID: string | undefined) => {
    try {
      if (userID) {
        const userData = await getUser(userID);
        this.setState({
          userData: userData,
        });
        Sentry.setUser(userData);
      }

      const { data } = await client.models.GlobalSettings.get({
        id: GLOBAL_SETTINGS_ID,
      });

      this.setState({
        globalSettings: data,
      });
    } catch (err) {
      console.log("Error in loadUserGlobalSettings: ", err);
      utils.errorHandling.logToSentry(
        "Error on loadUserGlobalSettings in AuthContext!",
        "AuthContext",
        err,
        this.state,
      );
    }
  };

  loginHandler = async () => {
    const { isSignedIn, currentUser, userAttributes } =
      await this.getUserSignedInAndAttributes();

    if (!isSignedIn || !currentUser || !userAttributes) {
      this.setState({
        cognitoUser: null,
        user: null,
        isAuth: false,
        userData: null, // userData contains data from database based on user group: user -> User-Table
      });
      return;
    }

    const userID = userAttributes["custom:userID"];

    await this.loadUserGlobalSettings(userID);

    const group = userAttributes["custom:group"];

    const userGroups: CognitoUserGroup[] = group
      ? group.split(",").map((groupItem) => {
          return {
            groupID: groupItem,
            groupName: getUserRoleName(groupItem),
          };
        })
      : [];

    const currentUserGroup = userAttributes["custom:currentGroup"];

    const currentGroup: CognitoUserGroup = {
      groupID: currentUserGroup ?? "Admin",
      groupName: getUserRoleName(currentUserGroup ?? "Admin"),
    };

    const currentCognitoUser: CurrentCognitoUserType = {
      username: currentUser.username,
      salutation: userAttributes.gender === "Mr" ? "Herr" : "Frau",
      customUsername: userAttributes["custom:username"] ?? "",
      userSUB: currentUser.userId,
      firstName: userAttributes.name ?? "",
      lastName: userAttributes.family_name ?? "",
      email: userAttributes.email ?? "",
      emailVerified: userAttributes.email_verified === "true" ? true : false,
      currentGroup: currentGroup,
      groups: userGroups,
      userID: userID ?? "",
    };

    this.setState({
      user: currentUser,
      cognitoUser: currentCognitoUser,
      isAuth: true,
    });
  };

  initAuthProcess = async () => {
    this.setState({
      isLoading: true,
    });
    await this.loginHandler();
    this.setState({
      isLoading: false,
    });
  };

  reloadUserData = async () => {
    await this.loginHandler();
  };

  setActiveView = (view: string) => {
    this.setState({
      activeView: view,
    });
  };

  setMenuOpen = (menuOpen: boolean) => {
    this.setState({
      menuOpen: menuOpen,
    });
  };

  setMenuLocked = (menuLocked: boolean) => {
    this.setState({
      menuLocked: menuLocked,
    });
  };

  setProduct = (product: Product | null) => {
    this.setState({
      product: product,
    });
  };

  setExpansion = (expansion: Expansion | null) => {
    this.setState({
      expansion: expansion,
    });
  };

  setGlobalSettings = (globalSettings: GlobalSettings | null) => {
    this.setState({
      globalSettings: globalSettings,
    });
  };

  setIsLoadingCurrentData = (isLoadingCurrentData: boolean) => {
    this.setState({
      isLoadingCurrentData: isLoadingCurrentData,
    });
  };

  setIsLoadingStateEntities = (isLoadingStateEntities: boolean) => {
    this.setState({
      isLoadingStateEntities: isLoadingStateEntities,
    });
  };

  setCurrentData = (currentData: CurrentData | null) => {
    this.setState({
      currentData: currentData,
    });
  };

  render() {
    const {
      isLoading,
      user,
      cognitoUser,
      isAuth,
      userData,
      activeView,
      menuOpen,
      menuLocked,
      product,
      expansion,
      globalSettings,
      isLoadingStateEntities,
      isLoadingCurrentData,
      currentData,
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
          isLoading: isLoading,
          loginHandler: this.loginHandler,
          logoutHandler: this.logoutHandler,
          reloadUserData: this.reloadUserData,
          checkUserExists: this.checkUserExists,
          isAuth: isAuth,
          cognitoUser: cognitoUser,
          user: user,
          userData: userData,
          activeView: activeView,
          setActiveView: this.setActiveView,
          menuOpen: menuOpen,
          setMenuOpen: this.setMenuOpen,
          menuLocked: menuLocked,
          setMenuLocked: this.setMenuLocked,
          product: product,
          setProduct: this.setProduct,
          expansion: expansion,
          setExpansion: this.setExpansion,
          globalSettings: globalSettings,
          setGlobalSettings: this.setGlobalSettings,
          isLoadingStateEntities,
          setIsLoadingStateEntities: this.setIsLoadingStateEntities,
          isLoadingCurrentData: isLoadingCurrentData,
          setIsLoadingCurrentData: this.setIsLoadingCurrentData,
          currentData: currentData,
          setCurrentData: this.setCurrentData,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
