import { useEffect, useState } from "react";
import {
  BooleanValue,
  Expansion,
  PriceModus,
  RarityWithAll,
  SelectOption,
} from "types";

export const usePriceModusForm = (
  formIntent: "create" | "edit",
  expansionList: Expansion[],
  priceModusList: PriceModus[],
  priceModus: PriceModus | null | undefined,
) => {
  const [priority, setPriority] = useState<number | "">("");
  const [expansion, setExpansion] = useState<Expansion | SelectOption>({
    label: "Alle",
    value: "All",
  });
  const [priceFactorStore, setPriceFactorStore] = useState<number | "">("");
  const [priceFactorOnline, setPriceFactorOnline] = useState<number | "">("");
  const [rarity, setRarity] = useState<RarityWithAll>("All");
  const [isFoil, setIsFoil] = useState<BooleanValue>("All");

  const resetPriceModusForm = () => {
    setPriority("");
    setExpansion({
      label: "Alle",
      value: "All",
    });
    setPriceFactorStore("");
    setPriceFactorOnline("");
    setRarity("All");
    setIsFoil("All");
  };

  useEffect(() => {
    if (formIntent === "edit") return;

    const highestPriority = priceModusList.reduce((acc, priceModusItem) => {
      return priceModusItem.priority > acc ? priceModusItem.priority : acc;
    }, 0);

    setPriority(highestPriority + 1);
  }, [formIntent, priceModusList]);

  useEffect(() => {
    if (!priceModus) {
      return;
    }

    setPriority(priceModus.priority);
    setPriceFactorStore(priceModus.priceFactorStore);
    setPriceFactorOnline(priceModus.priceFactorOnline);

    const expansionFromAPI = expansionList.find(
      (expansion) => expansion.id === priceModus.expansionID,
    );

    setExpansion(
      expansionFromAPI ?? {
        label: "Alle",
        value: "All",
      },
    );

    setRarity(priceModus.rarity ?? "All");
    setIsFoil(
      priceModus.isFoil === true
        ? "true"
        : priceModus.isFoil === false
          ? "false"
          : "All",
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceModus]);

  return {
    priority,
    setPriority,
    expansion,
    setExpansion,
    priceFactorStore,
    setPriceFactorStore,
    priceFactorOnline,
    setPriceFactorOnline,
    rarity,
    setRarity,
    isFoil,
    setIsFoil,
    resetPriceModusForm,
  };
};
