import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useProductUpdate } from "hooks";
import { Product, UpdateProductInput, ValueSubmitTrigger } from "types";
import { Loading } from "core";
import { TableNumberField } from "components";
import { getPriceCalc } from "modules/pricecalcs/api";

type ExpansionProductNumberColumnProps = {
  selectedRow: Product | null;
  product: Product;
  rowIndex: number;
  handleProductSelection: (
    rowIndex: number,
    trigger: ValueSubmitTrigger,
  ) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  decimalScale?: number;
  numberFormatOptions?: Intl.NumberFormatOptions;
};

export const ExpansionProductNumberColumn: React.FC<
  ExpansionProductNumberColumnProps
> = ({
  selectedRow,
  product,
  rowIndex,
  handleProductSelection,
  autoFocus,
  disabled,
  decimalScale,
  numberFormatOptions,
}) => {
  // const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentProduct, setCurrentProduct] = useState<Product>(product);
  const [value, setValue] = useState<number | "">("");

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    const defaultValue = product.priceStore ?? "";
    setValue(typeof defaultValue === "number" ? defaultValue : "");
  }, [product]);

  const updateMutation = useProductUpdate();

  if (selectedRow?.id !== product.id) {
    return currentProduct.priceStore?.toLocaleString(
      "de-DE",
      numberFormatOptions,
    );
  }

  const submitPrice = async (
    value: number | "",
    trigger: ValueSubmitTrigger,
  ) => {
    if (value === "" && trigger !== ValueSubmitTrigger.Escape) {
      enqueueSnackbar("Bitte gib einen Preis ein!");
      return;
    }

    setSubmitLoading(true);

    if (
      value !== "" &&
      value !== product.priceStore &&
      trigger !== ValueSubmitTrigger.Escape
    ) {
      setCurrentProduct((prev) => ({
        ...prev,
        priceStore: value,
      }));

      const priceCalc = product.storePriceCalcID
        ? await getPriceCalc(product.storePriceCalcID)
        : null;

      const updateProductInput: UpdateProductInput = {
        id: product.id,
        expansionID: product.expansionID,
        priceStore: value,
      };

      if (priceCalc && priceCalc.priceCalculationStore) {
        const samePrice = value === priceCalc.priceCalculationStore.price;

        if (!samePrice) {
          updateProductInput.priceStoreSetManually = true;
        }
      } else {
        updateProductInput.priceStoreSetManually = true;
      }

      updateMutation.mutate(updateProductInput);
    }

    handleProductSelection(rowIndex, trigger);
    setSubmitLoading(false);
  };

  return submitLoading ? (
    <Loading
      description={value.toLocaleString("de-DE", {
        minimumFractionDigits: decimalScale,
        maximumFractionDigits: decimalScale,
      })}
      size="20px"
      style={{ margin: 0 }}
    />
  ) : (
    <TableNumberField
      id={product.id}
      value={value}
      onValueChange={(value) => setValue(value)}
      submitValue={submitPrice}
      autoFocus={autoFocus}
      disabled={disabled}
      decimalScale={decimalScale}
      textAlign="right"
    />
  );
};
