import * as graphql from "./graphql";
import * as constants from "./constants";
import * as currency from "./currency";
import * as dates from "./dates";
import * as errorHandling from "./errorHandling";
import * as excel from "./excel";
import * as general from "./general";
import * as geo from "./geo";
import * as icons from "./icons";
import * as identify from "./identify";
import * as images from "./images";
import * as logger from "./logger";
import * as numbers from "./numbers";
import * as phone from "./phone";
import * as print from "./print";
import * as s3resources from "./s3resources";
import * as scroll from "./scroll";
import * as tables from "./tables";
import * as text from "./text";
import * as validation from "./validation";
import * as weight from "./weight";
import * as styles from "./styles";

const utils = {
  constants,
  currency,
  dates,
  errorHandling,
  excel,
  general,
  geo,
  graphql,
  icons,
  identify,
  images,
  logger,
  numbers,
  phone,
  print,
  s3resources,
  scroll,
  styles,
  tables,
  text,
  validation,
  weight,
};

export default utils;
