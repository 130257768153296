import React, { useMemo, useRef } from "react";
import { SyncTask } from "types";
import { CustomReactTableHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { SyncTaskListTable } from "../SyncTaskListTable";
import SyncTaskListUIProvider, {
  useSyncTaskListUIContext,
} from "../SyncTaskListUIContext";
import { UnsyncedExpansionListTable } from "../UnsyncedExpansionListTable";

const SyncTaskListCard: React.FC = () => {
  const syncTaskListUIContext = useSyncTaskListUIContext();
  const syncTaskListUIProps = useMemo(() => {
    return {
      syncTaskList: syncTaskListUIContext.syncTaskList,
      isLoadingSyncTaskList: syncTaskListUIContext.isLoadingSyncTaskList,
    };
  }, [syncTaskListUIContext]);

  const syncTaskTableRef = useRef<CustomReactTableHandles<SyncTask>>(null);

  return (
    <BoxHeadlineContainer
      boxTitle="Synchronisation"
      boxIcon={Routes.synchronization.icon}
      boxSubTitle={syncTaskListUIProps.syncTaskList.length.toString()}
      paddingHorizontal={0}
      paddingVertical={0}
      boxAlignment="center"
    >
      <SyncTaskListTable syncTaskTableRef={syncTaskTableRef} />
    </BoxHeadlineContainer>
  );
};

type SyncTaskListPageProps = {
  showUnsyncedExpansionList?: boolean;
};

const SyncTaskListPage: React.FC<SyncTaskListPageProps> = ({
  showUnsyncedExpansionList = true,
}) => {
  return (
    <SyncTaskListUIProvider>
      {showUnsyncedExpansionList && <UnsyncedExpansionListTable />}
      <SyncTaskListCard />
    </SyncTaskListUIProvider>
  );
};

export default SyncTaskListPage;
