import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { GlobalSettings, PriceCalc, PriceCalculationResult } from "types";
import { CustomTable, Spacing } from "core";
import { ArticleListTable } from "modules/articles/articlelist/ArticleListTable";
import { MinPriceView } from "../MinPriceView";
import { PriceModusView } from "../PriceModusView";
import useStyles from "./styles";

type PriceCalculationMethodProps = {
  priceCalcTarget:
    | "Store"
    | "CardmarketNoFoil"
    | "CardmarketFoil"
    | "StoreOld"
    | "CardmarketNoFoilOld"
    | "CardmarketFoilOld";
  priceCalc: PriceCalc;
  priceCalculationResult: PriceCalculationResult | null | undefined;
  globalSettings: GlobalSettings;
};

export const PriceCalculationMethod: React.FC<PriceCalculationMethodProps> = ({
  priceCalcTarget,
  priceCalc,
  priceCalculationResult,
  globalSettings,
}) => {
  const { classes } = useStyles();

  const filteredArticles = useMemo(
    () =>
      priceCalc.articles.filter((article) => {
        const isExcluded =
          priceCalcTarget === "CardmarketFoil" ||
          priceCalcTarget === "CardmarketFoilOld"
            ? article.isExcludedOnlineFoil
            : priceCalcTarget === "CardmarketNoFoil" ||
                priceCalcTarget === "CardmarketNoFoilOld"
              ? article.isExcludedOnlineNotFoil
              : article.isExcludedStore;
        return !isExcluded;
      }),
    [priceCalc, priceCalcTarget],
  );

  const lowestPrice = useMemo(
    () =>
      filteredArticles.reduce(
        (prev, curr) => (prev < curr.price ? prev : curr.price),
        Number.MAX_SAFE_INTEGER,
      ),
    [filteredArticles],
  );

  const highestPrice = useMemo(
    () =>
      filteredArticles.reduce(
        (prev, curr) => (prev > curr.price ? prev : curr.price),
        0,
      ),
    [filteredArticles],
  );

  if (!priceCalculationResult) {
    return (
      <Typography variant="h4">
        Für das ausgewählte Preisziel konnte keine Preisberechnung durchgeführt
        werden.
      </Typography>
    );
  }

  return (
    <>
      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        1. Berechneter Durchschnittspreis
      </Typography>

      <CustomTable tableClassName={classes.valueTable} sticky={false}>
        <tbody>
          <tr>
            <td>Anzahl Artikel</td>
            <td>{priceCalc.articles.length}</td>
          </tr>
          <tr>
            <td>Niedrigster Preis</td>
            <td>
              {lowestPrice?.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " €"}
            </td>
          </tr>
          <tr>
            <td>Höchster Preis</td>
            <td>
              {highestPrice?.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " €"}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Durchschnittspreis</strong>
            </td>
            <td>
              <strong>
                {priceCalculationResult.averagePrice.toLocaleString("de-DE") +
                  " €"}
              </strong>
            </td>
          </tr>
        </tbody>
      </CustomTable>

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        2. Preisaufschlag bei wenigen Artikeln
      </Typography>

      {priceCalc.usedCommercialArticles ? (
        <Typography variant="h4">
          Es wurden nur Händler für die Preisberechnung einbezogen, da die
          Mindestanzahl an gewerblichen Artikeln erreicht wurde.
        </Typography>
      ) : (
        <CustomTable tableClassName={classes.valueTable} sticky={false}>
          <tbody>
            <tr>
              <td>Mindestanzahl an gewerblichen Artikeln</td>
              <td>{globalSettings.minCommercialArticles}</td>
            </tr>
            <tr>
              <td>Prozentsatz für Preisaufschlag bei wenigen Artikeln</td>
              <td>
                {globalSettings.priceSurchargePrivate.toLocaleString("de-DE") +
                  " %"}
              </td>
            </tr>
            <tr>
              <td>Preisaufschlag</td>
              <td>
                {(
                  priceCalculationResult.averagePrice *
                  globalSettings.priceSurchargePrivate
                ).toLocaleString("de-DE") + " €"}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Preis nach Preisaufschlag</strong>
              </td>
              <td>
                <strong>
                  {(
                    priceCalculationResult.averagePrice *
                    globalSettings.priceSurchargePrivate
                  ).toLocaleString("de-DE") + " €"}
                </strong>
              </td>
            </tr>
          </tbody>
        </CustomTable>
      )}

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        3. Preismodus
      </Typography>

      <PriceModusView priceModus={priceCalculationResult.priceModus} />

      <Spacing height={21} />

      <CustomTable tableClassName={classes.valueTable} sticky={false}>
        <tbody>
          <tr>
            <td>
              <strong>Preis nach Preismodus</strong>
            </td>
            <td>
              <strong>
                {priceCalculationResult.priceModusValue.toLocaleString(
                  "de-DE",
                ) + " €"}
              </strong>
            </td>
          </tr>
        </tbody>
      </CustomTable>

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        4. Preisstufe / Aufrunden
      </Typography>

      {priceCalcTarget !== "Store" && (
        <>
          <Typography>
            Für Cardmarket-Preise wird der Preis auf zwei Nachkommastellen
            aufgerundet.
          </Typography>
          <Spacing height={15} />
        </>
      )}

      <CustomTable tableClassName={classes.valueTable} sticky={false}>
        <tbody>
          {priceCalcTarget === "Store" && (
            <tr>
              <td>Gefundene Preisstufe</td>
              <td>
                {priceCalculationResult.priceListStep
                  ? priceCalculationResult.priceListStep.toLocaleString(
                      "de-DE",
                    ) + " €"
                  : "Keine Preisstufe gefunden --> Aufrunden auf ganzen Euro"}
              </td>
            </tr>
          )}
          <tr>
            <td>
              <strong>Preisstufe / Aufgerundeter Preis</strong>
            </td>
            <td>
              <strong>
                {priceCalculationResult.price.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " €"}
              </strong>
            </td>
          </tr>
        </tbody>
      </CustomTable>

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        5. Mindestpreis
      </Typography>

      <MinPriceView minPrice={priceCalculationResult.minPrice} />

      <Spacing height={21} />

      <CustomTable tableClassName={classes.valueTable} sticky={false}>
        <tbody>
          <tr>
            <td>
              <strong>Preis nach Mindestpreis</strong>
            </td>
            <td>
              <strong>
                {priceCalculationResult.minPriceValue.toLocaleString("de-DE") +
                  " €"}
              </strong>
            </td>
          </tr>
        </tbody>
      </CustomTable>

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        6. Berechneter Preis
      </Typography>

      <CustomTable tableClassName={classes.valueTable} sticky={false}>
        <tbody>
          <tr>
            <td>
              <strong>Preis</strong>
            </td>
            <td>
              <strong>
                {priceCalculationResult.price.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " €"}
              </strong>
            </td>
          </tr>
        </tbody>
      </CustomTable>

      <Spacing height={33} />

      <Typography variant="h2" className={classes.dialogTitle}>
        7. Einbezogene Artikel für Preisberechnung
      </Typography>

      <ArticleListTable
        articleList={priceCalc.articles}
        priceCalcTarget={priceCalcTarget}
      />
    </>
  );
};
