import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    padding: 3,
    "& svg": {
      color: theme.palette.primary.main,
      height: 21,
      width: 21,
    },
  },
}));

export default useStyles;
