import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateStockTaskInput, StockTask } from "types";

export const useStockTaskCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateStockTaskInput) => {
      const { data: newStockTask } =
        await client.models.StockTask.create(input);
      return newStockTask;
    },
    // When mutate is called:
    onMutate: async (newStockTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.StockTasks] });

      // Snapshot the previous value
      const previousStockTasks = queryClient.getQueryData([
        QueryKeys.StockTasks,
      ]);

      // Optimistically update to the new value
      if (previousStockTasks) {
        queryClient.setQueryData([QueryKeys.StockTasks], (old: StockTask[]) => [
          ...old,
          newStockTask,
        ]);
      }

      // Return a context object with the snapshotted value
      return { previousStockTasks };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newStockTask, context) => {
      console.error("Error saving record:", err, newStockTask);
      if (context?.previousStockTasks) {
        queryClient.setQueryData(
          [QueryKeys.StockTasks],
          context.previousStockTasks,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.StockTasks] });
    },
  });

  return createMutation;
};
