import { FC, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button, ButtonGroup, FormControl, Typography } from "@mui/material";
import { LanguageFilterType } from "types";
import { CustomFormLabel } from "core";
import useStyles from "./styles";

export const LanguageFilter: FC = () => {
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const language = useMemo(() => {
    const languageParam = searchParams.get("language");

    if (!languageParam) {
      return LanguageFilterType.All;
    }

    return languageParam as LanguageFilterType;
  }, [searchParams]);

  const languageChangeHandler = (languageInput: LanguageFilterType) => {
    if (languageInput === LanguageFilterType.All) {
      searchParams.delete("language");
    } else {
      searchParams.set("language", languageInput);
    }
    sessionStorage.setItem(pathname, searchParams.toString());
    setSearchParams(searchParams, {
      replace: true,
    });
  };

  return (
    <FormControl>
      <CustomFormLabel label="Sprache" showRequiredSymbol={false} />

      <ButtonGroup>
        <Button
          classes={{
            root: cx(
              classes.toggleButton,
              language === LanguageFilterType.All
                ? classes.toggleButtonSelected
                : "",
            ),
          }}
          onClick={() => languageChangeHandler(LanguageFilterType.All)}
        >
          <Typography
            className={cx(
              classes.toggleTitle,
              language === LanguageFilterType.All
                ? classes.toggleTitleSelected
                : "",
            )}
          >
            {LanguageFilterType.All}
          </Typography>
        </Button>
        <Button
          classes={{
            root: cx(
              classes.toggleButton,
              language === LanguageFilterType.DE
                ? classes.toggleButtonSelected
                : "",
            ),
          }}
          onClick={() => languageChangeHandler(LanguageFilterType.DE)}
        >
          <Typography
            className={cx(
              classes.toggleTitle,
              language === LanguageFilterType.DE
                ? classes.toggleTitleSelected
                : "",
            )}
          >
            {LanguageFilterType.DE}
          </Typography>
        </Button>
        <Button
          classes={{
            root: cx(
              classes.toggleButton,
              language === LanguageFilterType.EN
                ? classes.toggleButtonSelected
                : "",
            ),
          }}
          onClick={() => languageChangeHandler(LanguageFilterType.EN)}
        >
          <Typography
            className={cx(
              classes.toggleTitle,
              language === LanguageFilterType.EN
                ? classes.toggleTitleSelected
                : "",
            )}
          >
            {LanguageFilterType.EN}
          </Typography>
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};
