import { client, publicClient } from "queryClient";
import { Expansion, ExpansionSelectionSet, ExpansionShortened } from "types";

export const getAllExpansionList = async (
  nextTokenParam: string | null = null,
  prevExpansionList: Expansion[] = [],
): Promise<Expansion[]> => {
  const params: any = {
    limit: 1000,
  };

  if (nextTokenParam) {
    params.nextToken = nextTokenParam;
  }

  const { data, nextToken } = await client.models.Expansion.list(params);

  const expansionList = [...prevExpansionList, ...data];

  return nextToken
    ? getAllExpansionList(nextToken, expansionList)
    : expansionList;
};

export const getAllExpansionListShortened = async (
  nextTokenParam: string | null = null,
  prevExpansionList: ExpansionShortened[] = [],
): Promise<ExpansionShortened[]> => {
  const params: any = {
    limit: 1000,
    selectionSet: ExpansionSelectionSet,
  };

  if (nextTokenParam) {
    params.nextToken = nextTokenParam;
  }

  console.log("params: ", params);

  const { data, nextToken } = await publicClient.models.Expansion.list(params);

  const expansionList = [...prevExpansionList, ...data];

  console.log("expansionList: ", expansionList);

  return nextToken
    ? getAllExpansionListShortened(nextToken, expansionList)
    : expansionList;
};

export const getExpansion = async (id: string): Promise<Expansion | null> => {
  const { data } = await client.models.Expansion.get({
    id,
  });

  return data;
};
