import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  noResults: {
    textAlign: "center",
    cursor: "default",
    lineHeight: "100px",
  },
  productItem: {
    // "& td": {
    //   border: "1px solid #E0E0E0",
    // },
  },
  numberColumn: {
    textAlign: "left",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      // border: "1px solid " + theme.palette.primary.main + " !important",
      backgroundColor: "#ddf2eb !important",
    },
    paddingLeft: "7px !important",
  },
  numberColumnSelected: {
    padding: "0px !important",
    boxShadow: "0 0 5px rgba(183, 216, 224, 0.4) !important",
    border: "1px solid " + theme.palette.primary.main + " !important",
    backgroundColor: "#b3ead7 !important",
  },
  evenRow: {
    backgroundColor: "#f7f9f9",
  },
  oddRow: {
    backgroundColor: "#eff4f4",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  productName: {
    width: "370px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "5px",
  },
}));

export default useStyles;
