import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  language: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& svg": {
      width: 23,
      height: 23,
    },
  },
}));

export default useStyles;
