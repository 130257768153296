import React from "react";
import { CustomTooltip } from "core";
import useStyles from "./styles";

type SellCountColumnFormatterProps = {
  sellCount: number;
};

export const SellCountColumnFormatter: React.FC<
  SellCountColumnFormatterProps
> = ({ sellCount }) => {
  const { classes } = useStyles();

  return (
    <CustomTooltip
      title={sellCount.toLocaleString("de-DE") + " Verkäufe"}
      followCursor={true}
    >
      <span className={classes.badge}>
        {sellCount < 1000 ? (
          sellCount
        ) : (
          <>
            {Math.floor(sellCount / 1000)}
            <span className={classes.thousand}>K</span>
          </>
        )}
      </span>
    </CustomTooltip>
  );
};
