import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { CardmarketCredential } from "types";
import { CustomDialog } from "core";
import {
  CardmarketCredentialForm,
  CardmarketCredentialFormHandles,
} from "../CardmarketCredentialForm";

type CardmarketCredentialFormDialogProps = {
  formIntent: "create" | "edit";
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  cardmarketCredentialList: CardmarketCredential[];
  cardmarketCredential?: CardmarketCredential;
  setCardmarketCredentials?: (
    value: React.SetStateAction<CardmarketCredential[]>,
  ) => void;
};

export const CardmarketCredentialFormDialog: React.FC<
  CardmarketCredentialFormDialogProps
> = ({
  formIntent,
  dialogOpen,
  setDialogOpen,
  cardmarketCredentialList,
  cardmarketCredential,
  setCardmarketCredentials,
}) => {
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const cardmarketCredentialFormRef =
    useRef<CardmarketCredentialFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitCardmarketCredential = async () => {
    const cardmarketCredentialFormInput =
      cardmarketCredentialFormRef.current?.validateCardmarketCredentialForm();
    if (!cardmarketCredentialFormInput) {
      console.error("CardmarketCredentialForm is invalid...");
      return;
    }

    if (!authContext.user || !setCardmarketCredentials) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Zugangs ist ein Fehler aufgetreten.`,
      );
    }
    setSubmitLoading(true);

    console.log(
      "cardmarketCredentialFormInput: ",
      cardmarketCredentialFormInput,
    );

    if (formIntent === "create") {
      setCardmarketCredentials((prev) => [
        ...prev,
        cardmarketCredentialFormInput,
      ]);
    } else {
      setCardmarketCredentials((prev) =>
        prev.map((cardmarketCredentialItem) =>
          cardmarketCredentialItem.id !== cardmarketCredential?.id
            ? cardmarketCredentialItem
            : cardmarketCredentialFormInput,
        ),
      );
    }

    setSubmitLoading(false);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText={
        "Zugang " + (formIntent === "edit" ? "speichern" : "anlegen")
      }
      dialogOpen={dialogOpen}
      positive={true}
      titleText={"Zugang " + (formIntent === "edit" ? "bearbeiten" : "anlegen")}
      setDialogOpen={setDialogOpen}
      confirmAction={() => submitCardmarketCredential()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={submitLoading}
      minWidth={700}
    >
      <CardmarketCredentialForm
        cardmarketCredentialList={cardmarketCredentialList}
        cardmarketCredential={cardmarketCredential}
        ref={cardmarketCredentialFormRef}
      />
    </CustomDialog>
  );
};
