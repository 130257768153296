import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  arrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowDown: {
    display: "block",
    width: 29,
    height: 29,
    color: theme.palette.red.main,
  },
  arrowUp: {
    display: "block",
    width: 29,
    height: 29,
    color: "#23AC5C",
  },
}));

export default useStyles;
