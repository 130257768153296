import { useEffect, useState } from "react";
import { ExcludedSeller } from "types";

export const useExcludedSellerForm = (
  excludedSeller: ExcludedSeller | null | undefined,
) => {
  const [username, setUsername] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  const resetExcludedSellerForm = () => {
    setUsername("");
    setComment("");
  };

  useEffect(() => {
    if (excludedSeller === null || excludedSeller === undefined) {
      return;
    }

    setUsername(excludedSeller.username);
    setComment(excludedSeller.comment ?? "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedSeller]);

  return {
    username,
    setUsername,
    comment,
    setComment,
    resetExcludedSellerForm,
  };
};
