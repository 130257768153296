import React from "react";
import { client } from "queryClient";
import { IntervalType, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface IntervalTypeOptionProps
  extends Partial<CustomSelectOptionProps<IntervalType>> {
  intervalType: IntervalType | null;
  setIntervalType: (value: React.SetStateAction<IntervalType | null>) => void;
  intervalTypeInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getIntervalDescription = (
  intervalType: IntervalType,
  intervalValue: number,
) => {
  if (intervalValue === 1) {
    return getIntervalTypeNameAdjektiv(intervalType);
  }

  switch (intervalType) {
    case "Daily":
      return `alle ${intervalValue} Tage`;
    case "Weekly":
      return `alle ${intervalValue} Wochen`;
    case "Monthly":
      return `alle ${intervalValue} Monate`;
    case "Yearly":
      return `alle ${intervalValue} Jahre`;
    default:
      return `${intervalValue} Tage`;
  }
};

export const getIntervalTypeNameAdjektiv = (value: IntervalType) => {
  switch (value) {
    case "Daily":
      return "täglich";
    case "Weekly":
      return "wöchentlich";
    case "Monthly":
      return "monatlich";
    case "Yearly":
      return "jährlich";
    default:
      return value;
  }
};

export const getIntervalTypeName = (value: IntervalType) => {
  switch (value) {
    case "Daily":
      return "Tage";
    case "Weekly":
      return "Wochen";
    case "Monthly":
      return "Monate";
    case "Yearly":
      return "Jahre";
    default:
      return value;
  }
};

export const getIntervalTypeEnumByName = (value: string): IntervalType => {
  switch (value) {
    case "Tage":
      return "Daily";
    case "Wochen":
      return "Weekly";
    case "Monate":
      return "Monthly";
    case "Jahre":
      return "Yearly";
    default:
      return "Daily";
  }
};

export const getIntervalTypeOptions = (): SelectOption[] => {
  const values = client.enums.IntervalType?.values() ?? [];

  return values.map((intervalType) => ({
    value: intervalType,
    label: getIntervalTypeName(intervalType),
  }));
};

export const IntervalTypeOption: React.FC<IntervalTypeOptionProps> = ({
  intervalType,
  setIntervalType,
  intervalTypeInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Intervall"
    options={getIntervalTypeOptions()}
    onChange={(intervalTypeOption) =>
      setIntervalType(intervalTypeOption.value as IntervalType)
    }
    value={
      intervalType
        ? {
            value: intervalType,
            label: getIntervalTypeName(intervalType),
          }
        : null
    }
    placeholder="Intervall auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={intervalTypeInputRef}
  />
);
