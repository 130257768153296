import React from "react";
import { client } from "queryClient";
import { SelectOption, TaskStatus, TaskStatusWithAll } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface TaskStatusOptionProps
  extends Partial<CustomSelectOptionProps<TaskStatus>> {
  taskStatus: TaskStatus | null;
  setTaskStatus: (value: React.SetStateAction<TaskStatus | null>) => void;
  taskStatusInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getTaskStatusWithAllName = (value: TaskStatusWithAll) => {
  switch (value) {
    case "All":
      return "Alle";
    case "Completed":
      return "Abgeschlossen";
    case "Running":
      return "Läuft";
    case "Pending":
      return "Ausstehend";
    case "Failed":
      return "Fehlgeschlagen";
    default:
      return value;
  }
};

export const getTaskStatusName = (value: TaskStatus) => {
  switch (value) {
    case "Completed":
      return "Abgeschlossen";
    case "Running":
      return "Läuft";
    case "Pending":
      return "Ausstehend";
    case "Failed":
      return "Fehlgeschlagen";
  }
};

export const getTaskStatusEnumByName = (value: string): TaskStatus => {
  switch (value) {
    case "Abgeschlossen":
      return "Completed";
    case "Läuft":
      return "Running";
    case "Ausstehend":
      return "Pending";
    case "Fehlgeschlagen":
      return "Failed";
    default:
      return "Running";
  }
};

export const getTaskStatusOptions = (): SelectOption[] => {
  const values = client.enums.TaskStatus?.values() ?? [];

  return values.map((syncTaskStatus) => ({
    value: syncTaskStatus,
    label: getTaskStatusName(syncTaskStatus),
  }));
};

export const TaskStatusOption: React.FC<TaskStatusOptionProps> = ({
  taskStatus,
  setTaskStatus,
  taskStatusInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Status"
    options={getTaskStatusOptions()}
    onChange={(taskStatusOption) =>
      setTaskStatus(taskStatusOption.value as TaskStatus)
    }
    value={
      taskStatus
        ? {
            value: taskStatus,
            label: getTaskStatusName(taskStatus),
          }
        : null
    }
    placeholder="Status auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={taskStatusInputRef}
  />
);
