import { makeStyles } from "tss-react/mui";

type ReputationStyleProps = {
  condition: string;
};

const useStyles = makeStyles<ReputationStyleProps>()((_, { condition }) => ({
  condition: {
    color: condition === "GD" ? "#212529" : "#ffffff",
    backgroundColor:
      condition === "MT"
        ? "#17a2b8"
        : condition === "NM"
          ? "#3bae55"
          : condition === "EX"
            ? "#82891e"
            : condition === "GD"
              ? "#ffc107"
              : condition === "LP"
                ? "#fb8929"
                : condition === "PL"
                  ? "#df6370"
                  : condition === "PO"
                    ? "#dc3545"
                    : "#82891e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 39,
    maxHeight: 21,
    margin: "0 auto",
    borderRadius: 7,
    fontWeight: 600,
  },
}));

export default useStyles;
