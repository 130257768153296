import { useEffect, useState } from "react";
import { Expansion, IntervalType, IntervalTypeWithEvery } from "types";
import utils from "utils";

export const useCalcIntervalForm = (expansion: Expansion) => {
  const [intervalTypeEvery, setIntervalTypeEvery] =
    useState<IntervalTypeWithEvery | null>(null);
  const [intervalType, setIntervalType] = useState<IntervalType | null>(null);
  const [intervalValue, setIntervalValue] = useState<number | "">(1);

  const [nextOnlinePriceCalc, setNextOnlinePriceCalc] = useState<Date | null>(
    null,
  );

  const resetCalcIntervalForm = () => {
    setIntervalType(null);
    setIntervalTypeEvery(null);
    setIntervalValue("");
  };

  useEffect(() => {
    setIntervalTypeEvery(
      expansion.intervalValue === 1 ? expansion.intervalType : "Every",
    );

    setIntervalType(expansion.intervalType);
    setIntervalValue(expansion.intervalValue);
    setNextOnlinePriceCalc(
      utils.dates.getDateFromAWSDateFormat(expansion.nextOnlinePriceCalc),
    );
  }, [expansion]);

  return {
    intervalTypeEvery,
    setIntervalTypeEvery,
    intervalType,
    setIntervalType,
    intervalValue,
    setIntervalValue,
    nextOnlinePriceCalc,
    setNextOnlinePriceCalc,
    resetCalcIntervalForm,
  };
};
