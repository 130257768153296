import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  input: {
    '&[value=""]': {
      color: theme.palette.text.secondary,
    },
    "&:hover": {
      borderColor: theme.palette.primary.main + " !important",
    },
    color: theme.palette.primary.main,
    textAlign: "left",
    fontSize: 14,
    width: "100%",
    height: "100%",
    maxWidth: 200,
    padding: `5px 7px !important`,
    backgroundColor: "#fafafc !important",
    boxShadow: "0 0 5px rgba(255, 255, 255, 0.4) !important",
    borderStyle: "solid !important",
    borderWidth: "1px !important",
    borderColor: "#a9bebb !important",
    position: "relative",
    overflow: "hidden",
    border: "none",
    outline: "none",
    "::selection": {
      backgroundColor: theme.palette.primary.C100,
    },
    "::-moz-selection": {
      backgroundColor: theme.palette.primary.C100,
    },
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
  focusedInput: {
    "& input": {
      boxShadow: "0 0 5px rgba(183, 216, 224, 0.4) !important",
      borderColor: "#a9bebb !important",
    },
  },
  formControlRoot: {
    width: "100%",
  },
  submitButton: {
    color: "white",
    backgroundColor: theme.palette.primary.C700,
    padding: 5,
    "&:hover": {
      backgroundColor: theme.palette.primary.C600,
    },
    "& svg": {
      height: 23,
      width: 23,
    },
  },
  cancelButton: {
    color: theme.palette.red.main,
    padding: 5,
    "& svg": {
      height: 23,
      width: 23,
    },
  },
  editButtons: {
    margin: 0,
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  clearButton: {
    marginLeft: -30,
    padding: 3,
    "& svg": {
      color: theme.palette.text.secondary,
      height: 17,
      width: 17,
    },
  },
}));

export default useStyles;
