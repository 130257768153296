import { Table } from "@tanstack/react-table";
import * as ExcelJS from "exceljs";
import { Product } from "types";
import utils from "utils";

export const exportOrderListAsExcel = async (productList: Product[]) => {
  const workbook = new ExcelJS.Workbook();

  workbook.creator = "Der andere Spieleladen";
  workbook.calcProperties.fullCalcOnLoad = true;

  const today = new Date();

  const filename =
    "Produktionsplanung_Stand_" +
    today.getFullYear() +
    "_" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "_" +
    ("0" + today.getDate()).slice(-2);

  const headers = [
    ["KW", "Liefert."],
    ["Artikelname"],
    ["Artikelnr."],
    ["Kunde"],
    ["Auftrag"],
    ["Arbeitsgänge"],
    ["AB", "Best.-Nr."],
    ["Termin"],
    ["Menge"],
    ["Status"],
    ["Bemerkungen"],
  ];

  const worksheet = workbook.addWorksheet(filename, {
    properties: { defaultRowHeight: 33 },
    pageSetup: {
      paperSize: 9,
      printArea: "A1:O" + (productList.length + 1),
      printTitlesRow: "1:1",
      orientation: "landscape",
      margins: {
        left: 0.2,
        right: 0.2,
        top: 0.5,
        bottom: 0.4,
        header: 0.2,
        footer: 0.1,
      },
    },
    headerFooter: {
      oddHeader:
        "Neue Aufträge " + utils.dates.getDateInGermanExtendedFormat(today),
      oddFooter: filename + "    -     Seite &P von &N",
    },
    views: [{ state: "normal", style: "pageLayout" }],
  });

  /** FORMAT HEADER */

  const headerRow = worksheet.getRow(1);
  headerRow.height = 33;

  for (let j = 0; j < headers.length; j++) {
    const headerNames = headers[j];

    const cell = headerRow.getCell(j + 1);
    console.log("Writing to header cell: " + j + 1);
    cell.value = {
      richText: headerNames.map((headerName, index) => ({
        font: {
          size: 12 - index,
          bold: true,
        },
        text: headerName + (index + 1 === headerNames.length ? "" : "\r\n"),
      })),
    };

    cell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: headerNames.length > 1,
    };

    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
  }

  worksheet.getColumn(1).width = 10; // KW + Liefert.
  worksheet.getColumn(2).width = 18; // Artikelname
  worksheet.getColumn(3).width = 9; // Artikelnr.
  worksheet.getColumn(4).width = 7; // Kunde
  worksheet.getColumn(5).width = 17; // Auftrag
  worksheet.getColumn(6).width = 21; // Arbeitsgänge
  worksheet.getColumn(7).width = 12; // AB + Best.-Nr.
  worksheet.getColumn(8).width = 10; // Liefertermin
  worksheet.getColumn(9).width = 8; // Liefermenge
  worksheet.getColumn(10).width = 8; // Lieferstatus
  worksheet.getColumn(11).width = 14; // Bemerkungen

  // Array.from(Array(maxOrderWorkStepsCount)).forEach((_, index) => {
  //   worksheet.getColumn(8 + index).width = 12;
  // });
  // worksheet.getColumn(
  //   8 + maxOrderWorkStepsCount
  // ).width = 10; // AB
  // worksheet.getColumn(
  //   9 + maxOrderWorkStepsCount
  // ).width = 9; // Best.-Nr.
  // worksheet.getColumn(
  //   10 + maxOrderWorkStepsCount
  // ).width = 13; // Bemerkungen

  const mergedRowsCount = 0;

  for (let i = 0; i < productList.length; i++) {
    const tableRow = worksheet.getRow(i + 2 + mergedRowsCount);
    // tableRow.height = 33;
    const product = productList[i];
    console.log("product: ", product);

    const kwLieferTerminCell = tableRow.getCell(1);
    kwLieferTerminCell.value = {
      richText: [
        {
          font: {
            size: 11,
          },
          text:
            utils.dates
              .getIsoWeekFromAWSDateFormat(product.createdAt)
              .toString() + " \r\n ",
        },
        {
          font: {
            size: 10,
          },
          text: utils.dates.getDateInGermanFromAWSDateFormat(product.createdAt),
        },
      ],
    };

    kwLieferTerminCell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };

    const artikelnameCell = tableRow.getCell(2);
    artikelnameCell.value = product.productDeName;
    artikelnameCell.alignment = {
      horizontal: "left",
      vertical: "middle",
      wrapText: true,
    };

    const artikelnrCell = tableRow.getCell(3);
    artikelnrCell.value = product.productEnName;
    artikelnrCell.alignment = {
      horizontal: "center",
      vertical: "middle",
    };

    const kundennameCell = tableRow.getCell(4);
    kundennameCell.value = product.expansionID;
    kundennameCell.alignment = {
      horizontal: "left",
      vertical: "middle",
      wrapText: true,
    };

    const arbeitsgaengeCell = tableRow.getCell(6);

    arbeitsgaengeCell.alignment = {
      horizontal: "left",
      vertical: "middle",
      wrapText: true,
      shrinkToFit: true,
    };
    arbeitsgaengeCell.font = {
      size: 8,
    };

    const abBestellNrCell = tableRow.getCell(
      7,
      // maxOrderWorkStepsCount
    );
    abBestellNrCell.value = {
      richText: [
        {
          font: {
            size: 11,
          },
          text: product.idProduct + " \r\n ",
        },
        {
          font: {
            size: 10,
          },
          text: product.countReprints.toString(),
        },
      ],
    };

    abBestellNrCell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };

    const bemerkungenCell = tableRow.getCell(
      11,
      // maxOrderWorkStepsCount
    );
    bemerkungenCell.value = product.productAlias
      ? product.productAlias
          .replaceAll("<p>", "")
          .replaceAll("</p>", "\r\n")
          .trim()
      : "";
    bemerkungenCell.alignment = {
      horizontal: "left",
      vertical: "middle",
      wrapText: true,
    };
    bemerkungenCell.font = {
      size: 7,
    };
  }

  for (let i = 1; i <= productList.length + 1; i++) {
    const tableRow = worksheet.getRow(i);

    for (let j = 1; j <= headers.length; j++) {
      const tableCell = tableRow.getCell(j);

      tableCell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: j === headers.length ? { style: "thin" } : undefined,
        bottom: i === productList.length + 1 ? { style: "thin" } : undefined,
      };
    }
  }

  const data = await workbook.xlsx.writeBuffer();

  const pdfBlob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename + ".xlsx"); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const tableListAsExcel = async (table: Table<any>, filename: string) => {
  const workbook = new ExcelJS.Workbook();

  workbook.creator = "Der andere Spieleladen";
  workbook.calcProperties.fullCalcOnLoad = true;

  const worksheet = workbook.addWorksheet("Preisliste ");
  worksheet.views = [{}];

  const headerGroups = table.getHeaderGroups();
  console.log("headerGroups: ", headerGroups);
  console.log("headerGroups.length: ", headerGroups.length);

  for (let i = 0; i < headerGroups.length; i++) {
    const row = worksheet.getRow(i + 1);
    const headerGroup = headerGroups[i];
    const headers = headerGroup.headers.filter(
      (header) =>
        header.column.id !== "select" &&
        header.column.columnDef.header !== "Aktionen",
    );

    let spans = 0;
    console.log("headerGroup.headers: ", headers);
    console.log("headerGroup.headers.length: ", headers.length);

    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      console.log("header: ", header.column.id);
      if (header.column.id === "select") {
        continue;
      }
      const headerName = header.column.columnDef.header;

      console.log(
        "Merging cell: ",
        i +
          1 +
          ":" +
          (j + 1 + spans) +
          " to " +
          (i + 1) +
          ":" +
          (j + header.colSpan + spans),
      );
      worksheet.mergeCells([
        i + 1,
        j + 1 + spans,
        i + 1,
        j + header.colSpan + spans,
      ]);
      const cell = row.getCell(j + 1 + spans);
      spans += header.colSpan - 1;
      console.log("Writing to cell: ", headerName);
      cell.value = headerName ? (headerName as string) : "";
      row.height = 33;
      cell.alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      cell.font = {
        size: 14,
        bold: true,
      };
    }
  }

  const rows = table.getIsSomeRowsSelected()
    ? table.getRowModel().rows.filter((row) => row.getIsSelected())
    : table.getRowModel().rows;

  console.log("rows.length: ", rows.length);

  for (let i = 0; i < rows.length; i++) {
    const tableRow = worksheet.getRow(i + 1 + headerGroups.length);
    const row = rows[i];

    console.log("row: ", row);
    const cells = row.getVisibleCells();

    console.log("cells: ", cells);

    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      if (cell.column.id === "select") {
        continue;
      }

      console.log("cell: ", cell);
      const tableCell = tableRow.getCell(j + 1);
      tableCell.value = cell.column.columnDef.meta?.getRawValue
        ? cell.column.columnDef.meta?.getRawValue(row)
        : "";
    }
  }

  worksheet.columns.forEach((column) => {
    if (!column.values) {
      return;
    }
    const lengths = column.values.map((v) =>
      v ? v.toString().length + 10 : 0,
    );
    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength;
  });

  const data = await workbook.xlsx.writeBuffer();

  const pdfBlob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};
