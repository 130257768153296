import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context";
import { Loading } from "core";
import { NewVersionTracker } from "components";
import { isAuthRoute } from "routes";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import useStyles from "./styles";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();

  const { pathname } = useLocation();

  const authRoute = isAuthRoute(pathname);

  useEffect(() => {
    const lockMenuFromLocalStorage = localStorage.getItem("lockMenu");
    if (lockMenuFromLocalStorage === "true") {
      authContext.setMenuOpen(true);
      authContext.setMenuLocked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("lockMenu", authContext.menuLocked ? "true" : "false");
    authContext.menuLocked && authContext.setMenuOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.menuLocked]);

  return (
    <div className={classes.App}>
      <NewVersionTracker />

      {!authRoute && <Sidebar />}

      <div
        className={cx(
          classes.dashboard,
          authContext.menuOpen ? "menuOpen" : "",
        )}
      >
        <Header layoutType={authRoute ? "authLayout" : "layout"} />
        <main
          className={
            authRoute
              ? classes.authContent
              : cx(
                  classes.contentContainer,
                  authContext.menuOpen
                    ? null
                    : classes.contentContainerMenuClosed,
                )
          }
        >
          <div className={classes.content}>
            {authContext.isLoading ? <Loading size="33px" /> : children}
          </div>
        </main>
      </div>
    </div>
  );
};
