import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllExpansionListShortened } from "modules/expansions/api";
import { useExpansionListFilterAndSortShortened } from "../useExpansionListFilterAndSortShortened";

export const useExpansionListShortened = (filter = true) => {
  const filterAndSortExpansions =
    useExpansionListFilterAndSortShortened(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ExpansionsShortened],
    queryFn: async () => {
      console.log("Called useExpansionListShortened");
      const expansionList = await getAllExpansionListShortened();

      return expansionList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const expansionList = useMemo(
    () => filterAndSortExpansions(data ?? []),
    [data, filterAndSortExpansions],
  );

  return {
    expansionList,
    isLoading,
  };
};
