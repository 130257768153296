import React from "react";
import { CustomLink } from "core";
import useStyles from "./styles";

type SellerUsernameColumnFormatterProps = {
  username: string;
};

export const SellerUsernameColumnFormatter: React.FC<
  SellerUsernameColumnFormatterProps
> = ({ username }) => {
  const { classes } = useStyles();

  return (
    <CustomLink
      link={"https://www.cardmarket.com/de/Magic/Users/" + username}
      linkText={username}
      className={classes.username}
    />
  );
};
