import { SpieleladenIcon } from "modules/icons";
import ProductListPage from "modules/products/productlist/ProductListPage";
import { USER_GROUPS } from "modules/roles/api";

export const products = {
  key: "products",
  path: "/products",
  section: "general",
  title: "Karten",
  view: <ProductListPage />,
  icon: <SpieleladenIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    product: {
      key: "product",
      path: "/products/:productID",
      section: "product",
      title: "Karte",
      view: <ProductListPage />,
      icon: <SpieleladenIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.EMPLOYEE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {},
    },
  },
};
