import React from "react";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { ErrorPage, MessagesProvider } from "./components";
import outputs from "../amplify_outputs.json";
import App from "./App.tsx";
import AuthContextProvider from "./context/auth-context";
import { SearchContextProvider } from "./context/search-context.tsx";
import "./index.css";
import { queryClient } from "./queryClient";
import theme from "./theme";

Sentry.init({
  dsn: "https://058f9388288a3399ab5446f11f8a887e@o4506835913015296.ingest.us.sentry.io/4506835914719232",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  release: import.meta.env.REACT_APP_SENTRY_RELEASE,
  enabled: import.meta.env.NODE_ENV !== "development",
  ignoreErrors: [
    "The user is not authenticated",
    "No current user",
    "Large Render Blocking Asset",
    "Incorrect username or password.",
  ],
  beforeBreadcrumb(breadcrumb) {
    if (
      breadcrumb.category === "ui.click" ||
      breadcrumb.category === "xhr" ||
      breadcrumb.category === "fetch"
    ) {
      return null;
    }
    return breadcrumb;
  },
  beforeSend(event, hint) {
    const originalException = hint.originalException as any;

    if (
      originalException &&
      "data" in originalException &&
      originalException.errors &&
      originalException.errors[0] &&
      originalException.errors[0].message
    ) {
      if (
        originalException.errors[0].message === "Request aborted" ||
        originalException.errors[0].message ===
          "Request failed with status code 401"
      ) {
        return null;
      }

      event.message = originalException.errors[0].message;
      const newException: Sentry.Exception = {
        type: "GraphQL Exception",
        value: originalException.errors[0].message,
      };

      event.exception = {
        ...event.exception,
        values: event.exception?.values
          ? [...event.exception.values, newException]
          : [newException],
      };

      event.extra = {
        ...event.extra,
        data: originalException.data,
        errorData: originalException.errors[0].data,
        errorInfo: originalException.errors[0].errorInfo,
        errorType: originalException.errors[0].errorType,
        locations: originalException.errors[0].locations,
        message: originalException.errors[0].message,
        path: originalException.errors[0].path,
      };

      event.tags = {
        ...event.tags,
        graphql: "true",
      };
    }

    return event;
  },
  maxBreadcrumbs: 10,
  normalizeDepth: 5,
});

Amplify.configure(outputs);

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Router>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <SearchContextProvider>
                <CssBaseline />
                <Sentry.ErrorBoundary
                  fallback={({ eventId }) => (
                    <ErrorPage
                      errorTitle="Oops!"
                      eventID={eventId}
                      errorMessage="Leider ist ein Fehler aufgetreten."
                    />
                  )}
                >
                  <MessagesProvider>
                    <App />
                  </MessagesProvider>
                </Sentry.ErrorBoundary>
              </SearchContextProvider>
            </AuthContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </Router>
  </React.StrictMode>,
);
