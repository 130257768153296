import moment from "moment";

export interface FilterField<T> {
  field: keyof T;
  value: string;
  comparisonType: "eq" | "gt" | "lt";
}

export const getComparisonType = (
  searchParams: URLSearchParams,
  field: string,
) => {
  const comparisonParamName = "comparison_" + field;
  const comparisonParamValue = searchParams.get(comparisonParamName);
  return comparisonParamValue === "gt" || comparisonParamValue === "lt"
    ? comparisonParamValue
    : "eq";
};

export const filterBySearchText = <T>(
  entityList: T[],
  fields: (keyof T)[],
  searchText: string,
) => {
  const searchTextLowerCase = searchText.toLowerCase();
  const searchTextParts = searchTextLowerCase.split(" ");

  return entityList.filter((entity) => {
    for (const field of fields) {
      const fieldValue = entity[field];
      if (typeof fieldValue !== "string") continue;

      const fieldValueLowerCase = fieldValue.toLowerCase();

      if (searchTextParts.length > 1) {
        const isMatch = searchTextParts.every((searchTextPart) =>
          fieldValueLowerCase.includes(searchTextPart),
        );

        if (isMatch) return true;
      } else {
        if (fieldValueLowerCase.includes(searchTextLowerCase)) return true;
      }
    }

    return false;
  });
};

export const filterByTextField = <T>(
  entityList: T[],
  field: keyof T,
  value: string,
) => entityList.filter((entity) => entity[field] === value);

export const filterByDateField = <T>(
  entityList: T[],
  field: keyof T,
  comparisonType: "eq" | "gt" | "lt",
  value: string,
) => {
  switch (comparisonType) {
    case "eq":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isSame(value, "day");
      });
    case "gt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isAfter(value);
      });
    case "lt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isBefore(value);
      });
    default:
      return entityList;
  }
};

export const filterByBooleanField = <T>(
  entityList: T[],
  field: keyof T,
  value: boolean,
) => entityList.filter((entity) => entity[field] === value);

export const filterByNumberField = <T>(
  entityList: T[],
  field: keyof T,
  comparisonType: "eq" | "gt" | "lt",
  value: number,
) => {
  switch (comparisonType) {
    case "eq":
      return entityList.filter((entity) => entity[field] === value);
    case "gt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "number") return false;

        return fieldValue > value;
      });
    case "lt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "number") return false;

        return fieldValue < value;
      });
    default:
      return entityList;
  }
};

export const filterEntities = <T>(
  entityList: T[],
  filterFields: FilterField<T>[],
  searchFields: (keyof T)[],
  searchText: string | null,
  filterByFieldFunction: (entityList: T[], filterField: FilterField<T>) => T[],
) => {
  const filteredBySearchText = searchText
    ? filterBySearchText<T>(entityList, searchFields, searchText)
    : entityList;

  return filterFields.reduce(
    (filteredEntities, filterField) =>
      filterField.value !== "all"
        ? filterByFieldFunction(filteredEntities, filterField)
        : filteredEntities,
    filteredBySearchText,
  );
};
