import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useExpansionUpdate, useProductUpdate } from "hooks";
import { Expansion } from "types";
import { Loading } from "core";
import { EyeLineIcon, EyeLineOffIcon } from "modules/icons";
import { getProductListByExpansion } from "modules/products/api";
import useStyles from "./styles";

type ExpansionActiveColumnFormatterProps = {
  expansion: Expansion;
};

export const ExpansionActiveColumnFormatter: React.FC<
  ExpansionActiveColumnFormatterProps
> = ({ expansion }) => {
  const { classes } = useStyles();

  const updateExpansionMutation = useExpansionUpdate();
  const updateProductMutation = useProductUpdate();

  const [isExpansionActive, setIsExpansionActive] = useState<boolean>(
    expansion.isExpansionActive,
  );

  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsExpansionActive(expansion.isExpansionActive);
  }, [expansion.isExpansionActive]);

  const expansionActiveHandler = async () => {
    setIsUpdateLoading(true);
    const newActiveState = !isExpansionActive;

    updateExpansionMutation.mutate({
      id: expansion.id,
      isExpansionActive: newActiveState,
    });

    const expansionProductList = await getProductListByExpansion(expansion.id);

    expansionProductList.forEach((product) => {
      updateProductMutation.mutate({
        id: product.id,
        expansionID: product.expansionID,
        isProductActive: newActiveState,
      });
    });

    setIsExpansionActive(newActiveState);
    setIsUpdateLoading(false);
  };

  return (
    <IconButton
      onClick={() => expansionActiveHandler()}
      className={classes.iconButton}
    >
      {isUpdateLoading ? (
        <Loading size="19px" style={{ margin: 0 }} />
      ) : isExpansionActive ? (
        <EyeLineIcon className={classes.active} />
      ) : (
        <EyeLineOffIcon className={classes.inactive} />
      )}
    </IconButton>
  );
};
