import { client } from "queryClient";
import { StockTask, TaskStatus } from "types";

export const getStockTaskList = async (
  token: string | null = null,
  loadAll = false,
): Promise<{
  newNextToken: string | null;
  newStockTaskList: StockTask[];
}> => {
  const stockTaskList: StockTask[] = [];
  let newNextToken = token;

  do {
    const { data, nextToken } = await client.models.StockTask.stockTasks(
      {
        taskType: "Stock",
      },
      newNextToken
        ? {
            limit: 50,
            nextToken: newNextToken,
            sortDirection: "DESC",
          }
        : {
            limit: 50,
            sortDirection: "DESC",
          },
    );

    stockTaskList.push(...data);

    newNextToken = nextToken ?? null;

    if (!loadAll) {
      return { newNextToken, newStockTaskList: data };
    }
  } while (loadAll && newNextToken !== null);

  return {
    newNextToken,
    newStockTaskList: stockTaskList,
  };
};

export const getStockTaskListByExpansion = async (
  expansionID: string,
  token: string | null = null,
  loadAll = false,
): Promise<{
  newNextToken: string | null;
  newStockTaskList: StockTask[];
}> => {
  const stockTaskList: StockTask[] = [];
  let newNextToken = token;

  do {
    const { data, nextToken } =
      await client.models.StockTask.stockTasksByExpansion(
        {
          expansionID: expansionID,
        },
        newNextToken
          ? {
              limit: 50,
              nextToken: newNextToken,
              sortDirection: "DESC",
            }
          : {
              limit: 50,
              sortDirection: "DESC",
            },
      );

    stockTaskList.push(...data);

    newNextToken = nextToken ?? null;

    if (!loadAll) {
      return { newNextToken, newStockTaskList: data };
    }
  } while (loadAll && newNextToken !== null);

  return {
    newNextToken,
    newStockTaskList: stockTaskList,
  };
};

export const getStockTaskListByStatus = async (
  status: TaskStatus,
): Promise<StockTask[]> => {
  const { data } = await client.models.StockTask.stockTasksByStatus(
    {
      status,
    },
    {
      limit: 50,
      sortDirection: "DESC",
    },
  );

  return data;
};

export const getRunningOrPendingStockTaskList = async (): Promise<
  StockTask[]
> => {
  const [runningStockTaskList, pendingStockTaskList] = await Promise.all([
    getStockTaskListByStatus("Running"),
    getStockTaskListByStatus("Pending"),
  ]);

  return [...runningStockTaskList, ...pendingStockTaskList];
};
