import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  iconButton: {
    padding: 0,
    "& svg": {
      height: 25,
      width: 25,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  inactive: {
    color: theme.palette.red.main,
  },
  aliasButton: {
    textDecoration: "none",
    fontSize: 14,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
    minWidth: "unset",
    lineHeight: "24px",
  },
  aliasButtonFilled: {
    color: theme.palette.primary.main,
  },
  aliasButtonEmpty: {
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    fontWeight: 400,
  },
}));

export default useStyles;
