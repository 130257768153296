import React, { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { Expansion, PriceModus } from "types";
import { DeleteDialog } from "components";
import { getExpansionName } from "modules/expansions/business";

type PriceModusDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  expansionList: Expansion[];
  priceModus?: PriceModus;
  setPriceModi?: (value: React.SetStateAction<PriceModus[]>) => void;
};

export const PriceModusDeleteDialog: React.FC<PriceModusDeleteDialogProps> = ({
  priceModus,
  dialogOpen,
  expansionList,
  setDialogOpen,
  setPriceModi,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const expansionName = useMemo(() => {
    if (!priceModus?.expansionID) return "Alle";

    const expansion = expansionList.find(
      (expansion) => expansion.id === priceModus.expansionID,
    );

    return expansion ? getExpansionName(expansion) : "Alle";
  }, [expansionList, priceModus?.expansionID]);

  if (!dialogOpen || priceModus === undefined) {
    return null;
  }

  const deletePriceModusRequest = async () => {
    if (!setPriceModi) {
      enqueueSnackbar(
        "Beim Löschen des Preismodus ist ein Fehler aufgetreten!",
      );
      return;
    }

    setDeleteLoading(true);

    setPriceModi((prev) =>
      prev.filter((priceModusItem) => priceModusItem.id !== priceModus.id),
    );

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deletePriceModusRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Preismodus Löschen"
      itemName={`Edition "${expansionName}", Rarity "${priceModus.rarity ?? "Alle"}", Foil "${priceModus.isFoil === true ? "Ja" : priceModus.isFoil === false ? "Nein" : "Alle"}", Preisfaktor Laden "${priceModus.priceFactorStore.toLocaleString(
        "de-DE",
      )}" und Preisfaktor Online "${priceModus.priceFactorOnline.toLocaleString(
        "de-DE",
      )}%"`}
      itemDeleteDescription="den Preismodus mit"
      confirmRequired={false}
    />
  );
};
