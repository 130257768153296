import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  noResults: {
    textAlign: "center",
    cursor: "default",
    lineHeight: "100px",
  },
  productItem: {
    // "& td": {
    //   border: "1px solid #E0E0E0",
    // },
  },
  productAlias: {
    padding: "0 !important",
    borderBottomWidth: "0 !important",
  },
  calcMethodButton: {
    padding: 0,
    "& svg": {
      height: 25,
      width: 25,
    },
    color: theme.palette.primary.main,
  },
  productName: {
    width: "370px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "5px",
  },
  numberColumn: {
    textAlign: "right",
    cursor: "pointer",
    paddingLeft: "7px !important",
  },
  numberColumnDefault: {
    color: theme.palette.primary.main,
    "&:hover": {
      // border: "1px solid " + theme.palette.primary.main + " !important",
      backgroundColor: "#ddf2eb !important",
    },
  },
  numberColumnError: {
    color: theme.palette.red.main,
    "&:hover": {
      backgroundColor: "#f9cbcb !important",
    },
  },
  numberColumnSelected: {
    padding: "0px !important",
    boxShadow: "0 0 5px rgba(183, 216, 224, 0.4) !important",
    border: "1px solid " + theme.palette.primary.main + " !important",
    backgroundColor: "#b3ead7 !important",
  },
  evenRowDefault: {
    backgroundColor: "#f7f9f9",
  },
  evenRowError: {
    backgroundColor: "#fdf6f6",
  },
  oddRowDefault: {
    backgroundColor: "#eff4f4",
  },
  oddRowError: {
    backgroundColor: "#f7ebeb",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  priceCalcDisabled: {
    color: theme.palette.primaryGrey.main,
  },
}));

export default useStyles;
