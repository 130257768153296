import React, { CSSProperties, FC, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { ValueSubmitTrigger } from "types";
import useStyles from "./styles";

type TableNumberFieldProps = {
  value: number | "";
  onValueChange: (value: number | "") => void;
  submitValue: (value: number | "", trigger: ValueSubmitTrigger) => void;
  id: string;
  autoFocus?: boolean;
  disabled?: boolean;
  decimalScale?: number;
  textAlign?: CSSProperties["textAlign"];
};

export const TableNumberField: FC<TableNumberFieldProps> = ({
  value,
  onValueChange,
  submitValue,
  id,
  autoFocus = false,
  disabled = false,
  decimalScale,
  textAlign = "left",
}) => {
  const { classes } = useStyles({ textAlign });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const triggerMapping: { [key: string]: ValueSubmitTrigger } = {
      Enter: ValueSubmitTrigger.Enter,
      Escape: ValueSubmitTrigger.Escape,
      Tab: event.shiftKey
        ? ValueSubmitTrigger.ShiftTab
        : ValueSubmitTrigger.Tab,
      ArrowUp: ValueSubmitTrigger.ArrowUp,
      ArrowDown: ValueSubmitTrigger.ArrowDown,
      ArrowLeft: ValueSubmitTrigger.ArrowLeft,
      ArrowRight: ValueSubmitTrigger.ArrowRight,
    };

    if (triggerMapping[event.key]) {
      event.preventDefault();
      event.stopPropagation();
      submitValue(value, triggerMapping[event.key]);
    }
  };

  return (
    <NumericFormat
      id={id}
      name={id}
      value={value}
      onValueChange={({ formattedValue, floatValue }) => {
        if (formattedValue === "," || formattedValue === "0,") return;
        onValueChange(floatValue ?? "");
      }}
      thousandSeparator="."
      decimalSeparator=","
      getInputRef={inputRef}
      decimalScale={decimalScale}
      fixedDecimalScale={false}
      autoFocus={autoFocus}
      onBlur={() => submitValue(value, ValueSubmitTrigger.Blur)}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      className={classes.input}
    />
  );
};
