import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys, client } from "queryClient";
import { Expansion } from "types";
import { getExpansionName } from "modules/expansions/business";

type ExpansionReadParamsType = {
  expansionID: string;
};

export const useExpansionRead = () => {
  const authContext = useAuthContext();

  const { expansionID } = useParams<ExpansionReadParamsType>();

  console.log("Loading expansion with ID: ", expansionID);

  const { data: expansion, isLoading } = useQuery({
    queryKey: [QueryKeys.Expansions, { id: expansionID }],
    queryFn: async () => {
      if (!expansionID) return null;

      const expansionResponse = await client.models.Expansion.get({
        id: expansionID,
      });

      const expansionFromAPI = expansionResponse.data;

      if (expansionFromAPI) {
        authContext.setCurrentData({
          id: expansionFromAPI.id,
          name: getExpansionName(expansionFromAPI),
        });

        if (!authContext.expansion) {
          authContext.setExpansion(expansionFromAPI);
        }
      }

      return expansionFromAPI ? (expansionFromAPI as Expansion) : null;
    },
  });

  return {
    expansionID,
    expansion: expansion ?? null,
    isLoading,
  };
};
