import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  username: {
    color: "#0240ca !important",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default useStyles;
