import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  dialogTitle: {
    marginTop: 0,
    marginBottom: 15,
  },
  instruction: {
    marginBottom: 20,
  },
  valueTable: {
    maxWidth: 400,
    fontSize: 16,

    "& tbody tr:first-child td": {
      borderTop: "1px solid #DAE5E3",
    },

    "& tbody tr td:first-child": {
      width: 300,
    },

    "& tbody tr td": {
      lineHeight: "49px",
    },
  },
}));

export default useStyles;
