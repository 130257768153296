import { client, publicClient } from "queryClient";
import {
  Product,
  ProductName,
  ProductSelectionSet,
  ProductShortened,
} from "types";
import utils from "utils";

export const getProductListByExpansion = async (
  expansionID: string,
  nextTokenParam: string | null = null,
  prevProductList: Product[] = [],
): Promise<Product[]> => {
  const { data, nextToken } = await client.models.Product.productsByExpansion(
    {
      expansionID,
    },
    {
      limit: 1000,
      nextToken: nextTokenParam,
    },
  );

  const productList = [...prevProductList, ...data];

  return nextToken
    ? getProductListByExpansion(expansionID, nextToken, productList)
    : productList;
};

export const getProductListByExpansionShortened = async (
  expansionID: string,
  nextTokenParam: string | null = null,
  prevProductList: ProductShortened[] = [],
): Promise<ProductShortened[]> => {
  const { data, nextToken } =
    await publicClient.models.Product.productsByExpansion(
      {
        expansionID,
      },
      {
        limit: 1000,
        nextToken: nextTokenParam,
        selectionSet: ProductSelectionSet,
      },
    );

  const productList = [...prevProductList, ...data];

  return nextToken
    ? getProductListByExpansionShortened(expansionID, nextToken, productList)
    : productList;
};

export const getSmallProductListByExpansion = async (
  expansionID: string,
): Promise<Product[]> => {
  const { data } = await client.models.Product.productsByExpansion(
    {
      expansionID,
    },
    {
      limit: 10,
    },
  );

  return data;
};

export const getAllProductList = async (
  isProductActive: boolean | null = true,
  nextTokenParam: string | null = null,
  prevProductList: Product[] = [],
): Promise<Product[]> => {
  const { data, nextToken } = await client.models.Product.list({
    limit: 15000,
    nextToken: nextTokenParam,
    filter:
      isProductActive === null
        ? undefined
        : {
            isProductActive: {
              eq: isProductActive,
            },
          },
  });

  const productList = [...prevProductList, ...data];

  return nextToken
    ? getAllProductList(isProductActive, nextToken, productList)
    : productList;
};

export const getProduct = async (id: string): Promise<Product | null> => {
  const { data } = await client.models.Product.get({
    id,
  });

  return data;
};

export const getProductShortened = async (
  id: string,
): Promise<ProductShortened | null> => {
  const { data } = await publicClient.models.Product.get(
    {
      id,
    },
    {
      selectionSet: ProductSelectionSet,
    },
  );

  return data;
};

export const getProductListByIDs = async (
  idList: string[],
): Promise<Product[]> => {
  if (idList.length === 0) {
    return [];
  }

  const productList: Product[] = [];

  const idListChunks = utils.general.sliceIntoChunks(idList, 50);

  for (const idListChunk of idListChunks) {
    const getProductPromises = idListChunk.map((id) => getProduct(id));

    const productListChunk = await Promise.all(getProductPromises);

    const definedProductListChunk =
      utils.graphql.getDefinedItems(productListChunk);

    productList.push(...definedProductListChunk);
  }

  return productList;
};

export const getProductListByIDsShortened = async (
  idList: string[],
): Promise<ProductShortened[]> => {
  if (idList.length === 0) {
    return [];
  }

  const productList: ProductShortened[] = [];

  const idListChunks = utils.general.sliceIntoChunks(idList, 50);

  for (const idListChunk of idListChunks) {
    const getProductPromises = idListChunk.map((id) => getProductShortened(id));

    const productListChunk = await Promise.all(getProductPromises);

    const definedProductListChunk =
      utils.graphql.getDefinedItems(productListChunk);

    productList.push(...definedProductListChunk);
  }

  return productList;
};

export const getProductNamesList = async (
  isProductActive: boolean | null = null,
): Promise<ProductName[]> => {
  const productNames: ProductName[] = [];
  let token = null;

  do {
    const { data } = await client.queries.listProductNames({
      isProductActive,
      limit: 30000,
      token,
    });

    if (data) {
      productNames.push(...data.productList);
      token = data.nextToken;
    }
  } while (token);

  return productNames;
};

export const getProductNamesListShortened = async (
  isProductActive: boolean | null = null,
): Promise<ProductName[]> => {
  const productNames: ProductName[] = [];
  let token = null;

  do {
    const { data } = await publicClient.queries.listProductNames({
      isProductActive,
      limit: 30000,
      token,
    });

    if (data) {
      productNames.push(...data.productList);
      token = data.nextToken;
    }
  } while (token);

  return productNames;
};
