import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { Product } from "types";
import { getProductListByExpansion } from "modules/products/api";
import { useProductListFilterAndSort } from "../useProductListFilterAndSort";

type ExpansionProductListParams = {
  expansionID: string;
};

export const useExpansionProductList = (
  filter = true,
  useAlias = false,
  expansionIDParam?: string,
) => {
  const filterAndSortProducts = useProductListFilterAndSort(filter, useAlias);

  const { expansionID } = useParams<ExpansionProductListParams>();

  useEffect(() => {
    const createSub = client.models.Product.onCreate().subscribe({
      next: (data) => {
        console.log("Product.onCreate: ", data);
        queryClient.setQueryData(
          [QueryKeys.ExpansionProducts, expansionIDParam ?? expansionID],
          (prevData: Product[]) => {
            if (prevData.some((product) => product.id === data.id))
              return prevData;

            return [...prevData, data];
          },
        );
      },
      error: (error) => console.error(error),
    });

    const updateSub = client.models.Product.onUpdate().subscribe({
      next: (data) => {
        console.log("Product.onUpdate: ", data);
        queryClient.setQueryData(
          [QueryKeys.ExpansionProducts, expansionIDParam ?? expansionID],
          (prevData: Product[]) => {
            return prevData.map((product) =>
              product.id === data.id ? data : product,
            );
          },
        );
      },
      error: (error) => console.warn(error),
    });

    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
    };
  }, [expansionID, expansionIDParam]);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.ExpansionProducts, expansionIDParam ?? expansionID],
    queryFn: async () => {
      const id = expansionIDParam ?? expansionID;
      if (!id) return [];

      const expansionProductList = await getProductListByExpansion(id);

      console.log("expansionProductList: ", expansionProductList);

      return expansionProductList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const expansionProductList = useMemo(
    () => filterAndSortProducts(data ?? []),
    [filterAndSortProducts, data],
  );

  console.log("expansionProductList: ", expansionProductList);

  return {
    expansionProductList,
    isLoading,
  };
};
