import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import { useGlobalSettingsRead, useGlobalSettingsUpdate } from "hooks";
import { client } from "queryClient";
import { CustomButton, LabeledTypography, Loading } from "core";
import { EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { CardmarketCredentialListTable } from "modules/globalsettings/cardmarketcredentials/cardmarketcredentiallist/CardmarketCredentialListTable";
import { ExcludedSellerListTable } from "modules/globalsettings/excludedsellers/excludedsellerlist/ExcludedSellerListTable";
import { MinPriceListTable } from "modules/globalsettings/minprices/minpricelist/MinPriceListTable";
import { PriceListStepListTable } from "modules/globalsettings/priceliststeps/priceliststeplist/PriceListStepListTable";
import { PriceModusListTable } from "modules/globalsettings/pricemodi/pricemoduslist/PriceModusListTable";
import { getAverageCalculationMethodName } from "modules/shared/options/AverageCalculationMethodOption";
import { Routes } from "routes";
import utils from "utils";
import useStyles from "./styles";

const GlobalSettingsPage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuthContext();

  const { globalSettings, isLoading } = useGlobalSettingsRead();

  const updateGlobalSettingsMutation = useGlobalSettingsUpdate();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [syncOldToolLoading, setSyncOldToolLoading] = useState<boolean>(false);

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung">
        <Loading
          description="Bitte warten. Einstellungen werden geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (!globalSettings) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung">
        Beim Laden der Einstellungen ist ein Fehler aufgetreten!
      </BoxHeadlineContainer>
    );
  }

  const changeCurrentVersionHandler = async () => {
    setSubmitLoading(true);

    await updateGlobalSettingsMutation.mutateAsync({
      id: globalSettings.id,
      currentVersion: utils.identify.getUUID(),
    });

    setSubmitLoading(false);
  };

  const syncOldToolHandler = async () => {
    setSyncOldToolLoading(true);

    const { data, errors } = await client.queries.synchronizeOldTool();

    if (errors) {
      const errorType = errors[0].errorType;
      if (errorType === "Lambda:ExecutionTimeoutException") {
        enqueueSnackbar(
          "Altes Tool erfolgreich synchronisiert! Dauert etwa 3 Minuten bis alle Daten übertragen wurden.",
          {
            variant: "success",
          },
        );
      } else {
        enqueueSnackbar("Fehler beim Synchronisieren des alten Tools");
      }
    } else if (!data || data.status !== "success") {
      enqueueSnackbar("Fehler beim Synchronisieren des alten Tools");
    } else {
      enqueueSnackbar("Altes Tool erfolgreich synchronisiert!", {
        variant: "success",
      });
    }

    setSyncOldToolLoading(false);
  };

  console.log("userData: ", userData);

  return (
    <>
      <BoxHeadlineContainer
        boxTitle="Preisberechnung"
        marginTop={false}
        boxMenu={
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                Routes.globalsettings.subNavigations.globalsettingsedit.path,
              )
            }
            size="small"
            color="blue"
            rootClassName={classes.editButton}
          />
        }
      >
        {userData?.email === "oe-bayram@live.de" && (
          <Grid container direction="row">
            <Grid item className={classes.gridItem}>
              <CustomButton
                text="Altes Tool synchronisieren"
                onClick={() => syncOldToolHandler()}
                loading={syncOldToolLoading}
                size="small"
              />
            </Grid>

            <Grid item className={classes.gridItem}>
              <CustomButton
                text="LocalStorage leeren"
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                size="small"
              />
            </Grid>
            <Grid item className={classes.gridItem}>
              <CustomButton
                text="Aktuelle Version ändern"
                onClick={() => changeCurrentVersionHandler()}
                size="small"
                loading={submitLoading}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <LabeledTypography
              label="Mindestanzahl an gewerblichen Artikeln"
              content={globalSettings.minCommercialArticles}
            />
          </Grid>
          <Grid item md={6}>
            <LabeledTypography
              label="Durchschnitts-Berechnungsmethode"
              content={getAverageCalculationMethodName(
                globalSettings.averageCalculationMethod,
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <LabeledTypography
              label="Preisberechnung Ignorieren"
              content={globalSettings.skipPriceCalcString}
            />
          </Grid>
          <Grid item md={6}>
            <LabeledTypography
              label="Cut Off Prozent"
              content={globalSettings.priceListCutOffPercentage + " %"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={4}
          className={classes.gridRow}
        >
          <Grid item md={6}>
            <LabeledTypography
              label="Preisaufschlag auf Privatpreise"
              content={globalSettings.priceSurchargePrivate + " %"}
            />
          </Grid>
          <Grid item md={6}>
            <LabeledTypography
              label="Preisschwelle für Ordnerwechsel"
              content={globalSettings.priceTresholdFolderChange + " €"}
            />
          </Grid>
        </Grid>
      </BoxHeadlineContainer>

      <ExcludedSellerListTable
        excludedSellers={globalSettings.excludedSellers.sort((a, b) =>
          a.username.localeCompare(b.username),
        )}
        editable={false}
      />

      <MinPriceListTable
        minPricesEnabled={globalSettings.minPricesEnabled}
        minPrices={globalSettings.minPrices.sort(
          (a, b) => a.priority - b.priority,
        )}
        editable={false}
      />

      <PriceModusListTable
        priceModi={globalSettings.priceModi.sort(
          (a, b) => a.priority - b.priority,
        )}
        editable={false}
      />

      <PriceListStepListTable
        priceListSteps={globalSettings.priceListSteps.sort((a, b) => a - b)}
        editable={false}
      />

      <BoxHeadlineContainer boxTitle="Editions-Icons URL">
        <Grid container direction="row">
          <Grid item className={classes.gridItem}>
            <LabeledTypography
              label="URL für Editions-Icons"
              content={globalSettings.expansionIconsURL}
            />
          </Grid>
        </Grid>
      </BoxHeadlineContainer>

      <CardmarketCredentialListTable
        title="Cardmarket Zugänge für manuelle Anfragen"
        editable={false}
        cardmarketCredentials={globalSettings.cardmarketCredentialsManually}
      />

      <CardmarketCredentialListTable
        title="Cardmarket Zugänge für automatische Anfragen"
        editable={false}
        cardmarketCredentials={globalSettings.cardmarketCredentialsScheduled}
      />
    </>
  );
};

export default GlobalSettingsPage;
